export default {
    stations: `ステーション`,
    filter: `ステーションのフィルター`,
    comment: `コメント`,
    comment_add: "コメントを追加",
    comment_edit: "コメントを編集",
    sortDate: `作成日でソート`,
    checkbox: `選択`,
    select: `選択`,
    actions: `アクション`,
    showStation: `ステーションを表示`,
    delete: `削除`,
    number: `番号`,
    name: `名前`,
    bootTimestamp: `起動タイムスタンプ`,
    bootTimestampM: `起動`,
    cpuUsageTotal: `CPU使用率, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `メモリ使用率, %`,
    memoryPercentM: `メモリ`,
    ipAddress: `IPアドレス`,
    ipAddressM: `IP`,
    disks: `利用可能なディスク`,
    disksM: `ディスク`,
    updatedAt: `更新日時`,
    system: `システム`,
    release: `リリース`,
    stationSelect: `ステーションの選択`,
    stationsDel: `削除されたステーション`,
    stationDel: `削除されたステーション`,
    downloadClient: `クライアントのダウンロード`,
    download: `ダウンロード`,
    online: `オンライン`,
    offline: `オフライン`,

    toDo: `未完了`,
    done: `完了`,
    error: `エラー`,
    canceled: `キャンセル`,

    info: `情報`,
    power: `電源`,
    process: `プロセス`,
    programs: `プログラム`,
    devices: `デバイス`,
    commands: `コマンド`,
    other: `その他`,

    reboot: `再起動`,
    shutdown: `シャットダウン`,
    sleep: `スリープ`,
    hibernate: `休止状態`,
    lock: `ロック`,

    refill: `補充`,
    writeOff: `出荷`,
    withdrawal: `引き出し`,
    dateUpdate: `更新日時`,
    autoUpdate: `自動更新`,
    autoUpdateM: `自動更新`,
    commandError: `コマンド作成エラー`,
    commandSend: `コマンド送信済み`,

    visualControl: `ビジュアルコントロール`,
    doCommand: `コマンドを実行`,
    viewStation: `ステーションの表示`,
    metaDesc: `リモートアクセスとコンピューターおよびサーバーの制御へのアクセス`,
    confirmAction: `アクションの確認`,
    confirmActionText: `コマンド「{{cmd}}」を実行してもよろしいですか？
アクションボタンを押す必要がある場合があります。`,
    waitingExe: `実行待ち...`,
    successEmpty: `成功しましたが、レスポンスは空です`,
    resultExe: `実行結果`,
    enterCommand: `コマンドを入力`,
    exeCustomCommand: `カスタムコマンドを実行`,
    enterEnable: `ENTERキーで送信を有効にする`,
    visualControlStation: `ステーションのビジュアルコントロール`,
    add: `ステーションを追加`,
    password: `パスワード`,
    stationAdded: `ステーションが追加されました`,
    passwordLength: `パスワードは6文字以上である必要があります`,

    cmd: {
        status: `ステータス`,
        type: `タイプ`,
        createdAtS: `作成日時`,
        checkbox: `選択`,
        remove: `削除`,
        createdAt: `日時`,
        raw: `データ`,
        user: `ユーザー`,
        result: `結果`,
        error: `エラー`,
        filterName: `コマンドのフィルター`,
        removeE: `コマンドの削除エラー`,
        removeS: `削除が完了しました`,
    },

    devs: {
        name: `名前`,
        group: `グループ`,
        status: `ステータス`,
        manufacturer: `製造元`,
        deviceID: `デバイスID`,
        driver: `ドライバー`,
        nameS: `名前順`,
        groupS: `グループ順`,
        filterName: `デバイスのフィルター`,
        dateUpdate: `更新日時`,
    },

    inf: {
        group: `グループ`,
        name: `名前`,
        value: `値`,

        general: `一般情報`,
        node: `コンピューター名`,
        bootTimestamp: `起動タイムスタンプ`,
        updatedAt: `データの最終更新時刻`,
        system: `システム`,
        release: `リリース`,
        version: `バージョン`,
        programVersion: `プログラムバージョン`,
        memory: `メモリ`,
        memoryTotal: `総容量`,
        memoryAvailable: `利用可能`,
        memoryUsed: `使用済み`,
        memoryPercent: `メモリ使用率, %`,
        swapTotal: `スワップ総容量`,
        swapFree: `利用可能なスワップ`,
        swapUsed: `使用済みスワップ`,
        swapPercent: `スワップ使用率, %`,
        processor: `プロセッサ`,
        processorVendor: `メーカー`,
        processorBrand: `ブランド`,
        cpuUsageTotal: `総CPU使用率, %`,
        cpuCores: `コア数`,
        cpuTotalCores: `スレッド数`,
        cpuFreqCurrent: `現在のクロック周波数`,
        net: `ネットワーク`,
        ipAddress: `IPアドレス`,
        macAddress: `MACアドレス`,
        interfaces: `ネットワークインターフェース`,
        totalSend: `送信データ量合計`,
        totalReceived: `受信データ量合計`,
        persistent: `永続メモリ`,
        disks: `ディスク`,
        dateUpdate: `更新日時`,
        autoUpdate: `自動更新`,
        free: `空き`,
    },

    proc: {
        name: `名前`,
        exe: `パス`,
        username: `ユーザー名`,
        pidS: `プロセスID`,
        nameS: `名前順`,
        cpuPercent: `CPU使用率, %`,
        memoryPercent: `メモリ使用率, %`,
        createTimeS: `作成日時順`,
        usernameS: `ユーザー名順`,
        checkbox: `選択`,
        finishP: `プロセスを終了`,
        finishPs: `複数のプロセスを終了`,
        pid: `プロセスID`,
        createTime: `作成日時`,
        diskUsageRead: `ディスク読み取り`,
        diskUsageReadTotal: `総ディスク読み取り`,
        diskUsageWrite: `ディスク書き込み`,
        diskUsageWriteTotal: `総ディスク書き込み`,
        status: `ステータス`,
        filterName: `プロセスのフィルター`,
    },

    prog: {
        startRemove: `プログラムの削除を開始`,
        name: `名前`,
        installDate: `インストール日時`,
        version: `バージョン`,
        size: `サイズ`,
        description: `説明`,
        publisher: `発行元`,
        location: `インストール場所`,
        uninstall: `アンインストール`,
        depends: `依存関係`,
        nameS: `名前順`,
        installDateS: `インストール日時順`,
        filterName: `プログラムのフィルター`,
        confirmAction: `アクションの確認`,
        confirmActionText: `"{{name}}" の削除を実行してもよろしいですか？
アクションボタンを押す必要がある場合があります。`,
    },

    control:{
        prepare: `準備中...`,
        connecting: `接続中`,
        connected: `接続済み`,
        disconnected: `切断済み`,
        error: `エラー`,
        tryAgain: `再試行`,
        zoom: `拡大`,
        zoomOut: `縮小`,
        showAllButtons: `全てのボタンを表示`,
        display: `ディスプレイ`,
        size: `サイズ`,
        keys: `キー`,
        showHideKey: `キーボードの表示/非表示`,
        fitScreen: `画面に合わせる`,
        fullscreen: `フルスクリーン`,
        tune: `カスタマイズ`,
        cursorMove: `カーソルの移動`,
        move: `移動`,
        click: `クリック`,
    },

    aiControl: 'AI制御',
    aiControlStation: 'AI制御ステーション',
    ai: {
        title: 'AIステーション制御',
        autoApply: '自動的にコマンドを適用する',
        autoContinueError: 'エラーを続行して処理する',
        send: '送信（Ctrl + Enter）',
        input: 'メッセージを入力',
        errorLength: 'メッセージが短すぎます',
        process: '実行中',
        success: '成功',
        error: 'エラー',
        showMore: '詳細を表示',
        continue: '続行',
        doCommand: 'コマンドを実行する',
        commandAny: '任意のコマンドを実行する',
        refine: '回答を改善する',
        newQuery: '新しいクエリ',
        auto: '自動',
        model: 'AIモデル',
        tokensLeft: 'トークン残り：{{value}}',
        allUsers: 'すべてのユーザー',
        tokenHelp: 'AIモデルに送信されるテキストはトークンに分割されます。トークンは、言語や文脈に応じて、単一の文字、単語、または単一の文になる場合があります。たとえば、テキスト「ChatGPT is great!」は、次のトークンに分割される可能性があります：["Chat", "G", "PT", " is", " great", "!"]。',
        enableHTML: 'HTMLで結果を表示する',
        userTokens: '現在のメッセージで使用されているトークンの数。異なる文脈に関連する場合は、新しいクエリを作成することをお勧めします。',
        inProgress: '情報を処理中...',
        previewCommand: '次のコマンドが実行されます',
        "userToken": "ユーザーAPIキー",
        "getToken": "キーを取得"
    },
};

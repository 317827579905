export default {
    nickname: `اسم المستخدم`,
    address: `عنوانك`,
    daysInMonth: `بناءً على شهر من 30 يومًا`,
    currentBalance: `الرصيد الحالي`,
    stationCount: `عدد المحطات`,
    expensesMonth: `المصاريف في الشهر`,
    enoughBalance: `الرصيد كافٍ`,
    currentTariff: `التعرفة الحالية`,
    optionPay: `دفع اختياري`,
    stationPerMonth: `لكل محطة في الشهر`,
    payFirstStations: `دفع لأول 5 محطات`,
    notSelect: `لم يتم التحديد`,
    changeTariff: `تغيير التعرفة`,
    createTariff: `إنشاء التعرفة`,
    fieldsCalc: `حقول الحساب`,
    stations: `المحطات`,
    mounths: `الشهور`,
    changeBalanceThis: `استبدل الرصيد بهذا المبلغ`,
    total: `الإجمالي`,
    addBalance: `إضافة رصيد`,
    summForAdd: `المبلغ المضاف، $`,
    pushToCopy: `انقر للنسخ إلى الحافظة`,
    textCopied: `تم نسخ النص إلى الحافظة`,
    createBill: `إنشاء فاتورة`,
    cancelBill: `إلغاء الفاتورة`,
    addressToSend: `عنوان الدفع`,
    or: `أو`,
    billPartner: `فاتورة للدفع لدى الشريك`,
    go: `اذهب`,
    textBalance: `تعليمات الدفع الموجزة (بواسطة بطاقة الائتمان أو طرق أخرى)
    <ol>
    <li>أنشئ فاتورة في هذه الصفحة</li>
    <li>قم بتحويل الأموال
    <ul>
    <li>بشكل مباشر - إذا كان لديك محفظة إلكترونية بعملات USDT bep20.</li>
    <!--<li>ادفع الفاتورة المستحقة للشريك - قد يفرض الشريك عمولة تبعاً لطريقة الدفع.</li>-->
    <li>من خلال صراف العملات، على سبيل المثال، عن طريق اختياره على <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> (بدون عمولة):
    <ol>
    <li>انتقل إلى <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">صفحة التجميع</a></li>
    <li>في عمود "يعطي" ، اختر طريقة الدفع المناسبة ، مثل "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">فيزا / ماستركارد</a>".
أوصي باختيار طريقة الدفع في هذا العمود بأقل سعر فائدة لبلدك (حوالي 3-5٪) ، عادةً أحد البنوك الشهيرة بالعملة المحلية ، وليس التحويل المباشر بالدولار الأمريكي.</li>
    <li>في عمود "يتلقى" - يجب أن يكون Tether bep20</li>
    <li>اختر صراف العملات الذي يقدم أفضل سعر أو شروط</li>
    <li>على موقع صراف العملات ، قم بتحديد المبلغ الذي أدخلته للتعبئة بالدولار ، انسخ عنوان التعبئة الخاص بك (أعلاه) ، واملأ الحقول الأخرى ، وقم بتحويل المبلغ</li>
    <li>يتم معالجته من قبل صراف العملات لمدة 20 دقيقة ، ثم بواسطة خدمتي</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>بعد استلام الأموال ، سيتم تحديث البيانات على هذه الصفحة تلقائيًا. إذا لم يتم دفع الفاتورة في غضون 24 ساعة ، سيتم إلغاؤها تلقائيًا.</p>
    يتم خصم الدفعة الشهرية للتعرفة يوميًا ، استنادًا إلى العدد الأقصى للمحطات خلال اليوم.
    <p>إذا واجهت أي مشاكل ، يرجى مراسلتي على
    <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
    <p>إذا كان لديك أي ميزات إضافية تحتاجها ، يرجى كتابتها في
    <a href="{{tg_idea}}" target="_blank" class="link">قسم أفكار المشروع</a>.
    سيتم تنفيذ الفكرة على الفور أو وضعها للتصويت من قبل أعضاء المجموعة ، وسيتم اتخاذ قرار بالتطوير. شكراً لمشاركتك مقدمًا!</p>`,

    textTariffCost: `يسمح لك الخدمة بتحديد تكلفة الخدمات الشهرية بنفسك ، ويعتبر هذا الخيار أيضًا تقييمًا لفكرة هذا المشروع. كلما زاد المبلغ الذي تحدده ، كلما تحسن هذا المشروع ، مع خوادم أقوى ، وأقل عدد من الأخطاء ، وواجهة تفاعلية أكثر سهولة للاستخدام ، وتفاعل أسرع. الأهم من ذلك ، ستتم إدخال ميزات جديدة لتسهيل إدارة المحطات الخاصة بك.`,

    textTariffFirstStations: `إذا كنت ترغب في دعم المشروع إلى درجة تكون حتى على استعداد لدفع ثمن المحطات المجانية بالنسبة لك ، فأنت رائع بشكل لا يصدق!`,

    textBillAmount: `لتحديد الدفعة تلقائيًا وربطها بحسابك ، يجب أن تدخل المبلغ مسبقًا والتأكد من أنه محدد بالضبط حتى السنت عند تحويل الأموال. يمكن إضافة أرقام عشرية إلى المبلغ إذا كان في انتظار الدفع في قاعدة البيانات.`,
    textBillAddress: `يتم إنشاؤها تلقائيًا عند إنشاء الفاتورة. يجب توفيرها لأي طريقة دفع ، انتبه إلى نوع الشبكة "USDT bep20" (Binance)
    - سيتم طلبه منك عند تقديم طلب لأي خدمة تبادل عملة مشفرة.`,
    textBillPartner: `وسيلة أخرى هي الدفع لدى الشريك ، تم إنشاء الفاتورة للمبلغ المحدد بالفعل ، ما عليك سوى اتباع
    الرابط وتحويل الأموال بالطريقة المريحة.`,

    billCreated: `تم إنشاؤها`,
    billPartPaid: `تم الدفع جزئياً`,
    billPaid: `تم الدفع`,
    billCancel: `تم الإلغاء`,
    billNum: `فاتورة رقم`,
    tariffUpdated: `تم تحديث التعرفة`,
    billCreatedFull: `تم إنشاء الفاتورة`,
    billPaidFull: `تم الدفع`,
    billCancelFull: `تم الإلغاء`,
    errorSaveUserEmpty: `خطأ في حفظ المستخدم - استجابة فارغة`,
    settings: `الإعدادات`,
    tariff: `التعرفة والدفع`,
    transactions: `المعاملات`,

    type: `النوع`,
    createdAt: `التاريخ`,
    value: `القيمة`,
    billString: `الفاتورة`,
    tariffString: `التعرفة`,
    stationsPay: `المحطات`,

    sortDateCreate: `حسب تاريخ الإنشاء`,
    transactionFilter: `مرشح المعاملات`,
};

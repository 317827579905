import {action, makeObservable, observable, runInAction} from "mobx";
import Helper from "../../utils/helper";
import NotifyService from "../../utils/notify.service";
import LANG from "../../lang/language";
import APIService from "../../utils/api.service";
import {constants} from "../../utils/constants";
import UserServiceStore from "../userServiceStore";


class ChatGPTControlStore {
    stationId: any
    @observable entityEdited: any
    isModal = true

    pathLang = ['station', 'ai']

    @observable messages: any = []
    @observable messageAI: number
    @observable blockInput = false
    @observable isSettingsOpen = false;
    @observable tokensLeft

    @observable settings = {
        model: constants.CHATGPT_3_5_TURBO,
        autoApply: false,
        autoContinueError: false,
        enableHTML: false,
        userToken: '',
    };

    scrollToBottom = true;

    constructor({stationId, isModal}: any) {
        makeObservable(this);

        this.stationId = stationId;
        this.entityEdited = {
            //input: `Создай файл в папке tmp в корне диска с именем "СтэтхемЛучшее" и вставь туда любые пацанские цитаты`,
            //input: `Очисти и перезапусти очередь печати`,
            //input: `Создай задание в планировщике задач, которое каждый час выводит сообщение с напоминанием отдохнуть`,
            //input: 'Пришли html фрагмент любого видео, которое ты знаешь из youtube. Без комментариев. ',
            //input: `Перезапусти сетевые подключения`,
            //input: `Сделай крутой сайт в папке C:\\\\tmp\\cool-site, что бы там можно было грабить корованы!`,
            input: ''
        };
        if (UserServiceStore.getInstance().settings.ai)
            this.settings = UserServiceStore.getInstance().settings.ai
        this.isModal = isModal;
    }

    onMount() {
        // if (this.messages.length == 0)
        //     this.sendQuery()
    }

    onUnmount() {

    }

    @action
    changeItem(el, name: string, value: any) {
        el[name] = value
    }

    getFields(fields, item, blockKey, props) {
        let fieldsLine = fields?.join('_');
        let fieldParent = item || this;
        let res;

        for (let i = 0; i < fields.length; i++) {
            let name = fields[i];
            if (Helper.isEmpty(fieldParent)) {
                break;
            }
            if (i === fields.length - 1) {
                res = fieldParent[name];
                if (props?.index != undefined)
                    res = res[props.index]
            } else {
                fieldParent = fieldParent[name];
            }
        }

        if (fields[0] == 'settings') {
            UserServiceStore.getInstance().save()
        }

        return res;
    }

    @action
    changeFields(fields, value, item?, blockKey?, props?): any {
        let fieldsLine = fields?.join('_');
        let fieldParent = item || this;
        for (let i = 0; i < fields.length; i++) {
            let name = fields[i];
            if (i === fields.length - 1) {
                if (props?.index != undefined)
                    fieldParent[name][props.index] = value
                else
                    fieldParent[name] = value;

                /*  switch (name) {

                  }*/
            } else {
                fieldParent = fieldParent[name];
            }
        }
    }

    @action
    async sendQuery() {
        let text = this.entityEdited.input;
        if (text?.length < 1)
            return NotifyService.error([...this.pathLang, 'errorLength'])

        let messageH: ChatMessage = {
            role: MessageRole.User,
            content: text,
            _text: text,
            _date: Date.now(),
            _tokens: 0
        }
        this.addMessage(messageH)

        let messageAI: ChatMessage = {
            role: MessageRole.AI,
            _status: MessageAIStatus.created,
            _date: Date.now() + 1,
            _tokens: 0
        }
        this.addMessage(messageAI)
        this.messageAI = messageAI._date

        this.entityEdited.input = ''

        await this.sendMessages()
    }

    @action
    async sendMessages() {

        let messages = []

        // convert to chat for AI
        for (const message of this.messages) {
            switch (message.role) {
                case MessageRole.System:
                case MessageRole.User:
                    messages.push(Helper.makeClone(message))
                    break
                case MessageRole.AI:
                    if (message._status != MessageAIStatus.created) {
                        messages.push(Helper.makeClone(message))
                        if (message._function_answer && message._function_answer?.content)
                            messages.push(Helper.makeClone(message._function_answer))
                    }
                    break
            }
        }

        this.blockInput = true
        console.debug('messages', messages)
        let answer: any = await APIService.getInstance().getAIComplete({
            stationId: this.stationId,
            model: this.settings.model,
            userToken: this.settings.userToken,
            language: LANG.getInstance().lang,
            messages: messages,
        });

        console.debug('answer', answer)
        let result = answer.result
        let error = answer.error
        let tokens = answer.tokens

        this.blockInput = false

        if (error) {
            return NotifyService.error(error)
        }

        runInAction(() => {
            if (answer.system)
                this.messages.unshift(answer.system)

            let messageAI: ChatMessage

            if (this.messageAI) {
                messageAI = this.messages.find(el => el._date == this.messageAI)
            } else {
                this.addMessage({
                    role: MessageRole.AI,
                    //_status: MessageAIStatus.done,
                    _date: Date.now()
                })
                messageAI = this.messages[this.messages.length - 1]
                this.messageAI = messageAI._date
            }

            messageAI.content = result.content
            messageAI._text = result.content
            messageAI._tokens = tokens?.use
            messageAI.function_call = result.function_call

            if (tokens?.left)
                this.tokensLeft = tokens?.left

            if (messageAI.function_call) {

                let name = messageAI.function_call.name;
                let args
                try {
                    messageAI.function_call.arguments = messageAI.function_call.arguments.replaceAll('\\ ', ' ')
                    args = JSON.parse(messageAI.function_call.arguments)
                } catch (e) {
                    console.error(e)
                    let messageError = Helper.getMessageError(e);
                    messageAI._function_answer = {
                        role: MessageRole.Function,
                        name: name,
                        content: messageError,
                        _command: {
                            stationId: this.stationId,
                            type: null,
                            data: {
                                command: null
                            },
                            error: messageError,
                            _execute: CommandExecuteType.wait
                        }
                    }
                    messageAI._status = MessageAIStatus.error
                    this.messageAI = null
                    return
                }

                messageAI._function_answer = {
                    role: MessageRole.Function,
                    name: name,
                    content: null,
                }

                // create command
                let type;
                let textCommand
                switch (name) {
                    case 'run_command':
                        type = constants.COMMAND_TYPE_CUSTOM
                        textCommand = args.text
                        break;
                    default:
                        return NotifyService.error('Command not found')
                }
                messageAI._function_answer._command = {
                    stationId: this.stationId,
                    type: type,
                    data: {
                        command: textCommand
                    },
                    _execute: CommandExecuteType.wait
                }

                messageAI._status = MessageAIStatus.hasCommand

                if (this.settings.autoApply)
                    this.doCommand(messageAI)
            } else {
                // make user right write new query - maybe rewrite?
                this.messageAI = null
            }
        })
    }

    @action
    async doCommand(messageAI: ChatMessage) {
        let command = messageAI?._function_answer?._command;
        if (!command)
            return NotifyService.error('Command not existed in messageAI')

        command._execute = CommandExecuteType.yes

        messageAI._status = MessageAIStatus.processCommand

        let commandToSend = {
            stationId: command.stationId,
            type: command.type,
            data: {
                command: command.data.command
            },
        }

        this.blockInput = true
        await APIService.getInstance().createCommands({
            commands: [commandToSend]
        }).then(res => {
            let result = res.result[0]
            if (result.error) { // error when send command
                NotifyService.error(`${LANG.get(['station', 'commandError'])} 
                                ${Helper.getMessageError(result.error)}`)
                console.error(result.error)
                messageAI._status = MessageAIStatus.hasCommand
            } else {
                let commandCreated = result.result

                let onResultCommand = (commandResult) => {
                    if (commandResult?.id != commandCreated.id) return
                    APIService.getInstance().socket.off(constants.SOCKET_RESULT_COMMAND, onResultCommand);

                    // clear params when send command again
                    delete command.error
                    delete command.result

                    if (commandResult.error) {
                        command.error = Helper.getMessageError(commandResult.error)
                        this.messageAI = null
                    } else if (!commandResult.result) {
                        //NotifyService.error(['station', 'successEmpty'])
                        command.result = 'Ok'
                    } else
                        command.result = commandResult.result

                    messageAI._function_answer.content = JSON.stringify(command.error || command.result)

                    messageAI._status = command.error ? MessageAIStatus.error : MessageAIStatus.successCommand


                    if (messageAI._status == MessageAIStatus.error) {
                        if (messageAI._status == MessageAIStatus.error && this.settings.autoContinueError)
                            this.sendMessages()
                    } else {
                        this.messageAI = null
                        this.sendMessages()
                    }
                    this.blockInput = false
                    this.scrollToBottom = true
                };
                APIService.getInstance().socket.on(constants.SOCKET_RESULT_COMMAND, onResultCommand);
            }
        })
    }

    @action
    clear() {
        this.messages = []
        this.messageAI = null
        this.blockInput = false
    }

    @action
    addMessage(message) {
        this.messages.push(message)
    }
}

export class ChatMessage {
    role: string
    name?: string
    content?: any
    function_call?: {
        name: string
        arguments: string
    }
    /* _refine?: {
         role: MessageRole.User
         content: String
         _text?: String // show to user
     }*/
    _function_answer?: {
        role: MessageRole.Function
        name: string
        content: string // result or error from command
        _command?: {
            stationId: string
            type: string
            data: {
                command: string
            }
            result?: any
            error?: any
            _execute: CommandExecuteType
            _showInner?: boolean // show result or error
        }
    }
    _status?: MessageAIStatus
    _text?: string // show to user
    _date: number // id
    _tokens: number // token used
}

export enum SendType {
    user,
    system,
}

export enum CommandExecuteType {
    wait,
    yes,
    no,
}

export enum MessageAIStatus {
    created,
    hasCommand,
    error, // when send to station and receive from his error
    processCommand,
    successCommand,
}

export enum MessageRole {
    User = 'user',
    AI = 'assistant',
    System = 'system',
    Function = 'function',
}

export default ChatGPTControlStore;
import './styles/main.scss'
import {observer} from "mobx-react";
import React, {lazy, Suspense, useEffect} from "react";
import {Route, Router, Switch} from 'react-router-dom';
import UserServiceStore from "./stores/userServiceStore";
import MyHistory from './utils/myHistory';
import "./styles/overrides/material.scss";
import Layout from "./components/layout/layout";
import {Redirect} from "react-router";

function Routes() {

    return <Router history={MyHistory}>
        <Suspense fallback={
            window.location.pathname.includes('/login') ?
                <div>Loading...</div> :
                <Layout>
                    <div className='w-100 h-100 f-c'>Loading...</div>
                </Layout>
        }>
            <Switch>

                <PrivateRoute exact path="/stations"
                              component={lazy(() => import('./pages/stations/stations'))}/>

                <PrivateRoute exact path="/station/:id"
                              component={lazy(() => import('./pages/station/station'))}/>
                <PrivateRoute exact path="/station/:id/:tab"
                              component={lazy(() => import('./pages/station/station'))}/>

                <PrivateRoute exact path="/user/:tab"
                              component={lazy(() => import('./pages/user/user'))}/>
                <PrivateRoute path='/user' redirectTo="/user/settings"/>


                <Route path="/login" component={lazy(() => import('./pages/login/login'))}/>

                <PrivateRoute path='*' redirectTo="/stations"/>

                <Route path="/404" component={lazy(() => import('./pages/404'))}/>
                <Route path="/403" component={lazy(() => import('./pages/403'))}/>

                <Route path="/" component={lazy(() => import('./pages/404'))}/>
            </Switch>
        </Suspense>
    </Router>
}

function PrivateRoute({component: Component, redirectTo, keyId, ...rest}: any) {
    let userService = UserServiceStore.getInstance();
    if (!redirectTo) {
        let path = rest.location.pathname;
        let hasRight = true;

        if (!hasRight)
            redirectTo = '/403?from=' + path;
    }
    return <Route
        {...rest}
        render={(props) =>
            userService.user ? (
                    redirectTo &&
                    <Redirect to={redirectTo}/>
                    ||
                    <Component key={keyId ? props.match.params[keyId] : undefined} {...props} />
                ) :
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />

        }
    />;
}

export default observer(Routes);

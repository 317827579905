export default {
    stations: `站点`,
    filter: `站点过滤器`,
    comment: `评论`,
    comment_add: "添加评论",
    comment_edit: "编辑评论",
    sortDate: `按创建日期排序`,
    checkbox: `选择`,
    select: `选择`,
    actions: `操作`,
    showStation: `显示站点`,
    delete: `删除`,
    number: `编号`,
    name: `名称`,
    bootTimestamp: `引导时间戳`,
    bootTimestampM: `引导`,
    cpuUsageTotal: `CPU使用率，%`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `内存使用率，%`,
    memoryPercentM: `内存`,
    ipAddress: `IP地址`,
    ipAddressM: `IP`,
    disks: `可用磁盘空间`,
    disksM: `磁盘`,
    updatedAt: `更新日期`,
    system: `系统`,
    release: `版本`,
    stationSelect: `选择站点`,
    stationsDel: `已删除的站点`,
    stationDel: `已删除的站点`,
    downloadClient: `下载客户端`,
    download: `下载`,
    online: `在线`,
    offline: `离线`,

    toDo: `待办`,
    done: `已完成`,
    error: `错误`,
    canceled: `已取消`,

    info: `信息`,
    power: `电源`,
    process: `进程`,
    programs: `程序`,
    devices: `设备`,
    commands: `命令`,
    other: `其他`,

    reboot: `重启`,
    shutdown: `关机`,
    sleep: `睡眠`,
    hibernate: `休眠`,
    lock: `锁定`,

    refill: `充值`,
    writeOff: `冲销`,
    withdrawal: `提取`,
    dateUpdate: `更新日期`,
    autoUpdate: `自动更新`,
    autoUpdateM: `自动更新`,
    commandError: `命令创建错误`,
    commandSend: `命令已发送`,

    visualControl: `可视化控制`,
    doCommand: `执行命令`,
    viewStation: `查看站点`,
    metaDesc: `远程访问和计算机服务器控制面板`,
    confirmAction: `确认操作`,
    confirmActionText: `您确定要执行命令 "{{cmd}}" 吗？
可能需要按下操作按钮。`,
    waitingExe: `等待执行...`,
    successEmpty: `成功，但响应为空`,
    resultExe: `执行结果`,
    enterCommand: `输入命令`,
    exeCustomCommand: `执行自定义命令`,
    enterEnable: `启用按回车键发送`,
    visualControlStation: `站点可视化控制`,
    add: `添加站点`,
    password: `密码`,
    stationAdded: `站点已添加`,
    passwordLength: `密码长度必须大于6个字符`,

    cmd: {
        status: `状态`,
        type: `类型`,
        createdAtS: `创建日期`,
        checkbox: `选择`,
        remove: `删除`,
        createdAt: `日期`,
        raw: `数据`,
        user: `用户`,
        result: `结果`,
        error: `错误`,
        filterName: `命令过滤器`,
        removeE: `删除命令错误`,
        removeS: `删除成功`,
    },

    devs: {
        name: `名称`,
        group: `组`,
        status: `状态`,
        manufacturer: `制造商`,
        deviceID: `设备ID`,
        driver: `驱动程序`,
        nameS: `按名称`,
        groupS: `按组`,
        filterName: `设备过滤器`,
        dateUpdate: `更新日期`,
    },

    inf: {
        group: `组`,
        name: `名称`,
        value: `值`,

        general: `常规信息`,
        node: `计算机名称`,
        bootTimestamp: `启动时间戳`,
        updatedAt: `数据更新时间`,
        system: `系统`,
        release: `版本`,
        version: `版本`,
        programVersion: `程序版本`,
        memory: `内存`,
        memoryTotal: `总量`,
        memoryAvailable: `可用`,
        memoryUsed: `已使用`,
        memoryPercent: `已使用内存，%`,
        swapTotal: `交换空间总量`,
        swapFree: `可用交换空间`,
        swapUsed: `已使用交换空间`,
        swapPercent: `已使用交换空间，%`,
        processor: `处理器`,
        processorVendor: `制造商`,
        processorBrand: `品牌`,
        cpuUsageTotal: `总CPU使用率，%`,
        cpuCores: `核心`,
        cpuTotalCores: `线程`,
        cpuFreqCurrent: `当前频率`,
        net: `网络`,
        ipAddress: `IP地址`,
        macAddress: `MAC地址`,
        interfaces: `网络接口`,
        totalSend: `总发送量`,
        totalReceived: `总接收量`,
        persistent: `持久存储`,
        disks: `磁盘`,
        dateUpdate: `更新日期`,
        autoUpdate: `自动更新`,
        free: `可用`,
    },

    proc: {
        name: `名称`,
        exe: `路径`,
        username: `用户名`,
        pidS: `进程ID`,
        nameS: `按名称`,
        cpuPercent: `CPU使用率，%`,
        memoryPercent: `内存使用率，%`,
        createTimeS: `按创建时间`,
        usernameS: `按用户名`,
        checkbox: `选择`,
        finishP: `结束进程`,
        finishPs: `结束多个进程`,
        pid: `进程ID`,
        createTime: `创建时间`,
        diskUsageRead: `磁盘读取使用量`,
        diskUsageReadTotal: `总读取量`,
        diskUsageWrite: `磁盘写入使用量`,
        diskUsageWriteTotal: `总写入量`,
        status: `状态`,
        filterName: `进程过滤器`,
    },

    prog: {
        startRemove: `开始删除程序`,
        name: `名称`,
        installDate: `安装日期`,
        version: `版本`,
        size: `大小`,
        description: `描述`,
        publisher: `发布商`,
        location: `安装位置`,
        uninstall: `卸载`,
        depends: `依赖项`,
        nameS: `按名称`,
        installDateS: `按安装日期`,
        filterName: `程序过滤器`,
        confirmAction: `确认操作`,
        confirmActionText: `您确定要开始删除 "{{name}}" 吗？
可能需要按下操作按钮。`,
    },

    control:{
        prepare: `准备中...`,
        connecting: `连接中`,
        connected: `已连接`,
        disconnected: `已断开连接`,
        error: `错误`,
        tryAgain: `重试`,
        zoom: `放大`,
        zoomOut: `缩小`,
        showAllButtons: `显示所有按钮`,
        display: `显示`,
        size: `大小`,
        keys: `按键`,
        showHideKey: `显示/隐藏键盘`,
        fitScreen: `适应屏幕`,
        fullscreen: `全屏`,
        tune: `自定义`,
        cursorMove: `移动光标`,
        move: `移动`,
        click: `点击`,
    },


    aiControl: 'AI控制',
    aiControlStation: 'AI控制站',
    ai: {
        title: 'AI站点控制',
        autoApply: '自动应用命令',
        autoContinueError: '继续处理错误',
        send: '发送（Ctrl + Enter）',
        input: '您的消息',
        errorLength: '消息太短',
        process: '正在处理',
        success: '成功',
        error: '错误',
        showMore: '显示更多',
        continue: '继续',
        doCommand: '执行命令',
        commandAny: '执行任意命令',
        refine: '优化答案',
        newQuery: '新查询',
        auto: '自动',
        model: 'AI模型',
        tokensLeft: '剩余令牌：{{value}}',
        allUsers: '所有用户',
        tokenHelp: '发送到AI模型的文本将被分割为令牌。一个令牌可以是一个字符、一个单词，甚至是一个句子，这取决于语言和上下文。例如，文本“ChatGPT is great!”可以分割为以下令牌：["Chat", "G", "PT", " is", " great", "!"]。',
        enableHTML: '在HTML中显示结果',
        userTokens: '当前消息中使用的令牌数。如果与其他上下文相关，请创建新的查询。',
        inProgress: '正在处理信息……',
        previewCommand: '将执行以下命令',
        "userToken": "用户API密钥",
        "getToken": "获取密钥"
    },
};

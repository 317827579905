export default {
    title: `لوحة تحكم المحطات الفضائية`,
    phrase: `عبارة سرية (12 كلمة)`,
    enter: `تسجيل الدخول`,
    register: `التسجيل`,
    back: `رجوع`,
    nickname: `الاسم المستعار`,
    secret: `العبارة السرية`,
    secretDesc: `يرجى حفظ العبارة في مكان آمن، حيث تتيح الوصول إلى حسابك والمحطات المضافة. لا يقوم الخدمة بتخزين هذه العبارة،
    في حالة فقدانها، لا يمكن استعادة الوصول وسيتعين عليك إنشاء حساب جديد وإضافة المحطات من جديد`,
    secretCheck: `أوافق على أنه في حالة فقدان العبارة السرية، لن يتمكن من استعادة الوصول إلى الحساب`,
    errorInitKey: `خطأ في تهيئة المفتاح، يرجى التحقق من البيانات`,
    registerSuccess: `تم التسجيل بنجاح`,
};

import './styles/main.scss'
import {observer, Provider} from "mobx-react";
import React, {useEffect, useMemo} from "react";
import Variables from "./utils/variables";
import RootStore from './stores/rootStore';
import Helper from "./utils/helper";
import {createMuiTheme, StylesProvider, ThemeProvider} from "@material-ui/core/styles";
import {ToastContainer} from "react-toastify";
import "./styles/overrides/material.scss";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import Routes from "./routes";
import Modals from "./components/modals/modals";
import APIService from "./utils/api.service";
import LANG from "./lang/language";

Variables.init();

function App() {
    useMemo(() => {
        App.init();
    }, []);
    let rootStore = RootStore.getInstance();

    useEffect(() => {
        let keyup = (e) => rootStore.onKeyUp(e);
        let keydown = (e) => rootStore.onKeyDown(e);
        //let popstate = (e) => rootStore.onPop(e);
        window.addEventListener('keyup', keyup);
        window.addEventListener('keydown', keydown);
        //window.addEventListener('popstate', popstate);

        rootStore.onMount()
        return () => {
            window.removeEventListener('keyup', keyup);
            window.removeEventListener('keydown', keydown);

            rootStore.onUmount()
            //window.removeEventListener('popstate', popstate);
        }
    }, []);

    return <ThemeProvider theme={rootStore.theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <StylesProvider injectFirst>
                <Provider rootStore={rootStore}>
                    <Routes/>
                    <Modals/>
                    <ToastContainer position="bottom-right"
                                    autoClose={3000}
                                    hideProgressBar={false}
                                    newestOnTop={true}
                                    pauseOnFocusLoss
                                    closeOnClick={false}
                                    draggable={false}
                                    pauseOnHover/>
                </Provider>
            </StylesProvider>
        </MuiPickersUtilsProvider>
    </ThemeProvider>
}

App.init = () => {
    let lang = LANG.getInstance();
    let rootStore = RootStore.getInstance();
    APIService.getInstance() // init socket and connect
    Helper.init();
    let args

    // try to find localizations
    let tmp = require('@material-ui/core/locale')
    let name = Object.keys(tmp).find(el => el.startsWith(lang.lang))
    if (name) {
        args = tmp[name]
    }

    rootStore.theme = createMuiTheme({
        palette: {
            primary: {
                light: '#3a5cd5',
                main: '#4961b6',
                dark: '#3c4f93',
                contrastText: 'rgba(255,255,255,0.90)',
            },
            secondary: {
                light: 'rgba(59,87,192,0.8)',
                main: 'rgba(59,87,192,0.90)',
                dark: 'rgba(59,87,192)',
                contrastText: 'rgba(0,0,0,0.75)',
            },
        },
    }, args);

    return {};
}

export default observer(App);

import React from "react";
import {Button, IconButton} from "@material-ui/core";
import styles from "./myIconButton.module.scss";
import cn from 'classnames';
import Helper from "../../utils/helper";
import LANG, {useLangMemo} from "../../lang/language";

interface Props extends Object {
    text?: string;
    iconM?: string;
    onClick?: () => void;
    className?;
    classNameIcon?;
    variant?;

    [key: string]: any;
    forceButton?;
}

function MyIconButton({text, iconM, color, onClick, className,  classNameIcon, variant="contained", key, forceButton,
                          disabled, ...props}: Props) {
    let icon;
    if (iconM) {
        let style:any = {};
        if (iconM === 'edit')
            style = {fontSize: '20px'};
        if(color)
            style.color = color;
        icon = <span className={cn(styles.icon, 'material-icons', classNameIcon)} style={style}>{iconM}</span>;
    }

    text = useLangMemo(text)

    if(!key)
        key = text || iconM ;

    if(disabled && disabled instanceof Function)
        disabled = disabled()

    if (!text && !forceButton) {
        // @ts-ignore
        return <IconButton key={key} variant={variant} className={`${cn(styles.main)} ${className}`} {...props}
                           onClick={onClick} disabled={disabled}>
            {icon}
        </IconButton>
    }

    return <Button key={key} variant={variant} className={`${cn(styles.main, {
        [styles.forceButton]: forceButton
    })} ${className}`} {...props}
                   disableElevation={true} onClick={onClick} startIcon={icon} color={color}  disabled={disabled}>
        <span className={cn(styles.text)}>{text}</span>

    </Button>

}

export default MyIconButton;

export default {
    nickname: `Ваш никнейм`,
    address: `Ваш адрес`,
    daysInMonth: `Из расчета, что в месяце 30 дней`,
    currentBalance: `Текущий баланс`,
    stationCount: `Кол-во станций`,
    expensesMonth: `Затраты в месяц`,
    enoughBalance: `Хватит баланса`,
    currentTariff: `Текущий тариф`,
    optionPay: `Оплата по желанию`,
    stationPerMonth: `За станцию в месяц`,
    payFirstStations: `Оплачивать первые 5 станций`,
    notSelect: `Не выбран`,
    changeTariff: `Изменить тариф`,
    createTariff: `Создать тариф`,
    fieldsCalc: `Поля для расчётов`,
    stations: `Станций`,
    mounths: `Месяцев`,
    changeBalanceThis: `Заменить баланс этой суммой`,
    total: `Итого`,
    addBalance: `Пополнение баланса`,
    summForAdd: `Сумма для пополнения, $`,
    pushToCopy: `Нажмите для копирования в буфер обмена`,
    textCopied: `Текст скопирован в буфер обмена`,
    createBill: `Создать счет`,
    cancelBill: `Отменить счет`,
    addressToSend: `Адрес для пополнения`,
    or: `или`,
    billPartner: `Счет для оплаты у партнера`,
    go: `перейти`,
    textBalance: `Краткая инструкция по оплате (кредитной картой или любыми другими способами) 
        <ol> 
        <li>Создать счет на этой странице</li> 
        <li>Перевести деньги 
        <ul> 
        <li>Напрямую - если у вас есть электронный кошелек с USDT bep20 монетами.</li> 
        <!--<li>Оплатить выставленный счет у партнера - может взиматься комиссия партнером, в зависимости от способа оплаты.</li> -->
        <li>Через обменник, например выбрав его на <a href="https://www.bestchange.ru/?p=1285001" target="_blank" class="link">bestchange.ru</a> (без комисии): 
        <ol> 
        <li>Перейти на страницу <a href="https://www.bestchange.ru/?p=1285001" target="_blank" class="link">агрегатора</a></li> 
        <li>В колонке отдаете - выберите способ оплаты подходящий вам, к примеру  
        <a href="https://www.bestchange.ru/sberbank-to-tether-bep20.html?p=1285001" target="_blank" class="link">сбербанк</a></li> 
        <li>В колонке получаете - должно стоять <a href="https://www.bestchange.ru/sberbank-to-tether-bep20.html?p=1285001" target="_blank" class="link">Tether bep20</a></li> 
        <li>Выбираете обменник с лучшим курсом или условиями, например <a href="https://ychanger.net/obmen-sberbank-na-usdt-bep20.html?R=16867378565078" target="_blank" class="link">YChanger</a> - минимум 1000 руб</li> 
        <li>На сайте обменника указываете введенную вами сумму для пополнения в долларах, копируете ваш адрес для пополнения (выше), заполняете остальные поля и переводите сумму</li> 
        <li>В течении 20 минут она обрабатывается обменником, а затем моим сервисом</li> 
        </ol> 
        </li> 
        </ul> 
        </li> 
        </ol> 
         
        <p>После поступления денежных средств данные на этой странице автоматически обновятся. Если счёт не был оплачен по истечении 24 часов, он автоматически отменится.</p> 
        Процесс списания оплаты по тарифу ежедневный, из расчета максимального количества станций в течение дня. 
        <p>Если возникнут проблемы - напишите мне в  
        <a href="{{tg}}" target="_blank" class="link">телеграмме</a>.</p> 
        <p>Если у вас есть потребность в дополнительном функционале - напишите об этом в разделе  
        <a href="{{tg_idea}}" target="_blank" class="link">идеи чата проекта</a>.  
        Идея будет или сразу реализована, или будет вынесена на голосование участникам группы, 
        по результату которого будет приниматься решение о разработке. Заранее спасибо за участие!</p>`,

    textTariffCost: `Сервис позволяет вам самим выбирать месячную стоимость услуг, эта возможность так-же  
        служит оценкой идеи этого проекта, чем выше указанная вами сумма - тем лучше будет становится данный проект,  
        тем производительней смогут быть сервера, тем меньше будет багов, удобней интерфейс и быстрее взаимодействие,  
        а что самое главное - будут появляться новые функции, облегчающие вам управление вашими станциями.`,

    textTariffFirstStations: `Если вы хотите поддержать проект настолько, что даже готовы оплатить бесплатные для вас  
        станции, то вы невероятно круты!`,

    textBillAmount: `Для того, что бы автоматически идентифицировать ваш платеж и привязать к вашему аккаунту необходимо ввести  
        сумму заранее и убедиться что при переводе средств она будет указанна в точности до цента.  
        К сумме могут прибавится десятичные числа, если эта сумма уже будет ожидать оплаты в базе данных.`,
    textBillAddress: `Генерируется автоматически при создании счета. Его необходимо указывать при любом способе оплаты,  
        обратите внимание на тип сети "USDT bep20" (Binance)  
        - его у вас спросят при создании заявки на любых криптообменниках`,
    textBillPartner: `Другой способ - оплатить у партнера, счет на выбранную сумму уже создан, просто перейдите 
         по ссылке и переведите деньги удобным способом.`,

    billCreated: `Создан`,
    billPartPaid: `Частично оплачен`,
    billPaid: `Оплачен`,
    billCancel: `Отменен`,
    billNum: `Счет №`,
    tariffUpdated: `Тариф обновлен`,
    billCreatedFull: `Счет создан`,
    billPaidFull: `Счет оплачен`,
    billCancelFull: `Счет отменен`,
    errorSaveUserEmpty: `Ошибка сохранения пользователя - пустой ответ`,
    settings: `Настройки`,
    tariff: `Тариф и оплата`,
    transactions: `Транзакции`,

    type: `Тип`,
    createdAt: `Дата`,
    value: `Значение`,
    billString: `Счет`,
    tariffString: `Тариф`,
    stationsPay: `Станций`,

    sortDateCreate: `По дате создания`,
    transactionFilter: `Фильтр транзакций`,
};

export default {
    title: `Panel de control de estaciones espaciales`,
    phrase: `Frase secreta (12 palabras)`,
    enter: `Ingresar`,
    register: `Registrarse`,
    back: `Atrás`,
    nickname: `Apodo`,
    secret: `Frase secreta`,
    secretDesc: `Por favor, guarde la frase en un lugar seguro. Le permite acceder a su cuenta y a las estaciones añadidas. El servicio no guarda esta frase.
    En caso de pérdida, no se podrá recuperar el acceso y tendrá que crear una nueva cuenta y añadir las estaciones de nuevo`,
    secretCheck: `Acepto que en caso de pérdida de la frase secreta no se podrá recuperar el acceso a la cuenta`,
    errorInitKey: `Error al inicializar la clave, verifique los datos`,
    registerSuccess: `Registro exitoso`,
};

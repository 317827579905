import EntityInterface from "./interfaces/entityInterface";
import {action, makeObservable, observable} from "mobx";
import Helper from "../utils/helper";
import RootStore from "./rootStore";
import NotifyService from "../utils/notify.service";
import MyHistory from "../utils/myHistory";

class TagStore implements EntityInterface {
    name: string;
    @observable view: any;
    menuItems = [];

    entity: any; // original
    @observable entityEdited: any; // to view
    @observable blocks: any[];

    @observable timeoutRefresh: NodeJS.Timeout;
    @observable refreshing = false;
    @observable saving = false;
    firstRefresh = true;
    isModal = true;

    defaultInclude = [];

    mode;

    constructor({id, isModal, entityFrom}: any) {
        makeObservable(this);

        this.menuItems = [
            {
                type: 'button', name: 'Обновить', disabled: false, onClick: val => {
                    this.refresh({});
                    return false;
                }
            },
            {type: 'button', name: 'История изменений', disabled: true, onClick: val => null},
        ];

        if (id === 'create') {
            this.mode = 'create';
            if (entityFrom) {
                this.entity = {};
                this.entityEdited = this.convert(Helper.makeClone(entityFrom, {}, 0, ['id']));
            } else {
                this.entity = {};
                this.entityEdited = this.convert(this.entity);
            }
            this.init();
        } else
            this.entityEdited = {id};

        this.name = !this.entityEdited.id ? 'Создание тэга' : 'Изменение тэга';

        this.isModal = isModal;
    }

    @action
    private init() {
        let entityEdited = this.entityEdited;
        this.name = !entityEdited.id ? 'Создание тэга' :
            (entityEdited?.name ? this.entityEdited?.name : 'Редактирование тэга');

        if (this.firstRefresh) {
            RootStore.getInstance().setMeta({
                title: entityEdited?.name ? (`${entityEdited?.name}`) : 'Создание тэга'
            });
            // set default values

            if (!this.entityEdited.data)
                this.entityEdited.data = [];

            this.fillFields();
            this.firstRefresh = false;
        }
    }

    private convert(entity) {

        if (!entity.data)
            entity.data = [];

        if (this.mode === 'create') {

        }

        return entity;
    }

    @action
    async refresh(props) {
        if (!this.entityEdited.id)
            return;

        let {pending} = props;

        if (this.refreshing)
            return;

        clearTimeout(this.timeoutRefresh);
        this.timeoutRefresh = undefined;
        if (pending) {
            this.timeoutRefresh = setTimeout(() => {
                delete props.pending;
                this.refresh(props)
            }, +(pending.constructor === Number ? pending : 1000));
            return;
        }

        this.refreshing = true;
        //await HelperService.delay(3000);
        let query: any = {};
        query.id = this.entityEdited.id;
        query.include = this.defaultInclude;

        let answer// = await APIService.getInstance().getTag(query);
        if (answer?.result) {
            this.entityEdited = this.convert(Helper.makeClone(answer?.result));
            this.entity = answer?.result;

            this.init();
        } else {
            NotifyService.error('Не найдено');
        }

        this.refreshing = false;
    }

    @action
    fillFields({skipWork}: any = {skipWork: false}): void {
        this.blocks = [
            {
                key: 'main',
                name: undefined,
                items: [
                    {
                        type: 'text',
                        field: 'name',
                        name: 'Имя',
                        disabled: false,
                        size: 12
                    },
                ]
            },
        ];

        if(!skipWork){
            this.blocks = Helper.filterFields(this.blocks, {parentFields: ['tag']});
        }
    }

    openHistory(): void {
        //throw new Error("Method not implemented.");
    }

    getFields(fields, item) {
        let fieldParent = item || this;
        let res;
        for (let i = 0; i < fields.length; i++) {
            let name = fields[i];
            if (i === fields.length - 1) {
                res = fieldParent[name];
            } else {
                fieldParent = fieldParent[name];
            }
        }
        return res;
    }

    @action
    changeFields(fields, value, item?): any {
        let fieldParent = item || this;
        for (let i = 0; i < fields.length; i++) {
            let name = fields[i];
            if (i === fields.length - 1) {
                fieldParent[name] = value;
            } else {
                fieldParent = fieldParent[name];
            }
        }
    }

    async getOptionsField({storeField, field, fields, key, item}) {
        let name = field || fields[0];
        let answer, query;
    }

    @action
    async save() {
        if (this.saving)
            return;

        this.saving = true;

        // convert selected
        let entity = Helper.makeClone(this.entityEdited);

        Helper.fillFieldsBeforeSave(entity, this.blocks);

        //this.entityEdited.carrierId = entity.carrier?.id || null;

        let query: any = {};
        query.target = entity;
        query.include = this.defaultInclude;

        let answer// = await APIService.getInstance().updateTag(query);
        if (answer?.result) {
            this.entityEdited = this.convert(Helper.makeClone(answer?.result));
            this.entity = answer?.result;
            NotifyService.success(query.target.id ? 'Тэг обновлен' : 'Тэг создан');
            if (!query.target.id && !this.isModal) {
                MyHistory.replace(`/objects/tag/${this.entity.id}`);
            }
            this.firstRefresh = true;
            this.init();
        }
        this.saving = false;
    }
}

export default TagStore;

export default {
    title: `Панель управления космическими станциями`,
    phrase: `Секретная фраза (12 слов)`,
    enter: `Войти`,
    register: `Регистрация`,
    back: `Назад`,
    nickname: `Никнейм`,
    secret: `Секретная фраза`,
    secretDesc: `Пожалуйста сохраните фразу в надежном месте, она дает возможность
                                получить доступ к вашему аккаунту и добавленным станциям. Сервис не хранит эту фразу,
                                в случае утери восстановить доступ будет невозможно, придется создавать новый аккаунт
                                и добавлять станции заново`,
    secretCheck: `Я согласен, что в случае утери секретной фразы восстановить доступ к аккаунту не получится`,
    errorInitKey: `Ошибка инициализации ключа, проверьте данные`,
    registerSuccess: `Регистрация прошла успешно`,
};

export default {
    nickname: `Seu apelido`,
    address: `Seu endereço`,
    daysInMonth: `Com base em um mês de 30 dias`,
    currentBalance: `Saldo atual`,
    stationCount: `Número de estações`,
    expensesMonth: `Despesas por mês`,
    enoughBalance: `Saldo suficiente`,
    currentTariff: `Tarifa atual`,
    optionPay: `Pagamento opcional`,
    stationPerMonth: `Por estação por mês`,
    payFirstStations: `Pague pelas primeiras 5 estações`,
    notSelect: `Não selecionado`,
    changeTariff: `Alterar tarifa`,
    createTariff: `Criar tarifa`,
    fieldsCalc: `Campos de cálculo`,
    stations: `Estações`,
    mounths: `Meses`,
    changeBalanceThis: `Substituir saldo por este valor`,
    total: `Total`,
    addBalance: `Adicionar saldo`,
    summForAdd: `Valor a ser adicionado, $`,
    pushToCopy: `Clique para copiar para a área de transferência`,
    textCopied: `Texto copiado para a área de transferência`,
    createBill: `Criar fatura`,
    cancelBill: `Cancelar fatura`,
    addressToSend: `Endereço para enviar o pagamento`,
    or: `ou`,
    billPartner: `Fatura para pagamento com parceiro`,
    go: `ir`,
    textBalance: `Instruções de pagamento resumidas (por cartão de crédito ou outros métodos)
        <ol>
        <li>Crie uma fatura nesta página</li>
        <li>Transfira o dinheiro
        <ul>
        <li>Diretamente - se você tiver uma carteira eletrônica com moedas USDT bep20.</li>
        <!--<li>Pague a fatura cobrada com o parceiro - o parceiro pode cobrar uma comissão, dependendo do método de pagamento.</li>-->
        <li>Através de uma casa de câmbio, por exemplo, selecionando uma na <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> (sem comissão):
        <ol>
        <li>Vá para a <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">página de agregação</a></li>
        <li>Na coluna "Oferece", selecione um método de pagamento adequado, como "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>". 
        Recomendo escolher nesta coluna o método de pagamento com a menor taxa de juros para o seu país (cerca de 3-5%), geralmente um dos bancos populares na moeda local, 
        e não uma transferência direta em USD.</li>
        <li>Na coluna "Recebe" - deve ser Tether bep20</li>
        <li>Escolha uma casa de câmbio com a melhor taxa ou condições</li>
        <li>No site da casa de câmbio, especifique o valor que você digitou para o reabastecimento em dólares, copie seu endereço de reabastecimento (acima), preencha os outros campos e transfira o valor</li>
        <li>Ele será processado pela casa de câmbio em 20 minutos e, em seguida, pelo meu serviço</li>
        </ol>
        </li>
        </ul>
        </li>
        </ol>
        
        <p>Após o recebimento dos fundos, os dados nesta página serão atualizados automaticamente. Se a fatura não for paga em até 24 horas, ela será cancelada automaticamente.</p>
        A tarifa é deduzida diariamente, com base no número máximo de estações durante o dia.
        <p>Se você encontrar algum problema, por favor me escreva no
        <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
        <p>Se você tiver alguma funcionalidade adicional que precise, por favor escreva sobre elas na
        <a href="{{tg_idea}}" target="_blank" class="link">seção de ideias do projeto</a>.
        A ideia será implementada imediatamente ou colocada em votação pelos membros do grupo, e uma decisão será tomada sobre o desenvolvimento. Desde já, agradeço sua participação!</p>`,

    textTariffCost: `O serviço permite que você escolha o custo mensal dos serviços por conta própria. Essa opção
        também serve como uma avaliação da ideia por trás deste projeto. Quanto maior o valor especificado, melhor este projeto se tornará,
        com servidores mais poderosos, menos bugs, uma interface mais amigável e interações mais rápidas.
        Mais importante, novos recursos serão introduzidos para facilitar a administração de suas estações.`,

    textTariffFirstStations: `Se você deseja apoiar o projeto a ponto de estar disposto a pagar pelas estações que são gratuitas para você,
        você é incrível!`,

    textBillAmount: `Para identificar automaticamente seu pagamento e associá-lo à sua conta, você precisa inserir
        o valor antecipadamente e garantir que ele seja especificado com precisão ao transferir os fundos.
        Números decimais podem ser adicionados ao valor se ele já estiver aguardando pagamento no banco de dados.`,
    textBillAddress: `É gerado automaticamente quando a fatura é criada. Ele deve ser fornecido para qualquer método de pagamento,
        preste atenção ao tipo de rede "USDT bep20" (Binance)
        - você será solicitado a fornecê-lo ao enviar uma solicitação para qualquer serviço de câmbio de criptomoedas`,
    textBillPartner: `Outra forma é pagar com um parceiro, a fatura para o valor selecionado já foi criada, basta seguir
        o link e transferir o dinheiro de forma conveniente.`,

    billCreated: `Criada`,
    billPartPaid: `Parcialmente paga`,
    billPaid: `Paga`,
    billCancel: `Cancelada`,
    billNum: `Fatura nº`,
    tariffUpdated: `Tarifa atualizada`,
    billCreatedFull: `Fatura criada`,
    billPaidFull: `Fatura paga`,
    billCancelFull: `Fatura cancelada`,
    errorSaveUserEmpty: `Erro ao salvar o usuário - resposta vazia`,
    settings: `Configurações`,
    tariff: `Tarifa e Pagamento`,
    transactions: `Transações`,

    type: `Tipo`,
    createdAt: `Data`,
    value: `Valor`,
    billString: `Fatura`,
    tariffString: `Tarifa`,
    stationsPay: `Estações`,

    sortDateCreate: `Por data de criação`,
    transactionFilter: `Filtro de transação`,
};

export default {
    title: `Pannello di controllo delle stazioni spaziali`,
    phrase: `Frase segreta (12 parole)`,
    enter: `Accedi`,
    register: `Registrati`,
    back: `Indietro`,
    nickname: `Nickname`,
    secret: `Frase segreta`,
    secretDesc: `Si prega di conservare la frase in un luogo sicuro. Permette di accedere al tuo account e alle stazioni aggiunte. Il servizio non memorizza questa frase.
    In caso di perdita, non sarà possibile recuperare l'accesso e sarà necessario creare un nuovo account e aggiungere nuovamente le stazioni`,
    secretCheck: `Accetto che in caso di perdita della frase segreta non sarà possibile recuperare l'accesso all'account`,
    errorInitKey: `Errore durante l'inizializzazione della chiave, controlla i dati`,
    registerSuccess: `Registrazione completata con successo`,
};

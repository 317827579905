import React, {useEffect, useMemo, useState} from "react";
import styles from "./mySelect.module.scss";
import cn from 'classnames';
import {observer} from "mobx-react";
import {isMobile} from "react-device-detect";

interface Props {
    className?: string;
    classNameList?: string;
    selected?: any;
    options: { name: string, selectedName: string, value: any }[];
    onSelect?: (value: any) => void;
    getHref?: (el: any) => string;
    getName?: (el: any) => string;

    [key: string]: any;
}

function MySelect({className, classNameList, options, selected, onSelect, title, icon, getHref, getName, ...props}: Props) {

    let selectedElement = useMemo(() => {
        return options.find(el => el.value == selected)
    }, [selected])

    let [opened, setOpen] = useState(false)

    useEffect(() => {
        let listener = () => {
            if (!isMobile)
                setOpen(false)
        };
        document.body.addEventListener('touchstart', listener);
        document.body.addEventListener('click', listener);
        return () => {
            document.body.removeEventListener('touchstart', listener);
            document.body.removeEventListener('click', listener);
        }
    }, [])

    return <div className={`${cn('f-c click', styles.main)} ${className}`} onClick={(event) => {
        event.stopPropagation();
        setOpen(!opened)
    }} title={title}>
        <div className={cn('f-c-l', styles.name)}>
            {
                icon && <span className={cn('material-icons', styles.icon)}>{icon}</span>
            }
            {
                selectedElement?.selectedName || selectedElement?.name || '-'
            }
        </div>
        {opened && <div className={cn('', classNameList, styles.list)}>
            {
                options.map(el =>
                    <a key={el.value} href={getHref && getHref(el)} className={cn('', styles.item, {
                        [styles.selected]: el.value == selected
                    })} onClick={(event) => {
                        event.stopPropagation();
                        onSelect && onSelect(el)
                        setOpen(false)
                    }}>
                        {getName ? getName(el): el.name}
                    </a>
                )
            }
        </div>}
    </div>;
}

export default observer(MySelect);

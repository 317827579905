export default {
    stations: `Stationen`,
    filter: `Filtern`,
    comment: `Kommentar`,
    comment_add: "Kommentar hinzufügen",
    comment_edit: "Kommentar bearbeiten",
    sortDate: `Nach Erstellungsdatum sortieren`,
    checkbox: `Auswahl`,
    select: `Auswählen`,
    actions: `Aktionen`,
    showStation: `Station anzeigen`,
    delete: `Löschen`,
    number: `Nummer`,
    name: `Name`,
    bootTimestamp: `Startzeitpunkt`,
    bootTimestampM: `Start`,
    cpuUsageTotal: `CPU-Auslastung, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `RAM-Auslastung, %`,
    memoryPercentM: `RAM`,
    ipAddress: `IP-Adresse`,
    ipAddressM: `IP`,
    disks: `Verfügbare Speicher`,
    disksM: `Speicher`,
    updatedAt: `Letzte Aktualisierung`,
    system: `Betriebssystem`,
    release: `Version`,
    stationSelect: `Station auswählen`,
    stationsDel: `Stationen gelöscht`,
    stationDel: `Station gelöscht`,
    downloadClient: `Client herunterladen`,
    download: `Herunterladen`,
    online: `Online`,
    offline: `Offline`,

    toDo: `Ausstehend`,
    done: `Erfolgreich`,
    error: `Fehler`,
    canceled: `Abgebrochen`,

    info: `Informationen`,
    power: `Energie`,
    process: `Prozesse`,
    programs: `Programme`,
    devices: `Geräte`,
    commands: `Befehle`,
    other: `Andere`,

    reboot: `Neustart`,
    shutdown: `Herunterfahren`,
    sleep: `Ruhezustand`,
    hibernate: `Ruhezustand`,
    lock: `Sperren`,

    refill: `Aufladen`,
    writeOff: `Abrechnung`,
    withdrawal: `Auszahlung`,
    dateUpdate: `Aktualisierungsdatum`,
    autoUpdate: `Automatische Aktualisierung`,
    autoUpdateM: `Auto-Update`,
    commandError: `Fehler beim Erstellen des Befehls`,
    commandSend: `Befehl gesendet`,

    visualControl: `Visuelle Steuerung`,
    doCommand: `Befehl ausführen`,
    viewStation: `Station anzeigen`,
    metaDesc: `Zugriff auf die Fernsteuerung für Computer und Server`,
    confirmAction: `Aktion bestätigen`,
    confirmActionText: `Sind Sie sicher, dass Sie den Befehl "{{cmd}}" ausführen möchten?`,
    waitingExe: `Warte auf Ausführung...`,
    successEmpty: `Erfolgreich, aber leere Antwort`,
    resultExe: `Ergebnis der Ausführung`,
    enterCommand: `Befehl eingeben`,
    exeCustomCommand: `Benutzerdefinierten Befehl ausführen`,
    enterEnable: `Senden durch Drücken der Eingabetaste aktiviert`,
    visualControlStation: `Visuelle Steuerung der Station`,
    add: `Station hinzufügen`,
    password: `Passwort`,
    stationAdded: `Station hinzugefügt`,
    passwordLength: `Das Passwort muss mindestens 6 Zeichen lang sein`,

    cmd: {
        status: `Status`,
        type: `Typ`,
        createdAtS: `Erstellungsdatum`,
        checkbox: `Auswahl`,
        remove: `Entfernen`,
        createdAt: `Datum`,
        raw: `Rohdaten`,
        user: `Benutzer`,
        result: `Ergebnis`,
        error: `Fehler`,
        filterName: `Befehle filtern`,
        removeE: `Fehler beim Entfernen der Befehle`,
        removeS: `Erfolgreich gelöscht`,
    },

    devs: {
        name: `Name`,
        group: `Gruppe`,
        status: `Status`,
        manufacturer: `Hersteller`,
        deviceID: `Geräte-ID`,
        driver: `Treiber`,
        nameS: `Nach Name`,
        groupS: `Nach Gruppe`,
        filterName: `Geräte filtern`,
        dateUpdate: `Aktualisierungsdatum`,
    },

    inf: {
        group: `Gruppe`,
        name: `Name`,
        value: `Wert`,

        general: `Allgemeine Informationen`,
        node: `Computernamen`,
        bootTimestamp: `Startzeitpunkt`,
        updatedAt: `Datenaktualisierung`,
        system: `System`,
        release: `Version`,
        version: `Version`,
        programVersion: `Programmversion`,
        memory: `Arbeitsspeicher`,
        memoryTotal: `Insgesamt`,
        memoryAvailable: `Verfügbar`,
        memoryUsed: `In Benutzung`,
        memoryPercent: `Speicherauslastung, %`,
        swapTotal: `Swap gesamt`,
        swapFree: `Swap verfügbar`,
        swapUsed: `Swap in Benutzung`,
        swapPercent: `Swap-Auslastung, %`,
        processor: `Prozessor`,
        processorVendor: `Hersteller`,
        processorBrand: `Marke`,
        cpuUsageTotal: `CPU-Auslastung, %`,
        cpuCores: `Cores`,
        cpuTotalCores: `Threads`,
        cpuFreqCurrent: `Aktuelle Frequenz`,
        net: `Netzwerk`,
        ipAddress: `IP-Adresse`,
        macAddress: `MAC-Adresse`,
        interfaces: `Netzwerkschnittstellen`,
        totalSend: `Gesendet`,
        totalReceived: `Empfangen`,
        persistent: `Persistent Storage`,
        disks: `Festplatten`,
        dateUpdate: `Aktualisierungsdatum`,
        autoUpdate: `Automatische Aktualisierung`,
        free: `Frei`,
    },

    proc: {
        name: `Name`,
        exe: `Pfad`,
        username: `Benutzername`,
        pidS: `Prozess-ID`,
        nameS: `Nach Name`,
        cpuPercent: `CPU-Auslastung, %`,
        memoryPercent: `Speicherauslastung, %`,
        createTimeS: `Nach Startzeitpunkt`,
        usernameS: `Nach Benutzer`,
        checkbox: `Auswahl`,
        finishP: `Prozess beenden`,
        finishPs: `Prozesse beenden`,
        pid: `PID`,
        createTime: `Startzeitpunkt`,
        diskUsageRead: `Festplattenzugriff (lesen)`,
        diskUsageReadTotal: `Insgesamt gelesen`,
        diskUsageWrite: `Festplattenzugriff (schreiben)`,
        diskUsageWriteTotal: `Insgesamt geschrieben`,
        status: `Status`,
        filterName: `Prozesse filtern`,
    },

    prog: {
        startRemove: `Deinstallation starten`,
        name: `Name`,
        installDate: `Installationsdatum`,
        version: `Version`,
        size: `Größe`,
        description: `Beschreibung`,
        publisher: `Verlag`,
        location: `Installationspfad`,
        uninstall: `Deinstallationspfad`,
        depends: `Abhängigkeiten`,
        nameS: `Nach Name`,
        installDateS: `Nach Installationsdatum`,
        filterName: `Programme filtern`,
        confirmAction: `Aktion bestätigen`,
        confirmActionText: `Möchten Sie die Deinstallation von "{{name}}" starten?
Es kann erforderlich sein, Aktionsschaltflächen zu betätigen.`,
    },

    control:{
        prepare: `Vorbereitung...`,
        connecting: `Verbindung herstellen`,
        connected: `Verbunden`,
        disconnected: `Getrennt`,
        error: `Fehler`,
        tryAgain: `Erneut versuchen`,
        zoom: `Vergrößern`,
        zoomOut: `Verkleinern`,
        showAllButtons: `Alle Schaltflächen anzeigen`,
        display: `Anzeige`,
        size: `Größe`,
        keys: `Tasten`,
        showHideKey: `Tastatur anzeigen/ausblenden`,
        fitScreen: `An Bildschirm anpassen`,
        fullscreen: `Vollbild`,
        tune: `Anpassen`,
        cursorMove: `Mauszeiger bewegen`,
        move: `Bewegen`,
        click: `Klicken`,
    },

    aiControl: 'KI-Steuerung',
    aiControlStation: 'Kontrollstation für künstliche Intelligenz',
    ai: {
        title: 'Kontrolle der KI-Station',
        autoApply: 'Befehle automatisch ausführen',
        autoContinueError: 'Fehler automatisch verarbeiten',
        send: 'Senden (Strg + Eingabetaste)',
        input: 'Ihre Nachricht',
        errorLength: 'Nachricht ist zu kurz',
        process: 'Wird ausgeführt',
        success: 'Erfolgreich',
        error: 'Fehler',
        showMore: 'Mehr anzeigen',
        continue: 'Fortfahren',
        doCommand: 'Befehl ausführen',
        commandAny: 'Beliebigen Befehl ausführen',
        refine: 'Antwort verfeinern',
        newQuery: 'Neue Anfrage',
        auto: 'Automatisch',
        model: 'KI-Modell',
        tokensLeft: 'Verbleibende Token: {{value}}',
        allUsers: 'Bei allen Benutzern',
        tokenHelp: 'Der gesendete Text wird in KI-Modelle in Tokens aufgeteilt. Ein Token kann ein einzelnes Zeichen, ein Wort oder sogar ein Satz sein, abhängig von der Sprache und dem Kontext. Zum Beispiel wird der Text "ChatGPT is great!" in die folgenden Tokens aufgeteilt: ["Chat", "G", "PT", " is", " great", "!"].',
        enableHTML: 'Ergebnis als HTML anzeigen',
        userTokens: 'Anzahl der verwendeten Tokens in der aktuellen Nachricht. Es wird empfohlen, eine neue Anfrage zu erstellen, wenn diese sich auf einen anderen Kontext bezieht.',
        inProgress: 'Informationen werden verarbeitet...',
        previewCommand: 'Der folgende Befehl wird ausgeführt',
        "userToken": "Benutzer-API-Schlüssel",
        "getToken": "Schlüssel erhalten"
    },
};

export default {
    nickname: `आपका उपनाम`,
    address: `आपका पता`,
    daysInMonth: `30 दिनों के महीने पर आधारित`,
    currentBalance: `वर्तमान शेष`,
    stationCount: `स्टेशनों की संख्या`,
    expensesMonth: `महीने की खर्च`,
    enoughBalance: `पर्याप्त शेष`,
    currentTariff: `वर्तमान टैरिफ`,
    optionPay: `वैकल्पिक भुगतान`,
    stationPerMonth: `महीने के हर स्टेशन के लिए`,
    payFirstStations: `पहले 5 स्टेशनों के लिए भुगतान करें`,
    notSelect: `चयन नहीं किया गया`,
    changeTariff: `टैरिफ बदलें`,
    createTariff: `टैरिफ बनाएँ`,
    fieldsCalc: `गणना के क्षेत्र`,
    stations: `स्टेशनों`,
    mounths: `महीनों`,
    changeBalanceThis: `इस राशि के साथ शेष को बदलें`,
    total: `कुल`,
    addBalance: `शेष जोड़ें`,
    summForAdd: `जोड़ने के लिए राशि, $`,
    pushToCopy: `क्लिपबोर्ड पर कॉपी करने के लिए क्लिक करें`,
    textCopied: `पाठ क्लिपबोर्ड पर कॉपी किया गया`,
    createBill: `बिल बनाएँ`,
    cancelBill: `बिल रद्द करें`,
    addressToSend: `भुगतान भेजने का पता`,
    or: `या`,
    billPartner: `साझेदार पर भुगतान के लिए बिल`,
    go: `जाएँ`,
    textBalance: `संक्षेप में भुगतान निर्देश (क्रेडिट कार्ड या अन्य तरीकों से)
    <ol>
    <li>इस पृष्ठ पर एक बिल बनाएँ</li>
    <li>पैसे ट्रांसफर करें
    <ul>
    <li>सीधे - यदि आपके पास USDT bep20 सिक्कों के साथ एक ई-वॉलेट है।</li>
    <!--<li>साझेदार पर चालान भुगतान करें - चालान का भुगतान करने पर साझेदार आपके द्वारा चुकाने का भुगतान कर सकता है, भुगतान के तरीके पर आधारित।</li>-->
    <li>एक एक्सचेंजर के माध्यम से, उदाहरण के लिए, इसे चुनकर <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> पर (कोई कमीशन नहीं) :
    <ol>
    <li><a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">एग्रीगेटर पेज</a> पर जाएं</li>
    <li>"देता" स्तंभ में, उपयुक्त भुगतान विधि जैसे "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">वीजा/मास्टरकार्ड</a>" का चयन करें।
मैं सलाह दूंगा कि आप इस स्तंभ में अपने देश के लिए ब्याज दर कम (लगभग 3-5%) वाली अधिकृत मुद्रा के लोकप्रिय बैंकों में से एक, और सीधे USD में एक सीधी ट्रांसफर से नहीं, चयन करें।</li>
    <li>"प्राप्ति" स्तंभ में - यह Tether bep20 होना चाहिए</li>
    <li>सबसे अच्छी दर या शर्तों के साथ एक्सचेंजर चुनें</li>
    <li>एक्सचेंजर की वेबसाइट पर, डॉलर में जमा करने के लिए आपने दर्ज किया राशि निर्दिष्ट करें, अपना भराव पता कॉपी करें (ऊपर), अन्य क्षेत्रों को भरें और राशि को ट्रांसफर करें</li>
    <li>इसे 20 मिनट में एक्सचेंजर द्वारा प्रसंस्कृत किया जाता है, और फिर मेरे सेवा द्वारा</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>फंड प्राप्त होने के बाद, इस पृष्ठ पर डेटा स्वचालित रूप से अपडेट होगा। यदि 24 घंटे के भीतर बिल भुगतान नहीं हुआ है, तो यह स्वचालित रूप से रद्द कर दिया जाएगा।</p>
    टैरिफ के लिए भुगतान रोजाना हटाया जाता है, दिन के दौरान अधिकतम स्टेशनों की संख्या पर आधारित।
    <p>यदि आपको किसी भी समस्या का सामना करना पड़ता है, तो कृपया मुझसे टेलीग्राम पर संपर्क करें
    <a href="{{tg}}" target="_blank" class="link">Telegram</a>।</p>
    <p>यदि आपको कोई अतिरिक्त सुविधा चाहिए, तो कृपया परियोजना की विचारों की चैट सेक्शन में उनके बारे में लिखें
    <a href="{{tg_idea}}" target="_blank" class="link">परियोजना विचार चैट सेक्शन</a>।
    विचार या तो तुरंत कार्रवाई में लाए जाएंगे या समूह के सदस्यों द्वारा मतदान किया जाएगा, और विकास पर निर्णय लिया जाएगा। आपकी सहभागिता के लिए पहले से ही धन्यवाद!</p>`,

    textTariffCost: `सेवाओं की मासिक लागत खुद ही चुनने की सुविधा उपलब्ध कराती है। यह विकल्प
    इस प्रोजेक्ट के पीछे के विचार का मूल्यांकन भी करता है। आप जितनी अधिक राशि निर्दिष्ट करेंगे, यह प्रोजेक्ट उत्कृष्ट होगा,
    शक्तिशाली सर्वर, कम बग, उपयोगकर्ता के लिए अधिक योग्य इंटरफेस और तेज़ इंटरेक्शन के साथ।
    सबसे महत्वपूर्ण बात यह है कि आपकी सुविधा के लिए नई सुविधाएं पेश की जाएंगी ताकि आप अपने स्टेशनों का प्रबंधन करने में आसानी हो।`,

    textTariffFirstStations: `यदि आप परियोजना का समर्थन उतना ही करना चाहते हैं, जितना कि आप उन स्टेशनों के लिए भुगतान करने के लिए तत्पर हैं जो आपके लिए मुफ्त हैं,
    तो आप बहुत शानदार हैं!`,

    textBillAmount: `अपने भुगतान की स्वचालित पहचान और अपने खाते से इसे जोड़ने के लिए, आपको
    पहले से राशि दर्ज करनी होगी और सुनिश्चित करना होगा कि यह बिल्कुल सही रूप से निर्दिष्ट हो जाए जब आप धन ट्रांसफर करते हैं।
    डेसिमल संख्याएं जोड़ी जा सकती हैं यदि यह पहले से ही डेटाबेस में भुगतान के लिए प्रतीक्षा कर रही है।`,
    textBillAddress: `यह स्वचालित रूप से बनाया जाता है जब बिल बनाया जाता है। इसे किसी भी भुगतान पद्धति के लिए प्रदान किया जाना चाहिए,
    "USDT bep20" नेटवर्क प्रकार पर ध्यान दें (बाइनेंस)
    - इसे आपसे बिनेंस सेवा में एक अनुरोध प्रस्तुत करते समय आपसे मांगा जाएगा।`,
    textBillPartner: `एक और तरीका है साझेदार पर भुगतान करने का, चयनित राशि के लिए बिल पहले से ही बना दिया गया है, बस लिंक पर जाएं
    और सुविधाजनक तरीके से पैसे ट्रांसफर करें।`,

    billCreated: `बनाई गई`,
    billPartPaid: `आंशिक भुगतान`,
    billPaid: `भुगतान किया गया`,
    billCancel: `रद्द किया गया`,
    billNum: `बिल संख्या`,
    tariffUpdated: `टैरिफ अपडेट किया गया`,
    billCreatedFull: `बिल बनाया गया`,
    billPaidFull: `बिल भुगतान किया गया`,
    billCancelFull: `बिल रद्द किया गया`,
    errorSaveUserEmpty: `उपयोगकर्ता को सहेजने में त्रुटि - खाली प्रतिक्रिया`,
    settings: `सेटिंग्स`,
    tariff: `टैरिफ और भुगतान`,
    transactions: `लेन-देन`,

    type: `प्रकार`,
    createdAt: `तिथि`,
    value: `मूल्य`,
    billString: `बिल`,
    tariffString: `टैरिफ`,
    stationsPay: `स्टेशनों`,

    sortDateCreate: `बनाने की तिथि के अनुसार`,
    transactionFilter: `लेन-देन फ़िल्टर`,
};

import {observer} from "mobx-react";
import React, {lazy} from "react";
import { Suspense } from "react";
import {isMobile} from "react-device-detect";

function EntitiesData(props) {
    let Data;
   if(!isMobile)
       Data = lazy(() => import('./entitiesDataDesktop'));
   else
       Data = lazy(() => import('./entitiesDataMobile'));

    /* <Suspense fallback={<div>Page is Loading...</div>}>*/
    return <Data {...props}/>
   /*</Suspense>*/
}

export default observer(EntitiesData);

import LANG from "../lang/language";

export default class Variables {
    static classInstance = null;

    static production = false;
    static host = null;
    static urlApi = null;
    static urlSockets = null;
    static baseHref = '/panel/';
    static externalSite = '';
    static urlTgMy = 'https://t.me/bob1258';
    static get urlTgChannel(){
        return LANG.getInstance().lang == 'ru' ? 'https://t.me/cusco_rc_chanel_ru' : 'https://t.me/cusco_rc_channel';
    }
    static get urlTgGroup(){
        return LANG.getInstance().lang == 'ru' ? 'https://t.me/cusco_rc_group_ru' : 'https://t.me/cusco_rc_group';
    }
    static get urlTgGroupTopics(){
        return LANG.getInstance().lang == 'ru' ? {
            main: 1,
            bugs: 4,
            ideas: 6,
        } : {
            main: 1,
            bugs: 8,
            ideas: 5,
        };
    }

    static init() {
        switch (process.env.REACT_APP_TYPE_BUILD) {
            case 'dev-test':
                break;
            case 'dev':
                if (process.env.REACT_APP_MODE === 'test' || process.env.REACT_APP_MODE === 'test_local') {
                    let host = window.location.host;
                    let protocol = window.location.protocol;
                    if (host.includes('2001'))
                        Variables.host = `http://${host.replace('2001', '5100')}/`;
                    else
                        Variables.host = protocol + '//' + host + '/';
                    Variables.urlApi = Variables.host;
                    if(process.env.REACT_APP_MODE !== 'test_local')
                        Variables.urlApi += 'api'
                    Variables.urlSockets = Variables.host;
                } else if (process.env.REACT_APP_MODE === 'test_prod') {
                    Variables.host = 'https://cusco-rc.com/';
                    Variables.urlApi = Variables.host + 'api/';
                    Variables.urlSockets = Variables.host;
                } else {
                    Variables.host = 'http://localhost:5100/';
                    Variables.urlApi = Variables.host + '';
                    Variables.urlSockets = Variables.host;
                }
                Variables.externalSite = `http://${window.location.hostname}:2002/`;
                break;
            case 'prod':
                Variables.production = true;
                Variables.host = `https://${window.location.host.replace('control.', '')}/`;
                Variables.urlApi = Variables.host + 'api/';
                Variables.urlSockets = Variables.host;
                Variables.externalSite = Variables.host;
                break;
        }
    }
}

export default {
    title: `Steuerungspanel für Raumstationen`,
    phrase: `Geheimer Satz (12 Wörter)`,
    enter: `Anmelden`,
    register: `Registrieren`,
    back: `Zurück`,
    nickname: `Spitzname`,
    secret: `Geheimer Satz`,
    secretDesc: `Bitte bewahren Sie den Satz an einem sicheren Ort auf. Er ermöglicht den Zugriff auf Ihr Konto und die hinzugefügten Stationen. Der Dienst speichert diesen Satz nicht.
    Wenn er verloren geht, ist eine Wiederherstellung des Zugriffs nicht möglich. Sie müssten ein neues Konto erstellen und die Stationen erneut hinzufügen`,
    secretCheck: `Ich bin einverstanden, dass bei Verlust des geheimen Satzes der Zugriff auf das Konto nicht wiederhergestellt werden kann`,
    errorInitKey: `Fehler bei der Initialisierung des Schlüssels, überprüfen Sie die Daten`,
    registerSuccess: `Registrierung erfolgreich`,
};

export default {
    nickname: `Dein Spitzname`,
    address: `Deine Adresse`,
    daysInMonth: `Basierend auf einem 30-Tage-Monat`,
    currentBalance: `Aktuelles Guthaben`,
    stationCount: `Anzahl der Stationen`,
    expensesMonth: `Ausgaben pro Monat`,
    enoughBalance: `Ausreichendes Guthaben`,
    currentTariff: `Aktueller Tarif`,
    optionPay: `Optionale Zahlung`,
    stationPerMonth: `Pro Station pro Monat`,
    payFirstStations: `Bezahlung für die ersten 5 Stationen`,
    notSelect: `Nicht ausgewählt`,
    changeTariff: `Tarif ändern`,
    createTariff: `Tarif erstellen`,
    fieldsCalc: `Berechnungsfelder`,
    stations: `Stationen`,
    mounths: `Monate`,
    changeBalanceThis: `Guthaben mit diesem Betrag ersetzen`,
    total: `Gesamt`,
    addBalance: `Guthaben hinzufügen`,
    summForAdd: `Hinzuzufügender Betrag, $`,
    pushToCopy: `Zum Kopieren anklicken`,
    textCopied: `Text in die Zwischenablage kopiert`,
    createBill: `Rechnung erstellen`,
    cancelBill: `Rechnung abbrechen`,
    addressToSend: `Adresse zur Zahlungsende`,
    or: `oder`,
    billPartner: `Rechnung zur Zahlung bei Partner`,
    go: `Los`,
    textBalance: `Kurze Zahlungsanweisungen (per Kreditkarte oder andere Methoden)
    <ol>
    <li>Erstellen Sie eine Rechnung auf dieser Seite</li>
    <li>Überweisen Sie Geld
    <ul>
    <li>Direkt - wenn Sie eine E-Wallet mit USDT bep20 Coins haben.</li>
    <!--<li>Zahlen Sie die in Rechnung gestellte Rechnung beim Partner - der Partner kann je nach Zahlungsmethode eine Provision erheben.</li>-->
    <li>Über einen Wechseldienst, zum Beispiel indem Sie ihn auf <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> auswählen (ohne Provision):
    <ol>
    <li>Gehen Sie zur <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">Aggregator-Seite</a></li>
    <li>Wählen Sie in der Spalte "Gibt" eine geeignete Zahlungsmethode wie "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>". 
Ich empfehle, in dieser Spalte die Zahlungsmethode mit dem niedrigsten Zinssatz für Ihr Land (etwa 3-5%) auszuwählen, normalerweise eine der beliebten Banken in der Landeswährung, 
und nicht eine direkte Überweisung in USD.</li>
    <li>In der Spalte "Empfang" sollte es sich um Tether bep20 handeln</li>
    <li>Wählen Sie einen Wechseldienst mit dem besten Kurs oder den besten Bedingungen</li>
    <li>Auf der Website des Wechslers geben Sie den Betrag ein, den Sie für die Aufladung in Dollar angegeben haben, kopieren Sie Ihre Aufladungsadresse (oben), füllen Sie die anderen Felder aus und überweisen Sie den Betrag</li>
    <li>Es wird vom Wechseldienst 20 Minuten lang verarbeitet und dann von meinem Service</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>Nachdem das Geld eingegangen ist, werden die Daten auf dieser Seite automatisch aktualisiert. Wenn die Rechnung innerhalb von 24 Stunden nicht bezahlt wird, wird sie automatisch storniert.</p>
    Die Zahlung für den Tarif wird täglich abgebucht, basierend auf der maximalen Anzahl von Stationen während des Tages.
    <p>Wenn Sie Probleme haben, schreiben Sie mir bitte auf
    <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
    <p>Wenn Sie zusätzliche Funktionen benötigen, schreiben Sie bitte darüber im
    <a href="{{tg_idea}}" target="_blank" class="link">Bereich Projektideen</a>.
    Die Idee wird entweder sofort umgesetzt oder von Gruppenmitgliedern zur Abstimmung gestellt und es wird eine Entscheidung zur Entwicklung getroffen. Vielen Dank im Voraus für Ihre Teilnahme!</p>`,

    textTariffCost: `Der Service ermöglicht es Ihnen, die monatlichen Kosten der Dienstleistungen selbst festzulegen. Diese Option
    dient auch als Bewertung der Idee hinter diesem Projekt. Je höher der von Ihnen angegebene Betrag ist, desto besser wird dieses Projekt sein,
    mit leistungsstärkeren Servern, weniger Fehlern, einer benutzerfreundlicheren Benutzeroberfläche und schnelleren Interaktionen.
    Vor allem werden neue Funktionen eingeführt, um die Verwaltung Ihrer Stationen zu erleichtern.`,

    textTariffFirstStations: `Wenn Sie das Projekt so weit unterstützen möchten, dass Sie sogar bereit sind, für die Stationen zu bezahlen, die für Sie kostenlos sind,
    sind Sie unglaublich fantastisch!`,

    textBillAmount: `Um Ihre Zahlung automatisch zu identifizieren und mit Ihrem Konto zu verknüpfen, müssen Sie den Betrag im Voraus eingeben
    und sicherstellen, dass er beim Transferieren der Gelder genau auf den Cent angegeben ist. Dezimalzahlen können dem Betrag hinzugefügt werden, wenn er bereits in der Datenbank zur Zahlung aussteht.`,
    textBillAddress: `Diese wird automatisch erstellt, wenn die Rechnung erstellt wird. Sie muss für jede Zahlungsmethode angegeben werden,
    achten Sie auf den Netzwerktyp "USDT bep20" (Binance)
    - Sie werden danach gefragt, wenn Sie eine Anfrage an einen beliebigen Kryptowährungsaustauschdienst stellen.`,
    textBillPartner: `Eine andere Möglichkeit besteht darin, bei einem Partner zu bezahlen. Die Rechnung für den ausgewählten Betrag wurde bereits erstellt, folgen Sie einfach dem
    Link und überweisen Sie das Geld auf bequeme Weise.`,

    billCreated: `Erstellt`,
    billPartPaid: `Teilweise bezahlt`,
    billPaid: `Bezahlt`,
    billCancel: `Abgebrochen`,
    billNum: `Rechnung Nr.`,
    tariffUpdated: `Tarif aktualisiert`,
    billCreatedFull: `Rechnung erstellt`,
    billPaidFull: `Rechnung bezahlt`,
    billCancelFull: `Rechnung abgebrochen`,
    errorSaveUserEmpty: `Fehler beim Speichern des Benutzers - leere Antwort`,
    settings: `Einstellungen`,
    tariff: `Tarif und Zahlung`,
    transactions: `Transaktionen`,

    type: `Typ`,
    createdAt: `Datum`,
    value: `Wert`,
    billString: `Rechnung`,
    tariffString: `Tarif`,
    stationsPay: `Stationen`,

    sortDateCreate: `Nach Erstellungsdatum`,
    transactionFilter: `Transaktionsfilter`,
};

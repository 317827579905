import React from "react";
import {Button, CircularProgress} from "@material-ui/core";
import {observer, useLocalObservable} from "mobx-react";
import {useLangMemo} from "../../lang/language";
import styles from "./myButton.module.scss";
import cn from "classnames";

function MyButton({children, onClick, disabled, iconM, text, ...props}: any) {
    const state = useLocalObservable(() => ({
        loading: false,
        setLoading(value) {
            this.loading = value;
        }
    }))
    let onClickLocal = async () => {
        state.setLoading(true)
        try {
            await onClick()
        } catch (e) {
        }
        state.setLoading(false)
    };

    text = useLangMemo(text)

    let icon = iconM && <span className={cn('material-icons', styles.icon)}>{iconM}</span>
    if(state.loading)
        icon = null

    let className = props.className || ''
    className += ` f-c ${styles.main} ` + (!children && styles.noChildren)

    return <Button variant="contained" color="primary" text={text} className={className} {...props}
                   disableElevation={true} disabled={state.loading || disabled} onClick={onClickLocal}>
        {state.loading && <CircularProgress style={{marginRight: '10px'}} size={20}/>}
        {icon} <span className='f-b'>{children}</span>
    </Button>

}

export default observer(MyButton);

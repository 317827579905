export default {
    nickname: `Tu apodo`,
    address: `Tu dirección`,
    daysInMonth: `Basado en un mes de 30 días`,
    currentBalance: `Saldo actual`,
    stationCount: `Número de estaciones`,
    expensesMonth: `Gastos por mes`,
    enoughBalance: `Saldo suficiente`,
    currentTariff: `Tarifa actual`,
    optionPay: `Pago opcional`,
    stationPerMonth: `Por estación por mes`,
    payFirstStations: `Pagar por las primeras 5 estaciones`,
    notSelect: `No seleccionado`,
    changeTariff: `Cambiar tarifa`,
    createTariff: `Crear tarifa`,
    fieldsCalc: `Campos de cálculo`,
    stations: `Estaciones`,
    mounths: `Meses`,
    changeBalanceThis: `Reemplazar saldo con esta cantidad`,
    total: `Total`,
    addBalance: `Agregar saldo`,
    summForAdd: `Cantidad a agregar, $`,
    pushToCopy: `Haz clic para copiar al portapapeles`,
    textCopied: `Texto copiado al portapapeles`,
    createBill: `Crear factura`,
    cancelBill: `Cancelar factura`,
    addressToSend: `Dirección para enviar el pago`,
    or: `o`,
    billPartner: `Factura para pagar en el socio`,
    go: `ir`,
    textBalance: `Instrucciones de pago breves (con tarjeta de crédito u otros métodos)
    <ol>
    <li>Crea una factura en esta página</li>
    <li>Transfiere dinero
    <ul>
    <li>Directamente: si tienes una billetera electrónica con monedas USDT bep20.</li>
    <!--<li>Paga la factura en el socio: el socio puede cobrar una comisión, según el método de pago.</li>-->
    <li>A través de un servicio de cambio, por ejemplo, seleccionándolo en <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> (sin comisión):
    <ol>
    <li>Ve a la <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">página del agregador</a></li>
    <li>En la columna "Da", selecciona un método de pago adecuado, como "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>". 
Recomiendo elegir en esta columna el método de pago con la tasa de interés más baja para tu país (aproximadamente 3-5%), generalmente uno de los bancos populares en la moneda local,
y no una transferencia directa en USD.</li>
    <li>En la columna "Recibe", debería ser Tether bep20</li>
    <li>Elige un servicio de cambio con la mejor tasa o condiciones</li>
    <li>En el sitio web del servicio de cambio, especifica la cantidad que ingresaste para recargar en dólares, copia tu dirección de recarga (arriba), completa los otros campos y realiza la transferencia</li>
    <li>El servicio de cambio lo procesará durante 20 minutos y luego mi servicio lo procesará</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>Después de recibir los fondos, los datos en esta página se actualizarán automáticamente. Si la factura no se ha pagado en un plazo de 24 horas, se cancelará automáticamente.</p>
    El pago de la tarifa se deduce diariamente, en función del número máximo de estaciones durante el día.
    <p>Si encuentras algún problema, escríbeme en
    <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
    <p>Si tienes alguna función adicional que necesitas, por favor descríbela en la sección de
    <a href="{{tg_idea}}" target="_blank" class="link">ideas del proyecto</a>.
    La idea se implementará de inmediato o se someterá a votación de los miembros del grupo, y se tomará una decisión sobre su desarrollo. ¡Gracias de antemano por tu participación!</p>`,

    textTariffCost: `El servicio te permite elegir el costo mensual de los servicios tú mismo. Esta opción
    también sirve como evaluación de la idea detrás de este proyecto. Cuanto mayor sea la cantidad que especifiques, mejor será este proyecto,
    con servidores más potentes, menos errores, una interfaz más amigable y interacciones más rápidas.
    Lo más importante es que se introducirán nuevas características para facilitar la gestión de tus estaciones.`,

    textTariffFirstStations: `¡Si deseas apoyar el proyecto hasta tal punto que incluso estés dispuesto a pagar por las estaciones que son gratuitas para ti,
    ¡eres increíblemente asombroso!`,

    textBillAmount: `Para identificar automáticamente tu pago y asociarlo con tu cuenta, debes ingresar
    la cantidad por adelantado y asegurarte de que esté especificada con precisión hasta el centavo al transferir los fondos.
    Se pueden agregar números decimales a la cantidad si ya está esperando el pago en la base de datos.`,
    textBillAddress: `Se genera automáticamente al crear la factura. Debe proporcionarse para cualquier método de pago.
    Presta atención al tipo de red "USDT bep20" (Binance): se te solicitará cuando envíes una solicitud a cualquier servicio de intercambio de criptomonedas.`,
    textBillPartner: `Otra forma es pagar en un socio, la factura por el monto seleccionado ya ha sido creada, simplemente sigue
    el enlace y transfiere el dinero de la manera conveniente.`,

    billCreated: `Creada`,
    billPartPaid: `Parcialmente pagada`,
    billPaid: `Pagada`,
    billCancel: `Cancelada`,
    billNum: `Factura N.º`,
    tariffUpdated: `Tarifa actualizada`,
    billCreatedFull: `Factura creada`,
    billPaidFull: `Factura pagada`,
    billCancelFull: `Factura cancelada`,
    errorSaveUserEmpty: `Error al guardar el usuario - respuesta vacía`,
    settings: `Configuraciones`,
    tariff: `Tarifa y Pago`,
    transactions: `Transacciones`,

    type: `Tipo`,
    createdAt: `Fecha`,
    value: `Valor`,
    billString: `Factura`,
    tariffString: `Tarifa`,
    stationsPay: `Estaciones`,

    sortDateCreate: `Por fecha de creación`,
    transactionFilter: `Filtro de transacciones`,
};

import {observer} from "mobx-react";
import React, {useEffect, useMemo} from "react";
import styles from './tableObject.module.scss';
import cn from 'classnames';
import RootStore from "../../stores/rootStore";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Helper from "../../utils/helper";
import TableObjectStore from "../../stores/tableObjectStore";
import MyInputInterface from "../../stores/interfaces/myInputInterface";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function TableObject({objectStore, store}: { objectStore?: TableObjectStore, store?: MyInputInterface }) {
    const rootStore = RootStore.getInstance();
    if (!objectStore && store)
        objectStore = store.objectStore
    //let object = store.getFields(objectStore.fields); // or array if target is array

    let rowsSelectedCount = 0;
    let rowsSelected = objectStore.rows.filter(el => {
        if (el.selected)
            rowsSelectedCount++
        return el.selected
    });
    let rowsSelectedAll = rowsSelected.length === objectStore.rows.length && objectStore.rows.length != 0;

    useMemo(() => {
        objectStore.rows.forEach(el => el.selected === undefined && objectStore.changeFields(['selected'], false, el))
    }, [objectStore.rows]);

    useEffect(() => {
        store.onMount && store.onMount()
        // returned function will be called on component unmount
        return () => {
            //store.onUnmount && store.onUnmount()
        }
    }, [])

    function getTableHead() {
        let columns = objectStore.columns;
        return <>
            {columns.map((col) => {
                let res, smallPad;

                function wrapContainer(res) {
                    return <div className={cn(styles.textContainer)} style={{
                        minWidth: `${col.minWidth}px`,
                        maxWidth: `${col.maxWidth}px`,
                        width: `${col.width}px`
                    }}>{res}</div>
                }

                switch (col.type) {
                    case 'dropdown':
                    case 'text':
                    case 'custom':
                    case 'color':
                        res = wrapContainer(col.name);
                        break;
                    case 'checkbox_select':
                        smallPad = true;
                        res = <Checkbox
                            size='small'
                            checked={rowsSelectedAll}
                            //color="primary"
                            className={cn('noColor')}
                            onChange={(e, checked) => {
                                objectStore.rows.forEach(el => objectStore.changeFields(['selected'], checked, el))
                            }}
                        />
                        break;
                    case 'checkbox':
                        smallPad = true;
                        if (col.allChange)
                            res = <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={rowsSelectedAll}
                                        //name={col.name}
                                        //color="primary"
                                        className={cn('noColor')}
                                        onChange={(e, checked) => {
                                            //objectStore.entities.forEach(el => store.changeFields(['selected'],  checked, el))
                                        }}
                                    />
                                }
                                label={col.name}
                            />
                        else
                            res = wrapContainer(col.name);
                        break;
                }
                return <TableCell key={col.field || col.fieldRow} className={cn({
                    [styles.smallPad]: smallPad
                })}>{res}</TableCell>;
            })}
        </>;
    }

    function getTableBody() {
        let columns = objectStore.columns;
        let rows = objectStore.rows;
        if (objectStore.isArray)
            rows = store.getFields(objectStore.fields)

        return rows.map((row, i, array) => {
                let entity;

                let objectRowFields = [...objectStore.fields];

                if (!objectStore.isArray && row.fields)
                    objectRowFields.push(...row.fields);

                try {
                    entity = store.getFields(objectRowFields)
                } catch (e) {
                    // nothing to do
                }
                if (!entity) {
                    store.changeFields(objectRowFields, objectStore.isArray ? [] : {});
                    entity = store.getFields(objectRowFields);
                }


                let key = (row.fields?.toString() || row.value) + '';
                return <TableRow key={key} className={cn({
                    [styles.selected]: row.selected
                })}>
                    {columns.map((col) => {
                        let res, smallPad;

                        function wrapContainer(res, title?) {
                            if (Helper.isArray(res))
                                res = res.map(el => {
                                    return <>
                                        {el} <br/>
                                    </>
                                })
                            //console.log('res ' + res)
                            return <div className={cn(styles.textContainer)} style={{
                                minWidth: `${col.minWidth}px`,
                                maxWidth: `${col.maxWidth}px`,
                                width: `${col.width}px`
                            }} title={title || res}>{res}</div>
                        }

                        let value: any = '';

                        if (col.getValue) {
                            value = col.getValue(row)
                        } else if (col.fieldRow) {
                            value = row[col.fieldRow];
                        } else if (!objectStore.isArray && col.field === '') {
                            value = entity;
                        } else if (!objectStore.isArray) {
                            value = store.getFields([col.field], entity);
                        }

                        switch (col.type) {
                            case 'text':
                                let link = col.getLink && col.getLink(objectStore.isArray ? row : entity);
                                if (link)
                                    res =
                                        <a href={link} //<NavLink id={el2.name} to={el.link + el2.link}
                                           onClick={e => {
                                               if (col.onClick) {
                                                   col.onClick(objectStore.isArray ? row : entity);
                                                   e.preventDefault();
                                               }
                                           }}
                                           className={cn(styles.clickField)}>{value}</a>
                                else
                                    res = value
                                res = wrapContainer(res, value);
                                break;
                            case 'checkbox':
                                smallPad = true;
                                //let checked = value;
                                if (value === undefined) { // "uncontrolled" issue
                                    store.changeFields([col.field], false, objectStore.isArray ? row : entity);
                                    value = store.getFields([col.field], objectStore.isArray ? row : entity);
                                }
                                //console.log('render checkbox ' + objectRowFields.concat('_') + ' ' + col.field + ' value: ' + value);
                                res = <Checkbox
                                    size='small'
                                    //name={col.name}
                                    //color="primary"
                                    className={cn('noColor')}
                                    checked={value}
                                    onChange={(el, checked1) => {
                                        //let valueCur = store.getFields([col.field], entity);
                                        store.changeFields([col.field], !value, objectStore.isArray ? row : entity);
                                        //console.log('change checkbox ' + objectRowFields.concat('_') + ' ' + col.field + ' value: ' + !value);

                                        if (objectStore.lastClick?.field === col.field) {
                                            if (rootStore.keysDowns.find(el => el.keyCode === 16)) {
                                                //if (e.nativeEvent.shiftKey) {
                                                let lastIndex = objectStore.lastClick.index;
                                                let curIndex = i;
                                                if (lastIndex != curIndex) {
                                                    let from = Math.min(lastIndex, curIndex);
                                                    let to = Math.max(lastIndex, curIndex);
                                                    for (let i = from; i < to; i++) {
                                                        let row = rows[i];

                                                        let objectRowFieldsTmp = [...objectStore.fields];
                                                        if (!objectStore.isArray && row.fields)
                                                            objectRowFieldsTmp.push(...row.fields);

                                                        let entityTmp = store.getFields(objectRowFieldsTmp)
                                                        /*try {
                                                            entityTmp = store.getFields(objectRowFieldsTmp)
                                                        } catch (e) {
                                                            // nothing to do
                                                            console.error('Can not get entityTmp');
                                                        }
                                                        if (!entityTmp) {
                                                            store.changeFields(objectRowFieldsTmp, {});
                                                            entityTmp = store.getFields(objectRowFieldsTmp);
                                                        }*/

                                                        store.changeFields([col.field], !value, entityTmp);
                                                        //console.log('change checkbox ' + objectRowFieldsTmp.concat('_') + ' ' + col.field + ' value: ' + !value);
                                                    }
                                                }
                                            }
                                        }
                                        objectStore.lastClick = col.field ? {index: i, field: col.field} : null;

                                    }}
                                />
                                break;
                        }

                        return <TableCell key={col.field || col.fieldRow}
                                          className={cn({
                                              [styles.smallPad]: smallPad
                                          })}
                        >{res}</TableCell>;
                    })}
                </TableRow>
            }
        );
    }

    let refMain: any = useMemo(() => React.createRef(), []);

    return (
        <div ref={refMain} className={cn(styles.wrap, 'grid dir-col')}>
            <div className={cn(styles.main, 'col')}>
                <div className={cn(styles.tableContainer, '')}>
                    <div className={cn(styles.tableWrap, '')}>
                        <Table className={cn(styles.table, {
                            [styles.compact]: true
                        })} size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        getTableHead()
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getTableBody()
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(TableObject);

import React from "react";
import {FormControl, FormLabel} from "@material-ui/core";
import styles from "./sections.module.scss";
import cn from 'classnames';
import MyInputInterface from "../../stores/interfaces/myInputInterface";
import {observer} from "mobx-react";

interface Props {
    store: MyInputInterface;
    item?: object;
    fields?: string[];
    name: string;
    className?: string;

    [key: string]: any;
}

function Sections({store, fields, name, className, isDisabled, item, size = 'small', onlyData, storeField, ...props}: Props) {

    let arrayFields;
    if (item) {
        arrayFields = fields;
    } else {
        arrayFields = [storeField, ...fields];
    }

    let sections = store.getFields(arrayFields, item);
    let hasSections = sections && sections.length;

    let disabled = store.isDisabled && store.isDisabled({fields: arrayFields, item});
    if (disabled === undefined)
        disabled = isDisabled;

    let res = <div className={cn('grid', styles.data)}>
        <div className={cn('col-fixed grid', styles.sections, {
            'o-3': disabled
        })}>
            {
                hasSections > 0 ? sections.map(section => <div
                        className={cn('col-fixed grid dir-col', styles.section)}>
                        <div className={cn('col-fixed f-b grid dir-col-rev', styles.sectionItems)}
                             title={`Свободно ${section.volumeFree}`}>
                            {
                                section.items &&
                                section.items.map(item => <div key={item.itemId}
                                                               className={cn('col-fixed', styles.sectionItem,{
                                                                   [styles.notFit]: !!item.notFit
                                                               })}
                                                               style={{
                                                                   height: `${item.percentOccupied * 100}%`,
                                                                   background: item.color || (item.isCurrent ? '#53a30e' : '#CA2C2C')
                                                               }}
                                                               title={`Занято ${item.volumeOccupied}${item.notFit ? ' Не поместилось ' + item.notFit : ''}`}
                                />)
                            }
                        </div>
                        <div className={cn('col-fixed f-b', styles.sectionValue)}
                             title={`Свободно ${section.volumeFree}`}>
                            {section.volume}
                        </div>
                    </div>) :
                    <div className={cn('col-fixed', styles.empty)} style={{marginTop: onlyData && '4px'}}>Выберите транспорт</div>
            }
        </div>
    </div>;

    if(onlyData)
        return res;

    return <div className={`${cn('grid', styles.main)} ${className}`}>
        <FormControl
            disabled={disabled}
            variant={props.variant || 'outlined'}
            style={{width: props.width}}
            size={size}
            className={cn(styles.inputDropdown)}>
            <FormLabel id="select-label">{hasSections ? '' : name}</FormLabel>
            { res }
        </FormControl>
    </div>;
}

export default observer(Sections);


import {ethers, Wallet} from "ethers";
import Helper from "./helper";
import UserServiceStore from "../stores/userServiceStore";

export default class CryptoService {

    static async generateWallet() {
        let walletRandom = Wallet.createRandom()

        return {
            mnemonic: walletRandom.mnemonic.phrase,
            address: walletRandom.address,
            privateKey: walletRandom.privateKey,
            publicKey: walletRandom.publicKey,
            wallet: walletRandom}

    }


    static async sign(privateKey, object) {
        //let objectSorted = HelperService.sortJson(object)
        let message = JSON.stringify(object)
        //console.log("message " + message)
        const signer = new ethers.Wallet(privateKey);
        let signature = await signer.signMessage(message);
        return signature
    }

    static async signQuery(query, wallet?) {
        query.time = Date.now()
        if (!wallet)
            wallet = UserServiceStore.getInstance().wallet
        query = JSON.parse(JSON.stringify(query)) // clear from object types
        let querySorted = Helper.sortJson(query)
        querySorted.sign = await CryptoService.sign(wallet.privateKey, querySorted)
        return querySorted
    }

    static async verify(message, signature, address) {
        return ethers.utils.verifyMessage(message, signature) === address;
    }

    static fromMnemonic(mnemonic) {
        if(mnemonic.constructor.name === 'Array')
            mnemonic = mnemonic.join(' ')
        return Wallet.fromMnemonic(mnemonic)
    }

    static fromPrivateKey(privateKey:string) {
        return privateKey ? new Wallet(privateKey) : undefined
    }
}


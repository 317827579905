export default {
    stations: `Stations`,
    filter: `Station Filter`,
    comment: `Comment`,
    comment_add: "Add Comment",
    comment_edit: "Edit Comment",
    sortDate: `Sort by Date`,
    checkbox: `Checkbox`,
    select: `Select`,
    actions: `Actions`,
    showStation: `Show Station`,
    delete: `Unlink`,
    number: `Number`,
    name: `Name`,
    bootTimestamp: `Boot Timestamp`,
    bootTimestampM: `Boot`,
    cpuUsageTotal: `CPU, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `RAM, %`,
    memoryPercentM: `RAM`,
    ipAddress: `IP Address`,
    ipAddressM: `IP`,
    disks: `Free Space`,
    disksM: `Disks`,
    updatedAt: `Last Update`,
    system: `OS`,
    release: `Release`,
    stationSelect: `Station Selection`,
    stationsDel: `Stations Unlinked`,
    stationDel: `Station Unlinked`,
    downloadClient: `Download Client`,
    download: `Download`,
    online: `Online`,
    offline: `Offline`,

    toDo: `To Do`,
    done: `Done`,
    error: `Error`,
    canceled: `Canceled`,

    info: `Info`,
    power: `Power`,
    process: `Processes`,
    programs: `Programs`,
    devices: `Devices`,
    commands: `Commands`,
    other: `Other`,

    reboot: `Reboot`,
    shutdown: `Shutdown`,
    sleep: `Sleep`,
    hibernate: `Hibernate`,
    lock: `Lock`,

    refill: `Refill`,
    writeOff: `Write Off`,
    withdrawal: `Withdrawal`,
    dateUpdate: `Date Updated`,
    autoUpdate: `Auto Update`,
    autoUpdateM: `Auto Upd.`,
    commandError: `Error Creating Command`,
    commandSend: `Command Sent`,

    visualControl: `Visual Control`,
    doCommand: `Execute Command`,
    viewStation: `Station Overview`,
    metaDesc: `Login to the remote computer and server control panel`,
    confirmAction: `Confirm Action`,
    confirmActionText: `Are you sure you want to execute the "{{cmd}}" command?`,
    waitingExe: `Waiting for Execution...`,
    successEmpty: `Success, but empty response`,
    resultExe: `Execution Result`,
    enterCommand: `Enter Command`,
    exeCustomCommand: `Execute Custom Command`,
    enterEnable: `Press ENTER to Send`,
    visualControlStation: `Visual Control of the Station`,
    add: `Add Station`,
    password: `Password`,
    stationAdded: `Station Added`,
    passwordLength: `Password must be more than 6 characters`,

    cmd: {
        status: `Status`,
        type: `Type`,
        createdAtS: `Created At`,
        checkbox: `Checkbox`,
        remove: `Remove`,
        createdAt: `Date`,
        raw: `Data`,
        user: `User`,
        result: `Result`,
        error: `Error`,
        filterName: `Command Filter`,
        removeE: `Command Removal Error`,
        removeS: `Successful Removal`,
    },

    devs: {
        name: `Name`,
        group: `Group`,
        status: `Status`,
        manufacturer: `Manufacturer`,
        deviceID: `Device ID`,
        driver: `Driver`,
        nameS: `By Name`,
        groupS: `By Group`,
        filterName: `Device Filter`,
        dateUpdate: `Date Updated`,
    },

    inf: {
        group: `Group`,
        name: `Name`,
        value: `Value`,

        general: `General Information`,
        node: `Computer Name`,
        bootTimestamp: `Boot Time`,
        updatedAt: `Data Updated At`,
        system: `System`,
        release: `Release`,
        version: `Version`,
        programVersion: `Program Version`,
        memory: `Memory`,
        memoryTotal: `Total`,
        memoryAvailable: `Memory Available`,
        memoryUsed: `Memory Used`,
        memoryPercent: `Memory Used, %`,
        swapTotal: `Swap Total`,
        swapFree: `Swap Free`,
        swapUsed: `Swap Used`,
        swapPercent: `Swap Used, %`,
        processor: `Processor`,
        processorVendor: `Vendor`,
        processorBrand: `Brand`,
        cpuUsageTotal: `Total CPU Usage, %`,
        cpuCores: `Cores`,
        cpuTotalCores: `Total Threads`,
        cpuFreqCurrent: `Current Frequency`,
        net: `Network`,
        ipAddress: `IP Address`,
        macAddress: `MAC Address`,
        interfaces: `Network Interfaces`,
        totalSend: `Total Sent`,
        totalReceived: `Total Received`,
        persistent: `Persistent Memory`,
        disks: `Disks`,
        dateUpdate: `Date Updated`,
        autoUpdate: `Auto Update`,
        free: `Free`,
    },

    proc: {
        name: `Name`,
        exe: `Path`,
        username: `User`,
        pidS: `Process ID`,
        nameS: `By Name`,
        cpuPercent: `CPU, %`,
        memoryPercent: `Memory, %`,
        createTimeS: `By Start Time`,
        usernameS: `By User`,
        checkbox: `Checkbox`,
        finishP: `Finish Process`,
        finishPs: `Finish Processes`,
        pid: `ID`,
        createTime: `Start`,
        diskUsageRead: `Disk Read`,
        diskUsageReadTotal: `Total Read`,
        diskUsageWrite: `Disk Write`,
        diskUsageWriteTotal: `Total Write`,
        status: `Status`,
        filterName: `Process Filter`,
    },

    prog: {
        startRemove: `Start Program Removal`,
        name: `Name`,
        installDate: `Installation Date`,
        version: `Version`,
        size: `Size`,
        description: `Description`,
        publisher: `Publisher`,
        location: `Installation Location`,
        uninstall: `Uninstall Path`,
        depends: `Dependencies`,
        nameS: `By Name`,
        installDateS: `By Installation Date`,
        filterName: `Program Filter`,
        confirmAction: `Confirm Action`,
        confirmActionText: `Are you sure you want to trigger the removal of "{{name}}"?
        Buttons action may be needed to click`,
    },

    control:{
        prepare: `Preparing the Route...`,
        connecting: `Connecting`,
        connected: `Connected`,
        disconnected: `Disconnected`,
        error: `Error`,
        tryAgain: `Try Again`,
        zoom: `Zoom In`,
        zoomOut: `Zoom Out`,
        showAllButtons: `Show All Buttons`,
        display: `Display`,
        size: `Size`,
        keys: `Keys`,
        showHideKey: `Show/Hide Keyboard`,
        fitScreen: `Fit to Screen`,
        fullscreen: `Fullscreen`,
        tune: `Customize`,
        cursorMove: `Move Cursor`,
        move: `By Moving`,
        click: `By Clicking`,
    },

    aiControl: 'AI Control',
    aiControlStation: 'AI Control Station',
    ai: {
        title: 'AI Station Control',
        autoApply: 'Apply commands automatically',
        autoContinueError: 'Continue processing errors',
        send: 'Send (Ctrl + Enter)',
        input: 'Your message',
        errorLength: 'Message is too short',
        process: 'Processing',
        success: 'Success',
        error: 'Error',
        showMore: 'Show more',
        continue: 'Continue',
        doCommand: 'Do Command',
        commandAny: 'Do any command',
        refine: 'Refine answer',
        newQuery: 'New query',
        auto: 'Auto',
        model: 'AI model',
        tokensLeft: 'Tokens left: {{value}}',
        allUsers: 'All users',
        tokenHelp: 'The text sent to the AI model is split into tokens. A token can be a single character, a single word, or even a single sentence, depending on the language and context. For example, the text "ChatGPT is great!" can be split into the following tokens: ["Chat", "G", "PT", " is", " great", "!"].',
        enableHTML: 'Enable result display in HTML',
        userTokens: 'Number of tokens used in the current message. It is recommended to create a new query if it relates to a different context.',
        inProgress: 'Processing information...',
        previewCommand: 'The following command will be executed',
        "userToken": "User API key",
        "getToken": "Get key"
    },
};

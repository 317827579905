export default {
    stations: `Станции`,
    filter: `Фильтр станций`,
    comment: `Комментарий`,
    comment_add: `Добавить комментарий`,
    comment_edit: `Редактировать комментарий`,
    sortDate: `По дате создания`,
    checkbox: `Выделение`,
    select: `Выбрать`,
    actions: `Действия`,
    showStation: `Показать станцию`,
    delete: `Отвязать`,
    number: `Номер`,
    name: `Имя`,
    bootTimestamp: `Дата включения`,
    bootTimestampM: `Вкл.`,
    cpuUsageTotal: `CPU, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `RAM, %`,
    memoryPercentM: `RAM`,
    ipAddress: `IP адрес`,
    ipAddressM: `IP`,
    disks: `Места свободно`,
    disksM: `Диски`,
    updatedAt: `Дата обновления`,
    system: `ОС`,
    release: `Релиз`,
    stationSelect: `Выбор станции`,
    stationsDel: `Станции отвязаны`,
    stationDel: `Станция отвязана`,
    downloadClient: `Скачать клиент`,
    download: `Скачать`,
    online: `Онлайн`,
    offline: `Оффлайн`,

    toDo: `К исполнению`,
    done: `Успешно`,
    error: `Ошибка`,
    canceled: `Отменена`,

    info: `Инфо`,
    power: `Питание`,
    process: `Процессы`,
    programs: `Программы`,
    devices: `Устройства`,
    commands: `Команды`,
    other: `Другое`,

    reboot: `Перезагрузка`,
    shutdown: `Выключение`,
    sleep: `Сон`,
    hibernate: `Гибернация`,
    lock: `Блокировка`,

    refill: `Пополнение`,
    writeOff: `Списание`,
    withdrawal: `Вывод`,
    dateUpdate: `Дата обновления`,
    autoUpdate: `Авто. обновление`,
    autoUpdateM: `Автообн.`,
    commandError: `Ошибка создания команды`,
    commandSend: `Команда отправлена`,

    visualControl: `Визуальное управление`,
    doCommand: `Выполнить команду`,
    viewStation: `Обзор станции`,
    metaDesc: `Вход в панель управления удаленного доступа к компьютерами и серверам`,
    confirmAction: `Подтвердите действие`,
    confirmActionText: `Вы уверены, что хотите выполнить команду "{{cmd}}"?`,
    waitingExe: `Ожидаю выполнения...`,
    successEmpty: `Успешно, но ответ пустой`,
    resultExe: `Результат выполнения`,
    enterCommand: `Введите команду`,
    exeCustomCommand: `Выполнить произвольную команду`,
    enterEnable: `Отправка по нажатию ENTER включена`,
    visualControlStation: `Визуальное управление станцией`,
    add: `Добавление станции`,
    password: `Пароль`,
    stationAdded: `Станция добавлена`,
    passwordLength: `Пароль должен быть больше 6 символов`,

    cmd: {
        status: `Статус`,
        type: `Тип`,
        createdAtS: `Дате создания`,
        checkbox: `Выделение`,
        remove: `Удалить`,
        createdAt: `Дата`,
        raw: `Данные`,
        user: `Пользователь`,
        result: `Результат`,
        error: `Ошибка`,
        filterName: `Фильтр команд`,
        removeE: `Ошибка удаления команд`,
        removeS: `Успешное удаление`,
    },

    devs: {
        name: `Имя`,
        group: `Группа`,
        status: `Статус`,
        manufacturer: `Производитель`,
        deviceID: `ID девайса`,
        driver: `Драйвер`,
        nameS: `По имени`,
        groupS: `По группе`,
        filterName: `Фильтр устройств`,
        dateUpdate: `Дата обновления`,
        serial: `Серийный номер`,
    },

    inf: {
        group: `Группа`,
        name: `Имя`,
        value: `Значение`,

        general: `Общая информация`,
        node: `Имя компьютера`,
        bootTimestamp: `Время включения`,
        updatedAt: `Время актуальности данных`,
        system: `Система`,
        release: `Релиз`,
        version: `Версия`,
        programVersion: `Версия программы`,
        memory: `Оперативная память`,
        memoryTotal: `Всего`,
        memoryAvailable: `Памяти свободно`,
        memoryUsed: `Памяти используется`,
        memoryPercent: `Памяти используется, %`,
        swapTotal: `Подкачка всего`,
        swapFree: `Подкачка свободно`,
        swapUsed: `Подкачка используется`,
        swapPercent: `Подкачка используется, %`,
        processor: `Процессор`,
        processorVendor: `Производитель`,
        processorBrand: `Бренд`,
        cpuUsageTotal: `Нагрузка всего, %`,
        cpuCores: `Ядер`,
        cpuTotalCores: `Потоков`,
        cpuFreqCurrent: `Текущая частота`,
        net: `Сеть`,
        ipAddress: `IP адрес`,
        macAddress: `Mac адрес`,
        interfaces: `Сетевые интерфейсы`,
        totalSend: `Всего отправлено`,
        totalReceived: `Всего получено`,
        persistent: `Постоянная память`,
        disks: `Диски`,
        dateUpdate: `Дата обновления`,
        autoUpdate: `Авто. обновление`,
        free: `свободно`,
    },

    proc: {
        name: `Имя`,
        exe: `Путь`,
        username: `Пользователь`,
        pidS: `ID процесса`,
        nameS: `По имени`,
        cpuPercent: `ЦП, %`,
        memoryPercent: `Память, %`,
        createTimeS: `По дате запуска`,
        usernameS: `По пользователю`,
        checkbox: `Выделение`,
        finishP: `Завершить процесс`,
        finishPs: `Завершить процессы`,
        pid: `ID`,
        createTime: `Запуск`,
        diskUsageRead: `Диск чтение`,
        diskUsageReadTotal: `Всего чтение`,
        diskUsageWrite: `Диск запись`,
        diskUsageWriteTotal: `Всего запись`,
        status: `Статус`,
        filterName: `Фильтр процессов`,
    },

    prog: {
        startRemove: `Запустить удаление программы`,
        name: `Имя`,
        installDate: `Дата установки`,
        version: `Версия`,
        size: `Размер`,
        description: `Описание`,
        publisher: `Издатель`,
        location: `Путь установки`,
        uninstall: `Путь удаления`,
        depends: `Зависимости`,
        nameS: `По имени`,
        installDateS: `По дате установки`,
        filterName: `Фильтр программ`,
        confirmAction: `Подтвердите действие`,
        confirmActionText: `Вы уверены, что хотите вызвать удаление "{{name}}" ?
        Может потребоваться нажатие на кнопки действия`,
    },

    control:{
        prepare: `Прокладываю маршрут...`,
        connecting: `Соединяюсь`,
        connected: `Соединено`,
        disconnected: `Отсоединено`,
        error: `Ошибка`,
        tryAgain: `Попробовать снова`,
        zoom: `Приблизить`,
        zoomOut: `Отдалить`,
        showAllButtons: `Показать все кнопки`,
        display: `Экран`,
        size: `Размер`,
        keys: `Клавиши`,
        showHideKey: `Показать/скрыть клавиатуру`,
        fitScreen: `Вписать в экран`,
        fullscreen: `Полный размер`,
        tune: `Настраиваемый`,
        cursorMove: `Перемещение указателя`,
        move: `Движением`,
        click: `Нажатием`,
    },

    aiControl: `ИИ управление`,
    aiControlStation: `Управление станцией искусственным интеллектом`,
    ai: {
        title: `Управление станцией ИИ`,
        autoApply: `Выполнять команды`,
        autoContinueError: `Обрабатывать ошибки`,
        send: `Отправить (Ctrl + Enter)`,
        input: `Ваше сообщение`,
        errorLength: `Сообщение слишком короткое`,
        process: `Выполняется`,
        success: `Успешно`,
        error: `Ошибка`,
        showMore: `Показать подробности`,
        continue: `Обработать`,
        doCommand: `Выполнить команду`,
        commandAny: `Выполнить произвольную команду`,
        refine: `Улучшить ответ`,
        newQuery: `Новый запрос`,
        auto: `Автоматически`,
        model: `Модель ИИ`,
        tokensLeft: `Осталось {{value}} токенов`,
        allUsers: `у всех пользователей`,
        tokenHelp: `Отправляемый текст в ИИ модель разбивается на токены. Токеном может быть один символ, одно слово или даже одно предложение, в зависимости от языка и контекста. Например, текст "ChatGPT is great!" может быть разбит на следующие токены: ["Chat", "G", "PT", " is", " great", "!"].`,
        enableHTML: `Включить отображение результата в HTML`,
        userTokens: `Количество использованных токенов в текущем сообщении. Рекомендуется создавать новый запрос, если он относится к другому контексту`,
        inProgress: `Обрабатываю информацию...`,
        previewCommand: `Будет выполнена следующая команда`,
        userToken: `Пользовательский API ключ`,
        getToken: `Получить ключ`,
    }
};

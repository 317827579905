export default {
  mainSite: `转到主网站`,
  menu: `菜单`,
  stations: `站点`,
  exit: `退出`,
  feedback: `反馈`,
  balance: `余额`,
  sortNo: `无排序`,
  sortDown: `降序`,
  sortUp: `升序`,
  sorting: `排序`,
  loadMore: `加载更多`,
  nothingFound: `未找到任何结果`,
  select: `选择`,
  refresh: `刷新`,
  refreshData: `刷新数据`,
  filter: `过滤`,
  filterOpen: `打开过滤器`,
  filterClear: `清除过滤器`,
  add: `添加`,
  downloadExcel: `下载为Excel`,
  openSettings: `打开设置`,
  back: `返回`,
  actions: `操作`,
  noData: `无数据`,
  addLine: `添加行`,
  change: `更改`,
  create: `创建`,
  open: `打开`,
  selectFromList: `从列表中选择`,
  loading: `加载中...`,
  showQR: `显示QR码`,
  save: `保存`,
  cancel: `取消`,
  yes: `是`,
  no: `否`,
  close: `关闭`,
  make: `执行`,
  checkbox: `选择`,
  notFound: `未找到`,
  errorUpdate: `更新错误`,
  errorGetUserEmpty: `获取用户错误 - 空响应`,
  dayS: `天`,
  successfully: `成功`,
  errorDetails: `错误详细信息`,
  clean: `清理`,
  apply: `应用`,
  settings: '设置',
  price: '价格',
};
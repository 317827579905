export default {
    stations: `Estaciones`,
    filter: `Filtro`,
    comment: `Comentario`,
    comment_add: "Agregar comentario",
    comment_edit: "Editar comentario",
    sortDate: `Ordenar por fecha de creación`,
    checkbox: `Seleccionar`,
    select: `Seleccionar`,
    actions: `Acciones`,
    showStation: `Mostrar estación`,
    delete: `Eliminar`,
    number: `Número`,
    name: `Nombre`,
    bootTimestamp: `Fecha de inicio`,
    bootTimestampM: `Inicio`,
    cpuUsageTotal: `Uso de CPU, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `Uso de memoria, %`,
    memoryPercentM: `Memoria`,
    ipAddress: `Dirección IP`,
    ipAddressM: `IP`,
    disks: `Espacio disponible`,
    disksM: `Discos`,
    updatedAt: `Fecha de actualización`,
    system: `Sistema operativo`,
    release: `Versión`,
    stationSelect: `Seleccionar estación`,
    stationsDel: `Estaciones eliminadas`,
    stationDel: `Estación eliminada`,
    downloadClient: `Descargar cliente`,
    download: `Descargar`,
    online: `En línea`,
    offline: `Desconectado`,

    toDo: `Pendiente`,
    done: `Completado`,
    error: `Error`,
    canceled: `Cancelado`,

    info: `Información`,
    power: `Energía`,
    process: `Procesos`,
    programs: `Programas`,
    devices: `Dispositivos`,
    commands: `Comandos`,
    other: `Otro`,

    reboot: `Reiniciar`,
    shutdown: `Apagar`,
    sleep: `Suspender`,
    hibernate: `Hibernar`,
    lock: `Bloquear`,

    refill: `Recargar`,
    writeOff: `Registrar`,
    withdrawal: `Retirar`,
    dateUpdate: `Fecha de actualización`,
    autoUpdate: `Actualización automática`,
    autoUpdateM: `Auto. Actualización`,
    commandError: `Error al crear el comando`,
    commandSend: `Comando enviado`,

    visualControl: `Control visual`,
    doCommand: `Ejecutar comando`,
    viewStation: `Ver estación`,
    metaDesc: `Acceso remoto para controlar ordenadores y servidores`,
    confirmAction: `Confirmar acción`,
    confirmActionText: `¿Estás seguro de que quieres ejecutar el comando "{{cmd}}"?`,
    waitingExe: `Esperando ejecución...`,
    successEmpty: `Éxito, pero respuesta vacía`,
    resultExe: `Resultado de la ejecución`,
    enterCommand: `Ingresa un comando`,
    exeCustomCommand: `Ejecutar comando personalizado`,
    enterEnable: `Habilitar envío con la tecla Enter`,
    visualControlStation: `Control visual de la estación`,
    add: `Agregar estación`,
    password: `Contraseña`,
    stationAdded: `Estación agregada`,
    passwordLength: `La contraseña debe tener más de 6 caracteres`,

    cmd: {
        status: `Estado`,
        type: `Tipo`,
        createdAtS: `Fecha de creación`,
        checkbox: `Seleccionar`,
        remove: `Eliminar`,
        createdAt: `Fecha`,
        raw: `Datos`,
        user: `Usuario`,
        result: `Resultado`,
        error: `Error`,
        filterName: `Filtrar comandos`,
        removeE: `Error al eliminar comandos`,
        removeS: `Eliminación exitosa`,
    },

    devs: {
        name: `Nombre`,
        group: `Grupo`,
        status: `Estado`,
        manufacturer: `Fabricante`,
        deviceID: `ID de dispositivo`,
        driver: `Controlador`,
        nameS: `Por nombre`,
        groupS: `Por grupo`,
        filterName: `Filtrar dispositivos`,
        dateUpdate: `Fecha de actualización`,
    },

    inf: {
        group: `Grupo`,
        name: `Nombre`,
        value: `Valor`,

        general: `Información general`,
        node: `Nombre del equipo`,
        bootTimestamp: `Tiempo de inicio`,
        updatedAt: `Tiempo de actualización de los datos`,
        system: `Sistema operativo`,
        release: `Versión`,
        version: `Versión`,
        programVersion: `Versión del programa`,
        memory: `Memoria`,
        memoryTotal: `Total`,
        memoryAvailable: `Disponible`,
        memoryUsed: `En uso`,
        memoryPercent: `Uso de memoria, %`,
        swapTotal: `Memoria virtual total`,
        swapFree: `Memoria virtual disponible`,
        swapUsed: `Memoria virtual en uso`,
        swapPercent: `Uso de memoria virtual, %`,
        processor: `Procesador`,
        processorVendor: `Fabricante`,
        processorBrand: `Marca`,
        cpuUsageTotal: `Uso de CPU total, %`,
        cpuCores: `Núcleos`,
        cpuTotalCores: `Hilos`,
        cpuFreqCurrent: `Frecuencia actual`,
        net: `Red`,
        ipAddress: `Dirección IP`,
        macAddress: `Dirección MAC`,
        interfaces: `Interfaces de red`,
        totalSend: `Total enviado`,
        totalReceived: `Total recibido`,
        persistent: `Almacenamiento persistente`,
        disks: `Discos`,
        dateUpdate: `Fecha de actualización`,
        autoUpdate: `Actualización automática`,
        free: `Libre`,
    },

    proc: {
        name: `Nombre`,
        exe: `Ruta`,
        username: `Usuario`,
        pidS: `ID de proceso`,
        nameS: `Por nombre`,
        cpuPercent: `Uso de CPU, %`,
        memoryPercent: `Uso de memoria, %`,
        createTimeS: `Por fecha de inicio`,
        usernameS: `Por usuario`,
        checkbox: `Seleccionar`,
        finishP: `Finalizar proceso`,
        finishPs: `Finalizar procesos`,
        pid: `ID`,
        createTime: `Inicio`,
        diskUsageRead: `Lectura de disco`,
        diskUsageReadTotal: `Total de lecturas de disco`,
        diskUsageWrite: `Escritura de disco`,
        diskUsageWriteTotal: `Total de escrituras de disco`,
        status: `Estado`,
        filterName: `Filtrar procesos`,
    },

    prog: {
        startRemove: `Iniciar desinstalación del programa`,
        name: `Nombre`,
        installDate: `Fecha de instalación`,
        version: `Versión`,
        size: `Tamaño`,
        description: `Descripción`,
        publisher: `Editor`,
        location: `Ruta de instalación`,
        uninstall: `Ruta de desinstalación`,
        depends: `Dependencias`,
        nameS: `Por nombre`,
        installDateS: `Por fecha de instalación`,
        filterName: `Filtrar programas`,
        confirmAction: `Confirmar acción`,
        confirmActionText: `¿Estás seguro de que quieres realizar la desinstalación de "{{name}}"?
Puede ser necesario hacer clic en los botones de acción.`,
    },

    control:{
        prepare: `Preparando...`,
        connecting: `Conectando`,
        connected: `Conectado`,
        disconnected: `Desconectado`,
        error: `Error`,
        tryAgain: `Intentar de nuevo`,
        zoom: `Acercar`,
        zoomOut: `Alejar`,
        showAllButtons: `Mostrar todos los botones`,
        display: `Pantalla`,
        size: `Tamaño`,
        keys: `Teclas`,
        showHideKey: `Mostrar/Ocultar teclado`,
        fitScreen: `Ajustar a la pantalla`,
        fullscreen: `Pantalla completa`,
        tune: `Ajuste`,
        cursorMove: `Mover cursor`,
        move: `Mover`,
        click: `Clic`,
    },

    aiControl: 'Control de IA',
    aiControlStation: 'Estación de Control de IA',
    ai: {
        title: 'Control de Estación de IA',
        autoApply: 'Aplicar comandos automáticamente',
        autoContinueError: 'Continuar procesando errores',
        send: 'Enviar (Ctrl + Enter)',
        input: 'Tu mensaje',
        errorLength: 'El mensaje es demasiado corto',
        process: 'Procesando',
        success: 'Éxito',
        error: 'Error',
        showMore: 'Mostrar más',
        continue: 'Continuar',
        doCommand: 'Hacer Comando',
        commandAny: 'Hacer cualquier comando',
        refine: 'Refinar respuesta',
        newQuery: 'Nueva consulta',
        auto: 'Automático',
        model: 'Modelo de IA',
        tokensLeft: 'Tokens restantes: {{value}}',
        allUsers: 'Todos los usuarios',
        tokenHelp: 'El texto enviado al modelo de IA se divide en tokens. Un token puede ser un solo carácter, una sola palabra o incluso una sola oración, dependiendo del idioma y el contexto. Por ejemplo, el texto "¡ChatGPT es genial!" se puede dividir en los siguientes tokens: ["Chat", "G", "PT", " es", " genial", "!"].',
        enableHTML: 'Habilitar visualización del resultado en HTML',
        userTokens: 'Número de tokens utilizados en el mensaje actual. Se recomienda crear una nueva consulta si se relaciona con un contexto diferente.',
        inProgress: 'Procesando información...',
        previewCommand: 'Se ejecutará el siguiente comando',
        "userToken": "Clave de API del usuario",
        "getToken": "Obtener clave"
    },
};

import {action, makeObservable, observable, observe} from "mobx";
import {StorageService} from "../utils/storage.service";
import Helper from "../utils/helper";
import EventBus from "../utils/eventBus";
import APIService from "../utils/api.service";
import NotifyService from "../utils/notify.service";
import MyHistory from "../utils/myHistory";
import CryptoService from "../utils/crypto.service";
import {Wallet} from "ethers";
import {constants} from "../utils/constants";
import LANG from "../lang/language";

class UserServiceStore {
    static classInstance: UserServiceStore = null;

    @observable settings;

    settingsDefault = {
        stations: {
            sort: {createdAt: 'DESC'},
            view: {
                compact: true,
                columns: []
            }
        },
        ai: {
            model: constants.CHATGPT_3_5_TURBO,
            autoApply: false,
            autoContinueError: false,
            enableHTML: false,
            userToken: ''
        },
        station: {
            info: {
                sort: {createdAt: 'DESC'},
                view: {
                    compact: true,
                    columns: []
                }
            },
            processes: {
                sort: {createdAt: 'DESC'},
                view: {
                    compact: true,
                    columns: []
                }
            },
            programs: {
                sort: {createdAt: 'DESC'},
                view: {
                    compact: true,
                    columns: []
                }
            },
            devices: {
                sort: {createdAt: 'DESC'},
                view: {
                    compact: true,
                    columns: []
                }
            },
            commands: {
                sort: {createdAt: 'DESC'},
                view: {
                    compact: true,
                    columns: []
                }
            },
        },
        user: {
            transactions: {
                sort: {createdAt: 'DESC'},
                view: {
                    compact: true,
                    columns: []
                }
            },
        },
    };

    wallet: Wallet;

    @observable user;
    @observable tariff;
    defaultInclude = ['tariffs']

    @observable summary = {
        //free: false,
        //balance: 40,
        //stations: 5,
        costMouth: 0,
        enoughBalance: 0,
        enoughBalanceString: '0',
        isPayRequired: false
    }

    subscriptions = []

    constructor(settingsJson?) {
        makeObservable(this);

        this.loadUserData();
        this.loadSettings(settingsJson);

        observe(this, (change) => {
            if (change.name === 'settings') {
                this.save();
            }
        });

        this.subscriptions.push(
            EventBus.subscribe(constants.SOCKET_BALANCE_UPDATED, (user) => {
                this.user.balance = user.balance
            })
        )
    }

    unsubscribe(){
        this.subscriptions.forEach(el => el.unsubscribe())
    }


    static getInstance() {
        if (UserServiceStore.classInstance === null) {
            UserServiceStore.classInstance = new this();
        }
        return this.classInstance;
    }


    save() {
        StorageService.setItem('storage', this.settings);
    }

    @action
    private loadSettings(settingsJson?) {

        if (settingsJson) {
            this.settings = JSON.parse(settingsJson);
        } else { // try get setting from localstorage
            this.settings = StorageService.getItem('storage');
        }

        if (!this.settings)
            this.settings = Helper.makeClone(this.settingsDefault);
        else
            this.settings = Helper.makeClone(this.settingsDefault, this.settings, 0, null, false);


    }

    @action loadUserData() {
        this.user = StorageService.getItem('user');
        this.wallet = CryptoService.fromPrivateKey(StorageService.getItem('privateKey'));
        //this.refreshUser();
    }

    @action async refreshUser() {
        if (this.user?.id && this.wallet) {
            let result = (await APIService.getInstance().getUser({id: this.user.id, include: this.defaultInclude}))?.result;
            if (result?.id) {
                this.updateUser(result)
                /*StorageService.setItem('user', result);
                this.user = StorageService.getItem('user');*/
            } else {
                NotifyService.error(LANG.get(['general', 'errorGetUserEmpty']));
            }
        }
    }

    @action updateUser(user) {
        if(user.tariffs?.length > 0)
            this.tariff = user.tariffs[0]
        StorageService.setItem('user', user);
        this.user = StorageService.getItem('user');
        this.calculateSummary()
    }

    @action calculateSummary(){
        let stationsCount = this.user.stationsCount - (this.tariff?.payFreeStation ? 0 : 5);
        this.summary.costMouth = (this.tariff?.cost || 0) * stationsCount
        if(this.summary.costMouth < 0)
            this.summary.costMouth = 0
        let perDay = this.summary.costMouth / 30
        this.summary.enoughBalance = ((this.user.balance || 0) / perDay) || 0
        if(!isFinite(this.summary.enoughBalance) || this.summary.enoughBalance < 0)
            this.summary.enoughBalance = 0

        let enoughBalance = this.summary.enoughBalance
        let value = Math.trunc(enoughBalance)
        this.summary.enoughBalanceString = `${value} ${LANG.get(['general', 'dayS'])}`
        this.summary.isPayRequired = stationsCount > 0 ? enoughBalance < 30 : false
        /*if(enoughBalance < 7) {
            let value = Math.trunc(enoughBalance)
            this.summary.enoughBalanceString = `${value} дн.`
        } else if(enoughBalance >= 7 && enoughBalance <= 30) {
            let value = Math.trunc(enoughBalance / 7)
            this.summary.enoughBalanceString = `${value} нед.`
        }else {
            let value = Math.trunc(enoughBalance / 30)
            this.summary.enoughBalanceString = `${value} мес.`
        }*/
    }

    @action change(array: (string)[], value: any, removeNeighbors?) {
        if (!array || array.length == 0) return;

        let oldValue;
        let newValue = value;

        let object = this.settings;
        for (let i = 0; i < array.length; i++) {
            let name = array[i];
            if (i != array.length - 1) {
                if (!object.hasOwnProperty(name))
                    object[name] = {};
                object = object[name];
            } else {
                oldValue = Helper.makeClone(value);
                if (removeNeighbors)
                    Helper.clear(object);
                object[name] = value;
            }

        }

        EventBus.publish("settings_changes", {array, oldValue, newValue})
        this.save();
    }

    get(array: string[], props?) {
        let def = props?.def;
        let copy = props?.copy;

        if (!array || array.length == 0) return def;

        let object = this.settings;
        for (let i = 0; i < array.length; i++) {
            let name = array[i];

            if (i != array.length - 1) {
                if (!object.hasOwnProperty(name))
                    return def;
                //object[name] = {};
            }
            object = object[name];
        }

        if (copy)
            return Helper.makeClone(object);

        return object;
    }

    login(wallet, query?) {
        return new Promise(async (resolve, reject) => {
            this.wallet = wallet
            APIService.getInstance().connect(query).then(answer => {
                //StorageService.setItem('user', answer.user);
                StorageService.setItem('privateKey', wallet.privateKey);
                this.loadUserData();
                //resolve(answer.user);
                resolve(true)
            }).catch(err => reject(err))
        })
    }

    logout() {
        return new Promise(async (resolve, reject) => {
            //StorageService.removeCookie('rc.sid');

            //APIService.getInstance().logout() // not wait answer
            APIService.getInstance().socketDisconnect()
            this.user = null;
            this.wallet = null;
            StorageService.removeItem('user')
            StorageService.removeItem('privateKey')

            MyHistory.push('/login');
        })
    }
}

export default UserServiceStore;

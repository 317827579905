export default {
    nickname: `Votre pseudo`,
    address: `Votre adresse`,
    daysInMonth: `Sur la base d'un mois de 30 jours`,
    currentBalance: `Solde actuel`,
    stationCount: `Nombre de stations`,
    expensesMonth: `Dépenses par mois`,
    enoughBalance: `Solde suffisant`,
    currentTariff: `Tarif actuel`,
    optionPay: `Paiement optionnel`,
    stationPerMonth: `Par station par mois`,
    payFirstStations: `Payer pour les 5 premières stations`,
    notSelect: `Non sélectionné`,
    changeTariff: `Changer de tarif`,
    createTariff: `Créer un tarif`,
    fieldsCalc: `Champs de calcul`,
    stations: `Stations`,
    mounths: `Mois`,
    changeBalanceThis: `Remplacer le solde par ce montant`,
    total: `Total`,
    addBalance: `Ajouter du solde`,
    summForAdd: `Montant à ajouter, $`,
    pushToCopy: `Cliquez pour copier dans le presse-papiers`,
    textCopied: `Texte copié dans le presse-papiers`,
    createBill: `Créer une facture`,
    cancelBill: `Annuler la facture`,
    addressToSend: `Adresse de paiement`,
    or: `ou`,
    billPartner: `Facture pour le paiement chez le partenaire`,
    go: `Aller`,
    textBalance: `Instructions de paiement succinctes (par carte de crédit ou autres méthodes)
    <ol>
    <li>Créez une facture sur cette page</li>
    <li>Transférez de l'argent
    <ul>
    <li>Directement - si vous disposez d'un portefeuille électronique avec des jetons USDT bep20.</li>
    <!--<li>Payez la facture chez le partenaire - le partenaire peut facturer une commission, en fonction du mode de paiement.</li>-->
    <li>Par l'intermédiaire d'un service d'échange, par exemple en le sélectionnant sur <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> (sans commission) :
    <ol>
    <li>Rendez-vous sur la <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">page de l'agrégateur</a></li>
    <li>Dans la colonne "Donne", sélectionnez une méthode de paiement appropriée, telle que "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>". 
Je vous recommande de choisir dans cette colonne la méthode de paiement avec le taux d'intérêt le plus bas pour votre pays (environ 3-5%), généralement l'une des banques populaires dans la devise locale,
et non un virement direct en USD.</li>
    <li>Dans la colonne "Reçoit", cela devrait être Tether bep20</li>
    <li>Choisissez un service d'échange avec le meilleur taux ou les meilleures conditions</li>
    <li>Sur le site web du service d'échange, spécifiez le montant que vous avez saisi pour le rechargement en dollars, copiez votre adresse de rechargement (ci-dessus), remplissez les autres champs et effectuez le transfert</li>
    <li>Il est traité par le service d'échange pendant 20 minutes, puis par mon service</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>Une fois les fonds reçus, les données sur cette page seront automatiquement mises à jour. Si la facture n'est pas payée dans les 24 heures, elle sera automatiquement annulée.</p>
    Le paiement du tarif est déduit quotidiennement, en fonction du nombre maximum de stations pendant la journée.
    <p>Si vous rencontrez des problèmes, veuillez m'écrire sur
    <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
    <p>Si vous avez besoin de fonctionnalités supplémentaires, veuillez les indiquer dans la section des
    <a href="{{tg_idea}}" target="_blank" class="link">idées de projet</a>.
    L'idée sera soit mise en œuvre immédiatement, soit soumise au vote des membres du groupe, et une décision sera prise quant à son développement. Merci d'avance pour votre participation !</p>`,

    textTariffCost: `Le service vous permet de choisir vous-même le coût mensuel des services. Cette option
    sert également d'évaluation de l'idée derrière ce projet. Plus le montant que vous spécifiez est élevé, plus ce projet sera performant,
    avec des serveurs plus puissants, moins de bugs, une interface utilisateur plus conviviale et des interactions plus rapides.
    Surtout, de nouvelles fonctionnalités seront introduites pour faciliter la gestion de vos stations.`,

    textTariffFirstStations: `Si vous souhaitez soutenir le projet à tel point que vous êtes même prêt à payer pour les stations qui sont gratuites pour vous,
    vous êtes incroyablement génial !`,

    textBillAmount: `Pour identifier automatiquement votre paiement et l'associer à votre compte, vous devez entrer
    le montant à l'avance et vous assurer qu'il est spécifié exactement jusqu'au centime lors du transfert des fonds.
    Des nombres décimaux peuvent être ajoutés au montant s'il est déjà en attente de paiement dans la base de données.`,
    textBillAddress: `Il est généré automatiquement lors de la création de la facture. Il doit être fourni pour tout mode de paiement.
    Faites attention au type de réseau "USDT bep20" (Binance) : il vous sera demandé lors de la soumission d'une demande à n'importe quel service d'échange de cryptomonnaies.`,
    textBillPartner: `Une autre possibilité est de payer chez un partenaire, la facture pour le montant sélectionné a déjà été créée, il suffit de suivre
    le lien et de transférer l'argent de manière pratique.`,

    billCreated: `Créée`,
    billPartPaid: `Partiellement payée`,
    billPaid: `Payée`,
    billCancel: `Annulée`,
    billNum: `Facture n°`,
    tariffUpdated: `Tarif mis à jour`,
    billCreatedFull: `Facture créée`,
    billPaidFull: `Facture payée`,
    billCancelFull: `Facture annulée`,
    errorSaveUserEmpty: `Erreur lors de l'enregistrement de l'utilisateur - réponse vide`,
    settings: `Paramètres`,
    tariff: `Tarif et Paiement`,
    transactions: `Transactions`,

    type: `Type`,
    createdAt: `Date`,
    value: `Valeur`,
    billString: `Facture`,
    tariffString: `Tarif`,
    stationsPay: `Stations`,

    sortDateCreate: `Par date de création`,
    transactionFilter: `Filtre de transaction`,
};

import {action, makeObservable, observable} from "mobx";
import UserServiceStore from "./userServiceStore";
import {Theme} from "@material-ui/core";
import EventBus from "../utils/eventBus";

class RootStore {
    static classInstance: RootStore = null;

    userService: UserServiceStore;

    isDragging: boolean;

    metaDefault: {
        title,
        desc,
        keywords,
    };

    @observable meta: any = {};
    theme: Theme;

    @observable modals = [];
    keysDowns = [];
    stopKeyListener = false

    constructor() {
        makeObservable(this);
        this.userService = UserServiceStore.getInstance();
        //this.groupsStore = GroupsStore.getInstance();
        this.metaDefault = {
            title: 'Cusco Remote Control',
            desc: '',
            keywords: [],
        }
        this.setMeta();
    }

    static getInstance() {
        if (this.classInstance === null) {
            this.classInstance = new this();
        }
        return this.classInstance;
    }

    @action
    setMeta(data?) {
        if (!data) // set to Default
            data = {
                title: null,
                desc: null,
                keywords: null,
            };
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (!data[key])
                this.meta[key] = this.metaDefault[key];
            else
                this.meta[key] = data[key];
        }
    }

    onPop(e) {
        console.log("!! onPop");
        if (this.closeLastModal()) {
            e.preventDefault();
        }
    }

    onKeyDown(e) {
        if(this.stopKeyListener) return;
        if (e.keyCode === 27) { // esc
            if (this.closeLastModal()) {
                e.preventDefault();
            }
        }
        this.keysDowns.push(e)
        EventBus.publish('onKeyDown', e)
    }

    onKeyUp(e) {
        if(this.stopKeyListener) return;
        this.keysDowns = this.keysDowns.filter(el => el.keyCode !== e.keyCode)
        EventBus.publish('onKeyUp', e)
    }

    showEntity({id, show, store}: any): any {
        this.showModal(show, id,'entity', store);
    }

    showTable({id, show, store}: any): any {
        this.showModal(show, id, 'table', store);
    }

    showDialog({id, show, ...params}: any) {
        params = params || {}
        this.showModal(show, id,'dialog', null, params);
    }

    @action
    private showModal(show, id, type, store, params?: {onClose?, anchor?}) {
        if (show) {
            // first - remove old
            let index = this.modals.findIndex(el => el.id === id);
            if (index >= 0)
                this.modals.splice(index, 1);
            // add new
            this.modals.push({id, type, store, ...params});
        } else if (id) {
            let index = this.modals.findIndex(el => el.id === id);
            if (index >= 0) {
                let modal = this.modals[index];
                if(modal.onClose)
                    modal.onClose();
                this.modals.splice(index, 1);
            }
        }
    }

    @action
    closeModal(id) {
        this.showModal(false, id, '', null);
    }

    @action
    closeLastModal() {
        if (this.modals.length > 0) {
            this.modals.splice(-1, 1);
            return true;
        }
    }

    onMount() {

    }

    onUmount() {

    }
}

export default RootStore;

import axios from "axios";
import Variables from "./variables";
import {toast} from "react-toastify";
import RootStore from "../stores/rootStore";
import {isMobile} from "react-device-detect";
import Helper from "./helper";
import LANG from "../lang/language";

export default class NotifyService {
    static classInstance: NotifyService = null;
    api;

    constructor() {
        this.api = axios.create({
            baseURL: Variables.urlApi,
            responseType: "json",
        });
        this.api.interceptors.response.use(function (response) {
            return response.data;
        }, function (error) {
            return Promise.reject(error);
        });
    }

    static getInstance() {
        if (NotifyService.classInstance === null) {
            NotifyService.classInstance = new this();
        }
        return this.classInstance;
    }

    static message(text, data?) {
        let options: any = {data: data};
        if (data && data.life)
            options.autoClose = data.life;
        toast.info(text, options);
    }

    static info(text, data?) {
        let options: any = {data: data};
        if (data && data.life)
            options.autoClose = data.life;
        toast.info(text, options);
    }

    static success(text, data?) {
        let options: any = {data: data};
        if (data && data.life)
            options.autoClose = data.life;
        toast.success(text, options);
    }

    static error(text, data: any = {}) {
        let options: any = {autoClose: 5000, data: data};
        if (data.life)
            options.autoClose = data.life;
        if (data.error) {
            options.onClick = _ => this.showDialog({
                title: LANG.get(['general', 'errorDetails']),
                text: Helper.getMessageError(data.error)
            })
            console.error(data.error)
        }
        toast.error(LANG.get(text), options);
    }

    static clear() {
        toast.dismiss();
    }

    static showDialog({id, title, text}:{id?, title?, text}){
        id = id || Date.now()
        RootStore.getInstance().showDialog({
            id: id,
            show: true,
            type: 'dialog',
            fullScreen: isMobile,
            title: title,
            content: [
                {text: text}
            ],
            actions: [
                {
                    type: 'button', text: LANG.get(['general', 'close']), disabled: false, variant: 'text',
                    onClick: async () => {
                        RootStore.getInstance().closeModal(id)
                    }
                }
            ]
        })
    }


    static prettyError(error) {
        let message;
        if (error.response?.data?.error) {
            message = error.response.data.error.message;
        } else {
            message = error.message;
        }
        console.error(error);
        NotifyService.error(`${message}`, {life: 7000});
    }
}

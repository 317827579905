export default {
    title: `太空站控制面板`,
    phrase: `秘密短语（12个词）`,
    enter: `登录`,
    register: `注册`,
    back: `返回`,
    nickname: `昵称`,
    secret: `秘密短语`,
    secretDesc: `请将短语保存在安全的地方。它允许访问您的账户和添加的站点。该服务不会存储此短语。
    如果丢失，将无法恢复访问权限，需要创建新账户并重新添加站点`,
    secretCheck: `同意如果秘密短语丢失，将无法恢复对账户的访问`,
    errorInitKey: `初始化密钥错误，请检查数据`,
    registerSuccess: `注册成功`,
};

import axios from "axios";
import Variables from "./variables";
import NotifyService from "./notify.service";
import UserServiceStore from "../stores/userServiceStore";
import {constants} from "./constants";
import {io} from "socket.io-client";
import CryptoService from "./crypto.service";
import {Wallet} from "ethers";
import {delay, timeout} from "q";
import Helper from "./helper";
import EventBus from "./eventBus";
import LANG from "../lang/language";

export default class APIService {
    static classInstance: APIService = null;
    api; //:AxiosInstance
    socket;

    constructor() {
        this.api = axios.create({
            baseURL: Variables.urlApi,
            responseType: "json",
            withCredentials: true,
        });
        this.api.interceptors.response.use(function (response) {
            return response.data;
        }, function (error) {
            let errorResp = error.response;
            NotifyService.prettyError(error);
            if (errorResp?.status === 401) {
                UserServiceStore.getInstance().logout();
            }
            return Promise.reject(error);
        });
        this.connect() // try to connect if wallet exist
    }

    subscribe() {
        let socket = this.socket

        socket.on("connect", () => {
            console.log('Socket connected ' + socket.id);
            EventBus.publish('connect')
        });

        socket.on("disconnect", async (error) => {
            console.log('Socket disconnected ');
            /*await HelperService.delay(1000)
            await this.connect()*/
        });

        socket.io.on("reconnect_attempt", () => {
            console.log('Socket reconnect attempt')
        });

        socket.io.on("reconnect", () => {
            console.log('Socket reconnect')
        });

        socket.io.on("reconnect", () => {
            console.log('Socket reconnect')
        });

        socket.on(constants.SOCKET_UPDATE_USER, (user) => {
            UserServiceStore.getInstance().updateUser(user)
        });

        socket.on(constants.SOCKET_RESULT_COMMAND, (command) => {
            let commandName = !command?.raw?.data?.command ? '' : `${command?.raw?.data?.command}`
            if (commandName == 'get')
                return
            if (command.error) {
                let error = Helper.getMessageError(command.error)
                error = error && error.slice(0, 200)
                NotifyService.error(`${command.type} ${commandName} : ${error}`)
            } else {
                let result = command.result ? LANG.get(['general', 'successfully']) : command.result?.slice(0, 200) || ''
                NotifyService.success(`${command.type} ${commandName} \n${result || ''}`, command)
            }
        });

        socket.onAny((eventName, ...args) => {
            EventBus.publish(eventName, ...args)
        });
    }

    connect(query?) {
        return new Promise(async (resolve, reject) => {
            console.log('Socket try to connect');
            if (!query)
                query = {}
            let wallet = UserServiceStore.getInstance().wallet
            if (!wallet)
                return reject()
            query.address = await wallet.getAddress()
            query = await CryptoService.signQuery(query, wallet)
            this.socket = io(Variables.urlSockets, {
                autoConnect: true,
                reconnection: true,
                transports: ["websocket"],
                auth: {
                    "user": query
                }
            })

            let socket = this.socket
            let onConnect = () => {
                resolve(true)
                unsubscribe()
            };
            let onDisconnect = (error) => {
                reject(error)
                unsubscribe()
            };
            socket.once("connect", onConnect);
            socket.once("disconnect", onDisconnect);

            this.subscribe()

            function unsubscribe() {
                socket.off("connect", onConnect);
                socket.off("disconnect", onDisconnect);
            }

            //this.socket.connect()
        })
    }

    static getInstance() {
        if (APIService.classInstance === null) {
            APIService.classInstance = new this();
        }
        return this.classInstance;
    }

    request(path, query, props?: { sign: boolean, wallet: Wallet }): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (props?.sign)
                query = await CryptoService.signQuery(query, props.wallet);
            this.api.post(path, query).then(answer => {
                resolve(answer)
            }).catch((error) => {
                console.error(error);
                reject(error)
            })
        })
    }

    async requestSocket(path, query, props?: { sign: boolean | string, wallet?: Wallet}): Promise<any> {
        if (!this.socket?.connected)
            await new Promise(async (resolve, reject) => {
                while (!this.socket?.connected) {
                    await delay(1000)
                }
                resolve(true)
            })
        return new Promise(async (resolve, reject) => {
            if (props?.sign) {
                if (Helper.is(props.sign, 'String')) {
                    let target = query['' + props.sign]
                    if (Helper.is(target, 'Array')) {
                        for (let i = 0; i < target.length; i++) {
                            let object = target[i];
                            target[i] = await CryptoService.signQuery(object, props.wallet);
                        }
                       /* for (const object of target) {
                            query = await CryptoService.signQuery(object, props.wallet);
                        }*/
                    } else
                        query = await CryptoService.signQuery(target, props.wallet);
                } else
                    query = await CryptoService.signQuery(query, props.wallet);
            }
            this.socket.emit(path, query, (answer: any) => {
                if (answer.error) {
                    NotifyService.prettyError(answer.error);
                    if (answer.error?.status === 401) {
                        UserServiceStore.getInstance().logout();
                    }
                    resolve(answer)
                } else {
                    console.log('' + path + ' res ', answer)
                    resolve(answer)
                }
            })
        })/*.catch(error => {
            console.error(error);
        })*/;
    }

    getData(host, name) {
        return axios.get(host + '/data/' + name).catch((error) => {
            console.error(error);
        }).then((data) => {
            console.log(data)
        });
    }


    getStations(query) {
        return this.requestSocket(constants.SOCKET_GET_STATIONS, query);
    }

    detachStations(query) {
        return this.requestSocket(constants.SOCKET_DETACH_STATIONS, query);
    }

    getStation(query) {
        return this.requestSocket(constants.SOCKET_GET_STATION, query);
    }

    updateStation(query) {
        return this.requestSocket(constants.SOCKET_UPDATE_STATION, query);
    }

    addStation(query) {
        return this.requestSocket(constants.SOCKET_ADD_STATION, query, {sign: true});
    }

    subscribeStations(query) {
        return this.requestSocket(constants.SOCKET_SUBSCRIBE_STATIONS, query, {sign: true});
    }

    unsubscribeStations(query) {
        return this.requestSocket(constants.SOCKET_UNSUBSCRIBE_STATIONS, query, {sign: true});
    }

    getUser(query) {
        return this.requestSocket(constants.SOCKET_GET_USER, query);
    }

    updateUser(query) {
        return this.requestSocket(constants.SOCKET_UPDATE_USER, query);
    }

    createCommands(query) {
        return this.requestSocket(constants.SOCKET_CREATE_COMMANDS, query, {sign: 'commands'});
    }

    getCommands(query) {
        return this.requestSocket(constants.SOCKET_GET_COMMANDS, query);
    }

    removeCommands(query) {
        return this.requestSocket(constants.SOCKET_REMOVE_COMMANDS, query, {sign: true});
    }

    sendWebRTCOffer(query) {
        return this.requestSocket(constants.SOCKET_WRTC_OFFER, query, {sign: true});
    }

    sendWebRTCAnswer(query) {
        return this.requestSocket(constants.SOCKET_WRTC_ANSWER, query, {sign: true});
    }

    sendWebRTCInvite(query) {
        return this.requestSocket(constants.SOCKET_WRTC_INVITE, query, {sign: true});
    }

    sendWebRTCCandidate(query) {
        return this.requestSocket(constants.SOCKET_WRTC_CANDIDATES, query, {sign: true});
    }

    socketDisconnect() {
        this.socket.disconnect()
    }

    getTariffs(query) {
        return this.requestSocket(constants.SOCKET_GET_TARIFFS, query);
    }

    updateTariff(query) {
        return this.requestSocket(constants.SOCKET_UPDATE_TARIFF, query);
    }

    getBill(query) {
        return this.requestSocket(constants.SOCKET_GET_BILL, query);
    }

    updateBill(query) {
        return this.requestSocket(constants.SOCKET_UPDATE_BILL, query);
    }

    getTransactions(query) {
        return this.requestSocket(constants.SOCKET_GET_TRANSACTIONS, query);
    }

    getAIComplete(query) {
        return this.requestSocket(constants.SOCKET_GET_AI_COMPLETE, query);
    }
}

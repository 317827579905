import React from "react";
import styles from "./myText.module.scss";
import cn from 'classnames';
import {observer} from "mobx-react";
import Helper from "../../utils/helper";
import MyTextInterface from "../../stores/interfaces/myTextInterface";

interface Props {
    store: MyTextInterface;
    item?: any;
    storeField?: string;
    field?: string;
    fields?: string[];
    //name: string;
    type: string;
    className?: string;
    blockKey?: string;

    [key: string]: any;
}

function MyText({
                     store, storeField, type, field, fields, /*name,*/ className, item, isDisabled, blockKey, ...props
                 }: Props) {
    //const forceUpdate = HelperService.useForceUpdate();

    let value;
    let res;
    let getIcons;

    function getValue() {
        value = store.getFields(arrayFields, item, blockKey);
    }

    let arrayFields = [storeField, field];

    let parentForSubscribe = store.getFields([], item);

    if (item) {
        arrayFields = fields ? fields : [field];
    } else {
        arrayFields = fields ? [storeField, ...fields] : [storeField, field];
    }

    getValue();

    let isValueEmpty = Helper.isEmpty(value);

    let disabled = store.isDisabled && store.isDisabled({field, fields, storeField, item});
    if (disabled === undefined)
        disabled = isDisabled;

    let {color, title, fontWeight, fontSize}:any = store.getFieldsParams ? store.getFieldsParams(arrayFields, item) : {};

    switch (type) {
        case 'text_number':
        case 'text_currency':
        case 'text':
        case 'title':
        case 'title_sub':
        default:
            if(value) {
                if (type === 'text_number')
                    value = value.toLocaleString();
                else if (type === 'text_currency')
                    value = value.toLocaleString() + ' '; // руб.
            }

            let isTitle = type === 'title' || type === 'title_sub';

            res =
                <div style={{width: props.width, opacity: disabled && 0.3, color: color,
                    fontWeight: fontWeight, fontSize: fontSize}} {...props} title={title}
                className={cn({
                    [styles.title]: isTitle
                })}>
                    {
                        value
                    }
                </div>;
            break;
    }

    return <div className={`${cn('grid', styles.main)} ${className}`}>{res}</div>;
}

export default observer(MyText);

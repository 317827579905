export const constants = {

    // GENERAL

    STATION_STATUS_OFFLINE: 0,
    STATION_STATUS_ONLINE: 1,

    STATION_INFO_TYPE_ACTUAL: 0,
    STATION_INFO_TYPE_HISTORY: 1,

    COMMAND_STATUS_ACTUAL: 0,
    COMMAND_STATUS_SUCCESS: 1,
    COMMAND_STATUS_ERROR: 2,
    COMMAND_STATUS_CANCELED: 3,

    COMMAND_TYPE_POWER: 'power',
    COMMAND_TYPE_PROCESSES: 'processes',
    COMMAND_TYPE_PROGRAMS: 'programs',
    COMMAND_TYPE_DEVICES: 'devices',
    COMMAND_TYPE_CUSTOM: 'custom',


    SOCKET_GET_USER: 'get_user',
    SOCKET_UPDATE_USER: 'update_user',

    SOCKET_UPDATE_STATION: 'update_station',
    SOCKET_STATION_UPDATED_INFO: 'updated_station_info',
    SOCKET_STATION_UPDATED_PROCESSES: 'updated_station_processes',
    //SOCKET_STATION_PUBLIC: 'station_public',
    SOCKET_STATION_STATUS: 'station_status',

    SOCKET_ADD_STATION: 'add_station',
    SOCKET_GET_STATION: 'get_station',

    SOCKET_GET_STATIONS: 'get_stations',
    SOCKET_DETACH_STATIONS: 'detach_stations',
    SOCKET_SUBSCRIBE_STATIONS: 'subscribe_stations',
    SOCKET_UNSUBSCRIBE_STATIONS: 'unsubscribe_stations',

    SOCKET_DETACH_USERS: 'detach_users',


    SOCKET_CREATE_COMMANDS: 'create_commands',
    SOCKET_GET_COMMANDS: 'get_commands',
    SOCKET_REMOVE_COMMANDS: 'remove_commands',
    SOCKET_DO_COMMAND: 'do_command',
    SOCKET_RESULT_COMMAND: 'result_command',

    SOCKET_CHANGE_STATION_INFO: 'changed_station_info',
    SOCKET_LOGIN_ON_STATION: 'login_on_station',

    SOCKET_WRTC_INVITE: 'wrtc_invite',
    SOCKET_WRTC_ANSWER: 'wrtc_answer',
    SOCKET_WRTC_OFFER: 'wrtc_offer',
    SOCKET_WRTC_CANDIDATES: 'wrtc_candidates',

    SOCKET_GET_TARIFFS: 'get_tariffs',
    SOCKET_UPDATE_TARIFF: 'update_tariff',

    SOCKET_GET_BILL: 'get_bill',
    SOCKET_UPDATE_BILL: 'update_bill',
    SOCKET_UPDATED_BILL: 'updated_bill',
    SOCKET_BALANCE_UPDATED: 'updated_balance',

    SOCKET_GET_TRANSACTIONS: 'get_transactions',

    SOCKET_GET_AI_COMPLETE: 'get_ai_complete',


    WEBRTC_DC_CHUNK_SIZE: 16384,

    WEBRTC_DC_CHANEL_MAIN: 'chanel_main',
    WEBRTC_DC_CHANEL_SCREEN: 'chanel_screen',

    WEBRTC_DC_EVENT_GET_SCREEN_INFO: 'event_screen_info',
    WEBRTC_DC_EVENT_SCREEN_START: 'event_screen_start',
    WEBRTC_DC_EVENT_SCREEN_STOP: 'event_screen_stop',
    WEBRTC_DC_EVENT_SCREEN_SELECT: 'event_screen_select',
    WEBRTC_DC_EVENT_SCREEN_FORMAT: 'event_screen_format',
    WEBRTC_DC_EVENT_SCREEN_COMMAND: 'event_screen_command',
    WEBRTC_DC_EVENT_HID_EVENT: 'event_hid_event',

    WEBRTC_DC_SCREEN_FORMAT_VP8: 'vp8',
    WEBRTC_DC_SCREEN_FORMAT_WEBP: 'webp',
    WEBRTC_DC_SCREEN_FORMAT_PNG: 'png',

    HID_MOUSE_MOVE: 0,
    HID_MOUSE_ADD: 1,
    HID_MOUSE_KEY: 2,
    HID_MOUSE_SCROLL: 3,
    HID_KEYBOARD_KEY: 4,

    HID_COMMAND_ACTION_UP: 0,
    HID_COMMAND_ACTION_DOWN: 1,
    HID_COMMAND_ACTION_CLICK: 2,



    BILL_STATUS_CREATED: 0,
    BILL_STATUS_PARTIALLY_PAID: 1,
    BILL_STATUS_PAID: 2,
    BILL_STATUS_CANCELED: 3,

    TARIFF_TYPE_CURRENT: 0,
    TARIFF_TYPE_OLD: 1,

    TRANSACTION_TYPE_FLAG: 0, // used on sync local base and blockchain
    TRANSACTION_TYPE_IN: 1, // incoming
    TRANSACTION_TYPE_DEBITING: 2, // pay for service
    TRANSACTION_TYPE_OUT: 3, //money refund

    CHATGPT_3_5_TURBO: 0,
    CHATGPT_4o: 1,
    CHATGPT_4_TURBO: 2,

    // LOCAL

    PATH_NO_IMG: 'assets/img/img.png',

    minWidthRightPanel: 400,
    maxWidthRightPanel: 500,

}


export const key = {
    MOUSE_LEFT: 0,
    MOUSE_MIDDLE: 1,
    MOUSE_RIGHT: 2,
    MOUSE_BACK: 3,
    MOUSE_FORWARD: 4,
    MOUSE_SCROLL_UP: 5,
    MOUSE_SCROLL_DOWN: 6,
    MOUSE_SCROLL_LEFT: 7,
    MOUSE_SCROLL_RIGHT: 8,

    SCROLL_Y: 0,
    SCROLL_X: 1,

    KEY_CHAR: 0,
    KEY_RAW: 1,

    KEY_ALT: 10,
    KEY_BACKSPACE: 11,
    KEY_CAPSLOCK: 12,
    KEY_COMMAND: 13,
    KEY_CONTROL: 14,
    KEY_DELETE: 15,
    KEY_DOWNARROW: 16,
    KEY_END: 17,
    KEY_ESCAPE: 18,
    KEY_F1: 19,
    KEY_F10: 20,
    KEY_F11: 21,
    KEY_F12: 22,
    KEY_F2: 23,
    KEY_F3: 24,
    KEY_F4: 25,
    KEY_F5: 26,
    KEY_F6: 27,
    KEY_F7: 28,
    KEY_F8: 29,
    KEY_F9: 30,
    KEY_HOME: 31,
    KEY_LEFTARROW: 32,
    KEY_META: 33,
    KEY_OPTION: 34,
    KEY_PAGEDOWN: 35,
    KEY_PAGEUP: 36,
    KEY_RETURN: 37,
    KEY_RIGHTARROW: 38,
    KEY_SHIFT: 39,
    KEY_SPACE: 40,
    KEY_SUPER: 41,
    KEY_TAB: 42,
    KEY_UPARROW: 43,
    KEY_WINDOWS: 44,
    KEY_NUMPAD0: 45,
    KEY_NUMPAD1: 46,
    KEY_NUMPAD2: 47,
    KEY_NUMPAD3: 48,
    KEY_NUMPAD4: 49,
    KEY_NUMPAD5: 50,
    KEY_NUMPAD6: 51,
    KEY_NUMPAD7: 52,
    KEY_NUMPAD8: 53,
    KEY_NUMPAD9: 54,
    KEY_CANCEL: 55,
    KEY_CLEAR: 56,
    KEY_PAUSE: 57,
    KEY_KANA: 58,
    KEY_HANGUL: 59,
    KEY_JUNJA: 60,
    KEY_FINAL: 61,
    KEY_HANJA: 62,
    KEY_KANJI: 63,
    KEY_CONVERT: 64,
    KEY_SELECT: 65,
    KEY_PRINT: 66,
    KEY_EXECUTE: 67,
    KEY_SNAPSHOT: 68,
    KEY_INSERT: 69,
    KEY_HELP: 70,
    KEY_SLEEP: 71,
    KEY_SEPARATOR: 72,
    KEY_VOLUMEUP: 73,
    KEY_VOLUMEDOWN: 74,
    KEY_MUTE: 75,
    KEY_SCROLL: 76,
    KEY_NUMLOCK: 77,
    KEY_RWIN: 78,
    KEY_APPS: 79,
    KEY_MULTIPLY: 80,
    KEY_ADD: 81,
    KEY_SUBTRACT: 82,
    KEY_DECIMAL: 83,
    KEY_DIVIDE: 84,
    KEY_EQUALS: 85,
    KEY_NUMPADENTER: 86,
    KEY_RIGHTSHIFT: 87,
    KEY_RIGHTCONTROL: 88,
    KEY_RIGHTALT: 89,
}



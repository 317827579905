import {action, makeObservable, observable} from "mobx";
import WebRTCConnection, {
    DataChanel,
    DataChanelStatus,
    DataChanelTypes,
    DCMessage,
    RTCConnectionState
} from "../../utils/webRTCConnection";
import {constants, key} from "../../utils/constants";
import Helper from "../../utils/helper";
import {isMobile} from "react-device-detect";
import RootStore from "../../stores/rootStore";
import LANG from "../../lang/language";

export enum VideoSizes {
    Fit,
    Full,
    Custom
}

export enum MouseModes {
    Click,
    Move,
}

class VisualControlStore {
    stationId: any
    @observable entityEdited: any

    @observable connectionState: RTCConnectionState

    videoRef: HTMLVideoElement

    @observable videoId: string // TODO: remove

    videoStream: any;

    isModal = true

    remoteStation: WebRTCConnection
    dcMain: DataChanel
    dcScreen: DataChanel

    @observable displays: Display[] = []

    sizes: any[] = []
    @observable selectedSizeType: VideoSizes = VideoSizes.Fit
    @observable videoSizeViewed = {w: 0, h: 0}
    videoSizeOriginal = {w: 0, h: 0}
    @observable videoSizePercent = 1
    @observable videoPosition = {x: 0, y: 0}

    @observable buttonsMain: any[] = []
    @observable buttonsMore: any[] = []
    @observable buttonsShowMore = false

    @observable formats: any[] = [
        constants.WEBRTC_DC_SCREEN_FORMAT_VP8,
        constants.WEBRTC_DC_SCREEN_FORMAT_WEBP,
        constants.WEBRTC_DC_SCREEN_FORMAT_PNG,
    ]
    @observable format = constants.WEBRTC_DC_SCREEN_FORMAT_VP8

    cursorPositionOrigin = {x: 0, y: 0}
    @observable cursorPositionViewed = {x: 0, y: 0}

    controlListeners: { name: string, listener: any }[] = []
    controlParentListeners: { name: string, listener: any }[] = []
    controlResize
    keyboardListeners: { name: string, listener: any }[] = []
    fakeKeyboardListeners: { name: string, listener: any }[] = []

    @observable controlMenuState = {
        'display': false,
        'size': false,
        'keys': false,
    }

    @observable controlMore: any = {selected: false, selectedItem: '', items: []}

    @observable mouseMode: MouseModes = MouseModes.Move

    @observable mobileKeyboard = {show: false, x: 0, y: 0}

    fakeInput

    constructor({stationId, isModal}: any) {
        makeObservable(this);

        this.stationId = stationId;
        this.entityEdited = {};
        this.isModal = isModal;

        this.sizes = [
            {key: VideoSizes.Fit, name: LANG.get(['station', 'control', 'fitScreen']), icon: 'fit_screen'},
            {key: VideoSizes.Full, name: LANG.get(['station', 'control', 'fullscreen']), icon: 'fullscreen'},
            {key: VideoSizes.Custom, name: LANG.get(['station', 'control', 'tune']), icon: 'tune'},
        ]

        this.buttonsMain = [
            {name: 'Ctrl', key: key.KEY_CONTROL, down: false, hold: true},
            {name: 'Alt', key: key.KEY_ALT, down: false, hold: true},
            {name: 'Esc', key: key.KEY_ESCAPE, down: false, hold: false},
            {name: 'Del', key: key.KEY_DELETE, down: false, hold: false},
            {name: 'Shift', key: key.KEY_SHIFT, down: false, hold: true},
        ]

        this.buttonsMore = [
            {name: 'Tab', key: key.KEY_TAB, down: false, hold: false},
            {name: 'Enter', key: key.KEY_RETURN, down: false, hold: false},
            {name: 'Win', key: key.KEY_META, down: false, hold: false},
            {name: 'Home', key: key.KEY_HOME, down: false, hold: false},
            {name: 'End', key: key.KEY_END, down: false, hold: false},
            {name: 'PgUp', key: key.KEY_PAGEUP, down: false, hold: false},
            {name: 'PgDd', key: key.KEY_PAGEDOWN, down: false, hold: false},
            {name: '←', key: key.KEY_LEFTARROW, down: false, hold: false},
            {name: '↑', key: key.KEY_UPARROW, down: false, hold: false},
            {name: '↓', key: key.KEY_DOWNARROW, down: false, hold: false},
            {name: '→', key: key.KEY_RIGHTARROW, down: false, hold: false},
            {name: 'F1', key: key.KEY_F1, down: false, hold: false},
            {name: 'F2', key: key.KEY_F2, down: false, hold: false},
            {name: 'F3', key: key.KEY_F3, down: false, hold: false},
            {name: 'F4', key: key.KEY_F4, down: false, hold: false},
            {name: 'F5', key: key.KEY_F5, down: false, hold: false},
            {name: 'F6', key: key.KEY_F6, down: false, hold: false},
            {name: 'F7', key: key.KEY_F7, down: false, hold: false},
            {name: 'F8', key: key.KEY_F8, down: false, hold: false},
            {name: 'F9', key: key.KEY_F9, down: false, hold: false},
            {name: 'F10', key: key.KEY_F10, down: false, hold: false},
            {name: 'F11', key: key.KEY_F11, down: false, hold: false},
            {name: 'F12', key: key.KEY_F12, down: false, hold: false},
        ]

        this.controlMore = {
            selected: false,
            selectedItem: '',
            items: [
                {
                    key: 'mouse_type', name: LANG.get(['station', 'control', 'cursorMove']), selectedItem: () => this.mouseMode,
                    items: [
                        {key: 'move', name: LANG.get(['station', 'control', 'move']), onSelect: () => this.mouseMode = MouseModes.Move},
                        {key: 'click', name: LANG.get(['station', 'control', 'click']), onSelect: () => this.mouseMode = MouseModes.Click},
                    ]
                },
            ]
        }

        this.videoId = `video_${Date.now()}`;

        this.remoteStation = new WebRTCConnection()

        this.remoteStation.events.subscribe('state', (newState: RTCConnectionState) => {
            this.connectionState = newState;
            switch (newState) {
                case RTCConnectionState.connected:
                    //this.initMain();
                    break;
            }
        })

        this.remoteStation.events.subscribe('initConnection', () => {
            this.initMain();
        })

        this.remoteStation.events.subscribe('newVideoStream', (stream) => {
            this.videoStream = stream
            this.updateStream()
        })

    }

    private initMain() {
        console.log('try init main chanel')
        this.dcMain = this.remoteStation.createDataChanel(constants.WEBRTC_DC_CHANEL_MAIN, DataChanelTypes.Message)
        this.dcMain.onOpen(() => {
            this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_GET_SCREEN_INFO, {}, (answer) => {
                console.info(`GET_SCREEN_INFO answer`, answer)
                this.setDisplays(answer.result)
                this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_START, {
                    display: this.displays.find(el => el.selected)?.number || 0,
                    format: this.format
                }, (answer) => {
                    console.info(`screen start answer`)
                    console.info(answer)
                })
            })
        })
        this.dcMain.onMessage((data: DCMessage) => {
            switch (data.event) {
                case constants.WEBRTC_DC_EVENT_HID_EVENT: {
                    //console.log('remote cursor ' + JSON.stringify(data.data))
                    this.updateDisplaysActiveCursor(data.data.coordinates)
                    this.updateCursorPosition(data.data.coordinates)
                }
            }
        })
    }

    @action
    changeDisplay(display) {
        display.selected = true;
        this.displays.forEach(el => el != display ? el.selected = false : null)
        this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_SELECT, {value: display.number}, (answer) => {
            console.info(`change_display answer`)
            console.info(answer)
        })
    }

    @action
    setDisplays(value) {
        value.forEach(el => {
            el.title = `${el.name} (${el.width} x ${el.height})`
        })
        this.displays = value
    }

    @action
    changeSizeType(value: VideoSizes) {
        if (this.selectedSizeType == value)
            return
        console.debug('changeSizeType', value)
        this.selectedSizeType = value
        this.calculateVideoSize()
    }

    @action
    changeSizePercent(value, calculate = true) {
        console.debug('changeSizePercent', value)

        if (value == Infinity)
            return;

        if (value > 1)
            value = 1
        else if (value < 0.1)
            value = 0.1

        if (this.videoSizePercent == value)
            return;

        this.videoSizePercent = value
        if (calculate)
            this.calculateVideoSize()
    }

    @action
    moveVideoPosition(x, y) {
        console.debug('moveVideoPosition x', x, 'y', y)

        this.videoPosition.x += x
        this.videoPosition.y += y
    }

    /*@action
    changeVideoPosition(x, y) {
        console.debug('changeVideoPosition x', x, 'y', y)

        this.videoPosition.x = x
        this.videoPosition.y = y
    }*/

    setVideoRef(e) {
        if (!e || this.videoRef == e) return
        this.removeControlListeners()

        this.videoRef = e
        this.updateStream()

        this.setControlListeners();
    }

    setVideoSizeOriginal(value) {
        this.videoSizeOriginal = value
        this.calculateVideoSize()
    }

    /*setVideoSizeViewed(value) {
        this.videoSizeViewed = value
        this.calculateVideoSize()
    }*/

    @action
    calculateVideoSize() {
        let parent = this.videoRef.parentElement.parentElement.parentElement
        switch (this.selectedSizeType) {
            case VideoSizes.Fit:
                let percentWidth = parent.offsetWidth / this.videoSizeOriginal.w;
                let percentHeight = parent.offsetHeight / this.videoSizeOriginal.h;
                let resultPercent = percentWidth;
                if (percentWidth > percentHeight)
                    resultPercent = percentHeight
                this.changeSizePercent(resultPercent, false);
                break
            case VideoSizes.Full:
                this.changeSizePercent(1);
                break
            case VideoSizes.Custom:
                break
        }
        this.videoSizeViewed.w = this.videoSizeOriginal.w * this.videoSizePercent;
        this.videoSizeViewed.h = this.videoSizeOriginal.h * this.videoSizePercent;
        this.calculateVideoPosition();
        this.calculateCursorPosition();
    }

    @action
    calculateCursorPosition() {
        this.cursorPositionViewed = {
            x: this.cursorPositionOrigin.x * this.videoSizePercent,
            y: this.cursorPositionOrigin.y * this.videoSizePercent,
        }
    }

    @action
    calculateVideoPosition() {
        let parent = this.videoRef.parentElement.parentElement.parentElement
        switch (this.selectedSizeType) {
            case VideoSizes.Fit:
                this.videoPosition.x = parent.offsetWidth / 2 - this.videoSizeViewed.w / 2
                this.videoPosition.y = parent.offsetHeight / 2 - this.videoSizeViewed.h / 2
                break
            case VideoSizes.Full:
                this.videoPosition.x = 0
                this.videoPosition.y = 0
                break
            case VideoSizes.Custom:
                if (!isMobile) {
                    this.videoPosition.x = parent.offsetWidth / 2 - this.videoSizeViewed.w / 2
                    this.videoPosition.y = parent.offsetHeight / 2 - this.videoSizeViewed.h / 2
                }
                break
        }
    }


    async onMount() {
        //await this.remoteStation.inviteConnection()
        await this.remoteStation.connect({stationId: this.stationId})
    }

    onUnmount() {
        this.removeControlListeners()
        this.remoteStation.disconnect()
    }

    private updateStream() {
        if (this.videoRef) {

            this.videoRef.addEventListener('loadedmetadata', function () {
                console.log(`Remote video videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`)
            });

            this.videoRef.addEventListener('resize', () => {
                if (!this.videoRef) return
                console.log(`Remote video size changed to ${this.videoRef.videoWidth}x${this.videoRef.videoHeight}`)
                this.setVideoSizeOriginal({w: this.videoRef.videoWidth, h: this.videoRef.videoHeight})
                // We'll use the first onsize callback as an indication that video has started
                // playing out.
            });
            if (this.videoStream && this.videoRef.srcObject !== this.videoStream) {
                this.videoRef.srcObject = this.videoStream;
                console.log('pc2 set remote stream');
            }

        }
    }

    removeControlListeners() {
        if (!this.videoRef) return
        this.controlListeners.forEach(el => this.videoRef.removeEventListener(el.name, el.listener))
        let parent = this.videoRef.parentElement.parentElement.parentElement;
        this.controlParentListeners.forEach(el => parent.removeEventListener(el.name, el.listener))
        this.controlResize?.disconnect()
        this.removeKeyboardListeners()
        this.removeFakeKeyboardListeners()
    }

    removeKeyboardListeners() {
        RootStore.getInstance().stopKeyListener = false
        this.keyboardListeners.forEach(el => window.removeEventListener(el.name, el.listener))
    }

    removeFakeKeyboardListeners() {
        let element = document.getElementById('fakeInput');
        this.fakeKeyboardListeners.forEach(el => element.removeEventListener(el.name, el.listener))
    }

    convertCursorLocalToGlobal(coordsRaw) {
        let coordsRes
        let display = this.displays.find(el => el.selected)

        if (display) {
            const globalX = coordsRaw.x + display.x;
            const globalY = coordsRaw.y + display.y;
            coordsRes = {x: globalX, y: globalY};
        }
        //console.log('cursor res local ' + JSON.stringify(coordsRaw) + ' global ' +JSON.stringify(coordsRes))
        return coordsRes
    }

    convertCursorGlobalToLocal(coordsGlobal) {
        let coordsRes
        let display = this.displays.find(el => el.selected)

        if (display) {
            const globalX = coordsGlobal.x - display.x
            const globalY = coordsGlobal.y - display.y
            coordsRes = {x: globalX, y: globalY}
        }
        //console.log('cursor res global ' + JSON.stringify(coordsGlobal) + ' local ' +JSON.stringify(coordsRes))
        return coordsRes
    }

    @action
    updateDisplaysActiveCursor(coordsGlobal) {
        let coords = coordsGlobal
        for (const display of this.displays) {
            const {x, y, width, height} = display
            display.cursor = coords.x >= x && coords.x <= x + width && coords.y >= y && coords.y <= y + height
        }
    }

    setControlListeners() {
        if (!this.videoRef) return

        let element = this.videoRef

        console.debug('setControlListeners')

        // // calculate size purposes
        // this.controlResize = new ResizeObserver(entries => {
        //     for (const entry of entries) {
        //         const {width, height} = entry.contentRect;
        //         this.setVideoSizeViewed({w: width, h: height})
        //     }
        // });
        // this.controlResize.observe(element)

        // hid events
        if (isMobile) {
            let isDrawing = false
            let previousCoords = null

            let tapped: any = false
            let tappedDouble: any = false
            let totalMoveThisTap: any = 0

            let touchTimeoutReset
            let touchTimeout
            let touchTime

            let pinchStartDistance = 0
            let pinchStartScale = 1
            let moveStartCoords = null

            let scrollStartCoords = null

            let getTotalOffset = (element) => {
                let y = 0, x = 0;
                while (element) {
                    y += element.offsetTop;
                    x += element.offsetLeft;
                    element = element.offsetParent;
                }
                return {x, y};
            }

            let touchstart = (event) => {
                if (event.touches.length == 1) {
                    const touch: any = event.touches[0];
                    switch (this.mouseMode) {
                        case MouseModes.Click:
                            event.preventDefault()
                            let offset = getTotalOffset(touch.target)
                            const x = touch.pageX - offset.x;
                            const y = touch.pageY - offset.y;
                            //console.log('touchstart ', event, ' result x ', x, ' y ', y, ' offset ', offset)
                            previousCoords = {x, y};
                            isDrawing = true;
                            moveCursor(x, y, false)
                            mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_DOWN)
                            clickHandleStart(event)
                            break;
                    }
                }
            };

            let touchmove = (event) => {
                if (event.touches.length == 1) {
                    const touch: any = event.touches[0];
                    switch (this.mouseMode) {
                        case MouseModes.Click:
                            event.preventDefault()
                            if (isDrawing) {
                                let offset = getTotalOffset(touch.target)
                                const x = touch.pageX - offset.x;
                                const y = touch.pageY - offset.y;
                                const coords = {x, y};

                                let distance = this.getDistance(previousCoords, coords);
                                if (previousCoords && distance >= 5) {
                                    moveCursor(x, y, false);
                                    previousCoords = coords;
                                    clickHandleMove(distance)
                                }
                            }
                            break;
                    }
                }
            };
            let touchend = (event) => {
                if (isDrawing) {
                    switch (this.mouseMode) {
                        case MouseModes.Click:
                            event.preventDefault()
                            isDrawing = false;
                            previousCoords = null;
                            mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_UP);
                            clickHandleEnd(event)
                            break;
                    }
                }
            };

            let touchstartParent = (event) => {
                event.preventDefault()
                //console.debug('touchstartParent touches', event.touches.length)
                if (event.touches.length == 1) {
                    const touch: any = event.touches[0]
                    switch (this.mouseMode) {
                        case MouseModes.Move:
                            isDrawing = true
                            previousCoords = {x: touch.clientX, y: touch.clientY}
                            clickHandleStart(event)
                            break;
                    }
                } else if (event.touches.length == 2) {
                    // очищаем переменные для одиночного нажатия
                    isDrawing = false
                    previousCoords = null
                    clearTimeout(touchTimeoutReset)
                    clearTimeout(touchTimeout)
                    tapped = false
                    tappedDouble = true
                    totalMoveThisTap = 0
                    // Запоминаем начальное расстояние между пальцами и текущий масштаб
                    const touch1 = event.touches[0];
                    const touch2 = event.touches[1];
                    const dx = touch1.clientX - touch2.clientX;
                    const dy = touch1.clientY - touch2.clientY;
                    pinchStartDistance = Math.sqrt(dx * dx + dy * dy);
                    pinchStartScale = this.videoSizePercent;

                    // Запоминаем начальные координаты для перемещения
                    moveStartCoords = {x: touch1.clientX, y: touch1.clientY};
                } else if (event.touches.length == 3) {
                    // очищаем переменные для одиночного нажатия
                    isDrawing = false
                    previousCoords = null
                    clearTimeout(touchTimeoutReset)
                    clearTimeout(touchTimeout)
                    tapped = false
                    tappedDouble = true
                    totalMoveThisTap = 0
                    // очищаем переменные для движения картинки и увеличения
                    pinchStartDistance = 0
                    pinchStartScale = 1
                    moveStartCoords = null

                    const touch1 = event.touches[0];
                    const touch2 = event.touches[1];
                    const touch3 = event.touches[2];
                    // Запоминаем начальные позиции
                    scrollStartCoords = [
                        {x: touch1.clientX, y: touch1.clientY},
                        {x: touch2.clientX, y: touch2.clientY},
                        {x: touch3.clientX, y: touch3.clientY},
                    ];
                }
            };

            let touchmoveParent = (event) => {
                //console.debug('touchmoveParent touches', event.touches.length)
                if (event.touches.length == 1) {
                    event.preventDefault()
                    const touch: any = event.touches[0]
                    switch (this.mouseMode) {
                        case MouseModes.Move:
                            if (!previousCoords) return
                            // Вычисляем разницу движения пальца
                            const deltaX = Math.round((touch.clientX - previousCoords.x) * 2)
                            const deltaY = Math.round((touch.clientY - previousCoords.y) * 2)

                            if (deltaX != 0 || deltaY != 0) {
                                moveCursor(deltaX, deltaY, true)
                                previousCoords = {x: touch.clientX, y: touch.clientY}
                                clickHandleMove(Math.abs(deltaX) + Math.abs(deltaY))
                            }
                            break;
                    }
                } else if (event.touches.length == 2) {
                    // Вычисляем текущее расстояние между пальцами и изменение масштаба
                    const touch1 = event.touches[0]
                    const touch2 = event.touches[1]
                    const dx = touch1.clientX - touch2.clientX
                    const dy = touch1.clientY - touch2.clientY
                    const pinchDistance = Math.sqrt(dx * dx + dy * dy)
                    const scale = (pinchDistance / pinchStartDistance) * pinchStartScale

                    // Изменяем масштаб картинки
                    this.changeSizeType(VideoSizes.Custom)
                    this.changeSizePercent(scale)

                    // Вычисляем разницу движения пальца
                    if (moveStartCoords) {
                        const touch = event.touches[0];
                        const deltaX = touch.clientX - moveStartCoords.x;
                        const deltaY = touch.clientY - moveStartCoords.y;

                        if (deltaX != 0 || deltaY != 0) {
                            // Перемещаем картинку на указанное расстояние
                            this.moveVideoPosition(deltaX, deltaY);
                        }

                        // Обновляем начальные координаты для следующего перемещения
                        moveStartCoords = {x: touch.clientX, y: touch.clientY};
                    }
                } else if (event.touches.length == 3) {
                    event.preventDefault()
                    // Получить текущие позиции касания каждого пальца
                    const touch1 = event.touches[0];
                    const touch2 = event.touches[1];
                    const touch3 = event.touches[2];

                    // Вычислить разницу между начальными и текущими позициями для каждого пальца
                    const deltaX1 = touch1.clientX - scrollStartCoords[0].x;
                    const deltaY1 = touch1.clientY - scrollStartCoords[0].y;
                    const deltaX2 = touch2.clientX - scrollStartCoords[1].x;
                    const deltaY2 = touch2.clientY - scrollStartCoords[1].y;
                    const deltaX3 = touch3.clientX - scrollStartCoords[2].x;
                    const deltaY3 = touch3.clientY - scrollStartCoords[2].y;

                    const deltaX = (deltaX1 + deltaX2 + deltaX3) / 3
                    const deltaY = (deltaY1 + deltaY2 + deltaY3) / 3

                    //console.debug('scroll deltaX', deltaX, 'deltaY', deltaY)
                    // Выполнить прокрутку на основе разницы позиций пальцев
                    let getValue = (value) => {
                        return Math.round(value * 0.01)
                    }
                    if (deltaX) {
                        scroll(key.SCROLL_X, getValue(-deltaX))
                    }
                    if (deltaY) {
                        scroll(key.SCROLL_Y, getValue(-deltaY))
                    }
                }
            };
            let touchendParent = (event) => {
                if (isDrawing) {
                    event.preventDefault()
                    switch (this.mouseMode) {
                        case MouseModes.Move:
                            isDrawing = false
                            previousCoords = null
                            clickHandleEnd(event)
                            break;
                    }
                } else if (event.touches.length == 0) {
                    // Сбрасываем значения переменных
                    pinchStartDistance = 0;
                    pinchStartScale = 1;
                    moveStartCoords = null;
                }
            };

            let clickHandleStart = (event) => {
                //console.debug('on clickHandleStart tapped', tapped, 'tappedDouble', tappedDouble)
                touchTime = Date.now()
                totalMoveThisTap = 0
                if (!tapped) {
                    // Если до этого не было одиночного нажатия, устанавливаем флаг tapped
                    tapped = true;
                    touchTimeoutReset = setTimeout(() => {
                        // По истечении времени таймера сбрасываем флаг tapped
                        tapped = false
                    }, 300);
                } else {
                    // Если уже было одиночное нажатие и таймер еще не сработал, значит это двойное нажатие
                    clearTimeout(touchTimeoutReset);
                    clearTimeout(touchTimeout);
                    tapped = false;
                    tappedDouble = true;
                    mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_DOWN)
                }
            };
            let clickHandleMove = (delta) => {
                //console.debug('on clickHandleMove', delta)
                totalMoveThisTap += delta
            }
            let clickHandleEnd = (event) => {
                // console.debug('on clickHandleEnd totalMoveThisTap', totalMoveThisTap, 'tapped', tapped, 'tappedDouble', tappedDouble)
                if (!tappedDouble) {

                    // проверяем контекстное меню
                    switch (this.mouseMode) {
                        case MouseModes.Click:
                            if (Date.now() - touchTime > 500) {
                                mouseKey(key.MOUSE_RIGHT, constants.HID_COMMAND_ACTION_CLICK)
                            }
                            return
                        case MouseModes.Move:
                            if (Date.now() - touchTime > 500 && totalMoveThisTap < 10) {
                                mouseKey(key.MOUSE_RIGHT, constants.HID_COMMAND_ACTION_CLICK)
                                return;
                            }
                            break
                    }
                    // Если до этого не было двойного нажатия, обрабатываем одиночное нажатие
                    if (tapped) {
                        // console.log('Single tap');
                        // Запускаем таймер для клика в случае если следующий клик не отменит его
                        if (totalMoveThisTap < 10)
                            touchTimeout = setTimeout(() => {
                                console.debug('on touchTimeout')
                                mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_CLICK)
                            }, 300);

                        //mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_CLICK)
                        // Ваш код для одиночного нажатия здесь
                    } else {
                        // Если не было ни одного нажатия, считаем это перемещением
                        // console.log('Move');
                        // Ваш код для перемещения здесь
                    }
                } else {
                    // console.log('Double tap');
                    // Если было двойное нажатие, сбрасываем флаг
                    tappedDouble = false;

                    switch (this.mouseMode) {
                        case MouseModes.Click:
                            mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_UP)
                            break
                        case MouseModes.Move:
                            if (Date.now() - touchTime <= 300) { // двойной клик
                                mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_UP)
                                mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_CLICK)
                            } else { // клик и перемещение
                                mouseKey(key.MOUSE_LEFT, constants.HID_COMMAND_ACTION_UP)
                            }
                            break
                    }
                }
            };

            this.addControlListener('touchstart', (e) => touchstart(e))
            this.addControlListener('touchmove', (e) => touchmove(e))
            this.addControlListener('touchend', (e) => touchend(e))

            this.addControlParentListener('touchstart', (e) => touchstartParent(e))
            this.addControlParentListener('touchmove', (e) => touchmoveParent(e))
            this.addControlParentListener('touchend', (e) => touchendParent(e))
            //this.addFakeKeyboardListener('keydown', (e) => keyboardButtons(e, true))
            //this.addFakeKeyboardListener('keyup', (e) => keyboardButtons(e, false))

        } else {
            let mousemove = (event) => {
                event.preventDefault();
                moveCursor(event.offsetX, event.offsetY, false)
            };

            let mouseButtons = (event, action) => {
                event.preventDefault();
                let res;
                // console.info('mouseButtons ', event)
                switch (event.button) {
                    case 0:
                        res = key.MOUSE_LEFT;
                        break;
                    case 1:
                        res = key.MOUSE_MIDDLE;
                        break;
                    case 2:
                        res = key.MOUSE_RIGHT;
                        break;
                    case 3:
                        res = key.MOUSE_BACK;
                        break;
                    case 4:
                        res = key.MOUSE_FORWARD;
                        break;
                    default:
                        return;
                }
                mouseKey(res, action)
            };

            let contextmenu = (event) => {
                // console.info('contextmenu ', event)
                event.preventDefault();
                return false; //Remove it if you want to keep the default contextmenu
            };


            this.addControlListener('mousemove', mousemove);
            this.addControlListener('mousedown', event => mouseButtons(event, constants.HID_COMMAND_ACTION_DOWN));
            this.addControlListener('mouseup', event => mouseButtons(event, constants.HID_COMMAND_ACTION_UP));
            this.addControlListener('contextmenu', contextmenu);
        }

        this.addControlListener('wheel', function (event) {
            event.preventDefault();

            function getValue(value) {
                return value * 0.01
            }

            if (event.deltaY) {
                scroll(key.SCROLL_Y, getValue(-event.deltaY))
            }
            if (event.deltaX) {
                scroll(key.SCROLL_X, getValue(-event.deltaX))
            }
        });

        let keyboardButtons = (event, action) => {
            event.preventDefault();
            // console.log('on keyboard key', event)
            let res;
            switch (event.keyCode) {
                case 8:
                    res = key.KEY_BACKSPACE;
                    break;
                case 20:
                    res = key.KEY_CAPSLOCK;
                    break;
                case 91:
                case 92:
                    res = key.KEY_META;
                    break;
                case 16:
                    switch (event.code) {
                        case 'ShiftLeft':
                            res = key.KEY_SHIFT;
                            break;
                        case 'ShiftRight':
                            res = key.KEY_RIGHTSHIFT;
                            break;
                    }
                    break;
                case 17:
                    switch (event.code) {
                        case 'ControlRight':
                            res = key.KEY_CONTROL;
                            break;
                        case 'ControlLeft':
                            res = key.KEY_CONTROL;
                            break;
                    }
                    break;
                case 18:
                    switch (event.code) {
                        case 'AltLeft':
                            res = key.KEY_ALT;
                            break;
                        case 'AltRight':
                            res = key.KEY_RIGHTALT;
                            break;
                    }
                    break;
                case 38:
                    res = key.KEY_UPARROW;
                    break;
                case 40:
                    res = key.KEY_DOWNARROW;
                    break;
                case 37:
                    res = key.KEY_LEFTARROW;
                    break;
                case 39:
                    res = key.KEY_RIGHTARROW;
                    break;

                case 27:
                    res = key.KEY_ESCAPE;
                    break;
                case 112:
                    res = key.KEY_F1;
                    break;
                case 113:
                    res = key.KEY_F2;
                    break;
                case 114:
                    res = key.KEY_F3;
                    break;
                case 115:
                    res = key.KEY_F4;
                    break;
                case 116:
                    res = key.KEY_F5;
                    break;
                case 117:
                    res = key.KEY_F6;
                    break;
                case 118:
                    res = key.KEY_F7;
                    break;
                case 119:
                    res = key.KEY_F8;
                    break;
                case 120:
                    res = key.KEY_F9;
                    break;
                case 121:
                    res = key.KEY_F10;
                    break;
                case 122:
                    res = key.KEY_F11;
                    break;
                case 123:
                    res = key.KEY_F12;
                    break;

                case 33:
                    res = key.KEY_PAGEUP;
                    break;
                case 34:
                    res = key.KEY_PAGEDOWN;
                    break;
                case 36:
                    res = key.KEY_HOME;
                    break;
                case 35:
                    res = key.KEY_END;
                    break;
                case 45:
                    res = key.KEY_INSERT;
                    break;
                case 46:
                    res = key.KEY_DELETE;
                    break;

                case 32:
                    res = key.KEY_SPACE;
                    break;
                case 9:
                    res = key.KEY_TAB;
                    break;
                case 13:
                    res = key.KEY_RETURN;
                    break;
                case 145:
                    res = key.KEY_SCROLL;
                    break;
                case 19:
                    res = key.KEY_PAUSE;
                    break;
                case 144:
                    res = key.KEY_NUMLOCK;
                    break;
            }

            switch (event.code) {
                case 'Numpad1':
                    res = key.KEY_NUMPAD1;
                    break;
                case 'Numpad2':
                    res = key.KEY_NUMPAD2;
                    break;
                case 'Numpad3':
                    res = key.KEY_NUMPAD3;
                    break;
                case 'Numpad4':
                    res = key.KEY_NUMPAD4;
                    break;
                case 'Numpad5':
                    res = key.KEY_NUMPAD5;
                    break;
                case 'Numpad6':
                    res = key.KEY_NUMPAD6;
                    break;
                case 'Numpad7':
                    res = key.KEY_NUMPAD7;
                    break;
                case 'Numpad8':
                    res = key.KEY_NUMPAD8;
                    break;
                case 'Numpad9':
                    res = key.KEY_NUMPAD9;
                    break;

                case 'NumpadEnter':
                    res = key.KEY_NUMPADENTER;
                    break;
            }

            if (res) {
                keyboardKey(res, action)
            } else {
                let res = event.key
                // change char because has problem with layout
                switch (event.code) {
                    case 'Digit1':
                        res = '1';
                        break;
                    case 'Digit2':
                        res = '2';
                        break;
                    case 'Digit3':
                        res = '3';
                        break;
                    case 'Digit4':
                        res = '4';
                        break;
                    case 'Digit5':
                        res = '5';
                        break;
                    case 'Digit6':
                        res = '6';
                        break;
                    case 'Digit7':
                        res = '7';
                        break;
                    case 'Digit8':
                        res = '8';
                        break;
                    case 'Digit9':
                        res = '9';
                        break;
                    case 'Digit0':
                        res = '0';
                        break;
                    /*case 'BracketLeft':
                        res = '[';
                        break;
                    case 'BracketRight':
                        res = ']';
                        break;
                    case 'Semicolon':
                        res = ';';
                        break;
                    case 'Quote':
                        res = '\'';
                        break;
                    case 'Comma':
                        res = ',';
                        break;
                    case 'Period':
                        res = '.';
                        break;
                    case 'Slash':
                        res = '/';
                        break;
                    case 'Backslash':
                        res = '\\';
                        break;
                    case 'Minus':
                        res = '-';
                        break;
                    case 'Equal':
                        res = '=';
                        break;
                    case 'Backquote':
                        res = '`';
                        break;*/
                    /*case 'KeyQ': res = 'q'; break;
                    case 'KeyW': res = 'w'; break;
                    case 'KeyE': res = 'e'; break;
                    case 'KeyR': res = 'r'; break;
                    case 'KeyT': res = 't'; break;
                    case 'KeyY': res = 'y'; break;
                    case 'KeyU': res = 'u'; break;
                    case 'KeyI': res = 'i'; break;
                    case 'KeyO': res = 'o'; break;
                    case 'KeyP': res = 'p'; break;
                    case 'KeyA': res = 'a'; break;
                    case 'KeyS': res = 's'; break;
                    case 'KeyD': res = 'd'; break;
                    case 'KeyF': res = 'f'; break;
                    case 'KeyG': res = 'g'; break;
                    case 'KeyH': res = 'h'; break;
                    case 'KeyJ': res = 'j'; break;
                    case 'KeyK': res = 'k'; break;
                    case 'KeyL': res = 'l'; break;
                    case 'KeyZ': res = 'z'; break;
                    case 'KeyX': res = 'x'; break;
                    case 'KeyC': res = 'c'; break;
                    case 'KeyV': res = 'v'; break;
                    case 'KeyB': res = 'b'; break;
                    case 'KeyN': res = 'n'; break;
                    case 'KeyM': res = 'm'; break;*/
                }

                if (isMobile && event.keyCode === 229) { // on android insert word instead chart  []
                    if (action == constants.HID_COMMAND_ACTION_UP) {
                        //console.debug('before send', event.target.value)
                        let input: any = this.getFakeInput();
                        let value = input.value
                        let chars = value.split('');
                        for (let char of chars) {
                            //console.debug('char ', char)
                            keyboardKey(key.KEY_CHAR, constants.HID_COMMAND_ACTION_CLICK, char)
                        }
                        input.value = ""
                        //console.debug('after clear', input.value)
                    }
                } else
                    keyboardKey(key.KEY_CHAR, action, res)
            }
        }

        RootStore.getInstance().stopKeyListener = true
        this.addKeyboardListener("keydown", event => keyboardButtons(event, constants.HID_COMMAND_ACTION_DOWN));
        this.addKeyboardListener("keyup", event => keyboardButtons(event, constants.HID_COMMAND_ACTION_UP));

        let lastSend;
        let moveCursor = (x, y, add) => {
            /*if (lastSend && Date.now() - lastSend < 20){
                return
            }*/
            if (!this.dcMain || this.dcMain.status == DataChanelStatus.Close) {
                //this.removeControlListeners()
                return
            }
            //console.log(`Mouse coordinates before change: x ${x}  y ${y} disp_width ${this.videoSizeOriginal.w} disp_height ${this.videoSizeOriginal.h}`)
            let command, coords;

            if (add) {
                command = constants.HID_MOUSE_ADD;
                coords = {x, y};
            } else {
                command = constants.HID_MOUSE_MOVE;
                coords = this.convertCursorLocalToGlobal({
                    x: Math.round((x / element.clientWidth) * this.videoSizeOriginal.w),
                    y: Math.round((y / element.clientHeight) * this.videoSizeOriginal.h),
                });
            }
            //console.log(`Mouse coordinates before send: x ${coords.x}  y ${coords.y}`)

            if (Helper.isEmpty(coords.x) || Helper.isEmpty(coords.y))
                return;
            // отправляем координаты на удаленный компьютер
            this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_COMMAND, {
                command,
                coordinates: coords
            }, (answer) => {
                // console.info(`select format answer`)
                // console.info(answer)
            })
            lastSend = Date.now();
        }

        let mouseKey = (key, action) => {
            if (!this.dcMain || this.dcMain.status == DataChanelStatus.Close) {
                //this.removeControlListeners()
                return
            }
            //console.log(`Key to send ${key} action ${action}`)

            this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_COMMAND, {
                command: constants.HID_MOUSE_KEY,
                key_code: key,
                action
            }, (answer) => {
                // console.info(`select format answer`)
                // console.info(answer)
            })
        }

        let scroll = (key, value) => {
            if (!this.dcMain || this.dcMain.status == DataChanelStatus.Close) {
                //this.removeControlListeners()
                return
            }

            //console.log(`Scroll to send ${key} value ${value}`)

            this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_COMMAND, {
                command: constants.HID_MOUSE_SCROLL,
                key_code: key,
                value
            }, (answer) => {
                // console.info(`select format answer`)
                // console.info(answer)
            })
        }

        let keyboardKey = (key, action, char = undefined) => {
            if (!this.dcMain || this.dcMain.status == DataChanelStatus.Close) {
                //this.removeControlListeners()
                return
            }
            //console.log(`Keyboard key to send ${key} action ${action} char ${char}`)

            this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_COMMAND, {
                command: constants.HID_KEYBOARD_KEY,
                key_code: key,
                char,
                action
            }, (answer) => {
                // console.info(`select format answer`)
                // console.info(answer)
            })
        }
    }

    private getFakeInput() {
        if (!this.fakeInput)
            this.fakeInput = document.getElementById('fakeInput');
        return document.getElementById('fakeInput')
    }

    @action
    sendFastKey(el) {
        if (!this.dcMain || this.dcMain.status == DataChanelStatus.Close) {
            return
        }
        let action = constants.HID_COMMAND_ACTION_CLICK;
        if (el.hold) {
            el.down = !el.down;
            action = el.down ? constants.HID_COMMAND_ACTION_DOWN : constants.HID_COMMAND_ACTION_UP;
        }
        //console.log(`Keyboard key to send ${el.key} down ${el.down} action ${action}`)
        this.dcMain.send_with_ask(constants.WEBRTC_DC_EVENT_SCREEN_COMMAND, {
            command: constants.HID_KEYBOARD_KEY,
            key_code: el.key,
            action
        }, (answer) => {

            // console.info(`select format answer`)
            // console.info(answer)
        })
    }

    @action
    toggleMore() {
        this.buttonsShowMore = !this.buttonsShowMore
    }

    getDistance(point1, point2) {
        const dx = point1.x - point2.x;
        const dy = point1.y - point2.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    @action
    private updateCursorPosition(coordinates: { x: number; y: number }) {
        //console.log('updateCursorPosition ', coordinates, 'percent', this.videoSizePercent, 'calculated', calculated)
        this.cursorPositionOrigin = this.convertCursorGlobalToLocal(coordinates)
        this.calculateCursorPosition()
    }

    private addControlListener(name: string, listener: (event) => void) {
        this.videoRef.addEventListener(name, listener)
        this.controlListeners.push({name, listener})
    }

    private addControlParentListener(name: string, listener: (event) => void) {
        let parent = this.videoRef.parentElement.parentElement.parentElement;
        parent.addEventListener(name, listener)
        this.controlParentListeners.push({name, listener})
    }

    private addFakeKeyboardListener(name: string, listener: (event) => void) {
        let element = document.getElementById('fakeInput');
        element.addEventListener(name, listener)
        this.fakeKeyboardListeners.push({name, listener})
    }

    private addKeyboardListener(name: string, listener: (event) => void) {
        window.addEventListener(name, listener)
        this.keyboardListeners.push({name, listener})
    }

    @action
    toggleControlMenu(name: string) {
        Object.keys(this.controlMenuState).map(el => el != name && (this.controlMenuState[el] = false))
        this.controlMenuState[name] = !this.controlMenuState[name]
    }

    @action
    toggleMoreControl() {
        this.controlMore.selected = !this.controlMore.selected
    }

    @action
    changeItem(el, name: string, value: any) {
        el[name] = value
    }

    @action
    toggleKeyBoard() {
        this.mobileKeyboard.show = !this.mobileKeyboard.show
    }
}

export default VisualControlStore;

class Display {
    number: number
    name: string
    selected: boolean
    primary: boolean
    width: number
    height: number
    x: number
    y: number
    cursor: boolean
}
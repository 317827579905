export default {
  mainSite: `Ir al sitio principal`,
  menu: `Menú`,
  stations: `Estaciones`,
  exit: `Salir`,
  feedback: `Comentarios`,
  balance: `Saldo`,
  sortNo: `Sin orden`,
  sortDown: `Orden descendente`,
  sortUp: `Orden ascendente`,
  sorting: `Ordenando`,
  loadMore: `Cargar más`,
  nothingFound: `No se encontraron resultados`,
  select: `Seleccionar`,
  refresh: `Actualizar`,
  refreshData: `Actualizar datos`,
  filter: `Filtrar`,
  filterOpen: `Abrir filtro`,
  filterClear: `Limpiar filtro`,
  add: `Agregar`,
  downloadExcel: `Descargar como Excel`,
  openSettings: `Abrir configuración`,
  back: `Volver`,
  actions: `Acciones`,
  noData: `Sin datos`,
  addLine: `Agregar línea`,
  change: `Cambiar`,
  create: `Crear`,
  open: `Abrir`,
  selectFromList: `Seleccionar de la lista`,
  loading: `Cargando...`,
  showQR: `Mostrar código QR`,
  save: `Guardar`,
  cancel: `Cancelar`,
  yes: `Sí`,
  no: `No`,
  close: `Cerrar`,
  make: `Ejecutar`,
  checkbox: `Selección`,
  notFound: `No encontrado`,
  errorUpdate: `Error de actualización`,
  errorGetUserEmpty: `Error al obtener el usuario - respuesta vacía`,
  dayS: `días`,
  successfully: `Correctamente`,
  errorDetails: `Detalles del error`,
  clean: `Limpiar`,
  apply: `Aplicar`,
  settings: 'Configuración',
  price: 'Precio',
};
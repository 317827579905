export default {
  mainSite: `Ir para o site principal`,
  menu: `Menu`,
  stations: `Estações`,
  exit: `Sair`,
  feedback: `Feedback`,
  balance: `Saldo`,
  sortNo: `Sem ordenação`,
  sortDown: `Ordenar descendentemente`,
  sortUp: `Ordenar ascendentemente`,
  sorting: `Ordenação`,
  loadMore: `Carregar mais`,
  nothingFound: `Nada encontrado`,
  select: `Selecionar`,
  refresh: `Atualizar`,
  refreshData: `Atualizar dados`,
  filter: `Filtrar`,
  filterOpen: `Abrir filtro`,
  filterClear: `Limpar filtro`,
  add: `Adicionar`,
  downloadExcel: `Baixar como Excel`,
  openSettings: `Abrir configurações`,
  back: `Voltar`,
  actions: `Ações`,
  noData: `Sem dados`,
  addLine: `Adicionar linha`,
  change: `Alterar`,
  create: `Criar`,
  open: `Abrir`,
  selectFromList: `Selecionar da lista`,
  loading: `Carregando...`,
  showQR: `Mostrar código QR`,
  save: `Salvar`,
  cancel: `Cancelar`,
  yes: `Sim`,
  no: `Não`,
  close: `Fechar`,
  make: `Executar`,
  checkbox: `Seleção`,
  notFound: `Não encontrado`,
  errorUpdate: `Erro de atualização`,
  errorGetUserEmpty: `Erro ao obter o usuário - resposta vazia`,
  dayS: `dias`,
  successfully: `Com sucesso`,
  errorDetails: `Detalhes do erro`,
  clean: `Limpar`,
  apply: `Aplicar`,
  settings: 'Configurações',
  price: 'Preço',
};
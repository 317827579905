export default {
    nickname: `Your nickname`,
    address: `Your address`,
    daysInMonth: `Based on a 30-day month`,
    currentBalance: `Current balance`,
    stationCount: `Number of stations`,
    expensesMonth: `Expenses per month`,
    enoughBalance: `Sufficient balance`,
    currentTariff: `Current tariff`,
    optionPay: `Optional payment`,
    stationPerMonth: `Per station per month`,
    payFirstStations: `Pay for the first 5 stations`,
    notSelect: `Not selected`,
    changeTariff: `Change tariff`,
    createTariff: `Create tariff`,
    fieldsCalc: `Calculation fields`,
    stations: `Stations`,
    mounths: `Months`,
    changeBalanceThis: `Replace balance with this amount`,
    total: `Total`,
    addBalance: `Add balance`,
    summForAdd: `Amount to add, $`,
    pushToCopy: `Click to copy to clipboard`,
    textCopied: `Text copied to clipboard`,
    createBill: `Create bill`,
    cancelBill: `Cancel bill`,
    addressToSend: `Address to send payment`,
    or: `or`,
    billPartner: `Bill for payment at partner`,
    go: `go`,
    textBalance: `Brief payment instructions (by credit card or other methods)
        <ol>
        <li>Create a bill on this page</li>
        <li>Transfer money
        <ul>
        <li>Directly - if you have an e-wallet with USDT bep20 coins.</li>
        <!--<li>Pay the invoiced bill at the partner - the partner may charge a commission, depending on the payment method.</li>-->
        <li>Through an exchanger, for example, by selecting it on <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> (without commission):
        <ol>
        <li>Go to the <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">aggregator page</a></li>
        <li>In the "Gives" column, select a suitable payment method, such as "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>". 
I recommend choosing in this column the payment method with the lowest interest rate for your country (about 3-5%), usually one of the popular banks in the local currency, 
and not a direct transfer in USD.</li>
        <li>In the "Receive" column - it should be Tether bep20</li>
        <li>Choose an exchanger with the best rate or conditions</li>
        <li>On the exchanger's website, specify the amount you entered for replenishment in dollars, copy your replenishment address (above), fill in the other fields, and transfer the amount</li>
        <li>It is processed by the exchanger for 20 minutes, and then by my service</li>
        </ol>
        </li>
        </ul>
        </li>
        </ol>
         
        <p>After the funds are received, the data on this page will be automatically updated. If the bill has not been paid within 24 hours, it will be automatically canceled.</p>
        The payment for the tariff is deducted daily, based on the maximum number of stations during the day.
        <p>If you encounter any problems, please write to me on
        <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
        <p>If you have any additional features you need, please write about them in the
        <a href="{{tg_idea}}" target="_blank" class="link">project ideas chat section</a>.
        The idea will either be implemented immediately or put to a vote by group members, and a decision will be made on development. Thank you in advance for your participation!</p>`,

    textTariffCost: `The service allows you to choose the monthly cost of services yourself, this option
        also serves as an evaluation of the idea behind this project. The higher the amount you specify, the better this project will become,
        with more powerful servers, fewer bugs, a more user-friendly interface, and faster interactions.
        Most importantly, new features will be introduced to make managing your stations easier.`,

    textTariffFirstStations: `If you want to support the project to such an extent that you are even willing to pay for the stations that are free for you,
        you are incredibly awesome!`,

    textBillAmount: `To automatically identify your payment and associate it with your account, you need to enter
        the amount in advance and make sure that it is specified exactly to the cent when transferring funds.
        Decimal numbers may be added to the amount if it is already awaiting payment in the database.`,
    textBillAddress: `It is generated automatically when the bill is created. It must be provided for any payment method,
        pay attention to the network type "USDT bep20" (Binance)
        - you will be asked for it when submitting a request to any cryptocurrency exchange service`,
    textBillPartner: `Another way is to pay at a partner, the bill for the selected amount has already been created, just follow
        the link and transfer the money in a convenient way.`,

    billCreated: `Created`,
    billPartPaid: `Partially paid`,
    billPaid: `Paid`,
    billCancel: `Canceled`,
    billNum: `Bill №`,
    tariffUpdated: `Tariff updated`,
    billCreatedFull: `Bill created`,
    billPaidFull: `Bill paid`,
    billCancelFull: `Bill canceled`,
    errorSaveUserEmpty: `Error saving user - empty response`,
    settings: `Settings`,
    tariff: `Tariff and Payment`,
    transactions: `Transactions`,

    type: `Type`,
    createdAt: `Date`,
    value: `Value`,
    billString: `Bill`,
    tariffString: `Tariff`,
    stationsPay: `Stations`,

    sortDateCreate: `By date created`,
    transactionFilter: `Transaction filter`,
};

export default {
    stations: `Stazioni`,
    filter: `Filtro stazioni`,
    comment: `Commento`,
    comment_add: "Aggiungi commento",
    comment_edit: "Modifica commento",
    sortDate: `Ordina per data`,
    checkbox: `Selezione`,
    select: `Seleziona`,
    actions: `Azioni`,
    showStation: `Mostra stazione`,
    delete: `Elimina`,
    number: `Numero`,
    name: `Nome`,
    bootTimestamp: `Timestamp di avvio`,
    bootTimestampM: `Avvio`,
    cpuUsageTotal: `Utilizzo CPU, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `Utilizzo RAM, %`,
    memoryPercentM: `RAM`,
    ipAddress: `Indirizzo IP`,
    ipAddressM: `IP`,
    disks: `Spazio disponibile`,
    disksM: `Dischi`,
    updatedAt: `Data di aggiornamento`,
    system: `Sistema operativo`,
    release: `Release`,
    stationSelect: `Selezione stazione`,
    stationsDel: `Stazioni eliminate`,
    stationDel: `Stazione eliminata`,
    downloadClient: `Scarica client`,
    download: `Scarica`,
    online: `Online`,
    offline: `Offline`,

    toDo: `Da fare`,
    done: `Completato`,
    error: `Errore`,
    canceled: `Annullato`,

    info: `Informazioni`,
    power: `Alimentazione`,
    process: `Processi`,
    programs: `Programmi`,
    devices: `Dispositivi`,
    commands: `Comandi`,
    other: `Altro`,

    reboot: `Riavvia`,
    shutdown: `Spegni`,
    sleep: `Sospendi`,
    hibernate: `Ibernazione`,
    lock: `Blocca`,

    refill: `Ricarica`,
    writeOff: `Scarico`,
    withdrawal: `Prelievo`,
    dateUpdate: `Data di aggiornamento`,
    autoUpdate: `Aggiornamento automatico`,
    autoUpdateM: `Agg. automatico`,
    commandError: `Errore durante la creazione del comando`,
    commandSend: `Comando inviato`,

    visualControl: `Controllo visuale`,
    doCommand: `Esegui comando`,
    viewStation: `Visualizza stazione`,
    metaDesc: `Accesso remoto e controllo dei computer e dei server`,
    confirmAction: `Conferma azione`,
    confirmActionText: `Sei sicuro di voler eseguire il comando "{{cmd}}"?
Potrebbe essere necessario premere i pulsanti di azione.`,
    waitingExe: `In attesa di esecuzione...`,
    successEmpty: `Successo, ma la risposta è vuota`,
    resultExe: `Risultato dell'esecuzione`,
    enterCommand: `Inserisci comando`,
    exeCustomCommand: `Esegui comando personalizzato`,
    enterEnable: `Abilita invio con il tasto INVIO`,
    visualControlStation: `Controllo visuale della stazione`,
    add: `Aggiungi stazione`,
    password: `Password`,
    stationAdded: `Stazione aggiunta`,
    passwordLength: `La password deve essere più lunga di 6 caratteri`,

    cmd: {
        status: `Stato`,
        type: `Tipo`,
        createdAtS: `Data di creazione`,
        checkbox: `Selezione`,
        remove: `Rimuovi`,
        createdAt: `Data`,
        raw: `Dati`,
        user: `Utente`,
        result: `Risultato`,
        error: `Errore`,
        filterName: `Filtro comandi`,
        removeE: `Errore durante la rimozione dei comandi`,
        removeS: `Rimozione completata`,
    },

    devs: {
        name: `Nome`,
        group: `Gruppo`,
        status: `Stato`,
        manufacturer: `Produttore`,
        deviceID: `ID dispositivo`,
        driver: `Driver`,
        nameS: `Per nome`,
        groupS: `Per gruppo`,
        filterName: `Filtro dispositivi`,
        dateUpdate: `Data di aggiornamento`,
    },

    inf: {
        group: `Gruppo`,
        name: `Nome`,
        value: `Valore`,

        general: `Informazioni generali`,
        node: `Nome del computer`,
        bootTimestamp: `Timestamp di avvio`,
        updatedAt: `Data di aggiornamento`,
        system: `Sistema`,
        release: `Release`,
        version: `Versione`,
        programVersion: `Versione del programma`,
        memory: `Memoria`,
        memoryTotal: `Totale`,
        memoryAvailable: `Disponibile`,
        memoryUsed: `Utilizzata`,
        memoryPercent: `Utilizzo memoria, %`,
        swapTotal: `Totale swap`,
        swapFree: `Swap disponibile`,
        swapUsed: `Swap utilizzata`,
        swapPercent: `Utilizzo swap, %`,
        processor: `Processore`,
        processorVendor: `Produttore`,
        processorBrand: `Brand`,
        cpuUsageTotal: `Utilizzo CPU totale, %`,
        cpuCores: `Core`,
        cpuTotalCores: `Thread`,
        cpuFreqCurrent: `Frequenza corrente`,
        net: `Rete`,
        ipAddress: `Indirizzo IP`,
        macAddress: `Indirizzo MAC`,
        interfaces: `Interfacce di rete`,
        totalSend: `Totale inviato`,
        totalReceived: `Totale ricevuto`,
        persistent: `Memoria persistente`,
        disks: `Dischi`,
        dateUpdate: `Data di aggiornamento`,
        autoUpdate: `Aggiornamento automatico`,
        free: `Libero`,
    },

    proc: {
        name: `Nome`,
        exe: `Percorso`,
        username: `Utente`,
        pidS: `ID processo`,
        nameS: `Per nome`,
        cpuPercent: `Utilizzo CPU, %`,
        memoryPercent: `Utilizzo memoria, %`,
        createTimeS: `Per data di creazione`,
        usernameS: `Per utente`,
        checkbox: `Selezione`,
        finishP: `Termina processo`,
        finishPs: `Termina processi`,
        pid: `ID`,
        createTime: `Creazione`,
        diskUsageRead: `Lettura disco`,
        diskUsageReadTotal: `Totale lettura`,
        diskUsageWrite: `Scrittura disco`,
        diskUsageWriteTotal: `Totale scrittura`,
        status: `Stato`,
        filterName: `Filtro processi`,
    },

    prog: {
        startRemove: `Avvia rimozione programma`,
        name: `Nome`,
        installDate: `Data di installazione`,
        version: `Versione`,
        size: `Dimensione`,
        description: `Descrizione`,
        publisher: `Produttore`,
        location: `Posizione`,
        uninstall: `Disinstalla`,
        depends: `Dipendenze`,
        nameS: `Per nome`,
        installDateS: `Per data di installazione`,
        filterName: `Filtro programmi`,
        confirmAction: `Conferma azione`,
        confirmActionText: `Sei sicuro di voler avviare la rimozione di "{{name}}"?
Potrebbe essere necessario premere i pulsanti di azione.`,
    },

    control:{
        prepare: `Preparazione in corso...`,
        connecting: `Connessione in corso`,
        connected: `Connesso`,
        disconnected: `Disconnesso`,
        error: `Errore`,
        tryAgain: `Riprova`,
        zoom: `Ingrandisci`,
        zoomOut: `Riduci`,
        showAllButtons: `Mostra tutti i pulsanti`,
        display: `Schermo`,
        size: `Dimensione`,
        keys: `Tasti`,
        showHideKey: `Mostra/Nascondi tastiera`,
        fitScreen: `Adatta allo schermo`,
        fullscreen: `Schermo intero`,
        tune: `Personalizza`,
        cursorMove: `Movimento del cursore`,
        move: `Sposta`,
        click: `Clicca`,
    },


    aiControl: 'Controllo IA',
    aiControlStation: 'Stazione di Controllo IA',
    ai: {
        title: 'Controllo Stazione IA',
        autoApply: 'Applica comandi automaticamente',
        autoContinueError: 'Continua a elaborare gli errori',
        send: 'Invia (Ctrl + Invio)',
        input: 'Il tuo messaggio',
        errorLength: 'Il messaggio è troppo breve',
        process: 'In elaborazione',
        success: 'Successo',
        error: 'Errore',
        showMore: 'Mostra di più',
        continue: 'Continua',
        doCommand: 'Esegui comando',
        commandAny: 'Esegui un comando qualsiasi',
        refine: 'Perfeziona la risposta',
        newQuery: 'Nuova richiesta',
        auto: 'Auto',
        model: 'Modello IA',
        tokensLeft: 'Token rimanenti: {{value}}',
        allUsers: 'Tutti gli utenti',
        tokenHelp: 'Il testo inviato al modello IA viene suddiviso in token. Un token può essere un singolo carattere, una singola parola o addirittura una singola frase, a seconda della lingua e del contesto. Ad esempio, il testo "ChatGPT è fantastico!" può essere suddiviso nei seguenti token: ["Chat", "G", "PT", " è", " fantastico", "!"].',
        enableHTML: 'Abilita la visualizzazione del risultato in HTML',
        userTokens: 'Numero di token utilizzati nel messaggio corrente. Si consiglia di creare una nuova richiesta se è relativa a un contesto diverso.',
        inProgress: 'Elaborazione delle informazioni...',
        previewCommand: 'Verrà eseguito il seguente comando',
        "userToken": "Chiave API utente",
        "getToken": "Ottieni chiave"
    },
};

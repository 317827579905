export default {
    nickname: `닉네임`,
    address: `주소`,
    daysInMonth: `30일 기준`,
    currentBalance: `현재 잔액`,
    stationCount: `역의 수`,
    expensesMonth: `월간 지출`,
    enoughBalance: `충분한 잔액`,
    currentTariff: `현재 요금제`,
    optionPay: `옵션 결제`,
    stationPerMonth: `월 당 한 역당`,
    payFirstStations: `첫 5개 역 결제`,
    notSelect: `선택하지 않음`,
    changeTariff: `요금제 변경`,
    createTariff: `요금제 생성`,
    fieldsCalc: `계산 필드`,
    stations: `역`,
    mounths: `개월`,
    changeBalanceThis: `이 금액으로 잔액 교체`,
    total: `총액`,
    addBalance: `잔액 추가`,
    summForAdd: `추가할 금액, $`,
    pushToCopy: `클릭하여 클립보드에 복사`,
    textCopied: `텍스트가 클립보드에 복사되었습니다`,
    createBill: `청구서 생성`,
    cancelBill: `청구서 취소`,
    addressToSend: `결제를 보낼 주소`,
    or: `또는`,
    billPartner: `파트너에서 결제할 청구서`,
    go: `이동`,
    textBalance: `간단한 결제 안내 (신용카드 또는 기타 방법)
    <ol>
    <li>이 페이지에서 청구서를 작성하세요.</li>
    <li>돈을 이체하세요.
    <ul>
    <li>직접 - USDT bep20 코인이 있는 경우.</li>
    <!--<li>파트너에서 청구서를 결제하세요 - 파트너는 결제 방법에 따라 수수료를 청구할 수 있습니다.</li>-->
    <li>예를 들어, <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a>에서 선택한 거래소를 통해 (수수료 없음):
    <ol>
    <li><a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">집계 페이지</a>로 이동합니다.</li>
    <li>"제공" 열에서 적합한 결제 방법을 선택하세요. 예를 들어 "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>"입니다.
    국가에 대해 가장 낮은 이자율 (약 3-5%)로 이 열에서 지불 방법을 선택하는 것이 좋습니다. 일반적으로 지역 통화의 인기있는 은행 중 하나입니다. USD로 직접 이체하지 않습니다.</li>
    <li>"받음" 열 - Tether bep20이어야 합니다.</li>
    <li>가장 좋은 환율이나 조건을 가진 거래소를 선택하세요.</li>
    <li>거래소의 웹 사이트에서 달러로 충전할 금액을 지정하고, 충전 주소 (위)를 복사하고, 다른 필드를 작성하고, 금액을 이체하세요.</li>
    <li>20분 동안 거래소에서 처리되며, 그 후에 내 서비스에서 처리됩니다.</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>자금이 수령되면 이 페이지의 데이터가 자동으로 업데이트됩니다. 24시간 이내에 청구서가 결제되지 않으면 자동으로 취소됩니다.</p>
    요금은 하루 동안의 최대 역 수를 기준으로 매일 공제됩니다.
    <p>문제가 발생하면 <a href="{{tg}}" target="_blank" class="link">Telegram</a>으로 연락주세요.</p>
    <p>필요한 추가 기능이 있는 경우, <a href="{{tg_idea}}" target="_blank" class="link">프로젝트 아이디어 채팅 섹션</a>에 설명해주세요.
    아이디어는 즉시 구현되거나 그룹 구성원의 투표에 제출되어 개발 결정이 내려집니다. 참여해 주셔서 미리 감사드립니다!</p>`,

    textTariffCost: `이 서비스를 통해 월간 서비스 비용을 스스로 선택할 수 있습니다. 이 옵션은
    이 프로젝트의 아이디어에 대한 평가로도 작용합니다. 지정하는 금액이 높을수록 이 프로젝트는 더욱 발전합니다.
    더 강력한 서버, 더 적은 버그, 더 사용자 친화적인 인터페이스 및 더 빠른 상호 작용이 제공됩니다.
    가장 중요한 것은, 역 관리를 보다 쉽게하기 위해 새로운 기능이 도입될 것입니다.`,

    textTariffFirstStations: `자신에게 무료인 역까지도 지불할 정도로 프로젝트를 지원하고 싶다면, 정말 멋지세요!`,

    textBillAmount: `자동으로 결제를 식별하고 계정과 연결하기 위해 사전에 금액을 입력하고 자금을 이체할 때 정확하게 지정해야 합니다.
    데이터베이스에서 이미 결제 대기 중인 경우 소수점 숫자를 금액에 추가할 수 있습니다.`,
    textBillAddress: `청구서가 생성될 때 자동으로 생성됩니다. 어떤 결제 방법이든 제공해야 합니다.
    "USDT bep20" (바이낸스) 네트워크 유형에 유의하세요
    - 암호화폐 거래소 서비스에 요청을 제출할 때 이를 제공해야 합니다.`,
    textBillPartner: `다른 방법은 파트너에서 지불하는 것입니다. 선택한 금액에 대한 청구서가 이미 생성되었으므로 링크를 따라가서
    편리한 방법으로 돈을 이체하면 됩니다.`,

    billCreated: `생성됨`,
    billPartPaid: `부분 지불됨`,
    billPaid: `지불됨`,
    billCancel: `취소됨`,
    billNum: `청구서 번호`,
    tariffUpdated: `요금제가 업데이트되었습니다`,
    billCreatedFull: `청구서가 생성되었습니다`,
    billPaidFull: `청구서가 지불되었습니다`,
    billCancelFull: `청구서가 취소되었습니다`,
    errorSaveUserEmpty: `사용자 저장 오류 - 응답이 없습니다`,
    settings: `설정`,
    tariff: `요금제 및 결제`,
    transactions: `거래`,

    type: `유형`,
    createdAt: `날짜`,
    value: `금액`,
    billString: `청구서`,
    tariffString: `요금제`,
    stationsPay: `역 수`,

    sortDateCreate: `생성 날짜별`,
    transactionFilter: `거래 필터`,
};

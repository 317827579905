export default {
    nickname: `您的昵称`,
    address: `您的地址`,
    daysInMonth: `基于30天一个月`,
    currentBalance: `当前余额`,
    stationCount: `站点数量`,
    expensesMonth: `每月支出`,
    enoughBalance: `余额充足`,
    currentTariff: `当前资费`,
    optionPay: `可选付款`,
    stationPerMonth: `每个站点每月`,
    payFirstStations: `支付前5个站点`,
    notSelect: `未选择`,
    changeTariff: `更改资费`,
    createTariff: `创建资费`,
    fieldsCalc: `计算字段`,
    stations: `站点`,
    mounths: `月份`,
    changeBalanceThis: `使用此金额替换余额`,
    total: `总计`,
    addBalance: `添加余额`,
    summForAdd: `要添加的金额，$`,
    pushToCopy: `点击复制到剪贴板`,
    textCopied: `文本已复制到剪贴板`,
    createBill: `创建账单`,
    cancelBill: `取消账单`,
    addressToSend: `付款地址`,
    or: `或`,
    billPartner: `合作伙伴付款账单`,
    go: `前往`,
    textBalance: `简要支付说明（通过信用卡或其他方式）
        <ol>
        <li>在此页面上创建账单</li>
        <li>转账
        <ul>
        <li>直接转账-如果您拥有USDT bep20币的电子钱包。</li>
        <!--<li>在合作伙伴处支付账单-根据付款方式，合作伙伴可能会收取佣金。</li>-->
        <li>通过兑换商，例如，在<a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a>上选择（无佣金）：
        <ol>
        <li>前往<a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">汇总页面</a></li>
        <li>在“给出”栏中选择合适的付款方式，例如“<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>”。建议您根据所在国家选择具有最低利率（约为3-5%）的付款方式，通常是本地货币中的一家流行银行，而不是直接以USD进行转账。</li>
        <li>在“接收”栏中应选择Tether bep20</li>
        <li>选择具有最佳利率或条件的兑换商</li>
        <li>在兑换商的网站上，指定您输入的充值金额（以美元为单位），复制您的充值地址（上面），填写其他字段并转账</li>
        <li>由兑换商处理20分钟，然后由我的服务处理</li>
        </ol>
        </li>
        </ul>
        </li>
        </ol>
        
        <p>收到资金后，此页面上的数据将自动更新。如果账单在24小时内未支付，将自动取消。</p>
        费用将根据每天的最大站点数量进行每日扣除。
        <p>如果遇到任何问题，请在
        <a href="{{tg}}" target="_blank" class="link">Telegram</a>上与我联系。</p>
        <p>如果您有任何其他所需功能，请在
        <a href="{{tg_idea}}" target="_blank" class="link">项目想法聊天部分</a>中提出。该想法将立即实施或由群组成员投票，并对开发做出决定。提前感谢您的参与！</p>`,

    textTariffCost: `该服务允许您自行选择每月服务费用，这也是对该项目背后理念的评估。您指定的金额越高，该项目的发展越好，
        提供更强大的服务器、更少的错误、更用户友好的界面和更快的交互。
        最重要的是，将引入新功能，使管理站点更加简单。`,

    textTariffFirstStations: `如果您愿意为免费的站点付费来支持该项目，您真是太棒了！`,

    textBillAmount: `为了自动识别您的付款并将其与您的账户关联，您需要提前输入金额并确保在转账时准确指定金额。
        如果已在数据库中等待付款，则可以在金额中添加小数位数。`,
    textBillAddress: `账单创建时会自动生成。无论使用哪种付款方式，都需要提供此地址。
        注意网络类型为“USDT bep20”（币安）
        - 在向任何加密货币交易所服务提交请求时，都会要求您提供此地址。`,
    textBillPartner: `另一种方式是在合作伙伴处付款，已经创建了所选金额的账单，只需点击链接并以方便的方式转账即可。`,

    billCreated: `已创建`,
    billPartPaid: `部分已支付`,
    billPaid: `已支付`,
    billCancel: `已取消`,
    billNum: `账单编号`,
    tariffUpdated: `资费已更新`,
    billCreatedFull: `账单已创建`,
    billPaidFull: `账单已支付`,
    billCancelFull: `账单已取消`,
    errorSaveUserEmpty: `保存用户时出错-空响应`,
    settings: `设置`,
    tariff: `资费和付款`,
    transactions: `交易`,

    type: `类型`,
    createdAt: `日期`,
    value: `值`,
    billString: `账单`,
    tariffString: `资费`,
    stationsPay: `站点数`,

    sortDateCreate: `按创建日期排序`,
    transactionFilter: `交易筛选`,
};

import {observer} from "mobx-react";
import React, {createRef} from "react";
import cn from "classnames";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer} from "@material-ui/core";
import styles from './modals.module.scss';
import RootStore from "../../stores/rootStore";
import TableData from "../entitiesData/entitiesData";
import EntityData from "../entityData/entityData";
import MyButton from "../../components/myButton/myButton";
import {isMobile} from "react-device-detect";
import LANG from "../../lang/language";

function Modals() {
    let rootStore = RootStore.getInstance();

    function getData(el: any) {
        let parent: any = createRef();
        let res;

        let style: any = {maxWidth: window.innerWidth * 0.9};

        let onClose = () => {
            if (el.onClose)
                el.onClose();
            rootStore.showDialog({id: el.id});
        };

        switch (el.type) {
            case 'table':
                res = <TableData store={el.store} onSelect={(res) => {
                    if (el.store.onSelect(res))
                        rootStore.showEntity({id: el.id});
                }} parent={parent}/>;
                break;
            case 'dialog':
            case 'dialogEntity':
                let content

                switch (el.type) {
                    case 'dialog':
                        content = <DialogContentText>
                            {el.content.map(el2 => {
                                    let text = el2.text.split('\n').join('<br/>')
                                    return <DialogContentText dangerouslySetInnerHTML={{__html: text}}>
                                    </DialogContentText>;
                                }
                            )}
                        </DialogContentText>
                        break
                    case 'dialogEntity':
                        content = <EntityData store={el.store} close={() => {
                            if (el.onClose)
                                el.onClose();
                            rootStore.showEntity({id: el.id});
                        }} parent={parent}/>
                        break

                }
                let styleInner: any = {};
                if (el.bigWidth && !isMobile)
                    styleInner.minWidth = '800px'
                return <Dialog key={el.id}
                               fullScreen={el.fullScreen || false}
                               open={true}
                               maxWidth={'xl'}
                               onClose={onClose}
                               aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {el.title}
                    </DialogTitle>
                    <DialogContent style={styleInner}>
                        {
                            content
                        }
                    </DialogContent>
                    <DialogActions>
                        {
                            el.actions.map(el2 => <MyButton {...el2}> {LANG.get(el2.text)} </MyButton>)
                        }
                    </DialogActions>
                </Dialog>
                break;
            case 'visualControl':
                const VisualControl = require('../visualControl/visualControl').default
                res = <VisualControl store={el.store} close={onClose}/>
                return <Dialog key={el.id} fullScreen={true} open={true} disableEscapeKeyDown={true}
                               onClose={onClose}>
                    {
                        res
                    }
                </Dialog>
            case 'aiControl':
                const ChatGPTControl = require('../chatGPTControl/chatGPTControl').default
                res = <ChatGPTControl store={el.store} close={onClose}/>
                return <Dialog key={el.id} fullScreen={true} open={true} disableEscapeKeyDown={true}
                               onClose={onClose}>
                    {
                        res
                    }
                </Dialog>
            case 'entity':
            default:
                res = <EntityData store={el.store} close={onClose}
                                  parent={parent}/>;
                break;
        }

        return <Drawer key={el.id} anchor={el.anchor || 'right'} open={true}
                       onClose={() => rootStore.showEntity({id: el.id})}>
            <div ref={parent} className={cn('grid dir-col', styles.modalWrap)}>
                <div className={cn('col', styles.modal)} style={style}>
                    {
                        res
                    }
                </div>
            </div>
        </Drawer>

    }

    return <>
        {
            rootStore.modals.map(el => getData(el))
        }
    </>
}

export default observer(Modals);

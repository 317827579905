export default {
    title: `宇宙ステーション管理パネル`,
    phrase: `秘密のフレーズ（12 単語）`,
    enter: `ログイン`,
    register: `登録`,
    back: `戻る`,
    nickname: `ニックネーム`,
    secret: `秘密のフレーズ`,
    secretDesc: `フレーズを安全な場所に保存してください。これにより、アカウントと追加されたステーションにアクセスできます。サービスはこのフレーズを保存しません。
    失われた場合、アクセスを復元することはできず、新しいアカウントを作成してステーションを再追加する必要があります`,
    secretCheck: `秘密のフレーズを失った場合、アカウントへのアクセスを復元することはできないことに同意します`,
    errorInitKey: `キーの初期化エラー、データを確認してください`,
    registerSuccess: `登録が成功しました`,
};

export default {
    stations: `Estações`,
    filter: `Filtro de estações`,
    comment: `Comentário`,
    comment_add: "Adicionar comentário",
    comment_edit: "Editar comentário",
    sortDate: `Ordenar por data de criação`,
    checkbox: `Seleção`,
    select: `Selecionar`,
    actions: `Ações`,
    showStation: `Mostrar estação`,
    delete: `Excluir`,
    number: `Número`,
    name: `Nome`,
    bootTimestamp: `Timestamp de inicialização`,
    bootTimestampM: `Inic.`,
    cpuUsageTotal: `Uso da CPU, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `Uso de memória, %`,
    memoryPercentM: `RAM`,
    ipAddress: `Endereço IP`,
    ipAddressM: `IP`,
    disks: `Espaço disponível`,
    disksM: `Discos`,
    updatedAt: `Data de atualização`,
    system: `Sistema`,
    release: `Versão`,
    stationSelect: `Seleção de estação`,
    stationsDel: `Estações excluídas`,
    stationDel: `Estação excluída`,
    downloadClient: `Baixar cliente`,
    download: `Baixar`,
    online: `Online`,
    offline: `Offline`,

    toDo: `A fazer`,
    done: `Concluído`,
    error: `Erro`,
    canceled: `Cancelado`,

    info: `Informações`,
    power: `Energia`,
    process: `Processos`,
    programs: `Programas`,
    devices: `Dispositivos`,
    commands: `Comandos`,
    other: `Outro`,

    reboot: `Reiniciar`,
    shutdown: `Desligar`,
    sleep: `Hibernar`,
    hibernate: `Suspender`,
    lock: `Bloquear`,

    refill: `Recarregar`,
    writeOff: `Escrever`,
    withdrawal: `Retirar`,
    dateUpdate: `Data de atualização`,
    autoUpdate: `Atualização automática`,
    autoUpdateM: `Auto. Atual.`,
    commandError: `Erro ao criar comando`,
    commandSend: `Comando enviado`,

    visualControl: `Controle visual`,
    doCommand: `Executar comando`,
    viewStation: `Visualizar estação`,
    metaDesc: `Acesso remoto e controle de computadores e servidores`,
    confirmAction: `Confirmar ação`,
    confirmActionText: `Tem certeza de que deseja executar o comando "{{cmd}}"?
Pode ser necessário pressionar os botões de ação.`,
    waitingExe: `Aguardando execução...`,
    successEmpty: `Sucesso, mas resposta vazia`,
    resultExe: `Resultado da execução`,
    enterCommand: `Digite o comando`,
    exeCustomCommand: `Executar comando personalizado`,
    enterEnable: `Ativar envio ao pressionar ENTER`,
    visualControlStation: `Controle visual da estação`,
    add: `Adicionar estação`,
    password: `Senha`,
    stationAdded: `Estação adicionada`,
    passwordLength: `A senha deve ter mais de 6 caracteres`,

    cmd: {
        status: `Status`,
        type: `Tipo`,
        createdAtS: `Data de criação`,
        checkbox: `Seleção`,
        remove: `Remover`,
        createdAt: `Data`,
        raw: `Dados`,
        user: `Usuário`,
        result: `Resultado`,
        error: `Erro`,
        filterName: `Filtro de comandos`,
        removeE: `Erro ao remover comandos`,
        removeS: `Remoção bem-sucedida`,
    },

    devs: {
        name: `Nome`,
        group: `Grupo`,
        status: `Status`,
        manufacturer: `Fabricante`,
        deviceID: `ID do dispositivo`,
        driver: `Driver`,
        nameS: `Por nome`,
        groupS: `Por grupo`,
        filterName: `Filtro de dispositivos`,
        dateUpdate: `Data de atualização`,
    },

    inf: {
        group: `Grupo`,
        name: `Nome`,
        value: `Valor`,

        general: `Informações gerais`,
        node: `Nome do computador`,
        bootTimestamp: `Horário de inicialização`,
        updatedAt: `Horário de atualização dos dados`,
        system: `Sistema`,
        release: `Versão`,
        version: `Versão`,
        programVersion: `Versão do programa`,
        memory: `Memória`,
        memoryTotal: `Total`,
        memoryAvailable: `Disponível`,
        memoryUsed: `Em uso`,
        memoryPercent: `Em uso, %`,
        swapTotal: `Swap total`,
        swapFree: `Swap livre`,
        swapUsed: `Swap em uso`,
        swapPercent: `Swap em uso, %`,
        processor: `Processador`,
        processorVendor: `Fabricante`,
        processorBrand: `Marca`,
        cpuUsageTotal: `Uso total da CPU, %`,
        cpuCores: `Cores`,
        cpuTotalCores: `Threads`,
        cpuFreqCurrent: `Frequência atual`,
        net: `Rede`,
        ipAddress: `Endereço IP`,
        macAddress: `Endereço MAC`,
        interfaces: `Interfaces de rede`,
        totalSend: `Total enviado`,
        totalReceived: `Total recebido`,
        persistent: `Armazenamento persistente`,
        disks: `Discos`,
        dateUpdate: `Data de atualização`,
        autoUpdate: `Atualização automática`,
        free: `livre`,
    },

    proc: {
        name: `Nome`,
        exe: `Caminho`,
        username: `Nome de usuário`,
        pidS: `ID do processo`,
        nameS: `Por nome`,
        cpuPercent: `Uso da CPU, %`,
        memoryPercent: `Uso de memória, %`,
        createTimeS: `Por data de criação`,
        usernameS: `Por nome de usuário`,
        checkbox: `Seleção`,
        finishP: `Encerrar processo`,
        finishPs: `Encerrar processos`,
        pid: `ID`,
        createTime: `Criação`,
        diskUsageRead: `Leitura do disco`,
        diskUsageReadTotal: `Total de leitura`,
        diskUsageWrite: `Escrita no disco`,
        diskUsageWriteTotal: `Total de escrita`,
        status: `Status`,
        filterName: `Filtro de processos`,
    },

    prog: {
        startRemove: `Iniciar remoção do programa`,
        name: `Nome`,
        installDate: `Data de instalação`,
        version: `Versão`,
        size: `Tamanho`,
        description: `Descrição`,
        publisher: `Editora`,
        location: `Local de instalação`,
        uninstall: `Desinstalar`,
        depends: `Dependências`,
        nameS: `Por nome`,
        installDateS: `Por data de instalação`,
        filterName: `Filtro de programas`,
        confirmAction: `Confirmar ação`,
        confirmActionText: `Tem certeza de que deseja iniciar a desinstalação de "{{name}}"?
Pode ser necessário pressionar os botões de ação.`,
    },

    control:{
        prepare: `Preparando...`,
        connecting: `Conectando`,
        connected: `Conectado`,
        disconnected: `Desconectado`,
        error: `Erro`,
        tryAgain: `Tentar novamente`,
        zoom: `Aumentar zoom`,
        zoomOut: `Diminuir zoom`,
        showAllButtons: `Mostrar todos os botões`,
        display: `Exibição`,
        size: `Tamanho`,
        keys: `Teclas`,
        showHideKey: `Mostrar/Ocultar teclado`,
        fitScreen: `Ajustar à tela`,
        fullscreen: `Tela cheia`,
        tune: `Personalizado`,
        cursorMove: `Mover cursor`,
        move: `Mover`,
        click: `Clicar`,
    },

    aiControl: 'Controle de IA',
    aiControlStation: 'Estação de Controle de IA',
    ai: {
        title: 'Controle da Estação de IA',
        autoApply: 'Aplicar comandos automaticamente',
        autoContinueError: 'Continuar processando erros',
        send: 'Enviar (Ctrl + Enter)',
        input: 'Sua mensagem',
        errorLength: 'Mensagem muito curta',
        process: 'Processando',
        success: 'Sucesso',
        error: 'Erro',
        showMore: 'Mostrar mais',
        continue: 'Continuar',
        doCommand: 'Executar comando',
        commandAny: 'Executar qualquer comando',
        refine: 'Refinar resposta',
        newQuery: 'Nova consulta',
        auto: 'Automático',
        model: 'Modelo de IA',
        tokensLeft: 'Tokens restantes: {{value}}',
        allUsers: 'Todos os usuários',
        tokenHelp: 'O texto enviado para o modelo de IA é dividido em tokens. Um token pode ser um único caractere, uma única palavra ou até mesmo uma única frase, dependendo do idioma e do contexto. Por exemplo, o texto "ChatGPT is great!" pode ser dividido nos seguintes tokens: ["Chat", "G", "PT", " is", " great", "!"].',
        enableHTML: 'Ativar exibição de resultado em HTML',
        userTokens: 'Número de tokens usados na mensagem atual. É recomendável criar uma nova consulta se ela se refere a um contexto diferente.',
        inProgress: 'Processando informações...',
        previewCommand: 'O seguinte comando será executado',
        "userToken": "Chave de API do usuário",
        "getToken": "Obter chave"
    },
};

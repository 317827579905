export default {
  mainSite: `Zur Hauptseite gehen`,
  menu: `Menü`,
  stations: `Stationen`,
  exit: `Beenden`,
  feedback: `Feedback`,
  balance: `Guthaben`,
  sortNo: `Keine Sortierung`,
  sortDown: `Absteigend sortieren`,
  sortUp: `Aufsteigend sortieren`,
  sorting: `Sortierung`,
  loadMore: `Mehr laden`,
  nothingFound: `Nichts gefunden`,
  select: `Auswählen`,
  refresh: `Aktualisieren`,
  refreshData: `Daten aktualisieren`,
  filter: `Filtern`,
  filterOpen: `Filter öffnen`,
  filterClear: `Filter löschen`,
  add: `Hinzufügen`,
  downloadExcel: `Als Excel herunterladen`,
  openSettings: `Einstellungen öffnen`,
  back: `Zurück`,
  actions: `Aktionen`,
  noData: `Keine Daten`,
  addLine: `Zeile hinzufügen`,
  change: `Ändern`,
  create: `Erstellen`,
  open: `Öffnen`,
  selectFromList: `Aus Liste auswählen`,
  loading: `Wird geladen...`,
  showQR: `QR-Code anzeigen`,
  save: `Speichern`,
  cancel: `Abbrechen`,
  yes: `Ja`,
  no: `Nein`,
  close: `Schließen`,
  make: `Ausführen`,
  checkbox: `Auswahl`,
  notFound: `Nicht gefunden`,
  errorUpdate: `Fehler beim Aktualisieren`,
  errorGetUserEmpty: `Fehler beim Abrufen des Benutzers - leere Antwort`,
  dayS: `Tage`,
  successfully: `Erfolgreich`,
  errorDetails: `Fehlerdetails`,
  clean: `Löschen`,
  apply: `Anwenden`,
  settings: 'Einstellungen',
  price: 'Preis',
};
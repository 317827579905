import {constants} from "./constants";

export const stationsFields = {
    arrayStatus: [
        {name: ['station', 'online'], value: true},
        {name: ['station', 'offline'], value: false},
    ],
};

export const userFields = {
    arrayBillStatus: [
        {name: ['user', 'billCreated'], value: constants.BILL_STATUS_CREATED},
        {name: ['user', 'billPartPaid'], value: constants.BILL_STATUS_PARTIALLY_PAID},
        {name: ['user', 'billPaid'], value: constants.BILL_STATUS_PAID},
        {name: ['user', 'billCancel'], value: constants.BILL_STATUS_CANCELED},
    ],
}

export const commandsFields = {
    arrayStatus: [
        {name: ['station', 'toDo'], value: constants.COMMAND_STATUS_ACTUAL},
        {name: ['station', 'done'], value: constants.COMMAND_STATUS_SUCCESS},
        {name: ['station', 'error'], value: constants.COMMAND_STATUS_ERROR},
        {name: ['station', 'canceled'], value: constants.COMMAND_STATUS_CANCELED},
    ],
    arrayType: [
        {name: ['station', 'power'], value: constants.COMMAND_TYPE_POWER},
        {name: ['station', 'process'], value: constants.COMMAND_TYPE_PROCESSES},
        {name: ['station', 'programs'], value: constants.COMMAND_TYPE_PROGRAMS},
        {name: ['station', 'devices'], value: constants.COMMAND_TYPE_DEVICES},
        {name: ['station', 'other'], value: constants.COMMAND_TYPE_CUSTOM},
    ],
    powerCommands: [
        {name: ['station', 'reboot'], value: 'reboot'},
        {name: ['station', 'shutdown'], value: 'shutdown'},
        {name: ['station', 'sleep'], value: 'sleep'},
        {name: ['station', 'hibernate'], value: 'hibernate'},
        {name: ['station', 'lock'], value: 'lock'},
    ],
}

export const transactionsFields = {
    arrayType: [
        {name: ['station', 'refill'], value: constants.TRANSACTION_TYPE_IN},
        {name: ['station', 'writeOff'], value: constants.TRANSACTION_TYPE_DEBITING},
        //{name: ['station', 'withdrawal'], value: constants.TRANSACTION_TYPE_OUT},
    ],
}

export const aiFields = {
    type: [
        {name: 'GPT-3.5 Turbo', value: constants.CHATGPT_3_5_TURBO, price: '~ $0.00002 / 1K tokens'},
        {name: 'GPT-4 Turbo', value: constants.CHATGPT_4_TURBO, price: '~ $0.0004 / 1K tokens'},
        {name: 'GPT-4 Omni', value: constants.CHATGPT_4o, price: '~ $0.0002 / 1K tokens'},
    ],
}
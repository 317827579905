export default {
    title: `우주 정거장 관리 패널`,
    phrase: `비밀 문구 (12 단어)`,
    enter: `로그인`,
    register: `회원가입`,
    back: `뒤로`,
    nickname: `닉네임`,
    secret: `비밀 문구`,
    secretDesc: `문구를 안전한 곳에 보관해주세요. 이를 통해 계정 및 추가된 정거장에 액세스할 수 있습니다. 서비스는 이 문구를 저장하지 않습니다.
    분실된 경우 액세스를 복구할 수 없으며, 새 계정을 생성하고 정거장을 다시 추가해야 합니다`,
    secretCheck: `비밀 문구를 분실한 경우 계정에 대한 액세스를 복구할 수 없음에 동의합니다`,
    errorInitKey: `키 초기화 오류, 데이터를 확인하세요`,
    registerSuccess: `등록에 성공했습니다`,
};

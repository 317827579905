export default {
  mainSite: `الانتقال إلى الموقع الرئيسي`,
  menu: `القائمة`,
  stations: `المحطات`,
  exit: `خروج`,
  feedback: `ردود الفعل`,
  balance: `الرصيد`,
  sortNo: `بدون ترتيب`,
  sortDown: `ترتيب تنازلي`,
  sortUp: `ترتيب تصاعدي`,
  sorting: `الترتيب`,
  loadMore: `تحميل المزيد`,
  nothingFound: `لم يتم العثور على شيء`,
  select: `تحديد`,
  refresh: `تحديث`,
  refreshData: `تحديث البيانات`,
  filter: `تصفية`,
  filterOpen: `فتح التصفية`,
  filterClear: `مسح التصفية`,
  add: `إضافة`,
  downloadExcel: `تنزيل كملف Excel`,
  openSettings: `فتح الإعدادات`,
  back: `العودة`,
  actions: `إجراءات`,
  noData: `لا توجد بيانات`,
  addLine: `إضافة سطر`,
  change: `تغيير`,
  create: `إنشاء`,
  open: `فتح`,
  selectFromList: `تحديد من القائمة`,
  loading: `جارٍ التحميل...`,
  showQR: `عرض رمز الاستجابة السريعة`,
  save: `حفظ`,
  cancel: `إلغاء`,
  yes: `نعم`,
  no: `لا`,
  close: `إغلاق`,
  make: `تنفيذ`,
  checkbox: `تحديد`,
  notFound: `لم يتم العثور`,
  errorUpdate: `خطأ في التحديث`,
  errorGetUserEmpty: `خطأ في الحصول على المستخدم - استجابة فارغة`,
  dayS: `أيام`,
  successfully: `بنجاح`,
  errorDetails: `تفاصيل الخطأ`,
  clean: `تنظيف`,
  apply: `تطبيق`,
  settings: 'الإعدادات',
  price: 'السعر',
};
export default {
    stations: `Stations`,
    filter: `Filtrer`,
    comment: `Commentaire`,
    comment_add: "Ajouter un commentaire",
    comment_edit: "Modifier un commentaire",
    sortDate: `Trier par date de création`,
    checkbox: `Sélection`,
    select: `Sélectionner`,
    actions: `Actions`,
    showStation: `Afficher la station`,
    delete: `Supprimer`,
    number: `Numéro`,
    name: `Nom`,
    bootTimestamp: `Horodatage de démarrage`,
    bootTimestampM: `Démarrage`,
    cpuUsageTotal: `Utilisation du CPU, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `Utilisation de la mémoire, %`,
    memoryPercentM: `Mémoire`,
    ipAddress: `Adresse IP`,
    ipAddressM: `IP`,
    disks: `Espace disponible`,
    disksM: `Disques`,
    updatedAt: `Date de mise à jour`,
    system: `Système d'exploitation`,
    release: `Version`,
    stationSelect: `Sélectionner une station`,
    stationsDel: `Stations supprimées`,
    stationDel: `Station supprimée`,
    downloadClient: `Télécharger le client`,
    download: `Télécharger`,
    online: `En ligne`,
    offline: `Hors ligne`,

    toDo: `À faire`,
    done: `Terminé`,
    error: `Erreur`,
    canceled: `Annulé`,

    info: `Informations`,
    power: `Alimentation`,
    process: `Processus`,
    programs: `Programmes`,
    devices: `Appareils`,
    commands: `Commandes`,
    other: `Autre`,

    reboot: `Redémarrer`,
    shutdown: `Arrêter`,
    sleep: `Mettre en veille`,
    hibernate: `Hiberner`,
    lock: `Verrouiller`,

    refill: `Recharger`,
    writeOff: `Débiter`,
    withdrawal: `Retrait`,
    dateUpdate: `Date de mise à jour`,
    autoUpdate: `Mise à jour automatique`,
    autoUpdateM: `Auto. Mise à jour`,
    commandError: `Erreur lors de la création de la commande`,
    commandSend: `Commande envoyée`,

    visualControl: `Contrôle visuel`,
    doCommand: `Exécuter la commande`,
    viewStation: `Voir la station`,
    metaDesc: `Accès à distance pour contrôler des ordinateurs et des serveurs`,
    confirmAction: `Confirmer l'action`,
    confirmActionText: `Êtes-vous sûr de vouloir exécuter la commande "{{cmd}}" ?`,
    waitingExe: `En attente d'exécution...`,
    successEmpty: `Succès, mais réponse vide`,
    resultExe: `Résultat de l'exécution`,
    enterCommand: `Entrer une commande`,
    exeCustomCommand: `Exécuter une commande personnalisée`,
    enterEnable: `Activer l'envoi avec la touche Entrée`,
    visualControlStation: `Contrôle visuel de la station`,
    add: `Ajouter une station`,
    password: `Mot de passe`,
    stationAdded: `Station ajoutée`,
    passwordLength: `Le mot de passe doit contenir plus de 6 caractères`,

    cmd: {
        status: `Statut`,
        type: `Type`,
        createdAtS: `Date de création`,
        checkbox: `Sélection`,
        remove: `Supprimer`,
        createdAt: `Date`,
        raw: `Données brutes`,
        user: `Utilisateur`,
        result: `Résultat`,
        error: `Erreur`,
        filterName: `Filtrer les commandes`,
        removeE: `Erreur lors de la suppression des commandes`,
        removeS: `Suppression réussie`,
    },

    devs: {
        name: `Nom`,
        group: `Groupe`,
        status: `Statut`,
        manufacturer: `Fabricant`,
        deviceID: `ID de l'appareil`,
        driver: `Pilote`,
        nameS: `Par nom`,
        groupS: `Par groupe`,
        filterName: `Filtrer les appareils`,
        dateUpdate: `Date de mise à jour`,
    },

    inf: {
        group: `Groupe`,
        name: `Nom`,
        value: `Valeur`,

        general: `Informations générales`,
        node: `Nom de l'ordinateur`,
        bootTimestamp: `Heure de démarrage`,
        updatedAt: `Heure de mise à jour des données`,
        system: `Système`,
        release: `Version`,
        version: `Version`,
        programVersion: `Version du programme`,
        memory: `Mémoire`,
        memoryTotal: `Total`,
        memoryAvailable: `Disponible`,
        memoryUsed: `Utilisé`,
        memoryPercent: `Utilisation de la mémoire, %`,
        swapTotal: `Mémoire virtuelle totale`,
        swapFree: `Mémoire virtuelle disponible`,
        swapUsed: `Mémoire virtuelle utilisée`,
        swapPercent: `Utilisation de la mémoire virtuelle, %`,
        processor: `Processeur`,
        processorVendor: `Fabricant`,
        processorBrand: `Marque`,
        cpuUsageTotal: `Utilisation du CPU totale, %`,
        cpuCores: `Cœurs`,
        cpuTotalCores: `Threads`,
        cpuFreqCurrent: `Fréquence actuelle`,
        net: `Réseau`,
        ipAddress: `Adresse IP`,
        macAddress: `Adresse MAC`,
        interfaces: `Interfaces réseau`,
        totalSend: `Total envoyé`,
        totalReceived: `Total reçu`,
        persistent: `Stockage persistant`,
        disks: `Disques`,
        dateUpdate: `Date de mise à jour`,
        autoUpdate: `Mise à jour automatique`,
        free: `Libre`,
    },

    proc: {
        name: `Nom`,
        exe: `Chemin`,
        username: `Nom d'utilisateur`,
        pidS: `ID du processus`,
        nameS: `Par nom`,
        cpuPercent: `Utilisation du CPU, %`,
        memoryPercent: `Utilisation de la mémoire, %`,
        createTimeS: `Par date de création`,
        usernameS: `Par utilisateur`,
        checkbox: `Sélection`,
        finishP: `Terminer le processus`,
        finishPs: `Terminer les processus`,
        pid: `ID`,
        createTime: `Création`,
        diskUsageRead: `Lecture du disque`,
        diskUsageReadTotal: `Total des lectures du disque`,
        diskUsageWrite: `Écriture du disque`,
        diskUsageWriteTotal: `Total des écritures du disque`,
        status: `Statut`,
        filterName: `Filtrer les processus`,
    },

    prog: {
        startRemove: `Lancer la désinstallation du programme`,
        name: `Nom`,
        installDate: `Date d'installation`,
        version: `Version`,
        size: `Taille`,
        description: `Description`,
        publisher: `Éditeur`,
        location: `Emplacement d'installation`,
        uninstall: `Emplacement de désinstallation`,
        depends: `Dépendances`,
        nameS: `Par nom`,
        installDateS: `Par date d'installation`,
        filterName: `Filtrer les programmes`,
        confirmAction: `Confirmer l'action`,
        confirmActionText: `Voulez-vous vraiment désinstaller "{{name}}" ?
Il peut être nécessaire de cliquer sur les boutons d'action.`,
    },

    control:{
        prepare: `Préparation en cours...`,
        connecting: `Connexion en cours`,
        connected: `Connecté`,
        disconnected: `Déconnecté`,
        error: `Erreur`,
        tryAgain: `Réessayer`,
        zoom: `Zoomer`,
        zoomOut: `Dézoomer`,
        showAllButtons: `Afficher tous les boutons`,
        display: `Affichage`,
        size: `Taille`,
        keys: `Touches`,
        showHideKey: `Afficher/Masquer le clavier`,
        fitScreen: `Adapter à l'écran`,
        fullscreen: `Plein écran`,
        tune: `Réglage`,
        cursorMove: `Déplacer le curseur`,
        move: `Déplacer`,
        click: `Clic`,
    },

    aiControl: 'Contrôle de l\'IA',
    aiControlStation: 'Station de Contrôle de l\'IA',
    ai: {
        title: 'Contrôle de la Station IA',
        autoApply: 'Appliquer les commandes automatiquement',
        autoContinueError: 'Continuer le traitement des erreurs',
        send: 'Envoyer (Ctrl + Entrée)',
        input: 'Votre message',
        errorLength: 'Message trop court',
        process: 'En cours de traitement',
        success: 'Succès',
        error: 'Erreur',
        showMore: 'Afficher plus',
        continue: 'Continuer',
        doCommand: 'Exécuter une commande',
        commandAny: 'Exécuter n\'importe quelle commande',
        refine: 'Affiner la réponse',
        newQuery: 'Nouvelle requête',
        auto: 'Auto',
        model: 'Modèle IA',
        tokensLeft: 'Tokens restants : {{value}}',
        allUsers: 'Tous les utilisateurs',
        tokenHelp: 'Le texte envoyé au modèle IA est découpé en tokens. Un token peut être un caractère unique, un mot unique, voire une seule phrase, en fonction de la langue et du contexte. Par exemple, le texte "ChatGPT est génial !" peut être découpé en les tokens suivants : ["Chat", "G", "PT", " est", " génial", "!"].',
        enableHTML: 'Activer l\'affichage du résultat en HTML',
        userTokens: 'Nombre de tokens utilisés dans le message en cours. Il est recommandé de créer une nouvelle requête s\'il s\'agit d\'un autre contexte.',
        inProgress: 'Traitement des informations en cours...',
        previewCommand: 'La commande suivante sera exécutée',
        "userToken": "Clé API utilisateur",
        "getToken": "Obtenir la clé"
    },
};

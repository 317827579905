export default {
  mainSite: `Go to the main site`,
  menu: `Menu`,
  stations: `Stations`,
  exit: `Exit`,
  feedback: `Feedback`,
  balance: `Balance`,
  sortNo: `No sorting`,
  sortDown: `Descending`,
  sortUp: `Ascending`,
  sorting: `Sorting`,
  loadMore: `Load more`,
  nothingFound: `Nothing found`,
  select: `Select`,
  refresh: `Refresh`,
  refreshData: `Refresh data`,
  filter: `Filter`,
  filterOpen: `Open filter`,
  filterClear: `Clear filter`,
  add: `Add`,
  downloadExcel: `Download as Excel`,
  openSettings: `Open settings`,
  back: `Back`,
  actions: `Actions`,
  noData: `No data`,
  addLine: `Add line`,
  change: `Change`,
  create: `Create`,
  open: `Open`,
  selectFromList: `Select from list`,
  loading: `Loading...`,
  showQR: `Show QR code`,
  save: `Save`,
  cancel: `Cancel`,
  yes: `Yes`,
  no: `No`,
  close: `Close`,
  make: `Execute`,
  checkbox: `Selection`,
  notFound: `Not found`,
  errorUpdate: `Update error`,
  errorGetUserEmpty: `Error getting user - empty response`,
  dayS: `days`,
  successfully: `Successfully`,
  errorDetails: `Error details`,
  clean: `Clean`,
  apply: `Apply`,
  settings: 'Settings',
  price: 'Price',
};

export default {
    title: `Space Station Control Panel`,
    phrase: `Secret Phrase (12 Words)`,
    enter: `Enter`,
    register: `Register`,
    back: `Back`,
    nickname: `Nickname`,
    secret: `Secret Phrase`,
    secretDesc: `Please save the phrase in a secure place, as it provides access
                                to your account and added stations. The service does not store this phrase,
                                and in case of loss, it will not be possible to restore access. You will have to create a new account
                                and add stations again`,
    secretCheck: `I agree that in case of loss of the secret phrase, it will not be possible to recover access to the account`,
    errorInitKey: `Error initializing key, please check the data`,
    registerSuccess: `Registration successful`,
};

export default {
  mainSite: `메인 사이트로 이동`,
  menu: `메뉴`,
  stations: `스테이션`,
  exit: `종료`,
  feedback: `피드백`,
  balance: `잔액`,
  sortNo: `정렬 없음`,
  sortDown: `내림차순 정렬`,
  sortUp: `오름차순 정렬`,
  sorting: `정렬`,
  loadMore: `더 보기`,
  nothingFound: `찾을 수 없음`,
  select: `선택`,
  refresh: `새로 고침`,
  refreshData: `데이터 새로 고침`,
  filter: `필터`,
  filterOpen: `필터 열기`,
  filterClear: `필터 지우기`,
  add: `추가`,
  downloadExcel: `Excel로 다운로드`,
  openSettings: `설정 열기`,
  back: `뒤로 가기`,
  actions: `동작`,
  noData: `데이터 없음`,
  addLine: `라인 추가`,
  change: `변경`,
  create: `생성`,
  open: `열기`,
  selectFromList: `리스트에서 선택`,
  loading: `로드 중...`,
  showQR: `QR 코드 보기`,
  save: `저장`,
  cancel: `취소`,
  yes: `예`,
  no: `아니요`,
  close: `닫기`,
  make: `실행`,
  checkbox: `선택`,
  notFound: `찾을 수 없음`,
  errorUpdate: `업데이트 오류`,
  errorGetUserEmpty: `사용자 가져오기 오류 - 빈 응답`,
  dayS: `일`,
  successfully: `성공`,
  errorDetails: `오류 세부 정보`,
  clean: `청소`,
  apply: `적용`,
  settings: '설정',
  price: '가격',
};

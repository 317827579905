export default {
  mainSite: `メインサイトに移動`,
  menu: `メニュー`,
  stations: `ステーション`,
  exit: `終了`,
  feedback: `フィードバック`,
  balance: `残高`,
  sortNo: `並べ替えなし`,
  sortDown: `降順で並べ替え`,
  sortUp: `昇順で並べ替え`,
  sorting: `並べ替え`,
  loadMore: `もっと読み込む`,
  nothingFound: `見つかりませんでした`,
  select: `選択`,
  refresh: `更新`,
  refreshData: `データを更新`,
  filter: `フィルター`,
  filterOpen: `フィルターを開く`,
  filterClear: `フィルターをクリア`,
  add: `追加`,
  downloadExcel: `Excelでダウンロード`,
  openSettings: `設定を開く`,
  back: `戻る`,
  actions: `アクション`,
  noData: `データなし`,
  addLine: `行を追加`,
  change: `変更`,
  create: `作成`,
  open: `開く`,
  selectFromList: `リストから選択`,
  loading: `読み込み中...`,
  showQR: `QRコードを表示`,
  save: `保存`,
  cancel: `キャンセル`,
  yes: `はい`,
  no: `いいえ`,
  close: `閉じる`,
  make: `実行`,
  checkbox: `選択`,
  notFound: `見つかりませんでした`,
  errorUpdate: `更新エラー`,
  errorGetUserEmpty: `ユーザーの取得エラー - 空のレスポンス`,
  dayS: `日`,
  successfully: `成功`,
  errorDetails: `エラーの詳細`,
  clean: `クリーン`,
  apply: `適用`,
  settings: '設定',
  price: '価格',
};
import React, {useMemo} from "react";
import styles from './headerMobile.module.scss';
import cn from 'classnames';
import {observer} from "mobx-react";
import RootStore from "../../stores/rootStore";
import UserServiceStore from "../../stores/userServiceStore";
import NavigationStore from "../../stores/navigationStore";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import MyImg from "../myImg/myImg";
import Variables from "../../utils/variables";
import MySelect from "../mySelect/mySelect";
import LANG, {LANGUAGES} from "../../lang/language";

function HeaderMobile(props) {
    let rootStore = RootStore.getInstance();
    let userService = UserServiceStore.getInstance();
    let navigationStore = NavigationStore.getInstance();
    let pathLang = 'general';

    let menu: any = useMemo(() => navigationStore.getMobileMenu(), []);
    let selectedElement = useMemo(() => navigationStore.getSelectedElement(menu, window.location), [window.location.pathname, navigationStore.menuOpen]);

    function getMenu(submenu: any, parentLink, forceParent = false) {
        return submenu.map(el => {
            let link = parentLink + el.link;
            return <div key={el.name} className={cn('col', styles.item)}>
                <div className={cn('col', styles.inner)}>
                    <NavLink id={el.name} to={link} exact={el.exact}
                             className={cn('col-fixed grid', styles.navLink)}
                             activeClassName={parentLink && !forceParent ? styles.activeCircle : styles.activeParent}
                             isActive={(match, location) => {
                                 return navigationStore.isActiveLink(el, location, parentLink);
                             }} onClick={event => navigationStore.openMenu(false)}>
                        <div className={cn('col-fixed f-c ns', styles.text)}>
                            {parentLink && !forceParent && <div className={cn(styles.circle)}/>} {el.name}
                        </div>
                    </NavLink>
                </div>
                {
                    el.submenu && <div key={el.name} className={cn('col-fixed', styles.listMenuSub)}>
                        {getMenu(el.submenu, link)}
                    </div>
                }
            </div>
        })
    }

    return <div className={cn('col-fixed', styles.main)}>
        {/* menu navigation */}
        {
            <div className={cn('', styles.menu, {
                [styles.menuOpen]: navigationStore.menuOpen
            })}>
                <div className={cn('col grid')}>
                    <a className={cn('col-fixed grid', styles.logo)} href={Variables.externalSite}>
                        <div className={cn('col-fixed grid f-c', styles.img)}>
                            <MyImg src='logo_60.png'/>
                        </div>
                        <div className={cn('col-fixed grid f-c-l', styles.text)}>
                            Cusco Remote Control
                        </div>
                    </a>
                </div>
                <div className={cn('grid', styles.topWrap)}>

                    <div className={cn('col grid', styles.icon)}>
                        <a href={Variables.urlTgGroup + '/' + Variables.urlTgGroupTopics.main} target='_blank'
                           className={cn('col grid', styles.link)}>
                            <div className={cn('col-fixed f-c', styles.text)}>
                                {LANG.get([pathLang, 'feedback'])}
                            </div>
                        </a>
                        <MySelect options={LANGUAGES} selected={LANG.getInstance().lang}
                                  className={`col-fixed ${styles.lang}`} classNameList={`${styles.list}`} icon={'translate'}
                                  getName={(el) => `${el.name} - ${el.nameEn}`}
                                  onSelect={(el) => {
                                      LANG.getInstance().selectLang(el.value)
                                  }}/>
                    </div>
                </div>
                <div className={cn('grid', styles.balanceWrap, {
                    [styles.danger]: userService.summary.isPayRequired
                })}>
                    <NavLink to={'/user/tariff'} className={cn('col-fixed grid')}
                             onClick={event => navigationStore.openMenu(false)}>
                        <div className={cn('col-fixed f-c-l', styles.text)}>
                            {LANG.get([pathLang, 'balance'])}
                        </div>
                        <div className={cn('col-fixed f-c-l', styles.value)}>
                            {`${userService.user?.balance}$`}
                            <span className={cn(styles.divider)}>|</span>
                            <span> {`${userService.summary.enoughBalanceString}`}</span>
                        </div>
                    </NavLink>
                </div>
                <div className={cn('grid', styles.menuItem, styles.userWrap)}>
                    <div className={cn('col-fixed f-c o-3 click', styles.icon)}>
                        <span className='material-icons'>notifications</span>
                    </div>
                    <div className={cn('col f-c-l click', styles.menuItem)}>
                        <NavLink to={'/user'} exact={false} activeClassName={styles.selected}
                                 className={cn('', styles.link, styles.userName)}
                                 isActive={(match, location) => {
                                     return navigationStore.isActiveLink({link: 'user'}, location);
                                 }} onClick={event => navigationStore.openMenu(false)}>
                            {userService.user?.name}
                        </NavLink>
                    </div>
                    <div className={cn('col-fixed f-c click', styles.icon)} title={LANG.get([pathLang, 'exit'])}
                         onClick={() => userService.logout()}>
                        <span className='material-icons'>logout</span>
                    </div>
                </div>

                {<div className={cn('', styles.listMenu)}> {getMenu(menu[menu.length - 1].submenu, '')} </div>}
            </div>
        }

        {/* sub menu navigation */}
        {navigationStore.subMenuOpen &&
            <div className={cn('', styles.subMenu, {
                [styles.subMenuOpen]: navigationStore.subMenuOpen
            })}>
                {<div
                    className={cn('', styles.listMenu)}> {getMenu(navigationStore.subMenuOpen.submenu, navigationStore.subMenuOpen.link, true)} </div>}
            </div>
        }

        <BottomNavigation
            value={selectedElement?.link || ''}
            onChange={(event, link) => {
                let target = menu.find(el => el.link === link);
                if (link == 'menu')
                    return navigationStore.openMenu(!navigationStore.menuOpen);
                else if (target.submenu)
                    return navigationStore.openSubMenu(target);

                navigationStore.goTo(link);
                navigationStore.openMenu(false);
                navigationStore.openSubMenu(false);
            }}
            showLabels
            className={styles.navigation}
        >
            {
                menu.map(el => {
                    return <BottomNavigationAction key={el.link} label={el.name} value={el.link} icon={
                        <span className='material-icons'
                              style={el.name === '' && {fontSize: '34px'} || {}}>{el.icon}</span>
                    }/>
                })
            }
        </BottomNavigation>
    </div>
}

export default observer(HeaderMobile);

export default {
  mainSite: `Vai al sito principale`,
  menu: `Menu`,
  stations: `Stazioni`,
  exit: `Esci`,
  feedback: `Feedback`,
  balance: `Saldo`,
  sortNo: `Nessun ordinamento`,
  sortDown: `Ordine decrescente`,
  sortUp: `Ordine crescente`,
  sorting: `Ordinamento`,
  loadMore: `Carica altro`,
  nothingFound: `Nessun risultato trovato`,
  select: `Seleziona`,
  refresh: `Aggiorna`,
  refreshData: `Aggiorna dati`,
  filter: `Filtra`,
  filterOpen: `Apri filtro`,
  filterClear: `Cancella filtro`,
  add: `Aggiungi`,
  downloadExcel: `Scarica come Excel`,
  openSettings: `Apri impostazioni`,
  back: `Indietro`,
  actions: `Azioni`,
  noData: `Nessun dato`,
  addLine: `Aggiungi linea`,
  change: `Cambia`,
  create: `Crea`,
  open: `Apri`,
  selectFromList: `Seleziona dalla lista`,
  loading: `Caricamento in corso...`,
  showQR: `Mostra codice QR`,
  save: `Salva`,
  cancel: `Annulla`,
  yes: `Sì`,
  no: `No`,
  close: `Chiudi`,
  make: `Esegui`,
  checkbox: `Selezione`,
  notFound: `Non trovato`,
  errorUpdate: `Errore di aggiornamento`,
  errorGetUserEmpty: `Errore durante l'ottenimento dell'utente - risposta vuota`,
  dayS: `giorni`,
  successfully: `Operazione completata`,
  errorDetails: `Dettagli errore`,
  clean: `Pulisci`,
  apply: `Applica`,
  settings: 'Impostazioni',
  price: 'Prezzo',
};


export default class EventBus{

    static subscriptions = { }
    static getNextUniqueId = EventBus.getIdGenerator()

    static subscribe(eventType, callback) {
        const id = EventBus.getNextUniqueId()

        if(!EventBus.subscriptions[eventType])
            EventBus.subscriptions[eventType] = { }

        EventBus.subscriptions[eventType][id] = callback

        return {
            unsubscribe: () => {
                try{
                    if (!EventBus.subscriptions.hasOwnProperty('eventType'))
                        return
                    delete EventBus.subscriptions[eventType][id]
                    if(Object.keys(EventBus.subscriptions[eventType]).length === 0)
                        delete EventBus.subscriptions[eventType]
                } catch (e){
                    console.log(e)
                }
            }
        }
    }

    static publish(eventType, arg?) {
        if(!EventBus.subscriptions[eventType])
            return

        Object.keys(EventBus.subscriptions[eventType]).forEach(key => EventBus.subscriptions[eventType][key](arg))
    }

    static getIdGenerator() {
        let lastId = 0

        return function getNextUniqueId() {
            lastId += 1
            return lastId
        }
    }
}

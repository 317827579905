import React, {useMemo} from "react";
import styles from './header.module.scss';
import cn from 'classnames';
import {observer} from "mobx-react";
import RootStore from "../../stores/rootStore";
import {NavLink} from "react-router-dom";
import UserServiceStore from "../../stores/userServiceStore";
import MyMenu from "../myMenu/myMenu";
import NavigationStore from "../../stores/navigationStore";
import MyImg from "../myImg/myImg";
import Variables from "../../utils/variables";
import LANG, {LANGUAGES} from "../../lang/language";
import MySelect from "../mySelect/mySelect";

function Header(props) {
    let rootStore = RootStore.getInstance();
    let userService = UserServiceStore.getInstance();
    const navigationStore = useMemo(() => new NavigationStore(), []);
    let pathLang = 'general';

    let menu: any = useMemo(() => navigationStore.getMenu(), []);

    return <div className={cn('grid', styles.main)}>
        <a className={cn('col-fixed grid', styles.logo)} href={Variables.externalSite}
           title={LANG.get([pathLang, 'mainSite'])}>
            <div className={cn('col-fixed grid f-c', styles.img)}>
                <MyImg src='logo_60.png'/>
            </div>
            <div className={cn('col-fixed grid f-c-l', styles.text)}>
                Cusco<br/>
                Remote Control
            </div>
        </a>
        <div className={cn('col-fixed grid')}>
            {
                menu.map(el => <div key={el.name} className={cn('col-fixed grid', styles.menuItem)}>
                    <NavLink id={el.name} to={el.link} exact={el.exact} activeClassName={styles.selected}
                             className={cn('col-fixed grid', styles.link)}
                             isActive={(match, location) => {
                                 return navigationStore.isActiveLink(el, location);
                             }}>
                        <div className={cn('col-fixed f-c', styles.text)}>
                            {el.name}
                        </div>
                        {
                            el.action &&
                            <div className={cn('col-fixed f-c click', styles.iconAdd)} onClick={(event) => {
                                event.preventDefault()
                                el.action()
                            }}>
                                <span className='material-icons'>add_box</span>
                            </div>
                        }

                    </NavLink>
                    {
                        el.submenu &&
                        <>
                            <div className={cn('col-fixed f-c', styles.icon)}>
                                <span className='material-icons'>arrow_drop_down</span>
                            </div>
                            <div className={cn('', styles.subMenu)}>
                                {
                                    el.submenu.map(el2 => <div key={el2.name}
                                                               className={cn('col-fixed grid', styles.subMenuItem)}>


                                        <NavLink id={el2.name} to={el.link + el2.link} exact={el.exact}
                                                 activeClassName={el2.submenu && styles.selectedSubMenu || styles.selected}
                                                 className={cn('', styles.link2)} isActive={(match, location) => {

                                            let active = true;
                                            if (!match)
                                                active = false;

                                            if (active && el2.link === '/companies' && location.pathname.includes('own'))
                                                return false;

                                            if(el2.exact)
                                                return location.pathname === el2.link;

                                            el.childActive = active;
                                            return active;
                                        }}>
                                            <div className={cn('', styles.textSubMenu)}>
                                                {el2.name}
                                            </div>
                                        </NavLink>
                                        {
                                            el2.submenu && <div className={cn('', styles.subMenuList)}>

                                                {
                                                    el2.submenu.map(el3 => <div key={el3.name}
                                                                                className={cn('', styles.subMenuListItem)}>
                                                        <NavLink id={el3.name} exact={el.exact}
                                                                 to={el.link + el2.link + el3.link}
                                                                 activeClassName={styles.selected}
                                                                 className={cn('col-fixed grid', styles.link3)}>

                                                            <div
                                                                className={cn('f-c-l col-fixed', styles.text)}>
                                                                {el3.name}
                                                            </div>

                                                        </NavLink>
                                                    </div>)
                                                }

                                            </div>
                                        }

                                    </div>)
                                }
                            </div>
                        </>
                    }
                </div>)
            }
        </div>
        <div className={cn('col grid')}/>
        <div className={cn('col-fixed grid')}>
            <NavLink to={'/user/tariff'} className={cn('col-fixed grid', styles.balanceWrap,{
                [styles.danger]: userService.summary.isPayRequired
            })}>
                <div className={cn('col-fixed f-c-l', styles.text)}>
                    {LANG.get([pathLang, 'balance'])}
                </div>
                <div className={cn('col-fixed f-c-l', styles.value)}>
                    {`${userService.user?.balance}$`}
                    <span className={cn(styles.divider)}>|</span>
                    <span className={cn()}> {`${userService.summary.enoughBalanceString}`}</span>
                </div>
            </NavLink>
        </div>
        <div className={cn('col grid')}/>
       {/* <div className={cn('col-fixed grid')}>
            <MySelect options={LANGUAGES} selected={LANG.getInstance().lang}
                      onSelect={(value) => {LANG.getInstance().selectLang(value)}}/>
        </div>*/}
        <div className={cn('col-fixed grid', styles.rightWrap)}>
            <a href={Variables.urlTgGroup + '/' + Variables.urlTgGroupTopics.main} target='_blank'
               className={cn('col-fixed grid', styles.link)}>
                <div className={cn('col-fixed f-c', styles.text)}>
                    {LANG.get([pathLang, 'feedback'])}
                </div>
            </a>
        </div>
        <div className={cn('col-fixed grid', styles.userWrap)}>
            {/*<div className={cn('col-fixed f-c o-3 click', styles.icon)} title={'Уведомлений нет'}>
                <span className='material-icons'>notifications</span>
            </div>*/}
            <div className={cn('col-fixed f-c click', styles.icon)} >
                <MySelect options={LANGUAGES} selected={LANG.getInstance().lang} className={styles.lang} icon={'translate'}
                          getName={(el) => `${el.name} - ${el.nameEn}`}
                          onSelect={(el) => {
                              LANG.getInstance().selectLang(el.value)
                          }}/>
            </div>
            <div className={cn('col-fixed f-c', styles.menuItem)}>
                <NavLink to={'/user'} exact={false} activeClassName={styles.selected}
                         className={cn('col-fixed grid', styles.link, styles.userName)}
                         isActive={(match, location) => {
                             return navigationStore.isActiveLink({link: 'user'}, location);
                         }}>
                    <div className={cn('col-fixed oneLine f-c-l', styles.text)}>
                        {userService.user?.name}
                    </div>

                </NavLink>
                {/*<div className={cn('f-c-l ', styles.userName)}
                     title={`${userService.user?.name}`}>
                    <div className={cn('col-fixed oneLine')}>
                        {userService.user?.name}
                    </div>
                </div>*/}
            </div>
            <div className={cn('col-fixed f-c click', styles.exit)} title={LANG.get([pathLang, 'exit'])}
                 onClick={() => userService.logout()}>
                <span className='material-icons'>logout</span>
            </div>
        </div>
    </div>
}

export default observer(Header);

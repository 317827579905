import {observer} from "mobx-react";
import React, {useEffect, useRef} from "react";
import styles from './chatGPTControl.module.scss';
import cn from 'classnames';
import RootStore from "../../stores/rootStore";
import MyHistory from "../../utils/myHistory";
import MyIconButton from "../myIconButton/myIconButton";
import {isMobile} from "react-device-detect";
import LANG from "../../lang/language";
import ChatGPTControlStore, {
    ChatMessage,
    CommandExecuteType,
    MessageAIStatus,
    MessageRole
} from "../../stores/station/chatGPTControlStore";
import MyInput from "../myInput/myInput";
import MyButton from "../myButton/myButton";
import {aiFields} from "../../utils/constantsLocal";
import Helper, {replaceHTML} from "../../utils/helper";

function ChatGPTControl(props) {
    const rootStore = RootStore.getInstance();
    let store: ChatGPTControlStore = props.store;

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        setTimeout(() => messagesEndRef.current?.scrollIntoView({behavior: "smooth"}), 100)
        store.scrollToBottom = false
    }
    useEffect(scrollToBottom, [store.messages.length, store.scrollToBottom]);

    useEffect(() => {
        store.onMount()
        return () => {
            store.onUnmount()
        }
    }, [])

    if (!store) // fixed modal error
        return <div/>;

    let pathLang = ['station', 'ai'];

    function getMessageData(message: ChatMessage, index) {
        let isLastMessage = store.messages.length - 1 == index
        let res
        switch (message.role) {
            case MessageRole.Function:
            case MessageRole.System:
                return <></>
            case MessageRole.User:
                return <div key={message._date} className={cn(' f-c-r')}>
                    <div className={cn('', styles.messageHuman)}>
                        <div className={cn('', styles.text)}>
                            {LANG.get(message._text)}
                        </div>
                    </div>
                </div>
            case MessageRole.AI:
                let text = message.content
                switch (message._status) {
                    case MessageAIStatus.created:
                        if (!text)
                            text = [...pathLang, 'inProgress']
                        break
                    /*case MessageAIStatus.hasCommand:
                        text = [...pathLang, 'previewCommand']
                        break*/
                }
                let command = message._function_answer?._command;
                let commandName = ''
                let hasResult = command?.result && command?.result != 'Ok';

                let enableHTML = store.settings.enableHTML
                if(enableHTML)
                    text = replaceHTML(text)
                return <div key={message._date} className={cn('f-c-l', styles.message)}>
                    <div className={cn('', styles.messageAI)}>
                        {message._tokens > 0 &&
                            <div className={cn('noSelect', styles.tokensUse)}
                                 title={LANG.get([...pathLang, 'userTokens'])}>
                                {message._tokens}
                            </div>
                        }
                        {text && (enableHTML ?
                                <div className={cn('', styles.text)}
                                     dangerouslySetInnerHTML={{__html: LANG.get(text)}}/>
                                :
                                <div className={cn('', styles.text)}>
                                    {LANG.get(text)}
                                </div>
                        )}

                        {command &&
                            <div className={cn('grid', styles.command)}>
                                {command.data?.command && <div className={cn('col', styles.inner)}>
                                    <div className={cn('col f-c-l', styles.title)}>
                                        {LANG.get([...pathLang, 'commandAny'])}
                                    </div>
                                    <div className={cn('col f-c-l', styles.data)}>
                                        {command.data?.command}
                                    </div>
                                </div>}
                                {
                                    isLastMessage && <>
                                        {message._status == MessageAIStatus.hasCommand &&
                                            <div className={cn('col-fixed f-c noSelect', styles.yes)}
                                                 onClick={() => {
                                                     store.changeFields(['execute'], CommandExecuteType.yes, command)
                                                     store.doCommand(message)
                                                 }} title={LANG.get([...pathLang, 'doCommand'])}>
                                                <span className='material-icons'>check</span>
                                            </div>}
                                        {message._status == MessageAIStatus.processCommand &&
                                            <div className={cn('col-fixed f-c noSelect', styles.process)}
                                                 title={LANG.get([...pathLang, 'process'])}>
                                                <span className='material-icons rotate'>autorenew</span>
                                            </div>}
                                    </>
                                }
                                {/* {[MessageAIStatus.hasCommand, MessageAIStatus.error].includes(message._status) &&
                                    <div className={cn('col-fixed f-c noSelect', styles.refine)}
                                         title={LANG.get([...pathLang, 'refine'])}>
                                        <span className='material-icons'>message</span>
                                    </div>}*/}
                            </div>
                        }

                        {message._status == MessageAIStatus.error &&
                            <div className={cn('', styles.error)}>
                                <div className={cn('grid')}>
                                    <div className={cn('col f-c-l click', styles.title)}
                                         title={command.error && LANG.get([...pathLang, 'showMore'])}
                                         onClick={e => {
                                             if (!command.error)
                                                 return
                                             store.changeItem(command, '_showInner', !command._showInner)
                                         }}>
                                        {LANG.get([...pathLang, 'error'])}
                                        {
                                            command.error && <span className={cn('material-icons', {
                                                'rollY': command._showInner
                                            })}>expand_more</span>
                                        }
                                    </div>

                                    <div className={cn('col-fixed f-c-r', styles.actions)}>
                                        {
                                            isLastMessage && command.error &&
                                            <MyButton className={cn('', styles.continue)}
                                                      onClick={() => store.sendMessages()}>
                                                {LANG.get([...pathLang, 'continue'])}
                                            </MyButton>
                                        }
                                    </div>
                                </div>
                                {command?._showInner && <div className={cn('col f-c-l', styles.details)}>
                                    {command.error}
                                </div>}
                            </div>}

                        {message._status == MessageAIStatus.successCommand &&
                            <div className={cn('', styles.result)}>
                                <div className={cn('grid')}>
                                    <div className={cn('col f-c-l', styles.title, {
                                        'click': hasResult
                                    })}
                                         title={hasResult && LANG.get([...pathLang, 'showMore'])}
                                         onClick={e => {
                                             if (!hasResult)
                                                 return
                                             store.changeItem(command, '_showInner', !command._showInner)
                                         }}>
                                        {LANG.get([...pathLang, 'success'])}
                                        {
                                            hasResult && <span className={cn('material-icons', {
                                                'rollY': command._showInner
                                            })}>expand_more</span>
                                        }
                                    </div>
                                </div>
                                {command?._showInner && <div className={cn('col f-c-l', styles.details)}>
                                    {command.result}
                                </div>}
                            </div>}

                        {/*{message._status == MessageAIStatus.successCommand &&
                            <div className={cn('', styles.result)}>
                                <div className={cn('grid', styles.text)}
                                     title={command.result && LANG.get([...pathLang, 'showMore'])}
                                     onClick={e => {
                                         if (!command.result)
                                             return
                                         store.changeItem(command, '_showInner', !command._showInner)
                                     }}>
                                    {LANG.get([...pathLang, 'success'])}
                                    {
                                        command.result && <span className={cn('material-icons', {
                                            'rollY': command._showInner
                                        })}>expand_more</span>
                                    }
                                </div>
                                {command?._showInner && <div className={cn('col f-c', styles.inner)}>
                                    {command?.result}
                                </div>}
                            </div>}*/}

                    </div>
                </div>
        }
    }

    function getSettings() {
        let model = Helper.findByKeyInArr(aiFields.type, 'value', store.settings.model)
        return <div className={cn('', styles.settings)}>
            <h1>{LANG.get(['general', 'settings'])}</h1>
            <div className={cn(!isMobile && 'f-b')}>
                <MyInput type={'dropdown'} store={store} storeField='settings' field={'model'}
                         source={aiFields.type}
                         name={LANG.get([...pathLang, 'model'])} variant={'standard'}/>
                {model &&
                    <span className={cn('', styles.price)}>{LANG.get(['general', 'price'])}: {model?.price}</span>
                }
            </div>
            <div className={cn('grid')}>
                <div className={cn('col')}>
                    <MyInput type={'text'} store={store} storeField='settings' field={'userToken'}
                             name={LANG.get([...pathLang, 'userToken'])} variant={'standard'}
                             width='100%'/>
                </div>
                <div className={cn('col-fixed f-c')}>
                    <a href={'https://platform.openai.com/account/api-keys'} target={'_blank'}
                       className={cn('', styles.price)}>{LANG.get([...pathLang, 'getToken'])}</a>
                </div>
            </div>
            <h2>{LANG.get([...pathLang, 'auto'])}</h2>
            <MyInput type={'checkbox'} store={store} storeField='settings' field={'autoApply'}
                     name={LANG.get([...pathLang, 'autoApply'])} variant={'standard'} width='100%'/>
            <MyInput type={'checkbox'} store={store} storeField='settings' field={'autoContinueError'}
                     name={LANG.get([...pathLang, 'autoContinueError'])} variant={'standard'} width='100%'/>
            <MyInput type={'checkbox'} store={store} storeField='settings' field={'enableHTML'}
                     name={LANG.get([...pathLang, 'enableHTML'])} variant={'standard'} width='100%'/>
        </div>
    }

    function getContent() {
        return <>
            <div className={cn('col', styles.chat)}>
                {
                    store.messages.map((el, i) => getMessageData(el, i))
                }
                <div ref={messagesEndRef} className={cn(styles.messagesEnd)}/>
            </div>
            <div className={cn('col-fixed grid', styles.control)}>
                {store.messages.length > 0 && <div className={cn('col-fixed f-c', styles.controlClear)}>
                    {!isMobile && <MyButton title={LANG.get([...pathLang, 'newQuery'])}
                                            onClick={() => {
                                                store.clear()
                                            }}>{!isMobile && LANG.get([...pathLang, 'newQuery'])}</MyButton>
                        || <MyIconButton iconM={'cleaning_services'} onClick={() => {
                            store.clear()
                        }}/>
                    }
                </div>}
                <MyInput type={'text_multiline'} store={store} storeField='entityEdited' field={'input'}
                         className={cn('col f-c', styles.controlInput)}
                         name={LANG.get([...pathLang, 'input'])}
                         variant={'standard'} width='100%'
                         disabled={store.blockInput}
                         onKey={(event) => {
                             switch (event.key) {
                                 case '\n':
                                 case 'Enter':
                                     if (event.ctrlKey)
                                         store.sendQuery()
                                     break;
                             }
                         }}/>
                <div className={cn('col-fixed f-c', styles.controlSend)}>
                    <MyIconButton iconM='send' title={LANG.get([...pathLang, 'send'])}
                                  disabled={store.blockInput}
                                  onClick={() => {
                                      store.sendQuery()
                                  }}/>
                </div>
            </div>
        </>
    }

    function getTopPanel() {
        function getCloseButton() {
            return <div className={cn('col-fixed f-c')}>
                <MyIconButton iconM='close' title={LANG.get(['general', 'close'])} className={cn()}
                              disabled={store.isSettingsOpen}
                              onClick={() => {
                                  if (store.isModal) {
                                      props.close();
                                  } else {
                                      MyHistory.goBack();
                                  }
                              }
                              }/>
            </div>;
        }

        function getSettingsButton() {
            return <div className={cn('col-fixed f-c', styles.menu)}>
                <MyIconButton iconM={!store.isSettingsOpen ? 'menu' : 'menu_open'} className={cn()}
                              title={LANG.get(['general', 'openSettings'])}
                              text={!isMobile && LANG.get(['general', 'settings'])}
                              onClick={() => {
                                  store.changeFields(['isSettingsOpen'], !store.isSettingsOpen)
                              }}/>
            </div>
        }

        function getTokensInfo() {
            return store.tokensLeft &&
                <a //href={'/user/tariff'} target="_blank"
                    title={LANG.get([...pathLang, 'tokenHelp'])}
                    className={cn('col-fixed', {
                        [styles.danger]: false
                    })}>
                    <div className={cn('f-c', styles.text)}>
                        {LANG.get([...pathLang, 'tokensLeft'], {
                            value: store.tokensLeft
                        })}
                    </div>
                    <div className={cn('f-c', styles.comment)}>
                        { !store.settings.userToken && LANG.get([...pathLang, 'allUsers'])}
                        {' Cusco Remote Control'}
                    </div>
                </a>
        }

        if(isMobile){
            return  <div className={cn(styles.topPanel, 'col-fixed grid p-r')}>
                <div className={cn('col')}>
                    <div className={cn('grid', styles.title)}>
                        <div className='col f-c-l'>
                            {getCloseButton()}
                            {LANG.get([...pathLang, 'title'])}
                        </div>
                        <div className='col-fixed'>
                            {getSettingsButton()}
                        </div>
                    </div>
                    {!store.isSettingsOpen && <div className={cn('', styles.tokensLeft)}>
                        {getTokensInfo()}
                    </div>}
                </div>
            </div>
        }

        return <div className={cn(styles.topPanel, 'col-fixed grid p-r')}>
            <div className={cn('col grid')}>
                <div className={cn('col-fixed grid', styles.title)}>
                    <div className='col f-c-l'>
                        {LANG.get([...pathLang, 'title'])}
                    </div>
                </div>
                <div className={cn('col')}/>
                <div className={cn('col-fixed grid', styles.tokensLeft)}>
                    {getTokensInfo()}
                </div>
                <div className={cn('col')}/>
                {getSettingsButton()}
            </div>
            {getCloseButton()}
        </div>
    }

    return (
        <div className={cn(styles.main, 'grid dir-col')}>
            {getTopPanel()}
            <div className={cn(styles.content, 'col grid dir-col')}>
                {store.isSettingsOpen ? getSettings() : getContent()}
            </div>
        </div>
    )
}

export default observer(ChatGPTControl);

export default {
    stations: `المحطات`,
    filter: `تصفية المحطات`,
    comment: `تعليق`,
    comment_add: "إضافة تعليق",
    comment_edit: "تعديل التعليق",
    sortDate: `ترتيب حسب التاريخ`,
    checkbox: `تحديد`,
    select: `تحديد`,
    actions: `إجراءات`,
    showStation: `عرض المحطة`,
    delete: `إلغاء الارتباط`,
    number: `رقم`,
    name: `اسم`,
    bootTimestamp: `توقيت التشغيل`,
    bootTimestampM: `تشغيل`,
    cpuUsageTotal: `استخدام المعالج الكلي، %`,
    cpuUsageTotalM: `استخدام المعالج`,
    memoryPercent: `نسبة الذاكرة، %`,
    memoryPercentM: `ذاكرة`,
    ipAddress: `عنوان IP`,
    ipAddressM: `IP`,
    disks: `الأقراص`,
    disksM: `أقراص`,
    updatedAt: `تاريخ التحديث`,
    system: `النظام`,
    release: `الإصدار`,
    stationSelect: `اختيار المحطة`,
    stationsDel: `تم إلغاء الارتباط بالمحطات`,
    stationDel: `تم إلغاء الارتباط بالمحطة`,
    downloadClient: `تنزيل العميل`,
    download: `تنزيل`,
    online: `متصل`,
    offline: `غير متصل`,

    toDo: `قيد الانتظار`,
    done: `تم بنجاح`,
    error: `خطأ`,
    canceled: `تم الإلغاء`,

    info: `معلومات`,
    power: `الطاقة`,
    process: `العمليات`,
    programs: `البرامج`,
    devices: `الأجهزة`,
    commands: `الأوامر`,
    other: `أخرى`,

    reboot: `إعادة التشغيل`,
    shutdown: `إيقاف التشغيل`,
    sleep: `السكون`,
    hibernate: `السبات`,
    lock: `قفل`,

    refill: `اعادة التعبئة`,
    writeOff: `خصم`,
    withdrawal: `سحب`,
    dateUpdate: `تاريخ التحديث`,
    autoUpdate: `التحديث التلقائي`,
    autoUpdateM: `تحديث تلقائي`,
    commandError: `خطأ في إنشاء الأمر`,
    commandSend: `تم إرسال الأمر`,

    visualControl: `التحكم المرئي`,
    doCommand: `تنفيذ الأمر`,
    viewStation: `عرض المحطة`,
    metaDesc: `الدخول إلى لوحة التحكم عن بُعد لأجهزة الكمبيوتر والخوادم`,
    confirmAction: `تأكيد الإجراء`,
    confirmActionText: `هل أنت متأكد أنك تريد تنفيذ الأمر "{{cmd}}"؟`,
    waitingExe: `في انتظار التنفيذ...`,
    successEmpty: `تم بنجاح، ولكن الاستجابة فارغة`,
    resultExe: `نتيجة التنفيذ`,
    enterCommand: `أدخل الأمر`,
    exeCustomCommand: `تنفيذ أمر مخصص`,
    enterEnable: `السماح بالإرسال عبر الضغط على مفتاح Enter مفعّل`,
    visualControlStation: `التحكم المرئي في المحطة`,
    add: `إضافة محطة`,
    password: `كلمة المرور`,
    stationAdded: `تمت إضافة المحطة`,
    passwordLength: `يجب أن تتكون كلمة المرور من أكثر من 6 أحرف`,

    cmd: {
        status: `الحالة`,
        type: `النوع`,
        createdAtS: `تاريخ الإنشاء`,
        checkbox: `تحديد`,
        remove: `إزالة`,
        createdAt: `التاريخ`,
        raw: `بيانات`,
        user: `المستخدم`,
        result: `النتيجة`,
        error: `خطأ`,
        filterName: `تصفية الأوامر`,
        removeE: `خطأ في إزالة الأوامر`,
        removeS: `تم الإزالة بنجاح`,
    },

    devs: {
        name: `الاسم`,
        group: `المجموعة`,
        status: `الحالة`,
        manufacturer: `الشركة المصنعة`,
        deviceID: `معرّف الجهاز`,
        driver: `التعريف`,
        nameS: `بالاسم`,
        groupS: `بالمجموعة`,
        filterName: `تصفية الأجهزة`,
        dateUpdate: `تاريخ التحديث`,
    },

    inf: {
        group: `المجموعة`,
        name: `الاسم`,
        value: `القيمة`,

        general: `معلومات عامة`,
        node: `اسم الكمبيوتر`,
        bootTimestamp: `وقت التشغيل`,
        updatedAt: `وقت تحديث البيانات`,
        system: `النظام`,
        release: `الإصدار`,
        version: `الإصدار`,
        programVersion: `إصدار البرنامج`,
        memory: `الذاكرة`,
        memoryTotal: `الإجمالي`,
        memoryAvailable: `الذاكرة المتاحة`,
        memoryUsed: `الذاكرة المستخدمة`,
        memoryPercent: `نسبة الذاكرة المستخدمة، %`,
        swapTotal: `إجمالي التبديل`,
        swapFree: `التبديل المتاح`,
        swapUsed: `التبديل المستخدم`,
        swapPercent: `نسبة التبديل المستخدمة، %`,
        processor: `المعالج`,
        processorVendor: `الشركة المصنعة`,
        processorBrand: `العلامة التجارية`,
        cpuUsageTotal: `استخدام المعالج الكلي، %`,
        cpuCores: `النوى`,
        cpuTotalCores: `إجمالي الخيوط`,
        cpuFreqCurrent: `التردد الحالي`,
        net: `الشبكة`,
        ipAddress: `عنوان IP`,
        macAddress: `عنوان MAC`,
        interfaces: `واجهات الشبكة`,
        totalSend: `إجمالي الإرسال`,
        totalReceived: `إجمالي الاستقبال`,
        persistent: `التخزين الدائم`,
        disks: `الأقراص`,
        dateUpdate: `تاريخ التحديث`,
        autoUpdate: `التحديث التلقائي`,
        free: `متاح`,
    },

    proc: {
        name: `الاسم`,
        exe: `المسار`,
        username: `اسم المستخدم`,
        pidS: `معرّف العملية`,
        nameS: `بالاسم`,
        cpuPercent: `نسبة استخدام المعالج، %`,
        memoryPercent: `نسبة الذاكرة، %`,
        createTimeS: `بتاريخ البدء`,
        usernameS: `باسم المستخدم`,
        checkbox: `تحديد`,
        finishP: `إنهاء العملية`,
        finishPs: `إنهاء العمليات`,
        pid: `معرّف`,
        createTime: `وقت البدء`,
        diskUsageRead: `قراءة القرص`,
        diskUsageReadTotal: `إجمالي قراءة القرص`,
        diskUsageWrite: `كتابة القرص`,
        diskUsageWriteTotal: `إجمالي كتابة القرص`,
        status: `الحالة`,
        filterName: `تصفية العمليات`,
    },

    prog: {
        startRemove: `بدء إزالة البرنامج`,
        name: `الاسم`,
        installDate: `تاريخ التثبيت`,
        version: `الإصدار`,
        size: `الحجم`,
        description: `الوصف`,
        publisher: `الناشر`,
        location: `مسار التثبيت`,
        uninstall: `مسار الإزالة`,
        depends: `التبعيات`,
        nameS: `بالاسم`,
        installDateS: `بتاريخ التثبيت`,
        filterName: `تصفية البرامج`,
        confirmAction: `تأكيد الإجراء`,
        confirmActionText: `هل أنت متأكد أنك تريد إزالة "{{name}}"؟
قد يتطلب الضغط على أزرار الإجراء`,
    },

    control:{
        prepare: `جارٍ التحضير...`,
        connecting: `جارٍ الاتصال`,
        connected: `متصل`,
        disconnected: `منفصل`,
        error: `خطأ`,
        tryAgain: `حاول مرة أخرى`,
        zoom: `تكبير`,
        zoomOut: `تصغير`,
        showAllButtons: `عرض جميع الأزرار`,
        display: `الشاشة`,
        size: `الحجم`,
        keys: `المفاتيح`,
        showHideKey: `إظهار/إخفاء لوحة المفاتيح`,
        fitScreen: `ملائم للشاشة`,
        fullscreen: `وضع ملء الشاشة`,
        tune: `تعديل`,
        cursorMove: `تحريك المؤشر`,
        move: `تحريك`,
        click: `النقر`,
    },
    aiControl: 'التحكم بالذكاء الاصطناعي',
    aiControlStation: 'محطة التحكم بالذكاء الاصطناعي',
    ai: {
        title: 'إدارة محطة الذكاء الاصطناعي',
        autoApply: 'تطبيق الأوامر تلقائيًا',
        autoContinueError: 'معالجة الأخطاء تلقائيًا',
        send: 'إرسال (Ctrl + Enter)',
        input: 'رسالتك',
        errorLength: 'الرسالة قصيرة جدًا',
        process: 'جارٍ التنفيذ',
        success: 'نجاح',
        error: 'خطأ',
        showMore: 'عرض المزيد',
        continue: 'متابعة',
        doCommand: 'تنفيذ الأمر',
        commandAny: 'تنفيذ أمر عشوائي',
        refine: 'تحسين الإجابة',
        newQuery: 'استعلام جديد',
        auto: 'تلقائيًا',
        model: 'نموذج الذكاء الاصطناعي',
        tokensLeft: 'الرصيد المتبقي للرموز: {{value}}',
        allUsers: 'لجميع المستخدمين',
        tokenHelp: 'يتم تقسيم النص المرسل إلى نموذج الذكاء الاصطناعي إلى رموز. يمكن أن يكون الرمز حرفًا واحدًا أو كلمة واحدة أو حتى جملة واحدة ، اعتمادًا على اللغة والسياق. على سبيل المثال ، يمكن تقسيم النص "ChatGPT is great!" إلى الرموز التالية: ["Chat", "G", "PT", " is", " great", "!"].',
        enableHTML: 'تمكين عرض النتيجة بتنسيق HTML',
        userTokens: 'عدد الرموز المستخدمة في الرسالة الحالية. من المستحسن إنشاء استعلام جديد إذا كان ذلك ذا صلة بسياق آخر',
        inProgress: 'جارٍ معالجة المعلومات...',
        previewCommand: 'سيتم تنفيذ الأمر التالي',
        "userToken": "مفتاح API للمستخدم",
        "getToken": "احصل على المفتاح"
    },

};

import {observer} from "mobx-react";
import React, {useEffect} from "react";
import styles from './visualControl.module.scss';
import cn from 'classnames';
import RootStore from "../../stores/rootStore";
import VisualControlStore, {VideoSizes} from "../../stores/station/visualControlStore";
import MyHistory from "../../utils/myHistory";
import MyIconButton from "../myIconButton/myIconButton";
import {RTCConnectionState} from "../../utils/webRTCConnection";
import {Button} from "@material-ui/core";
import Cursor from "./cursor";
import {isMobile} from "react-device-detect";
import FakeInput from "./fakeInput";
import MyImg from "../myImg/myImg";
import LANG from "../../lang/language";

function VisualControl(props) {
    const rootStore = RootStore.getInstance();
    let store: VisualControlStore = props.store;
    // const video:any = createRef();

    useEffect(() => {
        store.onMount()
        return () => {
            store.onUnmount()
        }
    }, [])

    if (!store) // fixed modal error
        return <div/>;

    function getControls() {
        let res, status;
        switch (store.connectionState) {
            case RTCConnectionState.prepare:
                status = ['station', 'control', 'prepare'];
                break;
            case RTCConnectionState.connecting:
                status = ['station', 'control', 'prepare'];
                break;
            case RTCConnectionState.connected:
                res = null;
                break;
            case RTCConnectionState.disconnected:
                status = ['station', 'control', 'disconnected'];
                break;
            case RTCConnectionState.error:
                status = ['station', 'control', 'error']
                break;
        }
        status = LANG.get(status)

        if ([RTCConnectionState.error, RTCConnectionState.disconnected].includes(store.connectionState)) {
            res = <Button variant="text"
                          color="primary"
                          style={{marginLeft: 20}}
                          disableElevation={true}
                          onClick={() => {
                              store.remoteStation.connect({stationId: store.stationId});
                          }}>
                {LANG.get(['station', 'control', 'tryAgain'])}
            </Button>
        }

        function drawMenuContent(key) {
            switch (key) {
                case 'display':
                    return store.displays.map((el, index) =>
                        <div className={cn(styles.paramItem, styles.display, 'col-fixed f-c click', {
                            [styles.selected]: el.selected
                        })} onClick={() => {
                            store.changeDisplay(el)
                        }} key={el.name} title={el.name}>
                            {
                                index + 1
                            }
                            {
                                el.cursor && <MyImg src={'cursor_blue.png'} className={cn(styles.cursor, '')}/>
                            }
                        </div>
                    );
                case 'size':
                    return <>
                        {
                            store.sizes.map((el, index) =>
                                <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                                    [styles.selected]: el.key == store.selectedSizeType
                                })} onClick={() => {
                                    store.changeSizeType(el.key)
                                }} key={el.key} title={el.name}>
                                    <span className={cn(styles.icon, 'material-icons')}>{el.icon}</span>
                                </div>)
                        }
                        <div className={cn(styles.paramItem, 'col-fixed f-c click')}
                             onClick={() => {
                                 store.changeSizeType(VideoSizes.Custom)
                                 store.changeSizePercent(store.videoSizePercent + 0.05)
                             }} title={LANG.get(['station', 'control', 'zoom'])}>
                            <span className={cn(styles.icon, 'material-icons')}>zoom_in</span>
                        </div>
                        <div className={cn(styles.paramItem, 'col-fixed f-c click')}
                             onClick={() => {
                                 store.changeSizeType(VideoSizes.Custom)
                                 store.changeSizePercent(store.videoSizePercent - 0.05)
                             }} title={LANG.get(['station', 'control', 'zoomOut'])}>
                            <span className={cn(styles.icon, 'material-icons')}>zoom_out</span>
                        </div>
                    </>

                case 'keys':
                    return <>{
                        store.buttonsMain.map((el, index) =>
                            <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                                [styles.selected]: el.down
                            })} onClick={() => {
                                store.sendFastKey(el)
                            }} key={el.name}>
                                {el.name}
                            </div>
                        )
                    }
                        <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                            [styles.selected]: store.buttonsShowMore
                        })} onClick={() => {
                            store.toggleMore()
                        }} title={LANG.get(['station', 'control', 'showAllButtons'])}>
                            <span className={cn(styles.icon, 'material-icons')}>expand_more</span>
                        </div>
                        {store.buttonsShowMore &&
                            <div className={cn(styles.paramMore, 'col-fixed grid f-c')}>
                                {
                                    store.buttonsMore.map((el, index) =>
                                        <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                                            [styles.selected]: el.down
                                        })} onClick={() => {
                                            store.sendFastKey(el)
                                        }} key={el.name}>
                                            {el.name}
                                        </div>
                                    )
                                }
                            </div>
                        }
                    </>
            }
        }

        if (res || status) {
            return <>
                <div className={cn('col f-c')}>
                    <div className={cn('')}>
                        {status}
                        {res}
                    </div>
                </div>
            </>
        } else {
            let menu = [
                {name: LANG.get(['station', 'control', 'display']), key: 'display', icon: 'monitor'},
                {name: LANG.get(['station', 'control', 'size']), key: 'size', icon: 'photo_size_select_large'},
                {name: LANG.get(['station', 'control', 'keys']), key: 'keys', icon: 'keyboard_alt'},
            ]
            if (isMobile) {
                let menuResult = menu.map(el => <>
                    <div className={cn(styles.paramWrap, 'col-fixed grid f-c')}>
                            <span className={cn(styles.paramName, 'col-fixed f-c')}>
                                <div key={el.key} className={cn(styles.paramItem, 'col-fixed f-c click', {
                                    [styles.selected]: store.controlMenuState[el.key]
                                })} onClick={() => {
                                    store.toggleControlMenu(el.key)
                                }}>{
                                    el.icon ?
                                        <span className={cn(styles.icon, 'material-icons')}>{el.icon}</span>
                                        : el.name
                                }</div>
                            </span>
                    </div>
                    <div className={cn(styles.paramMore, 'col-fixed grid f-c')}>
                        {
                            store.controlMenuState[el.key] && drawMenuContent(el.key)
                        }
                    </div>
                </>)
                return <>
                    {menuResult}
                    <div className={cn(styles.paramWrap, 'col-fixed grid f-c')}>
                        <span className={cn(styles.paramName, 'col-fixed f-c')}>
                            <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                                [styles.selected]: store.mobileKeyboard.show
                            })} onClick={() => {
                                store.toggleKeyBoard()
                            }} title={LANG.get(['station', 'control', 'showHideKey'])}>
                                <span className={cn(styles.icon, 'material-icons')} style={
                                    {transform: store.mobileKeyboard.show ? '' : 'rotate(180deg)'}
                                }>keyboard_hide</span>
                            </div>
                        </span>
                    </div>
                </>
            } else {
                let menuResult = menu.map(el =>
                    <div className={cn(styles.paramWrap, 'col-fixed grid f-c')}>
                    <span className={cn(styles.paramName, 'col-fixed f-c')}>
                        {el.name}
                    </span>
                        {drawMenuContent(el.key)}
                    </div>
                )
                return <>
                    {menuResult}
                </>
            }
        }
    }

    function getCloseButton() {
        return <div className={cn('col-fixed')}>
            <MyIconButton iconM='close' title={LANG.get(['general', 'close'])} className={cn()}
                          onClick={() => {
                              if (store.isModal) {
                                  props.close();
                              } else {
                                  MyHistory.goBack();
                              }
                          }
                          }/>
        </div>;
    }

    function getMoreControls() {
        if (store.connectionState != RTCConnectionState.connected) return;
        return <div className={cn('col-fixed')}>
            <MyIconButton iconM='more_vert' title={LANG.get(['global', 'menu'])} className={cn(styles.menuMore, {
                [styles.selected]: store.controlMore.selected
            })}
                          onClick={() => {
                              store.toggleMoreControl()
                          }}/>
            {
                store.controlMore.selected && <div className={cn(styles.paramMore, 'col-fixed grid f-c')}>
                    {
                        store.controlMore.items.map((el, index) => {
                                let isSelected = (parent, key) => {
                                    let selected = parent.selectedItem === key;
                                    if (parent.selectedItem && parent.selectedItem instanceof Function)
                                        selected = parent.selectedItem() === key
                                    return selected
                                };

                                let selected = isSelected(store.controlMore, el.key);
                                return <>
                                    <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                                        [styles.selected]: selected
                                    })} onClick={() => {
                                        store.changeItem(store.controlMore, 'selectedItem', selected ? '' : el.key)
                                    }} key={el.name}>
                                        {el.name}
                                    </div>
                                    {
                                        selected && <div className={cn(styles.paramMore, 'col-fixed grid f-c')}>
                                            {
                                                el.items?.map(el2 => {
                                                        return <div className={cn(styles.paramItem, 'col-fixed f-c click', {
                                                            [styles.selected]: isSelected(el, el2.key)
                                                        })} onClick={(e) => {
                                                            el2.onSelect()
                                                            store.changeItem(el, 'selectedItem', el2.key)
                                                        }}>
                                                            {el2.name}
                                                        </div>;
                                                    }
                                                )
                                            }
                                        </div>
                                    }
                                </>
                            }
                        )
                    }
                </div>
            }
        </div>;
    }

    return (
        <div className={cn(styles.main, 'grid dir-col')}>
            <div className={cn(styles.controlPanel, 'col-fixed grid  p-r')}>
                {isMobile && getCloseButton()}
                <div className={cn('col grid')}>
                    {
                        getControls()
                    }
                </div>
                {!isMobile && getCloseButton() || getMoreControls()}
            </div>
            <div className={cn(styles.content, 'col', {
                //'f-c': store.selectedSizeType == VideoSize.Fit
            })}>
                <div className={cn(styles.flyWrap, '')} style={{
                    top: store.videoPosition.y + 'px',
                    left: store.videoPosition.x + 'px',
                }}>
                    <div className={cn(styles.videoWrap, '')}>
                        <video ref={e => store.setVideoRef(e)} id="remoteVideo" className={cn('f-c', styles.video,
                            {
                                // [styles.fit]: store.selectedSize == 'fit'
                            })}
                               playsInline
                               autoPlay style={{
                            width: store.videoSizeViewed.w,
                            height: store.videoSizeViewed.h
                        }}></video>
                        <Cursor store={store}/>
                        {
                            isMobile && <FakeInput id="fakeInput"
                                                   show={store.mobileKeyboard.show}
                                                   x={store.cursorPositionViewed.x}
                                                   y={store.cursorPositionViewed.y}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(VisualControl);

export default {
    title: `Painel de controle de estações espaciais`,
    phrase: `Frase secreta (12 palavras)`,
    enter: `Entrar`,
    register: `Registrar`,
    back: `Voltar`,
    nickname: `Apelido`,
    secret: `Frase secreta`,
    secretDesc: `Por favor, guarde a frase em um local seguro. Ela permite acessar sua conta e as estações adicionadas. O serviço não armazena esta frase.
    Em caso de perda, não será possível recuperar o acesso e será necessário criar uma nova conta e adicionar as estações novamente`,
    secretCheck: `Concordo que, em caso de perda da frase secreta, não será possível recuperar o acesso à conta`,
    errorInitKey: `Erro na inicialização da chave, verifique os dados`,
    registerSuccess: `Registro realizado com sucesso`,
};

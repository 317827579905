export default {
  mainSite: `Aller sur le site principal`,
  menu: `Menu`,
  stations: `Stations`,
  exit: `Quitter`,
  feedback: `Retour`,
  balance: `Solde`,
  sortNo: `Pas de tri`,
  sortDown: `Tri décroissant`,
  sortUp: `Tri croissant`,
  sorting: `Tri`,
  loadMore: `Charger plus`,
  nothingFound: `Rien trouvé`,
  select: `Sélectionner`,
  refresh: `Actualiser`,
  refreshData: `Actualiser les données`,
  filter: `Filtrer`,
  filterOpen: `Ouvrir le filtre`,
  filterClear: `Effacer le filtre`,
  add: `Ajouter`,
  downloadExcel: `Télécharger en tant que Excel`,
  openSettings: `Ouvrir les paramètres`,
  back: `Retour`,
  actions: `Actions`,
  noData: `Aucune donnée`,
  addLine: `Ajouter une ligne`,
  change: `Changer`,
  create: `Créer`,
  open: `Ouvrir`,
  selectFromList: `Sélectionner dans la liste`,
  loading: `Chargement...`,
  showQR: `Afficher le code QR`,
  save: `Enregistrer`,
  cancel: `Annuler`,
  yes: `Oui`,
  no: `Non`,
  close: `Fermer`,
  make: `Exécuter`,
  checkbox: `Sélection`,
  notFound: `Non trouvé`,
  errorUpdate: `Erreur de mise à jour`,
  errorGetUserEmpty: `Erreur lors de la récupération de l'utilisateur - réponse vide`,
  dayS: `jours`,
  successfully: `Avec succès`,
  errorDetails: `Détails de l'erreur`,
  clean: `Nettoyer`,
  apply: `Appliquer`,
  settings: 'Paramètres',
  price: 'Prix',
};
import React from "react";
import {Checkbox, Menu, MenuItem} from "@material-ui/core";
import cn from "classnames";
import styles from "../myMenu/myMenu.module.scss";
import MyIconButton from "../myIconButton/myIconButton";
import LANG from "../../lang/language";

interface Props extends Object {
    items: any[];
    onClick?: () => void;
    onClose?: () => void;
    className?;
    propsClick?: object;

    [key: string]: any;
}

function MyMenu({items, onClick, onClose, className, propsClick, ...props}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        //setAnchorEl(event.currentTarget);
        if (props.onClick)
            props.onClick(event);
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        if (props.onClose)
            props.onClose();
        setOpen(false);
    };
    // items[0].name === 'По дате создания'
    return <>
        <span ref={(el) => setAnchorEl(el)}>
            {React.cloneElement(props.children, {'onClick': handleClick})}
        </span>
        {anchorEl && <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            {
                items.map(el => {
                    let res;

                    function onCLickChangeDir(e, dir) {
                        e.stopPropagation(); // not call parent on click
                        if (el.selected === dir)
                            return;

                        el.onChangeDir(dir);
                    }
                    let disabled = el.disabled;
                    if(disabled && disabled instanceof Function)
                        disabled = el.disabled()

                    let name = LANG.get(el.name || el.text);
                    switch (el.type) {
                        case 'checkbox':
                            res = <MenuItem key={name} onClick={el.onClick} disabled={el.disabled}>
                                <Checkbox
                                    size='small'
                                    style={{marginRight: 8, padding: 0}}
                                    checked={el.selected}
                                    onClick={el.onClick}
                                    disabled={disabled}
                                />
                                {name}
                            </MenuItem>;
                            break;
                        case 'menu':
                            res =
                                <MyMenu key={el.key} disabled={disabled} items={el.items} >
                                    {
                                        <MenuItem>
                                            {el.iconM && <span className={cn(styles.icon, 'material-icons')}
                                                               style={{marginRight: '5px'}}>{el.iconM}</span>}

                                            {name}
                                        </MenuItem>
                                    }
                                </MyMenu>;
                            break;
                        case 'button':
                        default:
                            res = <MenuItem key={name} onClick={() => {
                                if (el.onClick) {
                                    let isOpen = !!el.onClick(propsClick);
                                   // console.log('isOpen', isOpen)
                                    setOpen(isOpen);
                                }
                            }}  disabled={disabled} selected={!!el.selected}>
                                {el.iconM && <span className={cn(styles.icon, 'material-icons')}
                                                   style={{marginRight: '5px'}}>{el.iconM}</span>}

                                {el.onChangeDir && (
                                    <span className={cn(styles.changeDirWrap, 'f-c')}>

                                        <span className={cn(styles.icon, 'material-icons', {
                                            [styles.selected]: el.selected === 'DESC'
                                        })} onClick={(e) => onCLickChangeDir(e, 'DESC')} title={'По убыванию'}>
                                            keyboard_arrow_down
                                        </span>

                                        <span className={cn(styles.icon, 'material-icons', {
                                            [styles.selected]: el.selected === 'ASC'
                                        })} onClick={(e) => onCLickChangeDir(e, 'ASC')} title={'По возрастанию'}>
                                            keyboard_arrow_up
                                        </span>

                                    </span>
                                )}

                                {name}
                            </MenuItem>;
                            break;
                    }
                    return res;
                })
            }
        </Menu>}
    </>

}

export default MyMenu;

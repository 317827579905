
export default class EventBusLocal{

    subscriptions = { }
    lastId = 0

    subscribe(eventType, callback) {
        const id = this.getNextUniqueId()

        if(!this.subscriptions[eventType])
            this.subscriptions[eventType] = { }

        this.subscriptions[eventType][id] = callback

        return {
            unsubscribe: () => {
                try{
                    if (!this.subscriptions.hasOwnProperty('eventType'))
                        return
                    delete this.subscriptions[eventType][id]
                    if(Object.keys(this.subscriptions[eventType]).length === 0)
                        delete this.subscriptions[eventType]
                } catch (e){
                    console.log(e)
                }
            }
        }
    }

    publish(eventType, arg?) {
        if(!this.subscriptions[eventType])
            return

        Object.keys(this.subscriptions[eventType]).forEach(key => this.subscriptions[eventType][key](arg))
    }

    getNextUniqueId() {
        this.lastId += 1
        return this.lastId
    }
}

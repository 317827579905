import React, {useEffect, useMemo} from "react";
import Header from "../header/header";
import styles from './layout.module.scss';
import RootStore from "../../stores/rootStore";
import {observer} from "mobx-react";
import {HeadProvider, Link, Meta, Title} from "react-head";
import {isMobile} from "react-device-detect";
import HeaderBottom from "../headerMobile/headerMobile";

function Layout(props) {
    const rootStore = RootStore.getInstance();
    useMemo(() => {
        if(isMobile) {
            document.body.classList.add('mob');
            document.body.classList.remove('desk');
        }else {
            document.body.classList.add('desk');
            document.body.classList.remove('mob');
        }
    }, [isMobile])
    return <div className={styles.main + (isMobile ? ' grid dir-col' : '')}>
        <div className={styles.content + (isMobile ? ' col' : '')}>
            {props.children}
        </div>

        <HeadProvider>
            <Title>{`${rootStore.meta.title} | Cusco Remote Control`}</Title>
            <Meta property="og:description" content={rootStore.meta.desc} key="description"/>
            <Link rel="icon" href="/favicon.ico"/>
            <Meta name="viewport" content="width=device-width, initial-scale=1.0"/>
           {/* <Meta name='HandheldFriendly' content='true' />
            <Meta name='MobileOptimized' content='width' />
            <Meta name='apple-mobile-web-app-capable' content='yes'/>*/}
        </HeadProvider>

        { !isMobile ? <Header/> : <HeaderBottom/>}

        {/*<Footer/>*/}
    </div>
}

export default observer(Layout);

import MyHistory from "../utils/myHistory";
import {action, makeObservable, observable} from "mobx";
import LANG from "../lang/language";

class NavigationStore {
    static classInstance: NavigationStore = null;

    @observable menuOpen: boolean = false;
    @observable subMenuOpen:any = false;

    constructor() {
        makeObservable(this);
    }

    static getInstance() {
        if (NavigationStore.classInstance === null) {
            NavigationStore.classInstance = new this();
        }
        return this.classInstance;
    }


    getMenu() {
        function filterFalse(array) {
            return array.filter(el => {
                if (el?.submenu) {
                    el.submenu = filterFalse(el.submenu);
                    if (el.submenu.length === 0)
                        return false;
                }
                return !!el;
            })
        }
        const pathLang = 'general'

        return filterFalse([
           /* {
                link: '', name: 'Дашборд'
            },*/
            {
                link: '/stations', name: LANG.get([pathLang, 'stations']),
                action: () => MyHistory.push({pathname: `/station/add`}),
                submenu: null, links: ['/station/']
            },
        ]);
    }

    getMobileMenu() {

        return [
            /*{
                link: '',
                name: 'Дашбоард',
                icon: 'dashboard'
            },*/
            {
                link: '/stations', name: LANG.get(['general', 'stations']),
                icon: 'satellite_alt',
                submenu: null, links: [],
            },
            {
                link: 'menu', name: LANG.get(['general', 'menu']),
                icon: 'menu', submenu: [
                  /*  {
                        link: '', name: 'Главная', exact: true
                    },*/
                    {
                        link: '/stations', name: LANG.get(['general', 'stations']),
                        submenu: null, links: ['/station/']
                    },
                ]
            },
        ];
    }


    isActiveLink(el, location, parentLink?) {
        let active = false;
        let link = el.link;
        if (parentLink)
            link = parentLink + link;
        if (link === '/')
            return location.pathname === link;
        if (el.links)
            active = !!el.links.find(el2 => location.pathname.includes(el2));
        if (!active && link != '')
            active = location.pathname.includes(link);
        if (!active && el.submenu)
            active = !!el.submenu.find(el2 => {
                if (el2.exact)
                    return location.pathname === link + el2.link;
                if (location.pathname.includes(link + el2.link))
                    return true;
                if (el2.links && !!el2.links.find(el3 => location.pathname.includes(link + el3)))
                    return true;
            });
        return active;
    }

    getSelectedElement(menu, location: Location) {
        if (this.menuOpen)
            return menu.find(el => el.link === 'menu');
        for (let i = 0; i < menu.length; i++) {
            let item = menu[i];
            if (this.isActiveLink(item, location))
                return item;
        }
        return 'null';
    }

    goTo(link) {
        MyHistory.push(link)
    }

    @action
    openMenu(open: boolean) {
        this.menuOpen = open;
        this.subMenuOpen = false;
    }


    @action
    openSubMenu(element) {
        if(!element || element.link === this.subMenuOpen?.link)
            this.subMenuOpen = false;
        else {
            this.subMenuOpen = element;
            this.menuOpen = false;
        }
    }
}

export default NavigationStore;

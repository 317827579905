export default {
    stations: `스테이션`,
    filter: `스테이션 필터`,
    comment: `코멘트`,
    comment_add: "댓글 추가",
    comment_edit: "댓글 편집",
    sortDate: `작성일 기준 정렬`,
    checkbox: `선택`,
    select: `선택`,
    actions: `작업`,
    showStation: `스테이션 표시`,
    delete: `삭제`,
    number: `번호`,
    name: `이름`,
    bootTimestamp: `부팅 타임스탬프`,
    bootTimestampM: `부팅`,
    cpuUsageTotal: `CPU 사용률, %`,
    cpuUsageTotalM: `CPU`,
    memoryPercent: `메모리 사용률, %`,
    memoryPercentM: `메모리`,
    ipAddress: `IP 주소`,
    ipAddressM: `IP`,
    disks: `사용 가능한 디스크`,
    disksM: `디스크`,
    updatedAt: `업데이트 날짜`,
    system: `시스템`,
    release: `릴리즈`,
    stationSelect: `스테이션 선택`,
    stationsDel: `삭제된 스테이션`,
    stationDel: `삭제된 스테이션`,
    downloadClient: `클라이언트 다운로드`,
    download: `다운로드`,
    online: `온라인`,
    offline: `오프라인`,

    toDo: `진행 중`,
    done: `완료`,
    error: `오류`,
    canceled: `취소됨`,

    info: `정보`,
    power: `전원`,
    process: `프로세스`,
    programs: `프로그램`,
    devices: `장치`,
    commands: `명령어`,
    other: `기타`,

    reboot: `재부팅`,
    shutdown: `종료`,
    sleep: `절전`,
    hibernate: `절전 모드`,
    lock: `잠금`,

    refill: `충전`,
    writeOff: `폐기`,
    withdrawal: `인출`,
    dateUpdate: `업데이트 날짜`,
    autoUpdate: `자동 업데이트`,
    autoUpdateM: `자동 업데이트`,
    commandError: `명령어 생성 오류`,
    commandSend: `명령어 전송됨`,

    visualControl: `시각적 제어`,
    doCommand: `명령어 실행`,
    viewStation: `스테이션 보기`,
    metaDesc: `원격 액세스 및 컴퓨터 및 서버 제어`,
    confirmAction: `동작 확인`,
    confirmActionText: `명령어 "{{cmd}}"를 실행하시겠습니까?
동작 버튼을 눌러야 할 수도 있습니다.`,
    waitingExe: `실행 대기 중...`,
    successEmpty: `성공했지만 응답이 비어 있습니다`,
    resultExe: `실행 결과`,
    enterCommand: `명령어 입력`,
    exeCustomCommand: `사용자 정의 명령어 실행`,
    enterEnable: `ENTER 키로 전송 활성화`,
    visualControlStation: `스테이션 시각적 제어`,
    add: `스테이션 추가`,
    password: `비밀번호`,
    stationAdded: `스테이션 추가됨`,
    passwordLength: `비밀번호는 6자 이상이어야 합니다`,

    cmd: {
        status: `상태`,
        type: `유형`,
        createdAtS: `생성 날짜`,
        checkbox: `선택`,
        remove: `제거`,
        createdAt: `날짜`,
        raw: `데이터`,
        user: `사용자`,
        result: `결과`,
        error: `오류`,
        filterName: `명령어 필터`,
        removeE: `명령어 제거 오류`,
        removeS: `제거 완료`,
    },

    devs: {
        name: `이름`,
        group: `그룹`,
        status: `상태`,
        manufacturer: `제조사`,
        deviceID: `장치 ID`,
        driver: `드라이버`,
        nameS: `이름 기준`,
        groupS: `그룹 기준`,
        filterName: `장치 필터`,
        dateUpdate: `업데이트 날짜`,
    },

    inf: {
        group: `그룹`,
        name: `이름`,
        value: `값`,

        general: `일반 정보`,
        node: `컴퓨터 이름`,
        bootTimestamp: `부팅 타임스탬프`,
        updatedAt: `데이터 업데이트 시간`,
        system: `시스템`,
        release: `릴리즈`,
        version: `버전`,
        programVersion: `프로그램 버전`,
        memory: `메모리`,
        memoryTotal: `총 용량`,
        memoryAvailable: `사용 가능`,
        memoryUsed: `사용 중`,
        memoryPercent: `메모리 사용률, %`,
        swapTotal: `스왑 총 용량`,
        swapFree: `사용 가능한 스왑`,
        swapUsed: `사용 중인 스왑`,
        swapPercent: `스왑 사용률, %`,
        processor: `프로세서`,
        processorVendor: `제조사`,
        processorBrand: `브랜드`,
        cpuUsageTotal: `총 CPU 사용률, %`,
        cpuCores: `코어`,
        cpuTotalCores: `쓰레드`,
        cpuFreqCurrent: `현재 클록 속도`,
        net: `네트워크`,
        ipAddress: `IP 주소`,
        macAddress: `MAC 주소`,
        interfaces: `네트워크 인터페이스`,
        totalSend: `총 전송량`,
        totalReceived: `총 수신량`,
        persistent: `지속적인 메모리`,
        disks: `디스크`,
        dateUpdate: `업데이트 날짜`,
        autoUpdate: `자동 업데이트`,
        free: `사용 가능`,
    },

    proc: {
        name: `이름`,
        exe: `경로`,
        username: `사용자 이름`,
        pidS: `프로세스 ID`,
        nameS: `이름 기준`,
        cpuPercent: `CPU 사용률, %`,
        memoryPercent: `메모리 사용률, %`,
        createTimeS: `생성 날짜 기준`,
        usernameS: `사용자 이름 기준`,
        checkbox: `선택`,
        finishP: `프로세스 종료`,
        finishPs: `다중 프로세스 종료`,
        pid: `프로세스 ID`,
        createTime: `생성 날짜`,
        diskUsageRead: `디스크 읽기 사용량`,
        diskUsageReadTotal: `총 디스크 읽기 사용량`,
        diskUsageWrite: `디스크 쓰기 사용량`,
        diskUsageWriteTotal: `총 디스크 쓰기 사용량`,
        status: `상태`,
        filterName: `프로세스 필터`,
    },

    prog: {
        startRemove: `프로그램 제거 시작`,
        name: `이름`,
        installDate: `설치 날짜`,
        version: `버전`,
        size: `크기`,
        description: `설명`,
        publisher: `발행자`,
        location: `설치 위치`,
        uninstall: `제거`,
        depends: `의존성`,
        nameS: `이름 기준`,
        installDateS: `설치 날짜 기준`,
        filterName: `프로그램 필터`,
        confirmAction: `동작 확인`,
        confirmActionText: `"{{name}}"을(를) 제거하시겠습니까?
동작 버튼을 눌러야 할 수도 있습니다.`,
    },

    control:{
        prepare: `준비 중...`,
        connecting: `연결 중`,
        connected: `연결됨`,
        disconnected: `연결 해제됨`,
        error: `오류`,
        tryAgain: `다시 시도`,
        zoom: `확대`,
        zoomOut: `축소`,
        showAllButtons: `모든 버튼 표시`,
        display: `디스플레이`,
        size: `크기`,
        keys: `키`,
        showHideKey: `키보드 표시/숨기기`,
        fitScreen: `화면에 맞추기`,
        fullscreen: `전체 화면`,
        tune: `사용자 정의`,
        cursorMove: `커서 이동`,
        move: `이동`,
        click: `클릭`,
    },

    aiControl: '인공지능 제어',
    aiControlStation: '인공지능 제어 스테이션',
    ai: {
        title: '인공지능 스테이션 제어',
        autoApply: '명령 자동 적용',
        autoContinueError: '오류 계속 처리',
        send: '보내기 (Ctrl + Enter)',
        input: '메시지 입력',
        errorLength: '메시지가 너무 짧습니다',
        process: '처리 중',
        success: '성공',
        error: '오류',
        showMore: '더 보기',
        continue: '계속하기',
        doCommand: '명령 실행',
        commandAny: '임의의 명령 실행',
        refine: '답변 개선',
        newQuery: '새로운 쿼리',
        auto: '자동',
        model: '인공지능 모델',
        tokensLeft: '남은 토큰: {{value}}',
        allUsers: '모든 사용자',
        tokenHelp: '인공지능 모델로 전송된 텍스트는 토큰으로 분할됩니다. 토큰은 언어와 문맥에 따라 하나의 문자, 하나의 단어 또는 하나의 문장일 수 있습니다. 예를 들어, "ChatGPT is great!"라는 텍스트는 다음과 같이 토큰으로 분할될 수 있습니다: ["Chat", "G", "PT", " is", " great", "!"]。',
        enableHTML: 'HTML에서 결과 표시 사용',
        userTokens: '현재 메시지에서 사용된 토큰 수. 다른 문맥과 관련이 있다면 새로운 쿼리를 작성하는 것이 좋습니다.',
        inProgress: '정보 처리 중...',
        previewCommand: '다음 명령이 실행됩니다',
        "userToken": "사용자 API 키",
        "getToken": "키 받기"
    },
};

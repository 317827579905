export default {
    stations: `स्टेशन`,
    filter: `स्टेशन फ़िल्टर`,
    comment: `टिप्पणी`,
    comment_add: "टिप्पणी जोड़ें",
    comment_edit: "टिप्पणी संपादित करें",
    sortDate: `तारीख के आधार पर क्रमबद्ध करें`,
    checkbox: `चयन`,
    select: `चुनें`,
    actions: `कार्रवाईयाँ`,
    showStation: `स्टेशन दिखाएँ`,
    delete: `हटाएँ`,
    number: `नंबर`,
    name: `नाम`,
    bootTimestamp: `बूट टाइमस्टैम्प`,
    bootTimestampM: `बूट`,
    cpuUsageTotal: `सीपीयू उपयोग, %`,
    cpuUsageTotalM: `सीपीयू`,
    memoryPercent: `रैम उपयोग, %`,
    memoryPercentM: `रैम`,
    ipAddress: `आईपी पता`,
    ipAddressM: `आईपी`,
    disks: `उपलब्ध स्थान`,
    disksM: `डिस्क`,
    updatedAt: `अद्यतन तिथि`,
    system: `ओएस`,
    release: `रिलीज`,
    stationSelect: `स्टेशन का चयन करें`,
    stationsDel: `स्टेशन हटाए गए`,
    stationDel: `स्टेशन हटाए गए`,
    downloadClient: `क्लाइंट डाउनलोड करें`,
    download: `डाउनलोड`,
    online: `ऑनलाइन`,
    offline: `ऑफ़लाइन`,

    toDo: `करने के लिए`,
    done: `सफलतापूर्वक`,
    error: `त्रुटि`,
    canceled: `रद्द किया गया`,

    info: `जानकारी`,
    power: `बिजली`,
    process: `प्रक्रिया`,
    programs: `प्रोग्राम`,
    devices: `उपकरण`,
    commands: `कमांड`,
    other: `अन्य`,

    reboot: `रीबूट`,
    shutdown: `बंद करें`,
    sleep: `सो जाओ`,
    hibernate: `हाइबरनेट`,
    lock: `लॉक`,

    refill: `भरना`,
    writeOff: `खरीदारी`,
    withdrawal: `निकासी`,
    dateUpdate: `अद्यतन तिथि`,
    autoUpdate: `स्वचालित अद्यतन`,
    autoUpdateM: `ऑटो अद्यतन`,
    commandError: `कमांड बनाने में त्रुटि`,
    commandSend: `कमांड भेजी गई`,

    visualControl: `दृश्य नियंत्रण`,
    doCommand: `कमांड का पालन करें`,
    viewStation: `स्टेशन देखें`,
    metaDesc: `कंप्यूटर और सर्वरों के लिए दूरस्थ पहुंच का नियंत्रण`,
    confirmAction: `कार्रवाई की पुष्टि करें`,
    confirmActionText: `क्या आप "{{cmd}}" कमांड का पालन करना चाहते हैं?`,
    waitingExe: `क्रियान्वयन की प्रतीक्षा कर रहा हूँ...`,
    successEmpty: `सफलतापूर्वक, लेकिन रिस्पॉन्स खाली है`,
    resultExe: `क्रियान्वयन का परिणाम`,
    enterCommand: `कमांड दर्ज करें`,
    exeCustomCommand: `कस्टम कमांड का पालन करें`,
    enterEnable: `एंटर दबाने पर भेजें सक्षम करें`,
    visualControlStation: `स्टेशन का दृश्य नियंत्रण`,
    add: `स्टेशन जोड़ें`,
    password: `पासवर्ड`,
    stationAdded: `स्टेशन जोड़ा गया`,
    passwordLength: `पासवर्ड 6 अक्षरों से अधिक होना चाहिए`,

    cmd: {
        status: `स्थिति`,
        type: `प्रकार`,
        createdAtS: `निर्माण की तारीख`,
        checkbox: `चयन`,
        remove: `हटाएँ`,
        createdAt: `तारीख`,
        raw: `डेटा`,
        user: `उपयोगकर्ता`,
        result: `परिणाम`,
        error: `त्रुटि`,
        filterName: `कमांड फ़िल्टर करें`,
        removeE: `कमांड हटाने में त्रुटि`,
        removeS: `सफलतापूर्वक हटा दिया गया`,
    },

    devs: {
        name: `नाम`,
        group: `समूह`,
        status: `स्थिति`,
        manufacturer: `निर्माता`,
        deviceID: `डिवाइस ID`,
        driver: `ड्राइवर`,
        nameS: `नाम के अनुसार`,
        groupS: `समूह के अनुसार`,
        filterName: `उपकरण फ़िल्टर करें`,
        dateUpdate: `अद्यतन तिथि`,
    },

    inf: {
        group: `समूह`,
        name: `नाम`,
        value: `मूल्य`,

        general: `सामान्य जानकारी`,
        node: `कंप्यूटर का नाम`,
        bootTimestamp: `बूट समय`,
        updatedAt: `डेटा अद्यतन समय`,
        system: `ऑपरेटिंग सिस्टम`,
        release: `संस्करण`,
        version: `संस्करण`,
        programVersion: `प्रोग्राम संस्करण`,
        memory: `मेमोरी`,
        memoryTotal: `कुल`,
        memoryAvailable: `उपलब्ध`,
        memoryUsed: `इस्तेमाल किया जा रहा है`,
        memoryPercent: `मेमोरी उपयोग, %`,
        swapTotal: `कुल स्वैप`,
        swapFree: `उपलब्ध स्वैप`,
        swapUsed: `स्वैप में इस्तेमाल`,
        swapPercent: `स्वैप उपयोग, %`,
        processor: `प्रोसेसर`,
        processorVendor: `निर्माता`,
        processorBrand: `ब्रांड`,
        cpuUsageTotal: `कुल सीपीयू उपयोग, %`,
        cpuCores: `कोर्स`,
        cpuTotalCores: `समूह`,
        cpuFreqCurrent: `वर्तमान फ्रिक्वेंसी`,
        net: `नेटवर्क`,
        ipAddress: `आईपी पता`,
        macAddress: `मैक पता`,
        interfaces: `नेटवर्क इंटरफेसेज`,
        totalSend: `कुल भेजा गया`,
        totalReceived: `कुल प्राप्त हुआ`,
        persistent: `स्थायी संग्रहण`,
        disks: `डिस्क`,
        dateUpdate: `अद्यतन तिथि`,
        autoUpdate: `स्वचालित अद्यतन`,
        free: `खाली`,
    },

    proc: {
        name: `नाम`,
        exe: `पथ`,
        username: `उपयोगकर्ता`,
        pidS: `प्रक्रिया ID`,
        nameS: `नाम के अनुसार`,
        cpuPercent: `सीपीयू उपयोग, %`,
        memoryPercent: `मेमोरी उपयोग, %`,
        createTimeS: `शुरू करने की तिथि के अनुसार`,
        usernameS: `उपयोगकर्ता के अनुसार`,
        checkbox: `चयन`,
        finishP: `प्रक्रिया समाप्त करें`,
        finishPs: `प्रक्रियाओं को समाप्त करें`,
        pid: `प्रक्रिया ID`,
        createTime: `शुरू करने का समय`,
        diskUsageRead: `डिस्क पठन`,
        diskUsageReadTotal: `कुल डिस्क पठन`,
        diskUsageWrite: `डिस्क लेखन`,
        diskUsageWriteTotal: `कुल डिस्क लेखन`,
        status: `स्थिति`,
        filterName: `प्रक्रिया फ़िल्टर करें`,
    },

    prog: {
        startRemove: `प्रोग्राम के हटाने का आदेश दें`,
        name: `नाम`,
        installDate: `स्थापना की तारीख`,
        version: `संस्करण`,
        size: `आकार`,
        description: `विवरण`,
        publisher: `प्रकाशक`,
        location: `स्थान`,
        uninstall: `अनइंस्टॉल करें`,
        depends: `आधारित`,
        nameS: `नाम के अनुसार`,
        installDateS: `स्थापना की तारीख के अनुसार`,
        filterName: `प्रोग्राम फ़िल्टर करें`,
        confirmAction: `कार्रवाई की पुष्टि करें`,
        confirmActionText: `क्या आप "{{name}}" को हटाने के लिए बुलाना चाहते हैं?
कार्रवाई के बटन पर क्लिक करने की आवश्यकता हो सकती है।`,
    },

    control:{
        prepare: `तैयारी कर रहा हूँ...`,
        connecting: `कनेक्ट कर रहा हूँ`,
        connected: `कनेक्ट किया गया`,
        disconnected: `डिस्कनेक्ट हो गया`,
        error: `त्रुटि`,
        tryAgain: `फिर से कोशिश करें`,
        zoom: `ज़ूम करें`,
        zoomOut: `ज़ूम आउट करें`,
        showAllButtons: `सभी बटन दिखाएं`,
        display: `प्रदर्शन`,
        size: `आकार`,
        keys: `कुंजी`,
        showHideKey: `कीबोर्ड दिखाएँ/छिपाएँ`,
        fitScreen: `स्क्रीन में फिट करें`,
        fullscreen: `पूर्ण स्क्रीन`,
        tune: `समायोजन`,
        cursorMove: `कर्सर को हिलाएँ`,
        move: `हिलाएँ`,
        click: `क्लिक करें`,
    },


    aiControl: 'एआई नियंत्रण',
    aiControlStation: 'एआई नियंत्रण स्टेशन',
    ai: {
        title: 'एआई स्टेशन नियंत्रण',
        autoApply: 'स्वचालित आदेश लागू करें',
        autoContinueError: 'त्रुटियों का कार्यान्वयन जारी रखें',
        send: 'भेजें (Ctrl + Enter)',
        input: 'आपका संदेश',
        errorLength: 'संदेश बहुत कम है',
        process: 'प्रसंस्करण',
        success: 'सफलता',
        error: 'त्रुटि',
        showMore: 'और दिखाएं',
        continue: 'जारी रखें',
        doCommand: 'कमांड करें',
        commandAny: 'कोई भी कमांड करें',
        refine: 'उत्तर को पुनर्संशोधित करें',
        newQuery: 'नया प्रश्न',
        auto: 'स्वचालित',
        model: 'एआई मॉडल',
        tokensLeft: 'शेष टोकन: {{value}}',
        allUsers: 'सभी उपयोगकर्ताओं के लिए',
        tokenHelp: 'एआई मॉडल को भेजा गया पाठ टोकन में विभाजित किया जाता है। टोकन एकल वर्ण, एकल शब्द या भाषा और संदर्भ पर निर्भर करते हैं, यहां तक कि एकल वाक्य भी हो सकता है। उदाहरण के लिए, "ChatGPT बहुत बढ़िया है!" टेकन में निम्नलिखित टोकन में विभाजित किया जा सकता हैं: ["Chat", "G", "PT", " है", " बहुत बढ़िया", "!"]।',
        enableHTML: 'HTML में परिणाम प्रदर्शित करने को सक्षम करें',
        userTokens: 'वर्तमान संदेश में उपयोग किए गए टोकनों की संख्या। यदि यह किसी अन्य संदर्भ से संबंधित है, तो एक नई क्वेरी बनाना सिफारिश की जाती है।',
        inProgress: 'सूचनाएं प्रोसेस हो रही हैं...',
        previewCommand: 'निम्नलिखित कमांड को कार्यान्वित किया जाएगा',
        "userToken": "उपयोगकर्ता एपीआई कुंजी",
        "getToken": "कुंजी प्राप्त करें"
    },
};

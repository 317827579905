import {action, makeObservable, observable} from "mobx";

class MyInputStore{

    @observable options: any[];
    @observable loading = false;
    @observable inputValue: string;
    @observable timeout: NodeJS.Timeout;
    @observable open: Boolean;
    @observable isFocused: Boolean;
    @observable isChangedOnce: Boolean;

    //@observable value; // need for detect changes


    constructor(props) {
        makeObservable(this);
    }

    @action
    changeField(name: any, value: any) {
        this[name] = value
    }
}

export default MyInputStore;

import React, {useMemo} from "react";
import {
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Slider,
    TextField
} from "@material-ui/core";
import styles from "./myInput.module.scss";
import cn from 'classnames';
import MyInputInterface from "../../stores/interfaces/myInputInterface";
import {observer} from "mobx-react";
import {Autocomplete} from "@material-ui/lab";
import {toJS} from "mobx";
import Helper from "../../utils/helper";
import MyInputStore from "../../stores/myInputStore";
import {KeyboardDatePicker,} from '@material-ui/pickers';
import RootStore from "../../stores/rootStore";
import {InputBaseComponentProps} from "@material-ui/core/InputBase/InputBase";
import LANG, {useLangMemo} from "../../lang/language";

interface Props {
    store: MyInputInterface;
    item?: any;
    storeField?: string;
    field?: string;
    fields?: string[];
    index?: number;
    name?: string;
    type: string;
    className?: string;
    fieldId?: string;
    needSelectValue?: boolean;
    blockKey?: string;
    limitTags?: number;
    source?: any[];
    clearButton?: boolean;
    inputProperties?: InputBaseComponentProps;
    min?: number;
    max?: number;
    step?: number;
    onKey?: (event) => void;

    [key: string]: any;
}

function MyInput({
                     store,
                     storeField,
                     type,
                     field,
                     fields,
                     index,
                     name,
                     className,
                     size = 'small',
                     item,
                     needSelectValue = true,
                     isDisabled,
                     actions,
                     blockKey,
                     limitTags,
                     source,
                     clearButton = true,
                     inputProperties,
                     onKey,
                     ...props
                 }: Props) {
    // const forceUpdate = HelperService.useForceUpdate();
    let langPath = 'general';

    let value;
    let input;
    let getIcons;

    name = useLangMemo(name)

    let inputStore = useMemo(() => new MyInputStore({}), []);

    function getValue() {
        value = store.getFields(arrayFields, item, blockKey, {index});
    }

    function change(newValue) {
        let changeValue = true;
        if (newValue === '')
            switch (type) {
                case 'text_number':
                    //newValue = undefined; // if set undefined - looping occurs!
                    break;
                case 'autocomplete_many':
                case 'autocomplete_tags':
                    newValue = [];
                    break;
                case 'autocomplete':
                    newValue = {};
                    break;
                case 'dropdown':
                    newValue = null;
                    break;
                case 'dropdown_many':
                    newValue = [];
                    break;
            }
        else // parse values
            switch (type) {
                case 'text_number':
                    // if(newValue.includes('.'))
                    //     newValue = newValue.replace('.', ',');
                    let tmpValue = parseFloat(newValue);
                    /*if (tmpValue === 0 && (newValue.includes(',') || newValue.includes('.')))
                        changeValue = false;*/

                    newValue = tmpValue;
                    break;
            }

        if (changeValue)
            store.changeFields(/*arrayFieldsTarget || */arrayFields, newValue, item, blockKey, {index});

        if (value != undefined && newValue != value)
            inputStore.changeField('isChangedOnce', true)
        //forceUpdate();
    }

    function getOptionName(option?) {
        let optionStoreName = store.getOptionName && store.getOptionName({
            fields: arrayFields,
            option,
            item,
            blockKey
        })
        return optionStoreName || option?.name || '';
    }

    let arrayFields = [storeField, field];
    //let arrayFieldsTarget; // fields for changes value

    let parentForSubscribe = store.getFields([], item);

    if (item) {
        arrayFields = fields ? fields : [field];
        //arrayFieldsTarget = fieldsTarget ? fieldsTarget : undefined;
    } else {
        arrayFields = fields ? [storeField, ...fields] : [storeField, field];
        //arrayFieldsTarget = fieldsTarget ? [storeField, ...fieldsTarget] : undefined;
    }

    getValue();

    //check empty value and fix it
    if ((value === null || value === undefined) && type != 'date') {
        change('');
        //forceUpdate();
        getValue();
    }


    let isValueEmpty = Helper.isEmpty(value);
    // let disabled = store.isDisabled && store.isDisabled({field, fields, item});
    // if(disabled === undefined)
    //     disabled = isDisabled;
    let disabled = isDisabled || (store.isDisabled && store.isDisabled({field, fields, storeField, item, blockKey}));
    if (disabled === undefined)
        disabled = isDisabled;

    function getActions() {
        return actions.map(action => {
            let title, icon, disabled
            let order = 0;

            switch (action) {
                case 'open':
                    title = LANG.get([langPath, 'change']);
                    icon = 'search';
                    disabled = false;
                    if (!value /*inputStore.inputValue*/)
                        return;
                    break;
                case 'select':
                    title = LANG.get([langPath, 'selectFromList']);
                    icon = 'list';
                    disabled = false;
                    order = 1;
                    break;
                default:
                    return;
            }
            return <IconButton size='small'
                               onClick={() => store.onClick(action, {fields: arrayFields, item, blockKey})}
                               disabled={disabled}
                               style={{
                                   order: order, marginRight: `${props?.variant ? 0 : 4}px`
                               }}
                               title={title}>
                <span className={cn("material-icons", styles.icon)}>{icon}</span>
            </IconButton>
        });
    }


    // from Autocomplete - react does not allow to place the code in the conditions
    useMemo(() => {
        if (type === 'autocomplete' && !inputStore.inputValue) {
            inputStore.changeField('inputValue', value?.name)
        }
        if (type === 'autocomplete_text')
            inputStore.changeField('inputValue', value)
    }, []);
    useMemo(() => {
        if (type === 'autocomplete')
            inputStore.changeField('inputValue', getOptionName(value))
    }, [value]);


    switch (type) {
        case 'dropdown':
        case 'dropdown_many':
            let optionsField = source || inputStore.options || [];
            const getOptions =  async () => {
                let array = arrayFields.filter(el => el !== storeField);
                let res
                if(source)
                    res = source
                else
                    res = await store.getOptionsField({
                    storeField, field,
                    fields: array, key: null, item, blockKey
                });
                if (!inputStore.options || !Helper.isEqual(inputStore.options, res)) {
                    //let res = await store.getOptionsField(array, null, item);
                    inputStore.changeField('options', res);
                    //store.changeObjectField(storeField, field, res, 'options');
                }
            };
            if ((type === 'dropdown_many' && !isValueEmpty && value?.length > 0) || (type === 'dropdown' && !isValueEmpty))
                getOptions();

            input = <FormControl
                disabled={disabled}
                variant={props.variant || 'outlined'}
                style={{width: props.width}}
                size={size}
                className={cn(styles.inputDropdown)}
            >
                <InputLabel id="select-label" shrink={!isValueEmpty}>{name}</InputLabel>
                <Select
                    labelId="select-label"
                    value={value /*!isValueEmpty ? value : (type === 'dropdown_many' && [] || undefined)*/}
                    onChange={e => change(e.target.value)}
                    multiple={type === 'dropdown_many'}
                    label={name}
                    onOpen={() => {
                        getOptions();
                    }}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}
                    renderValue={(selected) => {
                        let name;
                        if (type === 'dropdown_many') {
                            return (selected as any[]).map((el, i, array) => {
                                name = Helper.getNameFromArr(optionsField, el, i == 0)
                                return name + (i + 1 !== array.length && ', ' || '');
                            })
                        } else
                            name = Helper.getNameFromArr(optionsField, selected, true)
                        return name;
                    }
                    }
                    {...props}>
                    {
                        optionsField.map(option => {
                            let val: any[] = toJS(value);
                            let name = LANG.get(option.name);
                            return <MenuItem key={name} value={option.value}>
                                {type === 'dropdown_many' && <Checkbox color="primary"
                                                                       checked={val?.findIndex(el => el === option.value) > -1}/>
                                }
                                <ListItemText primary={name}/>
                            </MenuItem>;
                        })
                    }
                </Select>
            </FormControl>;
            break;
        case 'autocomplete':
        case 'autocomplete_many':
        case 'autocomplete_text':
        case 'autocomplete_chip':
            const loadValues = () => {
                if (inputStore.loading)
                    return;
                if (inputStore.timeout) {
                    clearTimeout(inputStore.timeout);
                    inputStore.changeField('timeout', null);
                }

                let timeout = setTimeout(() => {
                    inputStore.changeField('loading', true);
                    (async () => {
                        let array = arrayFields.filter(el => el !== storeField);
                        let response = await store.getOptionsField({
                            storeField, field,
                            fields: array, key: inputStore.inputValue, item, blockKey
                        });
                        response = response || [];
                        if (type === 'autocomplete_chip') {
                            let foundIndex = response.findIndex(el => el.name == inputStore.inputValue);
                            if (foundIndex == -1 && inputStore.inputValue)
                                response.push({name: inputStore.inputValue})
                        }
                        inputStore.changeField('options', response);
                        inputStore.changeField('loading', false);
                    })();
                    inputStore.changeField('timeout', null);
                }, 1000);

                inputStore.changeField('timeout', timeout);
            }

            let options = inputStore.options || [];

            if (type === 'autocomplete' && !isValueEmpty && !options.find(el => el.name === value.name)) {
                options.unshift(value);
            }

            /*  useMemo(() => {
                  if (type === 'autocomplete' && !inputStore.inputValue) {
                      inputStore.changeField('inputValue', value?.name)
                  }
                  if (type === 'autocomplete_text')
                      inputStore.changeField('inputValue', value)

              }, []);

              if (type === 'autocomplete')
                  useMemo(() => inputStore.changeField('inputValue', getOptionName(value)), [value]);
  */

            getIcons = () => {
                if (actions && store.onClick)
                    return <>
                        {
                            getActions()
                        }
                    </>
            }

            let renderTags, freeSolo;

            if (type === 'autocomplete_chip') {
                renderTags = (value, getTagProps) =>
                    value.map((option, index) => {
                        let onClick;
                        let props = getTagProps({index});
                        if (option.id) {
                            onClick = e => {
                                let TagStore = require('../../stores/tagStore').default;
                                let id = option.id;
                                RootStore.getInstance().showEntity({
                                    id: id,
                                    show: true,
                                    store: new TagStore({
                                        id: id,
                                        isModal: true,
                                    })
                                })
                            };
                            props.onDelete = () => {
                                option.remove = !option.remove;
                                change('');
                                change(value);
                            }
                        }
                        return <Chip label={option.name} {...props} onClick={onClick} className={cn({
                            'o-4': option.remove
                        })}/>;
                    });
                freeSolo = true;
            }

            input = <Autocomplete
                multiple={['autocomplete_many', 'autocomplete_chip'].includes(type)}
                autoComplete
                value={value /*!isValueEmpty ?  : (type === 'autocomplete_many' && [] || undefined)*/}
                onChange={(e, newValue) => {
                    change(newValue);
                }}
                inputValue={inputStore.inputValue}
                onInputChange={(e, newValue) => {
                    if (e) {
                        inputStore.changeField('inputValue', newValue);
                        if (type === 'autocomplete_text' && !needSelectValue)
                            change(newValue);
                        /*if (newValue)*/
                        loadValues();
                    }
                }}
                disabled={disabled}
                freeSolo={freeSolo}
                disableClearable={['autocomplete_many'].includes(type) || isValueEmpty}
                disableCloseOnSelect={['autocomplete_many', 'autocomplete_chip'].includes(type)}
                getOptionSelected={(option: any, value) => {
                    if (!value)
                        return false;
                    if (type === 'autocomplete_text')
                        return option === value;
                    else {
                        return getOptionName(option) === getOptionName(value);
                    }
                }}
                getOptionDisabled={option => {
                    let opt = value?.constructor == Array ? value.find(el => getOptionName(el) === getOptionName(option)) : null;
                    return !!opt?.remove;
                }}
                getOptionLabel={(option: any) => {
                    if (type === 'autocomplete_text')
                        return option || '';
                    else {
                        return getOptionName(option);
                    }
                }}
                options={options}
                limitTags={limitTags || 2}
                open={!!inputStore.open}
                noOptionsText={LANG.get([langPath, 'nothingFound'])}
                loadingText={LANG.get([langPath, 'loading'])}
                size={size}
                onOpen={() => {
                    inputStore.changeField('open', true);
                    if (!inputStore.options || inputStore.options.length <= 1)
                        loadValues();
                }}
                onClose={() => {
                    inputStore.changeField('open', false);
                }}
                loading={inputStore.loading || !!inputStore.timeout}
                style={{width: props.width}}
                renderTags={renderTags}
                renderOption={(option, {selected}) => (
                    <React.Fragment>
                        {['autocomplete_many', 'autocomplete_chip'].includes(type) &&
                            !(type === 'autocomplete_chip' && !option.id) && <Checkbox
                                size='small'
                                color="primary"
                                icon={<React.Fragment><span
                                    className="material-icons">check_box_outline_blank</span></React.Fragment>}
                                checkedIcon={<React.Fragment><span
                                    className="material-icons">check_box</span></React.Fragment>}
                                style={{marginRight: 8}}
                                checked={selected}
                            />}
                        {type === 'autocomplete_chip' && !option.id &&
                            <span className={styles.addText}>Добавить:</span>}
                        {type === 'autocomplete_text' ? option : getOptionName(option)}
                    </React.Fragment>
                )}
                renderInput={(params) => {
                    if (!isValueEmpty && !['autocomplete_many', 'autocomplete_chip'].includes(type))
                        params.InputProps.startAdornment = getIcons();
                    return <TextField {...params}
                                      label={name} variant={props.variant || 'outlined'}
                                      className={cn(styles.inputAutocomplete)}/>
                }}
                ListboxProps={{
                    onScroll: (event: React.SyntheticEvent) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            console.log('load more');
                        }
                    }
                }}
                // freeSolo
                // renderTags={(value: string[], getTagProps) =>
                //     value.map((option: string, index: number) => (
                //         <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                //     ))
                // }
            />
            break;
        case 'date':
            if (value === '' || value === undefined)
                value = null;
            input = <KeyboardDatePicker
                value={value}
                //defaultValue={null}
                disabled={disabled}
                disableToolbar
                format={'dd.MM.yy HH:mm'}
                inputVariant={props.variant || 'outlined'}
                label={name}
                size={size}
                onChange={date => change(date)}
                style={{width: props.width}}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                {...props}
            />;
            break;
        case 'checkbox':
            input = <span className={cn(styles.checkbox)}>
                <Checkbox size='small' color="primary" checked={value} disabled={disabled}
                          onChange={(e, checked) => change(checked)} name={name}
                          {...props}/>
                <span onClick={() => change(!value)}>{name}</span>
            </span>
            break
        case 'slider':
            input = <Slider
                value={value}
                disabled={disabled}
                //getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={props.step}
                min={props.min}
                max={props.max}
                valueLabelDisplay="auto"
                onChange={(e, value) => change(value)} {...props}
                //marks={marks}
            />
            break
        case 'text':
        case 'text_multiline':
        case 'text_number':
        case 'text_action':
        case 'text_color':
        default:
            if (value === 0 && !inputStore.isFocused) {
                isValueEmpty = true;
                value = '';
            }

            let disabledClear = isValueEmpty || disabled;
            let typeText = 'text';

            if (!['text', 'text_multiline'].includes(type)) {
                let arr = type.split('_');
                typeText = arr[arr.length - 1];
            }

            if (type === 'text_actions')
                disabled = true;

            getIcons = () => {
                if (type === 'text_action')
                    return <>
                        {
                            getActions()
                        }
                        {/*{

                            !isValueEmpty && <IconButton size='small' onClick={() => store.onClick &&
                                store.onClick('open', {fields: arrayFields, item, blockKey})}
                                                         disabled={false}
                                                         style={{
                                                             order: 0, marginRight: `${props?.variant ? 0 : 4}px`
                                                         }}
                                                         title={'Открыть'}>
                                <span className={cn("material-icons", styles.icon)}>search</span>
                            </IconButton>
                        }
                        {
                            store.onClick && <IconButton size='small' onClick={() => store.onClick &&
                                store.onClick('select', {fields: arrayFields, item, blockKey})}
                                                         disabled={false}
                                                         style={{
                                                             order: 1, marginRight: `${props?.variant ? 0 : 4}px`
                                                         }}
                                                         title={'Выбрать из списка'}>
                                <span className={cn("material-icons", styles.icon)}>list</span>
                            </IconButton>
                        }*/}
                    </>
                else if (!isValueEmpty) {
                    return disabledClear || !clearButton ? <></> : <IconButton size='small' onClick={() => change('')}
                                       disabled={disabledClear}
                                       style={{
                                           order: 1, opacity: disabledClear ? 0 : undefined,
                                           marginRight: `${props?.variant ? 0 : 4}px`
                                       }}>
                        <span className={cn("material-icons", styles.icon)}>close</span>
                    </IconButton>
                }
            }

            let shrink = !isValueEmpty || ['date', 'color'].includes(typeText);

            input =
                <TextField value={value/*!isValueEmpty ? value : undefined*/} label={name} variant="outlined"
                           size={size}
                           style={{width: props.width}} multiline={type.includes('multiline')}
                           type={typeText}
                           InputLabelProps={{shrink, required: false}}
                           disabled={disabled}
                           onKeyPress={onKey}
                           InputProps={{
                               startAdornment: getIcons(),
                               inputProps: inputProperties
                           }}
                           onFocus={event => {
                               inputStore.changeField('isFocused', true);
                               setTimeout(() => {
                                   if (inputStore.isFocused)
                                       event.target.select()
                               }, 100);
                           }
                           }
                           onBlur={event => inputStore.changeField('isFocused', false)}
                           onChange={e => change(e.target.value)} {...props}/>;
            break;
    }

    /*let fieldsLine = fields?.join('_');
    console.log('Render myInput with name ' + fieldsLine);*/

    let errors = inputStore.isChangedOnce && store.getError && store.getError(arrayFields, item, blockKey)

    return <div className={`${cn('grid', styles.main)} ${className}`}>
        {input}
        {errors && <div className={`${cn('', styles.errors)}`}>
            {errors}
        </div>}
    </div>;

    /*    let iconClose = <MyIconButton iconM={'close'} onClick={() => store.clearField(storeField, field)} size='small'
                                      disabled={!value}/>;


        return <Button variant="contained" size='small' className={`${cn('grid', styles.main)} ${className}`} {...props}
                       disableRipple disableelevation onClick={() => store.clickField(storeField, field)} endIcon={iconClose}>
            <div className={cn('col-fixed', styles.name)}>{name}</div>
            <div className={cn('col', styles.inputWrap)}>{input}</div>
        </Button>*/

}

export default observer(MyInput);

import * as numeral from 'numeral';
import {toJS} from "mobx";
import RootStore from "../stores/rootStore";
// import queryString from "querystring";
import LANG, {LANGUAGES} from "../lang/language";
import moment from "moment";

export default class Helper {

    static init() {
        try {
            numeral.register('locale', 'ru', {
                delimiters: {
                    thousands: ' ',
                    decimal: ',',
                },
                abbreviations: {
                    thousand: 'тыс.',
                    million: 'млн.',
                    billion: 'млрд.',
                    trillion: 'трлн.',
                },
                ordinal: function () {
                    // not ideal, but since in Russian it can taken on
                    // different forms (masculine, feminine, neuter)
                    // this is all we can do
                    return '.';
                },
                currency: {
                    symbol: '₽',
                },
            });
        } catch (e) {

        }
        // switch between locales
        numeral.locale('ru');

    }


    static makeClone(from, target = null, curNesting = 0, exclude?, replace = true) {
        //let target = {}; // Создаем новый пустой объект
        if (!from)
            return target;
        const maxNesting = 8;
        ++curNesting;
        if (maxNesting == curNesting) {
            //--curNesting;
            return;
        }
        //--curNesting;
        if (from.constructor == Object) {
            if (!target)
                target = {};
            for (let prop in from) { // Перебираем все свойства копируемого объекта
                if (from.hasOwnProperty(prop)) { // Только собственные свойства
                    if (curNesting == 1 && exclude && exclude.indexOf(prop) != -1)
                        continue;
                    if (from && from._exclude && from._exclude.includes(prop))
                        continue;
                    if (from[prop] && (from[prop].constructor == Object || from[prop].constructor == Array)) // Если свойство так же объект или массив
                        target[prop] = this.makeClone(from[prop], target[prop], curNesting, exclude, replace); // Делаем клон свойства
                    else if (replace || (!replace && target[prop] === undefined))
                        target[prop] = from[prop]; // Или же просто копируем значение
                }
            }
        } else if (from.constructor == Array) {
            target = [];
            for (let i = 0; i < from.length; i++) {
                if (from[i] && (from[i].constructor == Object || from[i].constructor == Array)) // Если свойство так же объект или массив
                    target.push(this.makeClone(from[i], null, curNesting, exclude, replace)); // Делаем клон обьекта
                else
                    target[i] = from[i]; // Или же просто копируем значение
            }
        } else
            return from;

        return target;
    }

    static clear(target) {
        if (!target)
            return;
        for (const prop of Object.getOwnPropertyNames(target))
            delete target[prop];
    }

    static delay(ms: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(true), ms)
        });
    }

    static findValueInArr(array: { name: string, value }[], targetValue) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].value === targetValue)
                return array[i];
        }
        return;
    }

    static findByKeyInArr(array: any[], key, value) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] === value)
                return array[i];
        }
        return;
    }

    static firstUppercase(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    static getNameFromArr(array: { name: string, value }[], targetValue, firstUppercase = true) {
        let res = this.findValueInArr(array, targetValue);
        let name = LANG.get(res.name);
        return res ? (firstUppercase ? this.firstUppercase(name) : name) : '';
    }

    static removeFromArray(array, values) {
        if (!array || !values) return false;

        let countDone = 0;
        for (let i = 0; i < values.length; i++) {
            let value = values[i];
            let index = array.indexOf(value);
            if (index > -1) {
                array.splice(index, 1);
                countDone++;
            }
        }
        return countDone;
    }

    static isEmpty(target) {
        if (target != undefined && target != null && !Number.isNaN(target)) {
            if (target.constructor == Array) {
                if (target.length > 0)
                    return false;
            } else if (target.constructor == Object) {
                target = toJS(target);
                if (Object.keys(target).length > 0)
                    return false;
            } else if (target.constructor == String) {
                if (target.length > 0)
                    return false;
            } else
                return false;
        }
        return true;
    }

    static isEmptyObject(object, {exclude, include}: any = {}) {
        if (!object)
            return true;

        if (include) {
            for (let i = 0; i < include.length; i++) {
                let name = include[i];
                if (object[name] != undefined && object[name] != null && object[name] != '')
                    return false;
            }
            return true;
        }

        let arr = Object.keys(object);
        if (arr.length < 1)
            return true;
        for (let i = 0; i < arr.length; i++) {
            if ((exclude && exclude.some(value => value === arr[i])) || object[arr[i]] === undefined || object[arr[i]] === '' || object[arr[i]] === null ||
                (object[arr[i]] instanceof Object && !this.isEmptyObject(object[arr[i]], {exclude})))
                continue;
            return false
        }
        return true;
    }

    /*static useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update the state to force render
    }*/

    static round(number, digits = 2) {
        if (!number)
            return number;
        if (!this.is(number, 'Number'))
            number = parseFloat(number)
        return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    static percent(number, digits = 2) {
        return this.round(number * 100, digits);
    }

    static getRandomColor() {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }

    static getRandom(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    static isEqual(array1, array2) {
        return JSON.stringify(array1) === JSON.stringify(array2);
    }

    static removeFields(array: any[], fields: any[], parent = undefined) {
        return array.filter(el => {
            let arrayField = [];
            if (parent) {
                if (parent.field)
                    arrayField.push(parent.field);
                else if (parent.key)
                    arrayField.push(parent.key);
            }

            if (el.field)
                arrayField.push(el.field);
            else if (el.key)
                arrayField.push(el.key);

            for (let i = 0; i < fields.length; i++) {
                let field: any = fields[i];
                // convert string to array
                if (field.constructor == String) {
                    field = [field];
                }

                if (Helper.isEqual(arrayField, field))
                    return false;

                if (el.items)
                    el.items = this.removeFields(el.items, fields, el);
            }
            return true;
        });
    }

    static changeFields(array: any[], fields: any[], newValues: any, parent = undefined) {
        return array.map(el => {
            let arrayField = [];
            if (parent) {
                if (parent.field)
                    arrayField.push(parent.field);
                else if (parent.key)
                    arrayField.push(parent.key);
            }

            if (el.field)
                arrayField.push(el.field);
            else if (el.key)
                arrayField.push(el.key);

            for (let i = 0; i < fields.length; i++) {
                let field: any = fields[i];
                // convert string to array
                if (field.constructor == String) {
                    field = [field];
                }

                if (Helper.isEqual(arrayField, field)) {
                    Helper.copyFields(el, newValues);
                }

                if (el.items)
                    el.items = this.changeFields(el.items, fields, newValues, el);
            }
            return el;
        });
    }

    private static copyFields(target: any, from: any) {
        let keys = Object.keys(from);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            target[key] = from[key];
        }
    }

    static copyToClipboard(text: string) {
        let input = document.createElement('textarea');
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        let result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    static async getFromClipboard() {
        const text = await navigator.clipboard.readText();
        return text;
    }

    static filterFields(items: any[], {targetUser, parent, parentFields}: any) {
        if (!targetUser)
            targetUser = RootStore.getInstance().userService.user;

        if (!targetUser) {
            items.length = 0;
            return items;
        }

        if (!targetUser.ownerId) // is owner
            return items.map(el => {
                el.disabled = false;
                el.items?.map(el2 => {
                    el2.disabled = false;
                    return el2;
                })
                return el;
            });

        if (!targetUser.fields || targetUser.fields.length === 0) {
            items.length = 0;
            return items;
        }

        const skipFields = ['title_sub', 'separator', 'title', 'only_head'];
        return items.filter(el => {
            let arrayFields = [...parentFields];

            if (skipFields.includes(el.type))
                return true;

            if (el.items) {
                let itemsNew = this.filterFields(el.items, {targetUser, parent: el, parentFields: arrayFields});
                itemsNew = itemsNew.filter((item, i, array) => {
                    let nextItem = array.length > i + 1 ? array[i + 1] : null;
                    if (skipFields.includes(item.type) && (!nextItem || skipFields.includes(nextItem.type)))
                        return false;
                    return true;
                })
                el.items = itemsNew;
                if (el?.isArray && el.items.filter(item => !skipFields.includes(item.type) && !item.disabled).length === 0) {
                    el.allowChange = false;
                }
                if (el.items.length === 0)
                    return false;
            } else {
                if (parent?.isArray) {
                    if (parent.field)
                        arrayFields.push(parent.field);
                    else if (parent.fields)
                        arrayFields.push(...parent.fields);
                    else if (parent.key)
                        arrayFields.push(parent.key);
                }

                if (el.field)
                    arrayFields.push(el.field);
                else if (el.fields)
                    arrayFields.push(...el.fields);

                let value = targetUser.fields;
                if (Helper.isEmpty(value)) {
                    return false;
                }
                for (let i = 0; i < arrayFields.length; i++) {
                    let field = arrayFields[i];
                    value = value[field];
                    if (Helper.isEmpty(value)) {
                        return false;
                    }
                    if (i === arrayFields.length - 1) {
                        if (!value?.v) {
                            return false;
                        }
                        if (!value?.c) {
                            el.disabled = true;
                        }
                    }
                }
            }
            return true;
        });
    }

    static hasRight(right, targetUser?) {
        if (!targetUser)
            targetUser = RootStore.getInstance().userService.user;

        if (!targetUser)
            return false;

        if (!targetUser.ownerId) // is owner
            return true;

        if (!targetUser.rights || targetUser.rights.length === 0) {
            return false;
        }

        return targetUser.rights.includes(right);
    }

    static parseQueryString(string): any {
        let parsed = {}
        if (string != '') {
            string = string.substring(string.indexOf('?') + 1)
            let p1 = string.split('&')
            p1.map(function (value) {
                let params = value.split('=')
                parsed[params[0]] = params[1]
            });
        }
        return parsed
    }

    static fillFieldsBeforeSave(entity: any, blocks: any[]) {
        function work(item, block) {
            for (let j = 0; j < block.items.length; j++) {
                let blockItem = block.items[j];

                if (!['autocomplete', 'text_number', 'date', 'autocomplete_many'].includes(blockItem.type))
                    continue;


                let fields = blockItem.fields || [blockItem.field];
                let fieldParent = item;
                for (let i = 0; i < fields.length; i++) {
                    let name = fields[i];
                    if (!fieldParent)
                        break;
                    if (i === fields.length - 1) {
                        switch (blockItem.type) {
                            case 'autocomplete_many':
                                fieldParent[name] = fieldParent[name]?.map(el => el.id) || null;
                                break;
                            case 'autocomplete':
                                if (fieldParent[name] && fieldParent[name].id) {
                                    fieldParent[name + 'Id'] = fieldParent[name].id;
                                } else {
                                    fieldParent[name + 'Id'] = null;
                                }
                                break;
                            case 'date':
                                fieldParent[name] = fieldParent[name] || null;
                                break;
                            case 'text_number':
                                if (fieldParent[name] == '')
                                    delete fieldParent[name];
                                break;
                        }
                    } else {
                        fieldParent = fieldParent[name];
                    }
                }
            }
        }

        for (let i = 0; i < blocks.length; i++) {
            let block = blocks[i];
            if (!block.items)
                continue;

            if (block.isArray) {
                let array = entity[block.field];
                if (!array)
                    entity[block.field] = [];
                else
                    for (let k = 0; k < array.length; k++) {
                        let item = array[k];
                        work(item, block);
                    }
            } else
                work(entity, block);

        }
    }

    static isToday(date) {
        const today = new Date()
        return date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
    }


    static formatDate(value: any, short = false, params?: { sec: boolean }) {
        if (!value)
            return '';
        let date;
        if (value.constructor === String)
            date = new Date('' + value);
        else if (value.constructor === Number) { // @ts-ignore
            date = new Date(value);
        } else if (value.constructor === Date) {
            // @ts-ignore
            if (isNaN(value))
                return '';
            date = value
        } else
            date = new Date();

        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 101).toString().substring(1);
        let day = (date.getDate() + 100).toString().substring(1);
        let hour = ("0" + date.getHours()).slice(-2).toString();
        let min = ("0" + date.getMinutes()).slice(-2).toString();
        let sec = ("0" + date.getSeconds()).slice(-2).toString();

        if (params) {
            if (!params.sec) {
                sec = ""
            }
        }

        if (short && this.isToday(date))
            return hour + ':' + min + (sec ? ':' + sec : '');
        else
            return day + "." + month + "." + year + " " + hour + ':' + min + (sec ? ':' + sec : '');

        //return  date.toLocaleString('ru-RU');
    }

    static mapColumns(columns, columnsMob) {
        let res = columnsMob.map((el, index) => {
            let name = el.n;
            if (name === 'separator')
                return {type: 'separator', field: 'separator'};
            if (name.constructor == Array)
                name = name.join('_')

            let column = columns.find(el1 => {
                if (!el1.fieldsLine) el1.fieldsLine = el1.fields ? el1.fields.join('_') : el1.field;
                return el1.fieldsLine === name;
            });

            if (!column)
                return;

            switch (column.fieldsLine) {
                case 'actions':
                    column.actions = column.actions?.filter(el1 => {
                        let name = el1.key || el1.iconM;
                        return !['edit'].includes(name);
                    })
                    break;
            }

            if (el.c)
                column.columns = this.mapColumns(column.columns, el.c);

            column.size = el.s;
            column.nameM = el.nameM
            column.overflow = el.o

            return column;
        });
        res = res.filter(el => !!el);
        return res;
    }

    /*  static getQueryParams() {
          return queryString.parse(window.location.search.substring(1));
      }*/

    static getCompanyName(company: any) {
        return company?.nameShort || company?.name;
    }

    static getFromArray(array, index = 0, defaultVar = null) {
        return array?.length > index ? array[index] : defaultVar;
    }

    static isArray(res: any) {
        return this.is(res, 'Array')
    }

    static is(object: any, name) {
        return object?.constructor.name === name;
    }

    static getSize(bytes) {
        if(bytes == null || bytes == "")
            return ""
        if (this.is(bytes, 'String'))
            bytes = parseInt(bytes)
        if (bytes == 0) {
            return "0.00 B";
        }
        let e = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
    }

    static getDifferenceDate(target: any, dateTo: any = Date.now()) {
        if (!target)
            return undefined

        if (!this.is(target, 'Date'))
            target = new Date(target)

        if (!this.is(dateTo, 'Date'))
            dateTo = new Date(dateTo)

        let result = dateTo - target
        return result / (60 * 1000)
    }

    static getMessageError(error) {
        if (this.is(error, 'String')) {
            try {
                error = JSON.parse(error)
            } catch (e) {
                return error
            }
        }
        return error.message || error.errors?.map(el => el.message || '').join('; ') || JSON.stringify(error, null, 1) || ''
    }

    static getKey(entity, key) {
        if (this.is(key, 'Function'))
            return key(entity)
        else
            return entity[key]

    }

    static emulateSort(array, query: any) {
        let keys = Object.keys(query.sort)
        let result = array.sort((el1, el2) => {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = query.sort[key];

                let res = 0
                if (Helper.is(el1[key], 'String')) {
                    let nA = el1[key].toLowerCase();
                    let nB = el2[key].toLowerCase();
                    //res = nA.compare(nB)
                    res = (nA < nB ? -1 : (nA > nB ? 1 : 0))
                    if (value === 'DESC')
                        res = Math.abs(value)
                } else {
                    if (value === 'ASC')
                        res = el1[key] - el2[key]
                    else
                        res = el2[key] - el1[key]
                }
                return res
            }
            return 0
        })
        return result
    }

    static isObject(v) {
        return '[object Object]' === Object.prototype.toString.call(v);
    };

    static sortJson(o) {
        if (Array.isArray(o)) {
            return o.sort().map(Helper.sortJson);
        } else if (Helper.isObject(o)) {
            return Object
                .keys(o)
                .sort()
                .reduce(function (a, k) {
                    a[k] = Helper.sortJson(o[k]);

                    return a;
                }, {});
        }
        return o;
    }

    static sleep(miliseconds) {
        let currentTime = new Date().getTime();

        while (currentTime + miliseconds >= new Date().getTime()) {
        }
    }
}

export function filterFalse(list: any[]) {
    return list.filter(el => el)
}

export function calcRowSpan(list: any[]) {
    let targetItem;
    for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item.rowSpan != undefined) {
            targetItem = item;
            item.rowSpan = 1;
        } else if (targetItem) {
            targetItem.rowSpan += 1;
        }
    }
    return list;
}

export function getLanguageURL() {
    let path = window.location.pathname.split('/')
    let languageURL = path.length >= 1 ? path[1] : ''
    if (languageURL && !LANGUAGES.find(el => el.value == languageURL)) {
        languageURL = ''
    }
    return languageURL
}


export function replaceHTML(text) {
    if (!text)
        return text
    if (Helper.isArray(text))
        return text
    let result = text
    try {
        result = result.replaceAll('&lt;', '<')
        result = result.replaceAll('&gt;', '>')
    } catch (e) {

    }
    return result
}

import {observer} from "mobx-react";
import React, {useEffect, useRef, useState} from "react";

function FakeInput({id, show, x, y}) {
    let ref = useRef(null)

    useEffect(() => {
        if (ref) {
            if (show)
                ref.current.focus()
        }
        return () => {
        }
    }, [show, ref])

    //let [value, setValue] = useState('')

    return (
        <input ref={ref} id={id} type="text"
               onKeyDown={event => {
                   console.log('fake onKeyDown')
                   //event.preventDefault()
                   //onInput(event)
               }}
               onKeyUp={event => {
                   console.log('fake onKeyUp')
                   //event.preventDefault()
                   //window.dispatchEvent(event);
                   //onInput(event)
               }}
               onInput={event => {
                   console.log('fake onInput', event)
                   //event.preventDefault()
                   //window.dispatchEvent(event)
                   //onInput(event)
               }}  autoCapitalize="none"
               style={{
                   left: x,
                   top: y,
                   width: '10px',
                   // height: '1px',
                   opacity: 0.01,
                   overflow: 'hidden',
                   color: 'transparent',
                   //textTransform: 'lowercase',
                   position: 'absolute',
                   display: show ? 'initial' : 'hidden'
               }}/>
    )
}

export default observer(FakeInput);

import NotifyService from "./notify.service"
import {constants} from "./constants"
import CryptoService from "./crypto.service"
import {Wallet} from "ethers"
import Helper from "./helper"
import APIService from "./api.service"
import EventBusLocal from "./eventBusLocal"
import EventBus from "./eventBus";

export enum RTCConnectionState {
    prepare,
    connecting, // where receive answer from offer
    connected,
    disconnected,
    error
}

export default class WebRTCConnection {
    peerConnection: RTCPeerConnection
    events = new EventBusLocal()

    stationId
    connectionId

    subscriptions = []

    state: RTCConnectionState
    pendingCandidates = [];

    constructor() {

    }


    async init({stationId}) {
        this.stationId = stationId
        this.changeState(RTCConnectionState.prepare)

        let config: RTCConfiguration = {
            // sdpSemantics: 'unified-plan'
            iceServers: [
                {urls: "stun:stun.l.google.com:19302"},
                {urls: "stun:stun.cusco-rc.com:3478"},
                {urls: "turn:turn.cusco-rc.com:3478", username: "sharedcuscouser", credentialType: "password", credential: "sharedcuscopassword"},
            ]
        }

        this.peerConnection = new RTCPeerConnection(config)

        // register some listeners to help debugging
        this.peerConnection.addEventListener('icegatheringstatechange', () => {
            console.log('iceGatheringState ' + this.peerConnection.iceGatheringState)
        }, false)

        let findSelected = stats =>
            [...stats.values()].find(s => s.type == "candidate-pair" && s.state == "succeeded");

        let timeout;
        this.peerConnection.addEventListener('iceconnectionstatechange', () => {
            console.log('iceConnectionState ' + this.peerConnection.iceConnectionState)
            clearTimeout(timeout)
            switch (this.peerConnection.iceConnectionState) {
                case "checking":
                    break;
                case "closed":
                    break;
                case "completed":
                    break;
                case "connected":
                    this.changeState(RTCConnectionState.connected)
                    this.peerConnection.getStats().then(stats => {
                        let candidate = stats.get(findSelected(stats)?.localCandidateId);
                        if (!candidate)
                            return console.log("Active candidate not found: ");

                        if (candidate.candidateType == "relay") {
                            console.log("Uses TURN server: " + candidate.url, candidate);
                        } else {
                            console.log("Does not use TURN, uses ", candidate);
                        }
                    })
                    break;
                case "disconnected":
                    this.changeState(RTCConnectionState.connecting)
                    timeout = setTimeout(() => {
                        this.changeState(RTCConnectionState.disconnected)
                    }, 3000);
                    break;
                case "failed":
                    this.changeState(RTCConnectionState.disconnected)
                    break;
                case "new":
                    break;

            }
        })

        this.peerConnection.addEventListener('signalingstatechange', () => {
            console.log('signalingState ' + this.peerConnection.signalingState)
            switch (this.peerConnection.signalingState) {
                case "stable":
                    //this.events.publish("state", RTCConnectionState.connected)
                    break;
            }
        })

        // this.peerConnection.addEventListener('icecandidate', async event => {
        //     console.log('Local ICE candidate: ', event.candidate, ' state ', this.state);
        //     if (!event.candidate)
        //         return;
        //    /* if (!this.peerConnection.remoteDescription) {
        //         this.pendingCandidates.push(event.candidate)
        //     } else {
        //         await this.sendCandidates([event.candidate]);
        //     }*/
        // });


        this.subscriptions.push(
            //EventBus.subscribe('connect', () => !this.firstUpdate && this.entitiesStore.refresh({})),
            EventBus.subscribe(constants.SOCKET_WRTC_CANDIDATES, async (data) => {
                if (data?.connectionId === this.connectionId) {
                    console.log('3. RECEIVE candidates: ', data);
                    for (const candidate of data.candidates) {
                        let res = await this.peerConnection.addIceCandidate(candidate)
                        console.log('local add candidate: ', candidate)
                    }
                }
            }),
        )

        this.peerConnection.addEventListener('track', (e) => {
            console.log(`ON track`, e);
            /*if (this.remoteVideo.srcObject !== e.streams[0]) {
                this.remoteVideo.srcObject = e.streams[0];*/
            if (e.track.kind == 'video') {
                console.log('4. received remote stream');
                //this.onVideoSrc();
                //const remoteVideo: any = document.getElementById('remoteVideo');
                this.events.publish('newVideoStream', e.streams[0]);
            }
        }, false)

        this.peerConnection.addEventListener('datachannel', event => {
            console.log(`ON datachannel`, event);
            let receiveChannel = event.channel;
            receiveChannel.onmessage = (message) => {
                //console.log(`ON datachannel onmessage`, message);
            };
            receiveChannel.onopen = () => {
                console.log(`ON datachannel onopen`);
            };
            receiveChannel.onclose = () => {
                console.log(`ON datachannel onclose`);
            };
        });


        //Helper.sleep(1000);

        /*console.log('init chat1')
        const dc = this.peerConnection.createDataChannel('chat1', {
            negotiated: true,
            id: 0
        });
        dc.onclose = () => {
            console.log('webrtc chat1 - close')
        };
        dc.onopen = () => {
            console.log('webrtc chat1 - open')
        }
        dc.onerror = (error) => {
            console.log('webrtc chat1 error', error)
        }*/

        this.events.publish("initConnection")
        await this.subscribe_station();
    }


    private async sendCandidates(candidates: any[]) {
        console.log('3. SEND Candidates ')
        console.log(candidates)
        let answer = await APIService.getInstance().sendWebRTCCandidate({
            stationId: this.stationId,
            connectionId: this.connectionId,
            candidates: candidates
        })
    }

    async subscribe_station() {
        let query: any = {};
        query.stationIds = [this.stationId]
        query.dataType = 'webrtc'
        query.enable = true

        let answer: any = await APIService.getInstance().subscribeStations(query);
    }

    async unsubscribe_station() {
        if (!this.stationId) return
        let query: any = {};
        query.stationIds = [this.stationId]
        query.dataType = 'webrtc'
        let answer: any = await APIService.getInstance().unsubscribeStations(query);
    }

    async connect({stationId}) {
        try {
            await this.unsubscribe()

            await this.init({stationId})

            this.connectionId = '' + Helper.getRandom(100_000, 999_999)

            let offer = await this.peerConnection.createOffer({
                offerToReceiveVideo: true,
                iceRestart: true
            });
            await this.peerConnection.setLocalDescription(offer);
            console.log('1 OFFER prepare', offer)

            let offerSends = false;
            let sendOffer = async () => {
                offerSends = true

                offer.sdp = this.peerConnection.localDescription.sdp;

                console.log('1 OFFER ready', offer)
                this.changeState(RTCConnectionState.connecting)
                let answer = await APIService.getInstance().sendWebRTCOffer({
                    stationId: this.stationId,
                    connectionId: this.connectionId,
                    offer
                })

                console.log('2 ANSWER OFFER', answer)
                if (!answer.result)
                    throw new Error('Answer is empty');

                await this.peerConnection.setRemoteDescription(answer.result)
            }

            // let timeout = setTimeout(() => {
            //     console.log('sends by timeout')
            //     sendOffer()
            // }, 10000)

            this.peerConnection.onicecandidate = async ({candidate}) => {
                console.log('Local ICE candidate: ', candidate);
                if (candidate) return;

                if(offerSends){
                    await this.sendCandidates([candidate]);
                } else {
                    //clearTimeout(timeout);
                    console.log('sends by done gathering')
                    await sendOffer();
                }
            };

        } catch (error) {
            NotifyService.error('Error when establishing a p2p connection with a remote PC', {error})
            this.changeState(RTCConnectionState.error)
        }
    }

    createDataChanel(name: string, type: DataChanelTypes) {
        return new DataChanel(name, type, this.peerConnection);
    }

    async request(path, query, props?: { sign: boolean | string, wallet?: Wallet }): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (props?.sign) {
                if (Helper.is(props.sign, 'String')) {
                    let target = query['' + props.sign]
                    if (Helper.is(target, 'Array')) {
                        for (let i = 0; i < target.length; i++) {
                            let object = target[i];
                            target[i] = await CryptoService.signQuery(object, props.wallet);
                        }
                        /* for (const object of target) {
                             query = await CryptoService.signQuery(object, props.wallet);
                         }*/
                    } else
                        query = await CryptoService.signQuery(target, props.wallet)
                } else
                    query = await CryptoService.signQuery(query, props.wallet)
            }
            /*this.socket.emit(path, query, (answer: any) => {
                if (answer.error) {
                    NotifyService.prettyError(answer.error)
                    if (answer.error?.status === 401) {
                        UserServiceStore.getInstance().logout()
                    }
                    reject(answer.error)
                } else {
                    console.log('' + path + ' res ' + answer)
                    resolve(answer)
                }
            })*/
        })/*.catch(error => {
            console.error(error)
        })*/
    }

    async disconnect() {
        console.log('disconnect manual')
        await this.unsubscribe();
        //this.unsubscribe()
    }

    async unsubscribe() {
        this.peerConnection?.close()
        this.subscriptions.forEach(el => el.unsubscribe())
        this.subscriptions = []
        await this.unsubscribe_station()
    }

    private changeState(state: RTCConnectionState) {
        this.state = state
        this.events.publish("state", state)
    }
}

export enum DataChanelTypes {
    Message,
    Data,
}


export enum DataChanelStatus {
    Close,
    Open,
}

export class DataChanel {
    status: DataChanelStatus = DataChanelStatus.Close
    name: string
    lastId = 0
    type: DataChanelTypes

    dc: RTCDataChannel

    messages: DCMessage[] = []

    private cbMessage: (data: Object) => void
    private cbData: (data: ArrayBuffer) => void
    private cbOpen: () => void
    private cbClose: () => void

    //callbackMessage: (message: MessageEvent) => void

    constructor(name: string, type: DataChanelTypes, peerConnection: RTCPeerConnection) {
        this.type = type

        this.dc = peerConnection.createDataChannel(name)

        this.dc.addEventListener('close', () => {
            console.log(`Data chanel ${name} - close`)
            this.status = DataChanelStatus.Close
            this.cbClose && this.cbClose()
        })

        this.dc.addEventListener('open', () => {
            console.log(`Data chanel ${name} - open`)
            this.status = DataChanelStatus.Open
            this.cbOpen && this.cbOpen()
        })

        this.dc.onmessage = (evt) => {
            //console.log(`Data chanel ${name} - data`, evt)

            switch (this.type) {
                case DataChanelTypes.Message:
                    let message: DCMessage = JSON.parse(evt.data);
                    if (!Helper.isEmpty(message.id)) {
                        let pendingMessage = this.messages.find(el => el.id === message.id);
                        if (!pendingMessage) {
                            NotifyService.error("Not found pending message id")
                        } else {
                            pendingMessage.callback(message.data)
                        }
                    } else
                        this.cbMessage(message)
                        //NotifyService.error("Not message id set")
                    break;
                case DataChanelTypes.Data:
                    this.cbMessage(evt.data)
                    break;
            }
        }
    }

    send(message) {
        if (Helper.isObject(message))
            message = JSON.stringify(message)
        if (this.dc.readyState != "open") {
            console.error("readyState not open")
            return
        }
        this.dc.send(message)
    }

    close() {
        this.dc.close()
    }

    send_with_ask(event: String, data: Object, callback: (answer: any) => void) {
        /*console.log('send_with_ask ' + event + ' data: ')
        console.log(data)*/
        let message: DCMessage = {
            id: this.getNextId(),
            event,
            data,
            callback
        }
        this.send(message)
        this.messages.push(message)
    }

    getNextId() {
        return ++this.lastId
    }

    onMessage(callback: (data) => void) {
        this.cbMessage = callback
    }

    onData(callback: (data) => void) {
        this.onData = callback
    }

    onOpen(callback: () => void) {
        this.cbOpen = callback
    }

    onClose(callback: () => void) {
        this.cbClose = callback
    }
}

export class DCMessage {
    id: number
    event: String
    data: any
    callback?: (answer) => void
}
import {observer} from "mobx-react";
import React, {useMemo} from "react";
import styles from './entityData.module.scss';
import cn from 'classnames';
import RootStore from "../../stores/rootStore";
import MyIconButton from "../myIconButton/myIconButton";
import MyInput from "../myInput/myInput";
import {Button, CircularProgress} from "@material-ui/core";
import MyMenu from "../myMenu/myMenu";
import EntityInterface from "../../stores/interfaces/entityInterface";
import MyHistory from "../../utils/myHistory";
import Sections from "../sections/sections";
import MyText from "../myText/myText";
import TableObject from "../tableObject/tableObject";
import {isMobile} from "react-device-detect";
import LANG from "../../lang/language";

function EntityData(props) {
    const rootStore = RootStore.getInstance();
    let store: EntityInterface = props.store;

    useMemo(() => store?.refresh && store?.refresh({}), [store]);

    if (!store) // fixed modal error
        return <div/>;

    let langPath = 'general';
    return (
        <div className={cn(styles.main, '', {
            [styles.short]: store.isShort
        })}>
            {!store.isShort &&
                <div className={cn(styles.lineMain, 'grid')}>
                    {
                        isMobile && <div className={cn('col-fixed')}>
                            <MyIconButton iconM='chevron_left' title={LANG.get([langPath, 'back'])}
                                          className={cn()}
                                          onClick={() => {
                                              if (store.isModal) {
                                                  props.close();
                                              } else {
                                                  MyHistory.goBack();
                                              }
                                          }
                                          }/>
                        </div>
                    }
                    <div className={cn(styles.dataName, 'col', {
                        'f-c': isMobile
                    })}>
                        {store.name}
                    </div>
                    <div className={cn('col-fixed')}>

                        {
                            store.menuItems.length > 0 && <MyMenu items={store.menuItems}>
                                {
                                    isMobile ?
                                        <MyIconButton iconM='more_vert' color="primary" variant="contained"
                                                      className={cn(styles.dataButton)}/>
                                        : <MyIconButton iconM='more_vert' text={LANG.get([langPath, 'actions'])}
                                                        color="primary"
                                                        className={cn(styles.dataButton)}/>
                                }
                            </MyMenu>
                        }

                    </div>
                </div>
            }

            <div className={cn(styles.lineBody)}>
                {
                    store.refreshing && <div className={cn('w-100 f-c', styles.wrapInfo)}>
                        <CircularProgress size={50}/>
                    </div>
                }
                {
                    !store.refreshing && !store.entityEdited && <div className={cn('w-100 f-c', styles.wrapInfo)}>
                        {LANG.get([langPath, 'nothingFound'])}
                    </div>
                }

                <div className={cn(styles.lineData)}>

                    {
                        store.blocks?.map(block => {
                            function getMenuItems({item, i = -1, full = false}) {

                                const actions = block.menuItems.filter(el => el.singly);
                                const menuActions = block.menuItems.filter(el => !el.singly);

                                return <div className={cn('col-fixed', styles.arrayMenu)}>
                                    {
                                        menuActions.length > 0 && <MyMenu items={menuActions}
                                                                          propsClick={{item, i}}>
                                            {
                                                isMobile ?
                                                    <MyIconButton iconM='more_vert' size='small'
                                                                  className={cn(styles.dataButton)}/>
                                                    : <MyIconButton iconM='more_vert' size='small'
                                                                    text={full && LANG.get([langPath, 'actions'])}
                                                                    className={cn(styles.dataButton)}/>
                                            }
                                        </MyMenu>
                                    }
                                    {
                                        actions.length > 0 && actions.map(el =>
                                            <MyIconButton key={LANG.get(el.name)} iconM={el.iconM}
                                                          className={cn(styles.tableAction)}
                                                          size='small'
                                                          title={LANG.get(el.name)} disable={el.disable}
                                                          onClick={() => el.onClick({item, i})}/>
                                        )
                                    }
                                </div>;
                            }

                            function getArrayData() {
                                function getElementByType(el, item) {
                                    let menuItems;
                                    let colSize;

                                    if (!isMobile)
                                        colSize = 'col-' + (el.size || 4);
                                    else
                                        colSize = 'col-12';

                                    if (el.type === 'separator_col') {
                                        let className = 'col';
                                        if (el.size)
                                            className = 'col-' + el.size;
                                        return <div key={el.key}
                                                    className={cn(className, styles.separatorCol)}/>;
                                    }
                                    if (el.type === 'separator')
                                        return <div key={el.key}
                                                    className={cn('break', styles.separator)}/>;
                                    if (el.type === 'sections')
                                        return <div key={el.field || el.fields?.concat('_')}
                                                    className={cn(colSize, styles.blockItem)}>
                                            <Sections fields={el.fields}
                                                      name={LANG.get(el.name)}
                                                      isDisabled={item.remove || el.disabled}
                                                      store={store}
                                                      item={item}
                                                      variant="standard"
                                                      width='100%'
                                                      size="medium"/>
                                        </div>;

                                    if (el.type === 'title' || el.type === 'title_sub') {
                                        menuItems = el.type === 'title' && block.menuItems &&
                                            <div key={el.field || el.fields?.concat('_')}
                                                 className={cn('col-fixed', styles.blockItemTitle)}>
                                                {
                                                    getMenuItems({item, full: true})
                                                }
                                            </div>;
                                        colSize = 'col';
                                        return <>
                                            <div key={el.key}
                                                 className={cn('break', styles.separator)}/>
                                            <div key={el.field || el.fields?.concat('_')}
                                                 className={cn(colSize, styles.blockItemTitle)}>
                                                <MyText type={el.type} field={el.field} fields={el.fields}
                                                        name={LANG.get(el.name)} isDisabled={el.disabled}
                                                        blockKey={block.key} item={item}
                                                        store={store} storeField='entityEdited'
                                                        width='100%'/>
                                            </div>
                                            {
                                                menuItems
                                            }
                                            <div key={el.key}
                                                 className={cn('break', styles.separator)}/>
                                        </>;
                                    }

                                    return <div key={el.field || el.fields?.concat('_')}
                                                className={cn(colSize, styles.blockItem)}>
                                        <MyInput sectionName={el.key}
                                                 type={el.type}
                                                 fields={el.fields}
                                                 limitTags={el.limitTags}
                                            //fieldsTarget={el.fieldsTarget}
                                                 field={el.field}
                                                 name={LANG.get(el.name)}
                                                 isDisabled={item.remove || el.disabled}
                                                 needSelectValue={el.needSelectValue}
                                                 placeholder={el.placeholder}
                                                 actions={el.actions}
                                                 store={store}
                                                 item={item}
                                                 blockKey={block.key}
                                                 variant="standard"
                                                 width='100%'
                                                 size="medium"/>
                                    </div>;
                                }

                                if (!store.entityEdited[block.field] || store.entityEdited[block.field].length === 0)
                                    return <div className={cn('col-12 grid', styles.blockArrayItemNoData)}>
                                        {LANG.get([langPath, 'noData'])}
                                    </div>
                                else {
                                    let hasTitle = block.items.length > 0 && block.items[0] && block.items[0].type === 'title';
                                    return store.entityEdited[block.field].map((item, i) => {

                                        let colSize;

                                        if (!isMobile) {
                                            if (block.size)
                                                colSize = 'col-' + (block.size || 4);
                                            else if (!block.thin)
                                                colSize = 'col-12';
                                            else
                                                colSize = 'col';
                                        } else
                                            colSize = 'col-12';

                                        return <div key={block.getFieldKey ? block.getFieldKey(item) : item?.id}
                                                    className={cn('grid', styles.blockArrayItemWrap, colSize, {
                                                        /* 'col-12': !block.thin,
                                                         'col': block.thin && !block.size,
                                                         ['col-' + (block.size || 4)]: block.size,*/
                                                        [styles.thin]: block.thin,
                                                        'o-4': item.remove,
                                                    })}>
                                            {
                                                item.color &&
                                                <div className={cn('col-fixed', styles.blockArrayItemColor)}
                                                     style={{background: item.color}}/>
                                            }
                                            <div className={cn('col grid', styles.blockArrayItem,
                                                {
                                                    [styles.blockArrayItemHasColor]: !!item.color
                                                })}>
                                                <div className={cn('col-fixed', styles.arrayNumber)}>
                                                    {i + 1}
                                                </div>
                                                <div className={cn('col grid', styles.arrayData)}>
                                                    {
                                                        block.items.map(el => getElementByType(el, item))
                                                    }
                                                </div>
                                                {
                                                    block.menuItems && !hasTitle && getMenuItems({item, i})
                                                }
                                            </div>
                                        </div>
                                    });
                                }
                            }

                            function getBlockData(items) {
                                return items.map(el => {
                                    if (el.type === 'separator_col') {
                                        let className = 'col';
                                        if (el.size)
                                            className = 'col-' + el.size;

                                        return <div key={el.key}
                                                    className={cn(className, styles.separatorCol)}/>;
                                    }
                                    if (el.type === 'separator') {
                                        return <div key={el.key}
                                                    className={cn('break', styles.separator)}/>;
                                    }
                                    if (el.type === 'sections') {
                                        return <div key={el.field || el.fields?.concat('_')}
                                                    className={cn('col-' + (el.size || 4), styles.blockItem)}>
                                            <Sections fields={el.fields}
                                                      name={LANG.get(el.name)}
                                                      isDisabled={el.disabled}
                                                      store={store}
                                                      variant="standard"
                                                      width='100%'
                                                      size="medium"/>
                                        </div>;
                                    }
                                    if (el.type === 'object_table') {
                                        return <div key={el.field || el.fields?.concat('_')}
                                                    className={cn('col-' + (el.size || 12), styles.blockItem)}>
                                            <TableObject store={store} objectStore={el.store}/>
                                        </div>;
                                    }

                                    let colSize;

                                    if (!isMobile)
                                        colSize = 'col-' + (el.size || 4);
                                    else
                                        colSize = 'col-12';

                                    return <div key={el.field}
                                                className={cn(colSize, styles.blockItem)}>
                                        <MyInput type={el.type}
                                                 field={el.field}
                                                 fields={el.fields}
                                                 limitTags={el.limitTags}
                                            //fieldsTarget={el.fieldsTarget}
                                                 name={LANG.get(el.name)} isDisabled={el.disabled}
                                                 needSelectValue={el.needSelectValue}
                                                 store={store} storeField='entityEdited'
                                                 placeholder={el.placeholder}
                                                 actions={el.actions}
                                                 blockKey={block.key}
                                                 variant="standard"
                                                 width='100%'
                                                 size="medium"
                                                 onKey={(event) => {
                                                     if (el.hotkeys)
                                                         switch (event.key) {
                                                             case 'Enter':
                                                                 let hotkey = el.hotkeys.find(hc => hc.name == event.key);
                                                                 if(hotkey.onClick){
                                                                     hotkey.onClick()
                                                                     event.preventDefault()
                                                                 }
                                                                 break;
                                                         }
                                                 }}/>
                                    </div>
                                });
                            }

                            function getHeadData(items) {
                                return items.map(el => {
                                    if (el.type === 'separator_col') {
                                        let className = 'col';
                                        if (el.size)
                                            className = 'col-' + el.size;
                                        return <div key={el.key}
                                                    className={cn(className, styles.separatorCol)}/>;
                                    }
                                    if (el.type === 'separator') {
                                        return <div key={el.key}
                                                    className={cn('break', styles.separator)}/>;
                                    }
                                    if (el.type === 'sections') {
                                        return <div key={el.field || el.fields?.concat('_')}
                                                    className={cn('col-' + (el.size || 4), styles.blockHeadItem)}>
                                            <Sections fields={el.fields}
                                                      name={LANG.get(el.name)}
                                                      isDisabled={el.disabled}
                                                      store={store}
                                                      storeField='entityEdited'
                                                      onlyData={true}
                                                      width='100%'/>
                                        </div>;
                                    }
                                    if (el.type === 'button') {
                                        return <MyIconButton iconM={el.iconM} title={el.title} color="primary"
                                                             onClick={el.onClick} disabled={el.disabled}
                                                             className={cn(styles.blockButton)}
                                                             size='small'/>;
                                    }

                                    return <div key={el.field}
                                                className={cn('col-' + (el.size || 'fixed'), 'f-c-l', styles.blockHeadItem)}>
                                        <MyText type={el.type} field={el.field} fields={el.fields}
                                                name={LANG.get(el.name)} isDisabled={el.disabled}
                                                store={store} storeField='entityEdited'
                                                width='100%'/>
                                    </div>
                                });
                            }

                            if (block.isHidden)
                                return null;

                            return <div key={block.key || block.fields?.toString() || block.field}
                                        className={cn(styles.block)}>
                                {
                                    block.name && <div className={cn('grid', styles.blockHead)}>
                                        <div className={cn('col-fixed', styles.blockName, {
                                            [styles.alignTop]: block.head?.find(el => el.type === 'sections')
                                        })}>
                                            {block.name}
                                        </div>
                                        {
                                            block.allowChange &&
                                            <div key='add' className={cn('col-fixed')}>
                                                <MyIconButton iconM='add'
                                                              className={cn(styles.blockButton)}
                                                              size='small'
                                                              title={LANG.get([langPath, 'addLine'])}
                                                              onClick={() => {
                                                                  let array = store.entityEdited[block.field] || [];
                                                                  let item = store.getNewItem && store.getNewItem(block.field) || {};
                                                                  array.push(item);
                                                                  store.changeFields(['entityEdited', block.field], array);
                                                              }}/>
                                            </div>
                                        }
                                        {
                                            block.head &&
                                            <div key='blockHeadItems' className={cn('col grid', styles.blockHeadItems)}>
                                                {getHeadData(block.head)}
                                            </div>
                                        }
                                    </div>
                                }
                                <div className={cn('grid', styles.blockItems)}>
                                    {
                                        block.isArray ? getArrayData() : getBlockData(block.items)
                                    }
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
            {
                store.errors && <div className={cn(styles.lineBody)}>
                    <div className={cn('w-100 f-c', styles.wrapErrors)}>
                        {store.errors}
                    </div>
                </div>
            }
            {
                !store.isShort && store.entityEdited && <div className={cn(styles.lineControls, 'col-fixed grid')}>
                    {
                        !isMobile && <div className={cn('col')}/>
                    }
                    <div className={cn(!isMobile ? 'col-fixed' : 'col')}>
                        <Button variant="text" color="default"
                                className={cn(styles.buttonCancel)}
                                disableElevation={true} onClick={() => {
                            if (store.isModal) {
                                props.close();
                            } else {
                                MyHistory.goBack();
                            }
                        }}>
                            Назад
                        </Button>
                        {store.save && <Button variant="contained" color="primary" disabled={store.saving}
                                               className={cn(styles.buttonSave)}
                                               disableElevation={false} onClick={async () => {
                            await store.save();
                        }}>
                            {store.saving && <CircularProgress style={{marginRight: '10px'}} size={20}/>}
                            {store.entity?.id && LANG.get([langPath, 'change']) || LANG.get([langPath, 'create'])}
                        </Button>}
                        {store.buttons && store.buttons.map(el =>
                            <Button key={LANG.get(el.name)} variant="contained" color="primary" disabled={el.isBusy()}
                                    className={cn(styles.buttonSave)} title={el.title}
                                    disableElevation={false} onClick={async () => {
                                await el.onClick()
                            }}>
                                {el.isBusy() && <CircularProgress style={{marginRight: '10px'}} size={20}/>}
                                {LANG.get(el.name)}
                            </Button>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(EntityData);

export default {
    title: `अंतरिक्ष स्टेशन नियंत्रण पैनल`,
    phrase: `गुप्त वाक्य (12 शब्द)`,
    enter: `प्रवेश करें`,
    register: `रजिस्टर करें`,
    back: `वापस`,
    nickname: `उपनाम`,
    secret: `गुप्त वाक्य`,
    secretDesc: `कृपया वाक्य को सुरक्षित स्थान पर सहेजें। यह आपके खाते और जोड़ी गई स्टेशनों तक पहुँच प्रदान करता है। सेवा इस वाक्य को संग्रहित नहीं करती है।
    यदि यह खो जाता है, तो पहुँच को पुनर्प्राप्त करना संभव नहीं होगा और आपको एक नया खाता बनाना होगा और स्टेशनों को फिर से जोड़ना होगा`,
    secretCheck: `मैं सहमत हूँ कि गुप्त वाक्य खोने की स्थिति में खाते तक पहुँच पुनर्प्राप्त नहीं की जा सकती`,
    errorInitKey: `कुंजी का प्रारंभिकीकरण त्रुटि, कृपया डेटा की जांच करें`,
    registerSuccess: `सफलतापूर्वक पंजीकृत`,
};

export default {
  mainSite: `मुख्य साइट पर जाएं`,
  menu: `मेन्यू`,
  stations: `स्टेशन`,
  exit: `बाहर निकलें`,
  feedback: `प्रतिक्रिया`,
  balance: `बैलेंस`,
  sortNo: `कोई क्रमबद्धी नहीं`,
  sortDown: `अवरोही क्रमबद्धी`,
  sortUp: `आरोही क्रमबद्धी`,
  sorting: `क्रमबद्धी`,
  loadMore: `और लोड करें`,
  nothingFound: `कुछ नहीं मिला`,
  select: `चुनें`,
  refresh: `ताज़ा करें`,
  refreshData: `डेटा ताज़ा करें`,
  filter: `फ़िल्टर`,
  filterOpen: `फ़िल्टर खोलें`,
  filterClear: `फ़िल्टर साफ़ करें`,
  add: `जोड़ें`,
  downloadExcel: `Excel के रूप में डाउनलोड करें`,
  openSettings: `सेटिंग्स खोलें`,
  back: `वापस`,
  actions: `कार्रवाईयाँ`,
  noData: `कोई डेटा नहीं`,
  addLine: `लाइन जोड़ें`,
  change: `बदलें`,
  create: `बनाएँ`,
  open: `खोलें`,
  selectFromList: `सूची से चुनें`,
  loading: `लोड हो रहा है...`,
  showQR: `QR कोड दिखाएं`,
  save: `सहेजें`,
  cancel: `रद्द करें`,
  yes: `हाँ`,
  no: `नहीं`,
  close: `बंद करें`,
  make: `करें`,
  checkbox: `चयन`,
  notFound: `नहीं मिला`,
  errorUpdate: `अपडेट त्रुटि`,
  errorGetUserEmpty: `उपयोगकर्ता प्राप्त करने में त्रुटि - खाली प्रतिक्रिया`,
  dayS: `दिन`,
  successfully: `सफलतापूर्वक`,
  errorDetails: `त्रुटि का विवरण`,
  clean: `साफ़ करें`,
  apply: `लागू करें`,
  settings: 'सेटिंग्स',
  price: 'मूल्य',
};
export class StorageService {

    static prefix = 'rc_';

    static clear(): void {
        if(!localStorage)
            return;
    }

    static getItem(key: string) {
        if(!localStorage)
            return;

        let data = localStorage.getItem(this.prefix + key);
        return JSON.parse(data);
    }

    static removeItem(key: string): void {
        if(!localStorage) // for ssr
            return;
        localStorage.removeItem(this.prefix + key);
    }

    static setItem(key: string, data, args:any = {force: false, prefix: null}): void {
        if(!localStorage)
            return;

        if(data === undefined || data === null)
            this.removeItem(key);
        else
            localStorage.setItem((args.prefix || this.prefix) + key, JSON.stringify(data));
    }

    static removeCookie(name: string) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

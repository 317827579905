export default {
    title: `Panneau de contrôle des stations spatiales`,
    phrase: `Phrase secrète (12 mots)`,
    enter: `Se connecter`,
    register: `S'inscrire`,
    back: `Retour`,
    nickname: `Pseudonyme`,
    secret: `Phrase secrète`,
    secretDesc: `Veuillez conserver la phrase dans un endroit sûr. Elle permet d'accéder à votre compte et aux stations ajoutées. Le service ne stocke pas cette phrase.
    En cas de perte, il ne sera pas possible de récupérer l'accès et vous devrez créer un nouveau compte et réajouter les stations`,
    secretCheck: `J'accepte qu'en cas de perte de la phrase secrète, l'accès au compte ne pourra pas être récupéré`,
    errorInitKey: `Erreur lors de l'initialisation de la clé, veuillez vérifier les données`,
    registerSuccess: `Inscription réussie`,
};

export default {
    nickname: `ニックネーム`,
    address: `住所`,
    daysInMonth: `30日間の月に基づいて`,
    currentBalance: `現在の残高`,
    stationCount: `駅の数`,
    expensesMonth: `月の経費`,
    enoughBalance: `十分な残高`,
    currentTariff: `現在の料金プラン`,
    optionPay: `オプションの支払い`,
    stationPerMonth: `1駅ごとの月額`,
    payFirstStations: `最初の5駅の支払い`,
    notSelect: `未選択`,
    changeTariff: `料金プランの変更`,
    createTariff: `料金プランの作成`,
    fieldsCalc: `計算フィールド`,
    stations: `駅数`,
    mounths: `月数`,
    changeBalanceThis: `この金額で残高を置き換える`,
    total: `合計`,
    addBalance: `残高を追加`,
    summForAdd: `追加する金額, $`,
    pushToCopy: `クリックしてクリップボードにコピー`,
    textCopied: `テキストがクリップボードにコピーされました`,
    createBill: `請求書を作成`,
    cancelBill: `請求書をキャンセル`,
    addressToSend: `支払い先のアドレス`,
    or: `または`,
    billPartner: `パートナーでの支払いのための請求書`,
    go: `進む`,
    textBalance: `簡単な支払い手順（クレジットカードやその他の方法で）
    <ol>
    <li>このページで請求書を作成します</li>
    <li>お金を送金します
    <ul>
    <li>直接 - USDT bep20コインを保有している場合。</li>
    <!--<li>パートナーで請求書を支払う - パートナーは支払い方法に応じて手数料を請求する場合があります。</li>-->
    <li>例えば、<a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a>で選択するなどの取引所を介して（手数料なし）：
    <ol>
    <li><a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">集計ページ</a>に移動します</li>
    <li>"与える"列で適切な支払い方法を選択します。たとえば、"<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>"などです。
あなたの国に最も低い利子率（約3〜5％）であるこの列の支払い方法を選ぶことをお勧めします。通常、地元の通貨で人気のある銀行の1つです。そして、USDの直接送金ではありません。</li>
    <li>"受け取る"列 - Tether bep20である必要があります</li>
    <li>最高のレートまたは条件を持つ取引所を選択します</li>
    <li>取引所のウェブサイトで、入金額をドルで入力し、入金アドレス（上記）をコピーし、他のフィールドを入力し、金額を送金します</li>
    <li>20分間、取引所によって処理され、その後私のサービスによって処理されます</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>資金が受け取られると、このページのデータは自動的に更新されます。請求書が24時間以内に支払われない場合は、自動的にキャンセルされます。</p>
    料金は、1日の最大駅数に基づいて、日々引かれます。
    <p>何か問題が発生した場合は、<a href="{{tg}}" target="_blank" class="link">Telegram</a>で連絡してください。</p>
    <p>必要な追加機能がある場合は、<a href="{{tg_idea}}" target="_blank" class="link">プロジェクトアイデアのチャットセクション</a>でそれについて書いてください。
    アイデアは即座に実装されるか、グループメンバーによる投票にかけられ、開発についての決定が下されます。ご参加いただき、ありがとうございます！</p>`,

    textTariffCost: `このサービスでは、サービスの月額費用を自分で選択することができます。このオプションは
    また、このプロジェクトのアイデアに対する評価としても機能します。指定する金額が高ければ高いほど、このプロジェクトは良くなります。
    よりパワフルなサーバー、より少ないバグ、より使いやすいインターフェース、より速い相互作用が提供されます。
    重要なのは、駅の管理を容易にするための新機能が導入されることです。`,

    textTariffFirstStations: `あなたが自分のために無料の駅のためにさえ支払いをするほどプロジェクトをサポートしたい場合、あなたは信じられないほど素晴らしいです！`,

    textBillAmount: `自動的にあなたの支払いを識別し、あなたのアカウントと関連付けるには、事前に金額を入力し、資金を転送する際に正確に指定する必要があります。
    データベースで支払い待ちの場合、金額に小数点以下の数値を追加することもできます。`,
    textBillAddress: `請求書が作成されると自動的に生成されます。任意の支払い方法に対して提供する必要があります。
    "USDT bep20"（バイナンス）のネットワークタイプに注意してください
    - これを使用して、暗号通貨の取引所サービスにリクエストを送信する際に求められます`,
    textBillPartner: `別の方法は、パートナーでの支払いです。選択した金額の請求書が既に作成されていますので、リンクをたどって
    適切な方法でお金を転送してください。`,

    billCreated: `作成済み`,
    billPartPaid: `一部支払い`,
    billPaid: `支払い済み`,
    billCancel: `キャンセル済み`,
    billNum: `請求書番号`,
    tariffUpdated: `料金プランが更新されました`,
    billCreatedFull: `請求書が作成されました`,
    billPaidFull: `請求書が支払われました`,
    billCancelFull: `請求書がキャンセルされました`,
    errorSaveUserEmpty: `ユーザーの保存エラー - 空の応答`,
    settings: `設定`,
    tariff: `料金プランと支払い`,
    transactions: `取引`,

    type: `タイプ`,
    createdAt: `日付`,
    value: `値`,
    billString: `請求書`,
    tariffString: `料金プラン`,
    stationsPay: `駅数`,

    sortDateCreate: `作成日順`,
    transactionFilter: `取引フィルター`,
};

export default {
    nickname: `Il tuo soprannome`,
    address: `Il tuo indirizzo`,
    daysInMonth: `Basato su un mese di 30 giorni`,
    currentBalance: `Saldo attuale`,
    stationCount: `Numero di stazioni`,
    expensesMonth: `Spese al mese`,
    enoughBalance: `Saldo sufficiente`,
    currentTariff: `Tariffa attuale`,
    optionPay: `Pagamento opzionale`,
    stationPerMonth: `Per stazione al mese`,
    payFirstStations: `Paga per le prime 5 stazioni`,
    notSelect: `Non selezionato`,
    changeTariff: `Cambia tariffa`,
    createTariff: `Crea tariffa`,
    fieldsCalc: `Campi di calcolo`,
    stations: `Stazioni`,
    mounths: `Mesi`,
    changeBalanceThis: `Sostituisci saldo con questa quantità`,
    total: `Totale`,
    addBalance: `Aggiungi saldo`,
    summForAdd: `Importo da aggiungere, $`,
    pushToCopy: `Clicca per copiare negli appunti`,
    textCopied: `Testo copiato negli appunti`,
    createBill: `Crea fattura`,
    cancelBill: `Annulla fattura`,
    addressToSend: `Indirizzo per inviare il pagamento`,
    or: `o`,
    billPartner: `Fattura per il pagamento presso il partner`,
    go: `vai`,
    textBalance: `Breve istruzioni di pagamento (tramite carta di credito o altri metodi)
    <ol>
    <li>Crea una fattura su questa pagina</li>
    <li>Trasferisci denaro
    <ul>
    <li>Direttamente - se hai un e-wallet con monete USDT bep20.</li>
    <!--<li>Paga la fattura presso il partner - il partner potrebbe addebitare una commissione, a seconda del metodo di pagamento.</li>-->
    <li>Attraverso un exchange, ad esempio, selezionandolo su <a href="https://www.bestchange.com/?p=1285001" target=_blank class="link">bestchange.com</a> (senza commissioni):
    <ol>
    <li>Vai alla pagina <a href="https://www.bestchange.com/?p=1285001" target="_blank" class="link">dell'aggregatore</a></li>
    <li>Nella colonna "Da", seleziona un metodo di pagamento adatto, come "<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html?p=1285001" target=_blank class="link">Visa/Mastercard</a>". 
Ti consiglio di scegliere in questa colonna il metodo di pagamento con il tasso di interesse più basso per il tuo paese (circa 3-5%), di solito una delle banche popolari nella valuta locale, 
e non un trasferimento diretto in USD.</li>
    <li>Nella colonna "Ricevi" - dovrebbe essere Tether bep20</li>
    <li>Scegli un exchange con il miglior tasso o condizioni</li>
    <li>Sul sito dell'exchange, specifica l'importo che hai inserito per il ricarico in dollari, copia il tuo indirizzo di ricarico (sopra), compila gli altri campi e trasferisci l'importo</li>
    <li>Viene elaborato dall'exchange per 20 minuti, e poi dal mio servizio</li>
    </ol>
    </li>
    </ul>
    </li>
    </ol>
    
    <p>Dopo aver ricevuto i fondi, i dati su questa pagina saranno aggiornati automaticamente. Se la fattura non viene pagata entro 24 ore, verrà annullata automaticamente.</p>
    Il pagamento per la tariffa viene detratto giornalmente, in base al numero massimo di stazioni durante il giorno.
    <p>Se incontri qualche problema, per favore scrivimi su
    <a href="{{tg}}" target="_blank" class="link">Telegram</a>.</p>
    <p>Se hai bisogno di ulteriori funzionalità, per favore scrivile nella sezione delle
    <a href="{{tg_idea}}" target="_blank" class="link">idee di progetto</a>.
    L'idea verrà implementata immediatamente o sottoposta a votazione da parte dei membri del gruppo, e verrà presa una decisione sullo sviluppo. Grazie in anticipo per la tua partecipazione!</p>`,

    textTariffCost: `Il servizio ti permette di scegliere autonomamente il costo mensile dei servizi, questa opzione
    serve anche come valutazione dell'idea dietro a questo progetto. Più alto è l'importo che specificate, migliore diventerà questo progetto,
    con server più potenti, meno bug, un'interfaccia utente più intuitiva e interazioni più veloci.
    Inoltre, saranno introdotte nuove funzionalità per semplificare la gestione delle tue stazioni.`,

    textTariffFirstStations: `Se vuoi sostenere il progetto a tal punto da essere disposto a pagare anche per le stazioni che sono gratuite per te,
    sei incredibilmente fantastico!`,

    textBillAmount: `Per identificare automaticamente il tuo pagamento e associarlo al tuo account, devi inserire
    l'importo in anticipo e assicurarti che sia specificato esattamente al centesimo durante il trasferimento dei fondi.
    È possibile aggiungere numeri decimali all'importo se è già in attesa di pagamento nel database.`,
    textBillAddress: `Viene generato automaticamente quando viene creata la fattura. Deve essere fornito per qualsiasi metodo di pagamento,
    presta attenzione al tipo di rete "USDT bep20" (Binance)
    - ti verrà chiesto quando invii una richiesta a qualsiasi servizio di scambio di criptovalute`,
    textBillPartner: `Un'altra opzione è il pagamento presso un partner, la fattura per l'importo selezionato è già stata creata, basta seguire
    il link e trasferire i soldi nel modo più comodo.`,

    billCreated: `Creata`,
    billPartPaid: `Parzialmente pagata`,
    billPaid: `Pagata`,
    billCancel: `Annullata`,
    billNum: `Fattura n. `,
    tariffUpdated: `Tariffa aggiornata`,
    billCreatedFull: `Fattura creata`,
    billPaidFull: `Fattura pagata`,
    billCancelFull: `Fattura annullata`,
    errorSaveUserEmpty: `Errore durante il salvataggio dell'utente - risposta vuota`,
    settings: `Impostazioni`,
    tariff: `Tariffa e Pagamento`,
    transactions: `Transazioni`,

    type: `Tipo`,
    createdAt: `Data`,
    value: `Valore`,
    billString: `Fattura`,
    tariffString: `Tariffa`,
    stationsPay: `Stazioni`,

    sortDateCreate: `Per data di creazione`,
    transactionFilter: `Filtro transazioni`,
};

export default {
  mainSite: `Перейти на основной сайт`,
  menu: `Меню`,
  stations: `Станции`,
  exit: `Выйти`,
  feedback: `Обратная связь`,
  balance: `Баланс`,
  sortNo: `Без сортировки`,
  sortDown: `по убыванию`,
  sortUp: `по возрастанию`,
  sorting: `Сортировка`,
  loadMore: `Загрузить ещё`,
  nothingFound: `Ничего не найдено`,
  select: `Выбрать`,
  refresh: `Обновить`,
  refreshData: `Обновить данные`,
  filter: `Фильтр`,
  filterOpen: `Открыть фильтр`,
  filterClear: `Очистить фильтр`,
  add: `Добавить`,
  downloadExcel: `Скачать в Excel`,
  openSettings: `Открыть настройки`,
  back: `Назад`,
  actions: `Действия`,
  noData: `Нет данных`,
  addLine: `Добавить строчку`,
  change: `Изменить`,
  create: `Создать`,
  open: `Открыть`,
  selectFromList: `Выбрать из списка`,
  loading: `Загружаю...`,
  showQR: `Показать QR код`,
  save: `Сохранить`,
  cancel: `Отменить`,
  yes: `Да`,
  no: `Нет`,
  close: `Закрыть`,
  make: `Выполнить`,
  checkbox: `Выделение`,
  notFound: `Не найдено`,
  errorUpdate: `Ошибка обновления`,
  errorGetUserEmpty: `Ошибка получения пользователя - пустой ответ`,
  dayS: `дн.`,
  successfully: `Успешно`,
  errorDetails: `Детализация ошибки`,
  clean: `Очистить`,
  apply: `Применить`,
  settings: `Настройки`,
  price: `Стоимость`,
};

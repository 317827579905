import {observer} from "mobx-react";
import styles from './visualControl.module.scss';
import cn from 'classnames';
import VisualControlStore from "../../stores/station/visualControlStore";
import MyImg from "../myImg/myImg";
import React from "react";

function Cursor(props) {
    let store: VisualControlStore = props.store;

    if (!store) // fixed modal error
        return <div/>;


    return (
        <MyImg src={'cursor.png'} className={cn(styles.cursor, '')} style={{left: store.cursorPositionViewed.x, top: store.cursorPositionViewed.y}}/>
    )
}

export default observer(Cursor);
